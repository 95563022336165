import React, { FC } from 'react';
import styles from './StepWrapper.module.scss';
import Wrapper from 'components/ui/Wrapper/Wrapper';
import Box from 'components/ui/Box/Box';

interface StepWrapperProps {
  active: boolean;
  children?: React.ReactNode;
}

const StepWrapper: FC<StepWrapperProps> = ({ active, children }) => (
  <Wrapper
    className={active ? styles.active : styles.inactive}
    padding="12px 24px"
    flexDirection="column"
    sx={{ width: '100%' }}
  >
    <Box gap={12} flexDirection="row" alignItems="center">
      {children}
    </Box>
  </Wrapper>
);

export default StepWrapper;
