import React, { FC, useEffect } from 'react';
import styles from './ModalScriptDuplicate.module.scss';
import LoadingDots from 'components/ui/LoadingDots/LoadingDots';
import Modal from 'components/ui/Modal/Modal';
import Heading from 'components/ui/Heading/Heading';
import Box from 'components/ui/Box/Box';
import { ScriptMenuSuccess } from 'assets/icons/export';
import Text from 'components/ui/Text/Text';
import Button from 'components/ui/Button/Button';
import CloseSVG from 'assets/icons/CloseSVG';

interface ModalScriptDuplicateProps {
  handleCancelClick: () => void;
}

const ModalScriptDuplicate: FC<ModalScriptDuplicateProps> = ({ handleCancelClick }) => {
  return (
    <Modal className={styles.modalScriptDuplicate} sx={{ height: '260px', width: '50%' }}>
      <CloseSVG className={styles.closeIcon} onClick={handleCancelClick} />
      <Box gap={32} className={styles.modalContainer} sx={{ justifyContent: 'flex-start' }}>
        <Box gap={8}>
          <ScriptMenuSuccess />
          <Heading size="l">You already embedded the script</Heading>
          <Text size="s">You have successfully embedded the custom script.</Text>
        </Box>
        <Box className={styles.action} alignItems="flex-end">
          <Button padding="big" type="outlined" color="outline" handleClick={handleCancelClick}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalScriptDuplicate;
