import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
// @ts-ignore
import styles from './Table.module.scss';
import TableHeader from './TableHeader/TableHeader';
import TableRow from './TableRow/TableRow';
import HoverInfoComponent from './HoverInfoComponent/HoverInfoComponent';
import hubspot from '../../../assets/icons/HubspotMed';
import webflowIcon from '../../../assets/icons/WebflowM';
import marketo from '../../../assets/icons/MarketoM';
import mailchimp from '../../../assets/icons/MailchimpM';
import formstack from '../../../assets/icons/FormstackM';
import calendly from '../../../assets/icons/CalendlyM';
import typeform from '../../../assets/icons/TypeformM';
import noteform from '../../../assets/icons/NoteformM';
import convertkit from '../../../assets/icons/ConvertkitM';

interface TableProps {
  headers: string[];
  data?: any[];
  handleRowClick: any;
  dateRange?: string;
  fullName?: string[];
  cssClass?: boolean;
  clickable?: boolean;
  sortable?: boolean;
  statistics?: any;
  setFormNames?: any;
  blur?: boolean;
}

const slashesList = ['_', '-'];
const prefixMap: Record<string, any> = {
  wf: webflowIcon,
  mc: mailchimp,
  cl: calendly,
  ck: convertkit,
  tf: typeform,
  hs: hubspot,
  nf: noteform,
  fs: formstack
};

const Table: FC<TableProps> = ({
  headers,
  data,
  handleRowClick,
  dateRange,
  fullName,
  setFormNames,
  cssClass,
  clickable,
  sortable,
  statistics,
  blur = false
}) => {
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number } | undefined>(undefined);
  const [hoveredCellPosition, setHoveredCellPosition] = useState<{ top: number; left: number } | null>(null);
  const [hoveredInfo, setHoveredInfo] = useState<{ dimension: string | null; metric: string | null }>({
    dimension: null,
    metric: null
  });
  const [sortedData, setSortedData] = useState<any[] | undefined>(data);
  const getIconPaths = (fullNames: string[]): any[] => {
    if (fullNames) {
      return fullNames.map((form) => {
        for (let key in prefixMap) {
          if (form === key) return prefixMap[key];
        }
        return webflowIcon;
      });
    } else {
      return [];
    }
  };
  const sortBy = (header: string, asc: number) => {
    let newData = data;
    if (header === 'Conversions') {
      if (asc === 0) {
        newData?.sort((a, b) => b!.totalConversions - a!.totalConversions);
      } else {
        newData?.sort((a, b) => a!.totalConversions - b!.totalConversions);
      }
    }
    if (header === 'Impressions') {
      if (asc === 0) {
        newData?.sort((a, b) => b!.totalImpressions - a!.totalImpressions);
      } else {
        newData?.sort((a, b) => a!.totalImpressions - b!.totalImpressions);
      }
    }
    if (header === 'Conversion Rate') {
      if (asc === 0) {
        newData?.sort(
          (a, b) => parseFloat(b!.conversionRate.replace('%', '')) - parseFloat(a!.conversionRate.replace('%', ''))
        );
      } else {
        newData?.sort(
          (a, b) => parseFloat(a!.conversionRate.replace('%', '')) - parseFloat(b!.conversionRate.replace('%', ''))
        );
      }
    }
    setFormNames([...(newData as any[])]);
  };
  const iconPrefixList = data?.map((form) => {
    return form.icon;
  });
  const imgPaths = getIconPaths(iconPrefixList as string[]);

  const [hoveredEvent, setHoveredEvent] = useState<React.MouseEvent | null>(null);

  const handleHover = (dimension: string, metric: string, event: React.MouseEvent) => {
    setHoveredInfo({ dimension, metric });
    setHoveredEvent(event);
  };
  const handleMouseLeave = () => {
    setHoveredInfo({ dimension: null, metric: null });
    setHoveredEvent(null);
  };
  return (
    <div className={styles.Table}>
      <table className={styles.Table}>
        <thead>
          <TableHeader metrics={headers} sortable={sortable} sortBy={sortBy} statistics={statistics}></TableHeader>
        </thead>
        <tbody>
          {data?.map((value: any, index: any) => {
            const metrics: any[] = [];

            for (let key in value) metrics.push(value[key]);
            return (
              <TableRow
                key={metrics[6]}
                tableText={clickable ? metrics[1] : metrics[0]}
                metricValues={clickable ? metrics.slice(2, 6) : metrics.slice(1)}
                handleRowClick={handleRowClick}
                index={clickable ? metrics[6] + 1 : index + 1}
                onHover={handleHover}
                onMouseLeave={handleMouseLeave}
                headers={headers} // Pass headers to TableRow
                ImgSrc={imgPaths[index] || ''}
                cssClass={cssClass}
                clickable={clickable}
                blur={blur}
              />
            );
          })}
        </tbody>
      </table>
      {hoveredInfo.dimension !== null && hoveredInfo.metric !== null && hoveredEvent !== null && (
        <HoverInfoComponent
          date={dateRange as string}
          dimension={hoveredInfo.dimension}
          metric={hoveredInfo.metric}
          event={hoveredEvent} // Pass the entire event object
        />
      )}
    </div>
  );
};

export default Table;
