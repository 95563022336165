import { SignUpPayload } from 'api/auth/types';

import { SignUpForm } from 'components/shared/auth/sign-up-step/SignUpForm';
import SignUpCoverImg from 'assets/images/SignUpCoverImg.png';
import { LogoIcon } from 'assets/icons/export';

interface Props {
  setPayload: React.Dispatch<React.SetStateAction<SignUpPayload>>;
}

export const SignUpStep = ({ setPayload }: Props) => (
  <div className="signUpPage">
    <div className="left">
      <LogoIcon />
      <SignUpForm />
    </div>
    <div className="right">
      <img src={SignUpCoverImg} alt="DataGoat" className="coverPhoto" />
    </div>
  </div>
);
