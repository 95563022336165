import React, { FC, ReactNode } from 'react';
import styles from './ProgressBar.module.scss';

interface ProgressBarProps {
  progressWidth: number;
  position?: 'flex-start';
  text: string;
  sx?: React.CSSProperties;
}

const ProgressBar: FC<ProgressBarProps> = ({ progressWidth, position = 'flex-start', text, sx }) => (
  <div className={styles.progressBarContainer} style={{ alignItems: position }}>
    <div className={styles.progressBarTrack}>
      <div className={styles.progressBar} style={{ width: progressWidth + '%' }}></div>
    </div>
    <div className={styles.progressBarText}>{text}</div>
  </div>
);

export default ProgressBar;
