import * as React from 'react';
import type { SVGProps } from 'react';

const SvgAnalyticsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#C6D7CD"
      d="m12.19 7.502-1.55-4.497a.501.501 0 0 0-.93-.043l-3.816 8.513L4.524 7.5h-3.19v1H3.81l1.55 4.496a.5.5 0 0 0 .45.337h.024a.5.5 0 0 0 .456-.297l3.817-8.51 1.37 3.977h3.19v-1z"
    />
  </svg>
);
export default SvgAnalyticsIcon;
