import React, { FC, SVGProps } from 'react';
import styles from './List.module.scss';
import Container from '../Container/Container';
import Wrapper from '../Wrapper/Wrapper';
import Text from '../Text/Text';
import Box from '../Box/Box';
import ComingSoon from 'components/ComingSoon/ComingSoon';

interface ListProps {
  ListDecoration: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  list: string[];
  comingSoon?: boolean[];
  sx?: React.CSSProperties;
}

const List: FC<ListProps> = ({ ListDecoration, list, comingSoon, sx }) => (
  <Container flexDirection="column" sx={sx}>
    {list.map((item, index) => (
      <Wrapper className={styles.listItem} gap={24} padding="12px 0px" justifyContent="space-between">
        <Box flexDirection="row" alignItems="center" gap={24}>
          {<ListDecoration />}{' '}
          <Text size="s" color="primary-100">
            {item}
          </Text>
        </Box>
        {comingSoon?.[index] && (
          <Box>
            <ComingSoon />{' '}
          </Box>
        )}
      </Wrapper>
    ))}
  </Container>
);

export default List;
