import { DefaultCardIcon, MasterCardIcon, VisaIcon } from '../assets/icons/export';

export const getCardIcon = (code: string) => {
  switch (code) {
    case 'visa':
      return <VisaIcon />;
    case 'mastercard':
      return <MasterCardIcon />;
    default:
      return <DefaultCardIcon />;
  }
};
