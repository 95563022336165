import React, { FC } from 'react';
import styles from './Link.module.scss';

interface LinkProps {
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
}

const Link: FC<LinkProps> = ({ onClick, children, className }) => {
  const classRecord = `${className} ${styles.Link}`;
  return (
    <div className={classRecord} onClick={onClick}>
      {children}
    </div>
  );
};

export default Link;
