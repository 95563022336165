import { LogoIcon } from 'assets/icons/export';
import { Modal } from 'components/ui/Modal';
import GoatImg from 'assets/images/Goat-V3.png';
import { useMutation } from 'react-query';
import { undeleteAccount } from 'api/users';
import { queryClient } from 'index';

interface Props {
  className?: string;
  close: () => void;
}

export const ReActivateAccountModal = ({ close, className }: Props) => {
  const { mutate, isLoading } = useMutation(undeleteAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries('user');
      window.location.reload();
    }
  });

  const reActivateAccount = () => {
    mutate();
  };

  return (
    <Modal close={close} className={className} closeHidden={true}>
      <div className="modalWrapper">
        <LogoIcon className="logo" />
        <div className="goatImg">
          <img src={GoatImg} alt="" />
        </div>
        <div className="modalInfo">
          <h3>Do you want to recover your account?</h3>
          <p>We've paused your account for now, please Re-activate it to continue growing your agency</p>
          <button className="primary" onClick={reActivateAccount}>
            Recover account
          </button>
        </div>
      </div>
    </Modal>
  );
};
