import type { SVGProps } from 'react';
import * as React from 'react';

const SvgLoadArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <g clipPath="url(#load_arrow_svg__a)">
      <path
        stroke="#C0E666"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m5.334 6.664 2.667 2.667 2.666-2.667"
      />
    </g>
    <defs>
      <clipPath id="load_arrow_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLoadArrow;
