import { RunReportPayload } from 'api/analytics/types';

export const getPayloadLinkClick = (propertyId: string, startDate: string, endDate: string): RunReportPayload => {
  return {
    propertyId: `${propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'eventName' }],
    metrics: [{ name: 'eventCount' }, { name: 'totalUsers' }],
    dimensionFilter: {
      filter: {
        fieldName: 'eventName',
        stringFilter: {
          matchType: 'BEGINS_WITH',
          value: 'dg-click_'
        }
      }
    }
  };
};

export const getPayloadOutbound = (propertyId: string, startDate: string, endDate: string): RunReportPayload => {
  return {
    propertyId: `${propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'linkDomain' }],
    metrics: [{ name: 'totalUsers' }]
  };
};

export const getPayloadMedium = (propertyId: string, startDate: string, endDate: string): RunReportPayload => {
  return {
    propertyId: `${propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'firstUserSource' }],
    metrics: [{ name: 'sessions' }, { name: 'totalUsers' }]
  };
};

export const getPayloadCountry = (propertyId: string, startDate: string, endDate: string): RunReportPayload => {
  return {
    propertyId: `${propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'country' }],
    metrics: [{ name: 'totalUsers' }]
  };
};

export const getPayloadPageRanking = (propertyId: string, startDate: string, endDate: string): RunReportPayload => {
  return {
    propertyId: `${propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'pagePath' }],
    metrics: [{ name: 'totalUsers' }]
  };
};

export const getPayloadWebsiteChannel = (propertyId: string, startDate: string, endDate: string): RunReportPayload => {
  return {
    propertyId: `${propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'firstUserDefaultChannelGroup' }],
    metrics: [{ name: 'sessions' }, { name: 'totalUsers' }]
  };
};
