import React, { useEffect, useRef, useState } from 'react';

import { getReportAnalytics } from 'api/analytics';
import { RunReportPayload } from 'api/analytics/types';
import { Website } from 'api/users/types';

interface TopSourcesData {
  sourceMedia: string;
  activeUsers: number;
  conversions: number;
  conversionRate: string;
}

export const fetchSources = async (
  formName: string,
  website: Website,
  startDate: string,
  endDate: string,
  cancelToken: any
) => {
  let payloadSourceImpressions: RunReportPayload = {
    propertyId: `${website!.propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'sessionSource' }],
    metrics: [{ name: 'activeUsers' }],
    dimensionFilter: {
      filter: {
        fieldName: 'eventName',
        stringFilter: {
          matchType: 'EXACT',
          value: `${formName?.replace('dg-submit', 'dg-form-view').slice(0, 40)}`
        }
      }
    }
  };

  let payloadTotalConversions: RunReportPayload = {
    propertyId: `${website?.propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'sessionSource' }],
    metrics: [{ name: 'eventCount' }],
    dimensionFilter: {
      filter: {
        fieldName: 'eventName',
        stringFilter: {
          matchType: 'EXACT',
          value: `${formName?.slice(0, 40)}`
        }
      }
    }
  };

  try {
    cancelToken.sources = new AbortController();
    const signal = cancelToken.sources.signal;
    const activeUsersPromise = getReportAnalytics(payloadSourceImpressions, signal);
    let totalConversionsData: any;

    totalConversionsData = await getReportAnalytics(payloadTotalConversions, signal);

    const activeUsersData = await activeUsersPromise;

    let topSources = activeUsersData
      ?.map((userData) => {
        const source = userData.dimensionValues?.[0]?.value || '';
        const activeUsers = parseFloat(userData.metricValues?.[0]?.value) || 0;
        const conversionData = totalConversionsData.find(
          (conversionDataItem: any) => conversionDataItem.dimensionValues?.[0]?.value == source
        );
        const totalConversions = conversionData ? parseFloat(conversionData.metricValues?.[0]?.value) || 0 : 0;
        const conversionRate = activeUsers > 0 ? ((totalConversions / activeUsers) * 100).toFixed(0) + '%' : '0%';

        return {
          sourceMedia: source,
          activeUsers: activeUsers,
          conversions: totalConversions,
          conversionRate: conversionRate
        };
      })
      .sort((a, b) => b.conversions - a.conversions)
      .slice(0, 5);

    return new Promise((resolve) => {
      resolve(topSources);
    });
  } catch (e) {
    return new Promise((reject) => reject(undefined));
  }
};
