import React, { FC } from 'react';
// @ts-ignore
import styles from './TableRow.module.scss';
import Wrapper from 'components/ui/Wrapper/Wrapper';
import { ModalType, useModalStore } from 'store/modalStore';

interface TableRowProps {
  tableText: string;
  ImgSrc?: any;
  index: number;
  metricValues: string[];
  handleRowClick: any;
  onHover: (dimension: string, metric: string, event: React.MouseEvent) => void; // Update the signature
  headers: string[];
  onMouseLeave?: () => void;
  cssClass?: boolean;
  clickable?: boolean;
  blur?: boolean;
}

const TableRow: FC<TableRowProps> = ({
  tableText,
  ImgSrc,
  index,
  metricValues,
  handleRowClick,
  onHover,
  headers,
  onMouseLeave,
  cssClass,
  clickable,
  blur = false
}) => {
  const { setModal } = useModalStore();
  return (
    <tr
      className={`${styles.TableRow} ${clickable && !blur ? styles.Clickable : ''}`}
      onMouseLeave={onMouseLeave}
      onClick={() => {
        if (!blur) {
          handleRowClick(index);
        } else {
          setModal(ModalType.freeTrialStart);
        }
      }}
    >
      <td className={styles.dataText}>
        <div className={styles.dataTextSpan}>
          {clickable ? (
            <ImgSrc className={styles.Img} />
          ) : (
            <div className={styles.TableEnumeration}>{index}.&nbsp;</div>
          )}
          <span className={`${cssClass ? styles.TableTextOverflow : ''} ${styles.TableText}`}>
            <span onMouseEnter={(event) => onHover('Links', tableText, event)}>{tableText}</span>
          </span>
        </div>
      </td>
      {metricValues?.map((value, columnIndex) => (
        <td className={styles.dataMetrics} key={columnIndex} style={blur ? { position: 'relative' } : {}}>
          {blur && (
            <Wrapper
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backdropFilter: 'blur(5px)',
                top: '0',
                left: '0'
              }}
            ></Wrapper>
          )}
          <span onMouseEnter={(event) => onHover(headers[columnIndex], value, event)}>{value}</span>
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
