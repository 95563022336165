import React, { FC } from 'react';
import styles from './LoadingSpinner.module.scss';

interface LoadingSpinnerProps {}

const LoadingSpinner: FC<LoadingSpinnerProps> = () => (
  <div className={styles.LoadingSpinner}>
    <div className={styles.loadingCell}></div>
    <div className={styles.loadingCell}></div>
    <div className={styles.loadingCell}></div>
    <div className={styles.loadingCell}></div>
    <div className={styles.loadingCell}></div>
    <div className={styles.loadingCell}></div>
    <div className={styles.loadingCell}></div>
    <div className={styles.loadingCell}></div>
  </div>
);

export default LoadingSpinner;
