import React, { Dispatch, FC, SetStateAction } from 'react';
// @ts-ignore
import styles from './TableRow.module.scss';

interface TableRowProps {
  tableText: string;
  ImgSrc?: any;
  index: number;
  metricValues: string[];
  handleRowClick: any;
  onHover: (dimension: string, metric: string, event: React.MouseEvent) => void; // Update the signature
  headers: string[];
  onMouseLeave?: () => void;
  cssClass?: boolean;
  clickable?: boolean;
}

const TableRow: FC<TableRowProps> = ({
  tableText,
  ImgSrc,
  index,
  metricValues,
  handleRowClick,
  onHover,
  headers,
  onMouseLeave,
  cssClass,
  clickable
}) => (
  <tr
    className={`${styles.TableRow} ${clickable ? styles.Clickable : ''}`}
    onMouseLeave={onMouseLeave}
    onClick={() => {
      handleRowClick(index);
    }}
  >
    <td className={styles.dataText}>
      <div className={styles.dataTextSpan}>
        <span className={`${cssClass ? styles.TableTextOverflow : ''} ${styles.TableText}`}>
          <span onMouseEnter={(event) => onHover('Page', tableText, event)}>{tableText}</span>
        </span>
      </div>
    </td>
    {metricValues?.map((value, columnIndex) => (
      <td className={styles.dataMetrics} key={columnIndex}>
        <span onMouseEnter={(event) => onHover(headers[columnIndex], value, event)}>{value}</span>
      </td>
    ))}
  </tr>
);

export default TableRow;
