import * as React from 'react';
import type { SVGProps } from 'react';

const SvgAscendingActive = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" {...props}>
    <path
      fill="#C0E666"
      d="M.667 10h2.666C3.7 10 4 9.7 4 9.333c0-.366-.3-.666-.667-.666H.667c-.367 0-.667.3-.667.666S.3 10 .667 10M0 2.667c0 .366.3.666.667.666h10.666c.367 0 .667-.3.667-.666S11.7 2 11.333 2H.667C.3 2 0 2.3 0 2.667m.667 4h6.666C7.7 6.667 8 6.367 8 6s-.3-.667-.667-.667H.667C.3 5.333 0 5.633 0 6s.3.667.667.667"
    />
  </svg>
);
export default SvgAscendingActive;
