import { create } from 'zustand';

interface State {
  loadingPage: boolean;
  setLoadingPage: (loadingPage: boolean) => void;
}

const useLoadingStore = create<State>((set) => ({
  loadingPage: true,
  setLoadingPage: (loadingPage) =>
    set({
      loadingPage: loadingPage
    })
}));

export { useLoadingStore };
