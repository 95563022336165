import React, { FC } from 'react';
import styles from './Insights.module.scss';
import Container from 'components/ui/Container/Container';
import OverlayComingSoon from 'components/Overlay/ComingSoon/ComingSoon';
import Menu from '../Menu/Menu';

interface InsightsProps {}

const Insights: FC<InsightsProps> = () => (
  <>
    <Menu />
    <Container sx={{ width: '100%', height: '100%' }} className={styles.Insights}>
      <OverlayComingSoon />
    </Container>
  </>
);

export default Insights;
