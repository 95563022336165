import React, { FC, useState } from 'react';
import styles from './ModalGroupScript.module.scss';
import { ModalType, useModalStore } from 'store/modalStore';
import ModalUsStepOne from './ModalUsStepOne/ModalUsStepOne';
import ModalScriptEmbedding from './ModalScriptEmbeding/ModalScriptEmbedding';
import ModalScriptSuccess from './ModalScriptSuccess/ModalScriptSuccess';
import ModalScriptDuplicate from 'components/modal/ModalGroupScript/ModalScriptDuplicate/ModalScriptDuplicate';
import ModalServerError from 'components/modal/ModalGroupScript/ModalServerError/ModalServerError';
import ModalUsPopUp from './ModalUsPopUp/ModalUsPopUp';
import { useAuthStore } from 'store/auth';
import { editUser } from 'api/users';

interface ModalGroupScriptProps {
  modalType: ModalType;
  modalTypeInGroup: ModalScriptType;
}

export enum ModalScriptType {
  none = 'none',
  popup = 'popup',
  generate = 'generate',
  embedding = 'embedding',
  success = 'success',
  scriptDuplicate = 'script_duplicate',
  serverError = 'server_error'
}

const ModalGroupScript: FC<ModalGroupScriptProps> = ({ modalType, modalTypeInGroup }) => {
  const { user } = useAuthStore();
  const { setModal, modalData, setModalTypeInGroup } = useModalStore();
  const [showModal, setShowModal] = useState<ModalScriptType>(modalTypeInGroup);
  const [modalScriptData, setModalScriptData] = useState(modalData);
  const handleCancelClick = () => {
    setModal(ModalType.none);
    setShowModal(ModalScriptType.generate);
  };

  const modalMap: Record<ModalScriptType, any> = {
    none: <></>,
    popup: (
      <ModalUsPopUp
        handleCancelClick={() => {
          setModal(ModalType.none);
          setModalTypeInGroup(ModalScriptType.generate);
          if (user) {
            user.firstLogin = false;
            editUser(user);
          }
        }}
        handleStartClick={() => {
          setModalTypeInGroup(ModalScriptType.generate);
          if (user) {
            user.firstLogin = false;
            editUser(user);
          }
        }}
      />
    ),
    generate: (
      <ModalUsStepOne
        handleButtonClick={() => {
          setModalTypeInGroup(ModalScriptType.embedding);
        }}
        handleCancelClick={handleCancelClick}
      />
    ),
    embedding: (
      <ModalScriptEmbedding
        propertyId={modalData.propertyId}
        url={modalData.url}
        handleSuccess={() => {
          setModalTypeInGroup(ModalScriptType.success);
          if (user) {
            user.addedNewScript = true;
            editUser(user);
          }
        }}
        handleScriptDuplicate={() => {
          setModalTypeInGroup(ModalScriptType.scriptDuplicate);
        }}
        handleServerError={() => {
          setModalTypeInGroup(ModalScriptType.serverError);
        }}
        handleCancelClick={handleCancelClick}
      />
    ),
    script_duplicate: <ModalScriptDuplicate handleCancelClick={handleCancelClick} />,
    server_error: (
      <ModalServerError
        handleTryAgainClick={() => {
          setModalTypeInGroup(ModalScriptType.embedding);
        }}
        handleCancelClick={handleCancelClick}
      />
    ),
    success: (
      <ModalScriptSuccess
        handleCancelClick={handleCancelClick}
        handleButtonClick={() => {
          window.open(`https://webflow.com/dashboard/sites/${modalData.shortName}/code`);
        }}
      />
    )
  };
  return (
    <div className={modalType === 'script' && modalTypeInGroup !== 'none' ? styles.show : styles.hide}>
      {modalMap[modalTypeInGroup]}
    </div>
  );
};

export default ModalGroupScript;
