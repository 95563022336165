import React, { CSSProperties, FC, HTMLAttributes, ReactNode } from 'react';
import styles from './Modal.module.scss';

interface ModalProps {
  children?: ReactNode;
  className?: string;
  sx?: CSSProperties;
}

const Modal: FC<ModalProps> = ({ children, className, sx }) => {
  const classRecord = `${styles.background} ${styles.modalLuna} ${className} `;

  return (
    <div className={styles.modalOverlay}>
      <div className={classRecord} style={sx}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
