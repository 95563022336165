import { ChartPayloadProps } from 'api/previewLink/types';
import { useAnalyticsData } from 'hooks/useAnalyticsData';
import { formatMinutesAndSeconds } from 'utils';

export const SessionsChart = ({ payload }: ChartPayloadProps) => {
  const { data }: any = useAnalyticsData(payload);

  return (
    <div className="chartPaper sessions">
      <h2 className="typo-heading-s">Sessions</h2>
      <div className="row">
        <div className="item">
          <p className="typo-body-xxs">Avg. engagement time</p>
          {data && <span className="typo-label-l">{formatMinutesAndSeconds(data[0]?.metricValues[0]?.value)}</span>}
        </div>
        <div className="item">
          <p className="typo-body-xxs">Engaged sessions per user</p>
          {data && (
            <span className="typo-label-l">{parseFloat(data[0]?.metricValues[1]?.value || '0').toFixed(2)}</span>
          )}
        </div>
      </div>
    </div>
  );
};
