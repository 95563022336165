import React, { FC, useEffect, useState } from 'react';
import styles from './PaymentCard.module.scss';
import Wrapper from 'components/ui/Wrapper/Wrapper';
import Box from 'components/ui/Box/Box';
import PlanLabel from './PlanLabel/PlanLabel';
import Heading from 'components/ui/Heading/Heading';
import List from 'components/ui/List/List';
import { StarPayment } from 'assets/icons/export';
import Button from 'components/ui/Button/Button';
import Container from 'components/ui/Container/Container';
import Label from 'components/ui/Label/Label';
import { useAuthStore } from 'store/auth';
import Text from 'components/ui/Text/Text';
import { ModalType, useModalStore } from 'store/modalStore';

interface PaymentCardProps {
  plan: string;
  price: string;
  features: string[];
  comingSoon?: boolean[];
  active: boolean;
  level: number;
  handleUpgrade: () => void;
  handleStartFreeTrial?: () => void;
  freePlanPriceId?: string;
  planDate?: string;
}

const PaymentCard: FC<PaymentCardProps> = ({
  plan,
  price,
  features,
  comingSoon,
  active,
  level,
  handleUpgrade,
  handleStartFreeTrial,
  freePlanPriceId,
  planDate
}) => {
  const { user } = useAuthStore();
  const [buttonText, setButtonText] = useState('');
  const { setModal, setModalData } = useModalStore();

  useEffect(() => {
    if (!user?.trialUsed && price !== 'Free') setButtonText('Start free trial');
    else if (user?.subscription.status === 'trialing' && active) setButtonText('End free trial');
    else if (active) setButtonText('Current');
    else {
      if (user?.tier.level > level) setButtonText('Downgrade');
      if (user?.tier.level < level) setButtonText('Upgrade');
      if (user?.tier.level === level) setButtonText('Change');
    }
  }, [active]);

  return (
    <Wrapper
      className={active ? styles.paymentCardActive : styles.paymentCard}
      padding="24px"
      borderRadius="16px"
      flexDirection="column"
      justifyContent="space-between"
      sx={{ minHeight: plan !== 'Starter' && !user?.trialUsed ? '700px' : '750px', position: 'relative' }}
    >
      {active && (
        <Container className={styles.current} justifyContent="center">
          <Wrapper padding="4px 35px" borderRadius="12px 12px 0px 0px" className={styles.planWrapper}>
            <Label size="s" className={styles.planText}>
              {user?.subscription.status === 'trialing' ? 'Free trial' : 'Current plan'}
            </Label>
          </Wrapper>
        </Container>
      )}
      <Box gap={plan === 'Starter' ? 68 : 32}>
        <Box gap={16} alignItems="flex-start">
          <PlanLabel>{plan}</PlanLabel>

          <Heading size="xs" color="neutral-100">
            {price}
          </Heading>
        </Box>
        <Box flexDirection="column" gap={12}>
          {plan === 'Startup' && (
            <Text size="xs" color="primary-100">
              Everything from Starter, plus
            </Text>
          )}
          {plan === 'Business' && (
            <Text size="xs" color="primary-100">
              Everything from Startup, plus
            </Text>
          )}

          <List ListDecoration={StarPayment} list={features} comingSoon={comingSoon} />
        </Box>
      </Box>
      <Box gap={16}>
        {/*{plan !== 'Starter' && !user?.trialUsed && (*/}
        {/*  <Box*/}
        {/*    onClick={() => {*/}
        {/*      handleStartFreeTrial!();*/}
        {/*    }}*/}
        {/*    alignItems="center"*/}
        {/*    sx={{ cursor: 'pointer' }}*/}
        {/*  >*/}
        {/*    <Label size="m" color="white">*/}
        {/*      Start a free trial*/}
        {/*    </Label>*/}
        {/*  </Box>*/}
        {/*)}*/}
        {/*{active && user?.subscription.status === 'trialing' && (*/}
        {/*  <Box*/}
        {/*    onClick={() => {*/}
        {/*      setModal(ModalType.freeTrialManualEndWithoutUpdate);*/}
        {/*      setModalData({ priceId: freePlanPriceId });*/}
        {/*    }}*/}
        {/*    alignItems="center"*/}
        {/*    sx={{ cursor: 'pointer' }}*/}
        {/*  >*/}
        {/*    <Label size="m" color="white">*/}
        {/*      End free trial*/}
        {/*    </Label>*/}
        {/*  </Box>*/}
        {/*)}*/}
        <Button
          type={'outlined'}
          disabled={active && user?.subscription.status !== 'trialing'}
          color="white"
          handleClick={() => {
            if (!active) handleUpgrade();
            else {
              if (user?.subscription.status === 'trialing') handleUpgrade();
            }
          }}
        >
          {buttonText}
        </Button>
      </Box>
    </Wrapper>
  );
};

export default PaymentCard;
