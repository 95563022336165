const CopyLinkSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      fill="#A1B8B4"
      fillRule="evenodd"
      d="M10.326 13.508l-.795.796a4.127 4.127 0 01-5.834 0 4.127 4.127 0 010-5.834l.796-.795.795.795-.795.796a3.004 3.004 0 000 4.242 3.004 3.004 0 004.242 0l.796-.795.795.796zm3.978-3.977l-.796.796-.795-.796.795-.795a3.004 3.004 0 000-4.243 3.004 3.004 0 00-4.242 0l-.796.795-.795-.795.795-.796a4.127 4.127 0 015.834 0 4.127 4.127 0 010 5.834zM5.95 11.255l5.304-5.304.795.796-5.303 5.303-.796-.795z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default CopyLinkSVG;
