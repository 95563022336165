import React, { FC } from 'react';
import styles from './FreeTrial.module.scss';
import Modal from 'components/ui/Modal/Modal';
import Heading from 'components/ui/Heading/Heading';
import Box from 'components/ui/Box/Box';
import { StarPayment } from 'assets/icons/export';
import Text from 'components/ui/Text/Text';
import Button from 'components/ui/Button/Button';
import CloseSVG from 'assets/icons/CloseSVG';
import { ModalType, useModalStore } from 'store/modalStore';
import Container from 'components/ui/Container/Container';

import GoatBadge from '../../../assets/images/datagoat_badge.png';
import List from 'components/ui/List/List';
import Link from 'components/ui/Link/Link';
import { useNavigate } from 'react-router-dom';
import Wrapper from 'components/ui/Wrapper/Wrapper';
import { createSubscription, fetchOrderProducts } from 'api/orders';

interface FreeTrialProps {}

const FreeTrial: FC<FreeTrialProps> = () => {
  const { setModal } = useModalStore();
  const navigate = useNavigate();
  return (
    <Modal sx={{ height: 'auto', width: '50em', justifyContent: 'center' }}>
      <CloseSVG
        className={styles.closeIcon}
        onClick={() => {
          setModal(ModalType.none);
        }}
      />
      <Container gap={32} flexDirection="column" alignItems="center" justifyContent="center" sx={{ flex: 1 }}>
        <img src={GoatBadge} alt="?" />
        <Box gap={8} alignItems="center" sx={{ width: '100%' }}>
          <Box gap={24} alignItems="center" sx={{ width: '100%' }}>
            <Box gap={12} alignItems="center">
              <Heading size="m">Start a free trial</Heading>
              <Text size="xs">Try out our pro features (Business plan) for free for 7 days.</Text>
            </Box>
            <Box flexDirection="row" gap={12} sx={{ width: '100%' }}>
              <List
                ListDecoration={StarPayment}
                list={['Track up to 10 sites', 'Embedded form tracking', 'Weekly Email summaries']}
                sx={{ flex: 1 }}
              />
              <List
                ListDecoration={StarPayment}
                list={['Post tracking', 'Track additional websites', 'Share analytics link']}
                sx={{ flex: 1 }}
              />
            </Box>
            <Button
              handleClick={() => {
                navigate('subscription');
                setModal(ModalType.none);
              }}
            >
              Get Started
            </Button>
          </Box>
          <Wrapper padding="12px 24px">
            <Link
              onClick={() => {
                setModal(ModalType.none);
                navigate('subscription');
              }}
            >
              Choose plan
            </Link>
          </Wrapper>
        </Box>
      </Container>
    </Modal>
  );
};

export default FreeTrial;
