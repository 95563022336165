import type { SVGProps } from 'react';
import * as React from 'react';

const SvgNotion = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" {...props}>
    <rect width={12} height={12} fill="#fff" rx={6} />
    <g clipPath="url(#notion_svg__a)">
      <path
        fill="#000"
        d="M6.605 2.223c.442 0 .85.148 1.177.397l.003-.002.022.02q.157.124.285.276l.513.497q.611.698.716 1.011c.096.23.15.483.15.748v2.66c0 1.075-.871 1.945-1.945 1.945h-2.66a1.94 1.94 0 0 1-1.452-.65l-.565-.536a5.6 5.6 0 0 1-.55-.724 2 2 0 0 1-.073-.126 1.5 1.5 0 0 1-.15-.368A2 2 0 0 1 2 6.828v-2.66c0-1.075.87-1.945 1.944-1.945z"
      />
      <path
        fill="#fff"
        d="M7.772 4.043h-3.07a.92.92 0 0 0-.922.921v3.07c0 .509.413.921.921.921h3.07a.92.92 0 0 0 .922-.92V4.963a.92.92 0 0 0-.921-.921"
      />
      <path
        fill="#000"
        d="M6.438 6.566v.81q0 .203.07.285.07.078.291.078h.062v.243H5.263V7.74h.044q.221 0 .286-.08.068-.084.068-.283v-1.76q0-.203-.07-.282-.068-.08-.284-.08h-.053v-.24h2.489l.02.786h-.248a1.1 1.1 0 0 0-.14-.326.4.4 0 0 0-.177-.162.8.8 0 0 0-.28-.037h-.48v1.024h.235q.165 0 .23-.07.065-.072.08-.293h.245v1.02h-.245q-.01-.239-.076-.313-.066-.077-.239-.077z"
      />
    </g>
    <defs>
      <clipPath id="notion_svg__a">
        <path fill="#fff" d="M2 2.225h7.471v7.553H2z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNotion;
