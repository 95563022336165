import { PieChart, Pie, Cell, Tooltip } from 'recharts';

import { ChartPayloadProps } from 'api/previewLink/types';

import { useAnalyticsData } from 'hooks/useAnalyticsData';
import dayjs from 'dayjs';

const COLORS = [
  { key: 'desktop', color: '#134c54' },
  { key: 'tablet', color: '#c0e666' },
  { key: 'mobile', color: '#dbf0a3' }
];

export const DeviceChart = ({ payload }: ChartPayloadProps) => {
  const { data } = useAnalyticsData(payload);

  const formated = data?.map(({ dimensionValues, metricValues }: any) => ({
    name: dimensionValues[0]?.value || '',
    value: +metricValues[0]?.value || 0
  }));

  return (
    <div className="chartPaper device">
      <div className="left">
        <h2 className="typo-heading-s">Device</h2>
        <div className="sizes">
          {formated
            ?.sort((a: any, b: any) => b.value - a.value)
            .map(({ name: objName }: any, index: number) => (
              <div className="item" key={index}>
                <div className="box" style={{ background: COLORS.find((el) => el.key === objName)?.color }} />
                <p className="typo-label-s">
                  <span> {objName}</span> <span> - </span>{' '}
                  <span>{formated.find(({ name }: any) => name === objName)?.value}</span>
                </p>
              </div>
            ))}
        </div>
      </div>
      <div className="chart">
        {formated && (
          <PieChart width={144} height={144}>
            <Pie
              data={formated}
              cx={68}
              cy={67}
              innerRadius={50}
              outerRadius={70}
              fill="#8884d8"
              dataKey="value"
              stroke="none"
              paddingAngle={-1}
            >
              {formated?.map((_: any, index: any) => (
                <Cell key={`cell-${index}`} fill={COLORS.find(({ key }) => key === _.name)?.color} />
              ))}
            </Pie>
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  return (
                    <div className="device-chart-tooltip">
                      <p className="typo-body-xxs">
                        {dayjs().subtract(7, 'day').format('MMM DD')} -{' '}
                        {dayjs().subtract(1, 'day').format('MMM DD, YYYY')}
                      </p>
                      <div className="row">
                        <span className="typo-body-xs">Users</span>
                        <span className="typo-label-m">{payload[0].value}</span>
                      </div>
                    </div>
                  );
                }

                return null;
              }}
            />
          </PieChart>
        )}
      </div>
    </div>
  );
};
