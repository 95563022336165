import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import styles from './SideNavbar.module.scss';
import Wrapper from 'components/ui/Wrapper/Wrapper';
import Container from 'components/ui/Container/Container';
import Box from 'components/ui/Box/Box';
import SideNavbarItem from './Item/Item';
import { AnalyticsIcon, DgLogo, ExitIcon, InsightsIcon, SettingsIcon } from 'assets/icons/export';
import { useAuthStore } from 'store/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { SignOutModal } from 'components/shared/SignOutModal';
import { useAnalyticsStore } from 'store/AnalyticsStore';
import { getUserWebsites, trackWebsiteAnalytics } from 'api/users';
import ProjectMenu from './ProjectMenu/ProjectMenu';
import Label from 'components/ui/Label/Label';
import Dropdown from './Dropdown/Dropdown';
import { ModalType, useModalStore } from 'store/modalStore';

interface SideNavbarProps {}

type Link = 'analytics' | 'insights' | 'settings' | 'log_out';

const pathnameMap: any = {
  '/': 'analytics',
  '/insights': 'insights',
  '/subscription': 'settings',
  '/my-projects': 'settings',
  '/account-settings': 'settings'
};

const SideNavbar: FC<SideNavbarProps> = () => {
  const { user } = useAuthStore();
  const { pathname } = useLocation();
  const { website, setWebsite } = useAnalyticsStore();
  const [isOpenSignOutModal, setIsOpenSignOutModal] = useState(false);
  const [active, setActive] = useState<Link>(pathnameMap[pathname]);
  const [websiteOptions, setWebsiteOptions] = useState<any>();
  const [showProjectMenu, setShowProjectMenu] = useState<any>();
  const [dropdownActive, setDropdownActive] = useState<boolean>(false);
  const { setModal } = useModalStore();
  const projectMenuRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  
  let currentDate = new Date();
  let pastDateTime = new Date(user?.subscription?.currentPeriodEnd.toString() || '').getTime();
  let currentDateTime = currentDate.getTime();
  let differenceMs;
  let differenceDays;
  if (pastDateTime) {
    differenceMs = pastDateTime - currentDateTime;
    differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  }
  const handleItemClick = (link: Link, navigation: string) => {
    setActive(link);
    navigate(navigation);
  };

  useEffect(() => {
    setActive(pathnameMap[pathname]);
  }, [pathname]);

  useEffect(() => {
    //Ovde treba da se doda funkcija koja ce da vrati sve novo izabrane sajtove a ne user?.websites da bi se u listi prikazao vebsajt
    const getUserWebsitesHandler = async () => {
      const websites = await getUserWebsites();
      const websiteOptions = websites?.map((website: any) => {
        return { value: website, label: website?.displayName };
      });
      setWebsiteOptions(websiteOptions);
    };

    getUserWebsitesHandler();
  }, [website, user]);
  return (
    <Container
      className={styles.sideNavbar}
      padding="16px 8px 0px"
      flexDirection="column"
      justifyContent="space-between"
      sx={
        active === 'settings' ? { height: '100%', width: '11em', position: 'fixed' } : { height: '100%', width: '11em' }
      }
    >
      <Box gap={27} sx={{ height: '100%', position: 'relative' }}>
        <Box alignItems="center" sx={{ width: '100%' }}>
          <DgLogo
            onClick={() => {
              handleItemClick('analytics', '/');
            }}
            className={styles.datagoatLogo}
          />
        </Box>
        <Box sx={{ height: '100%', position: 'relative' }}>
          <Box justifyContent="space-between" sx={{ height: '100%' }}>
            <Box gap={12}>
              {/* <Select
            options={websiteOptions}
            initialOption={{ value: website, label: website.displayName }}
            handleOptionClickProp={async (websiteProject) => {
              try {
                await trackWebsiteAnalytics(websiteProject);
                setWebsite(websiteProject);
              } catch (e) {
                console.log(e);
              }
            }}
          ></Select> */}

              <Dropdown
                title={website?.displayName}
                url={website?.url}
                showProjectMenu={showProjectMenu}
                handleClick={(showProjectMenu: boolean, dropdownActive: boolean) => {
                  setShowProjectMenu(showProjectMenu);
                  setDropdownActive(dropdownActive);
                }}
                projectMenuRef={projectMenuRef}
                dropdownActive={dropdownActive}
              ></Dropdown>

              <Box>
                <SideNavbarItem
                  Icon={AnalyticsIcon}
                  active={active === 'analytics'}
                  onClick={() => {
                    handleItemClick('analytics', '/');
                  }}
                >
                  Analytics
                </SideNavbarItem>
                <SideNavbarItem
                  Icon={InsightsIcon}
                  active={active === 'insights'}
                  onClick={() => {
                    handleItemClick('insights', 'insights');
                  }}
                >
                  Insights
                </SideNavbarItem>
              </Box>
            </Box>
            <Box>
              {user?.subscription?.status === 'trialing' && (
                <Wrapper
                  className={styles.freeTrialExpires}
                  justifyContent="center"
                  alignItems="center"
                  padding="8px 8px"
                  borderRadius="4px"
                >
                  <Label size="xs" sx={{ textAlign: 'center' }}>
                    {differenceDays} {differenceDays && differenceDays > 1 ? 'days' : 'day'} left in trial. &nbsp;
                  </Label>
                  <Label
                    color="highlight"
                    handleClick={() => {
                      navigate('subscription');
                    }}
                    size="xs"
                    sx={{ cursor: 'pointer' }}
                  >
                    Upgrade
                  </Label>
                </Wrapper>
              )}
              <SideNavbarItem
                Icon={SettingsIcon}
                active={active === 'settings'}
                onClick={() => {
                  handleItemClick('settings', 'account-settings');
                }}
              >
                Settings
              </SideNavbarItem>
              <SideNavbarItem Icon={ExitIcon} onClick={() => setIsOpenSignOutModal(true)}>
                Log out
              </SideNavbarItem>
              <Wrapper padding="0 0 12px 0"></Wrapper>
            </Box>
          </Box>

          <ProjectMenu
            show={showProjectMenu}
            websiteList={websiteOptions}
            handleWebsiteClick={async (websiteProject: any) => {
              try {
                setActive('analytics');
                setShowProjectMenu(false);
                setDropdownActive(false);
                await trackWebsiteAnalytics(websiteProject);
                setWebsite(websiteProject);
                navigate('/');
              } catch (e) {
                console.log(e);
              }
            }}
            handleUpgradeClick={() => {
              user?.trialUsed ? navigate('subscription') : setModal(ModalType.freeTrial);
              setShowProjectMenu(false);
              setDropdownActive(false);
            }}
            projectMenuRef={projectMenuRef}
          />
        </Box>
      </Box>

      {isOpenSignOutModal && <SignOutModal onClose={() => setIsOpenSignOutModal(false)} />}
    </Container>
  );
};

export default SideNavbar;
