import React, { FC } from 'react';
// @ts-ignore
import styles from './TabsArrow.module.scss';
import Wrapper from 'components/ui/Wrapper/Wrapper';
import DropdownArrowIcon from 'assets/icons/DropdownArrowIcon';

interface TabsArrowProps {
  rotation: 'left' | 'right';
  onClick: () => void;
}

const TabsArrow: FC<TabsArrowProps> = ({ rotation, onClick }) => {
  const rotationClass = rotation == 'left' ? styles.left : styles.right;
  return (
    <Wrapper
      className={styles.TabsArrow}
      sx={{ minWidth: '24px', height: '24px' }}
      borderRadius="2px"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
    >
      <DropdownArrowIcon className={rotationClass} />
    </Wrapper>
  );
};

export default TabsArrow;
