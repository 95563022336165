import type { SVGProps } from 'react';
import * as React from 'react';

const SvgHubspot = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <circle cx={8} cy={8} r={8} fill="#000" />
    <g clipPath="url(#Hubspot_svg__a)">
      <path fill="#000" d="M3.002 3H13v9.998H3.002z" />
      <path
        fill="#fff"
        d="M10.569 6.303V5.118a.92.92 0 0 0 .527-.824v-.028a.916.916 0 0 0-.913-.914h-.028a.916.916 0 0 0-.914.914v.027a.92.92 0 0 0 .522.822l.005.003v1.188c-.453.07-.88.257-1.236.546l.005-.005-3.261-2.539a1.04 1.04 0 1 0-.483.632l-.005.002 3.207 2.496a2.57 2.57 0 0 0-.433 1.435c0 .539.167 1.064.478 1.503l-.005-.008-.976.976a.8.8 0 0 0-.242-.04.847.847 0 1 0 .846.847.8.8 0 0 0-.041-.248l.002.006.965-.965a2.602 2.602 0 1 0 1.992-4.639l-.015-.002zm-.402 3.907a1.335 1.335 0 1 1 1.34-1.336 1.335 1.335 0 0 1-1.337 1.337z"
      />
    </g>
    <defs>
      <clipPath id="Hubspot_svg__a">
        <path fill="#fff" d="M3.002 3H13v9.998H3.002z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHubspot;
