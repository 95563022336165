import { useNavigate } from 'react-router-dom';

import { CloseIcon } from '../../assets/icons/export';
import { useAuthStore } from 'store/auth';
import { toast } from 'react-toastify';

interface Props {
  onClose: () => void;
}

export const SignOutModal = ({ onClose }: Props) => {
  const { setUser, setIsLogged } = useAuthStore();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    setIsLogged(false);
    setUser(null);
    onClose();
    navigate('/sign-in');
    toast.success('Logged out successfully');
  };

  return (
    <div className="signOutModal" onClick={onClose}>
      <div className="box" onClick={(e) => e.stopPropagation()}>
        <CloseIcon />
        <h2 className="typo-heading-l">
          Are you sure you want
          <br /> to log out?
        </h2>
        <div className="buttons">
          <button className="delete" onClick={logout}>
            Log out
          </button>
          <button className="outlined" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
