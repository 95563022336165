import { XAxis, Tooltip, ResponsiveContainer, LineChart, CartesianGrid, Line, YAxis } from 'recharts';
import { useAnalyticsData } from '../../../../hooks/useAnalyticsData';
import { useVisitorsData } from 'hooks/useVisitorsData';
import { FC, useEffect, useState } from 'react';
import { isActiveClass } from 'utils';
import { ChartPayloadProps } from 'api/previewLink/types';
import { getReportAnalyticsV2 } from 'api/analytics';
import VisitorsLineChart from './VisitorsLineChart/VisitorsLineChart';
import { getPayloadVisitors } from './payloads/payloads';
import { Website } from 'api/users/types';
import { getChartData } from './fetchChartData';
import LineChartLegend from './VisitorsLineChart/VisitorsChartLegend/LineChartLegend';
import Card from 'components/ui/Card/Card';
import Box from 'components/ui/Box/Box';

interface NewVisitors {
  website?: Website;
  shortName?: string;
  propertyId: string;
  startDate: string;
  endDate: string;
}

export const NewVisitorsChart: FC<NewVisitors> = ({ propertyId, startDate, endDate }) => {
  const [active, setActive] = useState([true, false]);
  const [visitorsData, setVisitorsData] = useState<any>(null);
  const [chartData, setChartData] = useState<any>(null);
  //getReportAnalyticsV2(payload)
  const fetchV = async () => {
    const payloadVisitors = getPayloadVisitors(propertyId, startDate, endDate);
    const visitorsData = await getReportAnalyticsV2(payloadVisitors);
    const chartData = await getChartData(propertyId, startDate, endDate);
    setVisitorsData(visitorsData);
    setChartData(chartData);
  };
  const handleElementClick = (clickedIndex: number) => {
    const active = [false, false];
    active[clickedIndex] = true;
    setActive(active);
  };

  useEffect(() => {
    fetchV();
  }, [startDate, propertyId]);
  //const { data: dataForChart } = useVisitorsData({ ...payload, active });

  return (
    // <div className="chartPaper newVisitors">
    //   <div className="left">
    //     <h2 className="typo-heading-s">New Visitors</h2>
    //     <div className="sizes">
    //       <div className={isActiveClass(active === 0, 'size')} onClick={() => setActive(0)}>
    //         <span className="typo-label-s">Total visitors</span>
    //         <div className="stats">{visitorsData && <p>{visitorsData[0]?.totalUsers}</p>}</div>
    //       </div>
    //       <div className={isActiveClass(active === 1, 'size')} onClick={() => setActive(1)}>
    //         <span className="typo-label-s">New visitors</span>
    //         {<div className="stats">{visitorsData && <p>{visitorsData[0]?.newUsers}</p>}</div>}
    //       </div>
    //     </div>
    //   </div>
    //   <div className="chart">
    <>
      <Card heading="Visitors">
        <Box gap={3}>
          <Box flexDirection="row" gap={16}>
            <LineChartLegend
              id={0}
              metricName="Total Users"
              metricValue={visitorsData?.[0]?.totalUsers}
              active={active[0]}
              handleElementClick={handleElementClick}
            />
            <LineChartLegend
              id={1}
              metricName="New Visitors"
              metricValue={visitorsData?.[0]?.newUsers}
              active={active[1]}
              handleElementClick={handleElementClick}
            />
          </Box>
          <VisitorsLineChart activeLine={active} chartData={chartData} />
        </Box>
      </Card>
      {/* {dataForChart && (
          // <ResponsiveContainer width={'100%'} height="100%">
          //   <LineChart data={dataForChart} margin={{ bottom: 0, left: 0, right: 10, top: 0 }}>
          //     <CartesianGrid stroke="#134C54" vertical={false} />
          //     <YAxis
          //       tickLine={false}
          //       axisLine={false}
          //       tickMargin={0}
          //       style={{ fontSize: '10px', fontWeight: 400, fill: '#A1B8B4' }}
          //       orientation="right"
          //       width={27}
          //     />
          //     <XAxis
          //       dataKey="date"
          //       tickLine={false}
          //       axisLine={false}
          //       tickMargin={5}
          //       padding={{ left: 30, right: 0 }}
          //       style={{
          //         fontSize: '10px',
          //         lineHeight: '10px',
          //         fontWeight: 400,
          //         fill: '#A1B8B4',
          //         textAnchor: 'middle'
          //       }}
          //     />
          //     <Line type="linear" dataKey="value" stroke="#C0E666" dot={false} />
          //     <Tooltip
          //       cursor={{ stroke: '#DBF0A3', strokeWidth: 1 }}
          //       content={({ active, payload, label }) => {
          //         if (active && payload && payload.length) {
          //           return (
          //             <div className="device-chart-tooltip">
          //               <p className="typo-body-xxs left">{label}</p>
          //               <div className="row">
          //                 <span className="typo-body-xs">Visitors</span>
          //                 <span className="typo-label-m">{payload[0].value}</span>
          //               </div>
          //             </div>
          //           );
          //         }
          //         return null;
          //       }}
          //     />
          //   </LineChart>
          // </ResponsiveContainer>
        )} */}
      {/* //   </div> */}
    </>
    // </div>
  );
};
