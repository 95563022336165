import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStorageItem } from 'utils/storage';

export const usePublicRoute = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (getStorageItem('token')) navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
