import React, { FC } from 'react';
import styles from './AddPayment.module.scss';
import Modal from 'components/ui/Modal/Modal';
import CloseSVG from 'assets/icons/CloseSVG';
import { ModalType, useModalStore } from 'store/modalStore';
import { Elements } from '@stripe/react-stripe-js';
import { StripeForm } from 'components/shared/auth/payment-step/StripeForm';
import { loadStripe } from '@stripe/stripe-js';
import WrapperPremium from 'components/ui/WrapperPremium/WrapperPremium';

interface AddPaymentProps {}

const planMap: any = {
  business_monthly: 'Business',
  business_yearly: 'Business',
  startup_monthly: 'Startup',
  startup_yearly: 'Startup',
  free: 'Free'
};
const priceMap: any = {
  business_monthly: '$24/mo',
  business_yearly: '$192/yr',
  startup_monthly: '$12/mo',
  startup_yearly: '$96/mo',
  free: ''
};
const AddPayment: FC<AddPaymentProps> = () => {
  const { setModal, modalData } = useModalStore();
  const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || '';
  
  const stripePromise = loadStripe(STRIPE_KEY);
  const handleCancelClick = () => {
    setModal(ModalType.none);
  };
  return (
    <Modal
      className={styles.modalServerError}
      sx={{ height: 'auto', width: '50%', gap: '24px', flexDirection: 'column' }}
    >
      <CloseSVG className={styles.closeIcon} onClick={handleCancelClick} />
      <WrapperPremium>
        {planMap[modalData?.planFlag]} {priceMap[modalData?.planFlag]}
      </WrapperPremium>
      <Elements stripe={stripePromise}>
        <StripeForm
          payload={undefined}
          billingPlan={{ ...modalData.plan }}
          addSubscription={modalData.addSubscription}
        />
      </Elements>
    </Modal>
  );
};

export default AddPayment;
