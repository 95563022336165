import React, { FC } from 'react';
import styles from './RadioButtonActive.module.scss';
import Wrapper from '../Wrapper/Wrapper';

interface RadioButtonActiveProps {
  onClick?: () => void;
}

const RadioButtonActive: FC<RadioButtonActiveProps> = ({ onClick }) => (
  <Wrapper
    sx={{ width: '16px', height: '16px' }}
    borderRadius="50%"
    className={styles.radioButtonActive}
    onClick={onClick}
    justifyContent="center"
    alignItems="center"
  >
    <Wrapper sx={{ width: '6px', height: '6px' }} borderRadius="50%" className={styles.circle}></Wrapper>
  </Wrapper>
);

export default RadioButtonActive;
