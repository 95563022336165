import dayjs from 'dayjs';
import { create } from 'zustand';

interface State {
  dateRangeLink: { startDate: string; endDate: string };
  setDateRangeLink: (days: number) => void;
  startDate: string;
  setStartDate: (startDate: Date) => void;
  endDate: string;
  setEndDate: (endDate: Date) => void;

  website: any;
  setWebsite: (website: any) => void;

  cancelToken: {
    allForms: AbortController;
    charts: AbortController;
    pages: AbortController;
    sources: AbortController;
    singleForm: AbortController;
  };
  setCancelToken: (cancelToken: {
    allForms: AbortController;
    charts: AbortController;
    pages: AbortController;
    sources: AbortController;
    singleForm: AbortController;
  }) => void;
}

const useAnalyticsStore = create<State>((set) => ({
  dateRangeLink: { startDate: '', endDate: '' },
  setDateRangeLink: (days) =>
    set({
      dateRangeLink: {
        startDate: dayjs().subtract(days, 'day').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      }
    }),
  startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
  setStartDate: (startDate: Date) => set({ startDate: startDate.toISOString().split('T')[0] }),

  endDate: new Date().toISOString().split('T')[0],
  setEndDate: (endDate: Date) => set({ endDate: endDate.toISOString().split('T')[0] }),

  website: null,
  setWebsite: (website: any) => set({ website }),

  cancelToken: {
    allForms: new AbortController(),
    charts: new AbortController(),
    pages: new AbortController(),
    sources: new AbortController(),
    singleForm: new AbortController()
  },
  setCancelToken: (cancelToken) =>
    set({
      cancelToken
    })
}));

export { useAnalyticsStore };
