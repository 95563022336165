/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/auth';
import { wfConnect } from 'api/webflow';
import { ExtensionLoginSuccessModal } from 'components/layout/home/ExtensionLoginSuccessModal';
import { fetchAuthUser } from 'api/users';
import { toast } from 'react-toastify';

export const WfAuthPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { mutate } = useMutation(wfConnect);
  const { setWfAuthCode, setWebflowAuthorized, setUser, setIsLogged } = useAuthStore();
  const [isOpen, setIsOpened] = useState(false);

  const stateQuery = new URLSearchParams(search).get('state') || '';

  const connectWebflow = async (code: string, state: string) => {
    try {
      const response = await wfConnect({ code, state });
      if (response.success) {
        setWebflowAuthorized(true);

        const userResponse = await fetchAuthUser();
        if (userResponse) setUser(userResponse);
      }
    } catch (e) {
      setUser(null);
      setIsLogged(false);
      window.location.href = `${window.location.origin}/sign-in`;
    }
  };

  useEffect(() => {
    const code = new URLSearchParams(search).get('code') || '';
    const state = new URLSearchParams(search).get('state') || '';
    const error = new URLSearchParams(search).get('error') || '';

    if (error === 'access_denied') {
      toast.error('You canceled webflow authorization process. Please try again');
      navigate('/');
      return;
    }

    if (!code) return;

    if (state.includes('wf-ex-')) setIsOpened(true);
    connectWebflow(code, state);
    /*mutate(
      { code, state },
      {
        onSuccess: () => {
         
          if (!state.includes('-ex-')) {
            queryClient.refetchQueries('auth-user');
            setTimeout(() => (window.location.href = '/?connected=true'), 1500);
          }
        },
        onError: () => {
          setWfAuthCode(code);
          navigate('/sign-in');
        }
      }
    );*/
  }, [search]);

  return (
    <>
      {isOpen ? (
        <ExtensionLoginSuccessModal close={() => setIsOpened(false)} code={stateQuery} />
      ) : (
        <p className="loadingText">Please wait.....</p>
      )}
    </>
  );
};
