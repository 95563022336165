const ShareLinkSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="#C0E666"
      fillRule="evenodd"
      d="M6 11.667H5a3.669 3.669 0 01-3.666-3.666 3.669 3.669 0 013.667-3.667h1v1H5a2.67 2.67 0 00-2.667 2.667 2.67 2.67 0 002.667 2.666h1v1zm5 0h-1v-1h1a2.67 2.67 0 002.667-2.666 2.67 2.67 0 00-2.666-2.667h-1v-1h1a3.669 3.669 0 013.666 3.667 3.669 3.669 0 01-3.666 3.666zM4.668 7.501h6.667v1H4.667v-1z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default ShareLinkSVG;
