import { useMutation, useQuery } from 'react-query';
import { orderKeys } from 'api/orders/keys';
import { deletePaymentMethod, fetchUsersPaymentInfo, setDefaultPaymentMethod } from 'api/orders';
import {
  ChevronIcon,
  DefaultCardIcon,
  FourDotsIcon,
  MasterCardIcon,
  ThreeDotsIcon,
  VisaIcon
} from '../../../assets/icons/export';
import { useAuthStore } from 'store/auth';
import { isActiveClass } from '../../../utils';
import { useRef, useState } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { AddPaymentMethodForm } from './AddPaymentMethodForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { DeleteSecurityModal } from '../../shared/auth/DeleteSecurityModal';
import { getCardIcon } from 'utils/payments';

export const PaymentInfo = () => {
  const [openActions, setOpenActions] = useState('');
  const [isOpenForm, setIsOpenForm] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const { user } = useAuthStore();
  const { data, refetch } = useQuery(orderKeys.paymentInfo, fetchUsersPaymentInfo);
  const { mutate: setDefault } = useMutation(setDefaultPaymentMethod);

  const [idForDelete, setIdForDelete] = useState('');

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

  useOutsideClick(ref, () => setOpenActions(''));

  const onSuccess = () => {
    setOpenActions('');
    refetch();
  };

  return (
    <div className="paymentInfo grid-col-2">
      <p className="borderTopTitle">Update payment information</p>
      <div>
        <p className="heading">Payment methods</p>
        <div className="cards" ref={ref}>
          {data?.paymentInfo?.map(({ card, id }) => (
            <div key={id} className="card">
              <div className="cardIcon">{getCardIcon(card.brand)}</div>
              <div className="cardNumber">
                <FourDotsIcon />
                {card.last4}
              </div>
              <div>{data.defaultPaymentMethod === id ? <p className="default typo-body-xs">Default</p> : null}</div>
              <p>
                {user?.firstName} {user?.lastName}
              </p>
              <span
                className="threeDots"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setOpenActions(id);
                }}
              >
                <ThreeDotsIcon />
              </span>
              <div className={isActiveClass(id === openActions, 'actions')}>
                <button type="button" onClick={() => setIdForDelete(id)}>
                  Delete this method
                </button>
                <button type="button" onClick={() => setDefault(id, { onSuccess })}>
                  Set to default
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className={isActiveClass(isOpenForm, 'addNewPayment')} onClick={() => setIsOpenForm((p) => !p)}>
          <p className="typo-body-s">Add payment method</p>
          <ChevronIcon />
        </div>
        {isOpenForm && (
          <Elements stripe={stripePromise}>
            <AddPaymentMethodForm backgroundColor="#08282d" closeForm={() => setIsOpenForm(false)} />
          </Elements>
        )}
      </div>

      {idForDelete && (
        <DeleteSecurityModal
          className="deleteCard"
          id={idForDelete}
          onSuccess={refetch}
          title="Delete this payment method?"
          subtext={`Are you sure you want to delete this payment method`}
          deleteFunc={deletePaymentMethod}
          deleteScript={() => {}}
          close={() => setIdForDelete('')}
        />
      )}
    </div>
  );
};
