const ArrowSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" fill="none" viewBox="0 0 10 7" {...props}>
    <path
      fill="#C6D7CD"
      d="M5.707 1.207a1 1 0 00-1.414 0L.707 4.793c-.63.63-.184 1.707.707 1.707h7.172c.89 0 1.337-1.077.707-1.707L5.707 1.207z"
    ></path>
  </svg>
);

export default ArrowSVG;
