import React, { MutableRefObject, useEffect, useRef } from 'react';
// @ts-ignore
import styles from './Tabs.module.scss';
import SubmissionTabsArrow from './TabsArrow/TabsArrow';
import Box from 'components/ui/Box/Box';
import Label from 'components/ui/Label/Label';
import Wrapper from 'components/ui/Wrapper/Wrapper';

interface Tab {
  label: string;
  content: React.ReactNode;
}

interface TabsProps {
  label: string[];
  width: number;
  activeTabIndex: number;
  handleTabClickProp: (index: number) => void;
  // No need for apiEndpoint
}

const Tab: React.FC<Tab> = ({ content }) => {
  return <div>{content}</div>;
};

const Tabs: React.FC<TabsProps> = ({ label, width, activeTabIndex, handleTabClickProp }) => {
  const itemsRef = useRef<HTMLDivElement[]>([]);
  const scrollLeftValue: MutableRefObject<number[]> = useRef([]);
  const tabsRef = useRef<HTMLDivElement | null>(null);
  const tablistWidth = width - 30 - 100 - 48; // submission width - submission padding - all forms width - 2 * arrow width

  let pos = useRef<number>(0);
  let canScroll = true;
  const handleTabClick = (index: number) => {
    handleTabClickProp(index);

    /*if (index > 0) {
      if (
        100 - scrollLeftValue.current[index - 1] <
        -(scrollLeftValue.current[scrollLeftValue.current.length - 1] - tabsRef.current!.offsetWidth)
      )
        tabsRef!.current!.style.left =
          -(scrollLeftValue.current[scrollLeftValue.current.length - 1] - tabsRef.current!.offsetWidth) + 'px';
      else tabsRef!.current!.style.left = 100 - scrollLeftValue.current[index - 1] + 'px';
      pos.current = parseInt(tabsRef!.current!.style.left);
    }*/
  };

  useEffect(() => {
    scrollLeftValue.current = [];
    itemsRef.current.forEach((_, index: number) => {
      if (index > 0)
        scrollLeftValue.current.push(itemsRef.current[index - 1]?.offsetWidth + scrollLeftValue.current[index - 1]);
      else {
        scrollLeftValue.current.push(0);
      }
      if (index == itemsRef.current.length - 1)
        scrollLeftValue.current.push(itemsRef.current[index]?.offsetWidth + scrollLeftValue.current[index]);
    });

    if (activeTabIndex > 0) {
      if (
        -scrollLeftValue.current[activeTabIndex - 1] <
        -(scrollLeftValue.current[scrollLeftValue.current.length - 1] - tabsRef.current!.offsetWidth)
      ) {
        pos.current = -(scrollLeftValue.current[scrollLeftValue.current.length - 1] - tabsRef.current!.offsetWidth);
      } else pos.current = -scrollLeftValue.current[activeTabIndex - 1];
      tabsRef!.current!.style.left = pos.current + 'px';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      //document.body.classList.remove('stop-scrolling');
      //window.onscroll = function () {};
    };
  }, [activeTabIndex]);
  return (
    <Box className={styles.tabs} flexDirection="row" alignItems="flex-start">
      <div className={styles.fixed}>
        <Wrapper
          padding="4px 0px"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          sx={{ height: '24px' }}
          className={`${styles.tab} ${styles.allForms} ${activeTabIndex === 0 ? styles.active : ''}`}
          onClick={() => handleTabClick(0)}
        >
          <Label size="s">All Forms</Label>
        </Wrapper>
      </div>

      <SubmissionTabsArrow
        rotation="left"
        onClick={() => {
          let deltaPos = 200;
          if (pos.current + deltaPos > 0) pos.current = 0;
          else pos.current += deltaPos;
          tabsRef!.current!.style.left = pos.current + 'px';
        }}
      />
      <div className={styles.tabsContainer} style={{ width: `100%` }}>
        <div
          style={{
            width: `${tablistWidth}px` // submission width - submission padding - all forms width - 2 * arrow width
            //width: '100%'
          }}
          className={styles.tabList}
          ref={tabsRef}
        >
          {label?.map((tab, index) => (
            <div
              ref={(el: any) => (itemsRef.current[index] = el)}
              className={styles.tab}
              style={{ display: 'flex' }}
              key={index}
            >
              <Wrapper
                padding="4px 30px"
                justifyContent="center"
                alignItems="center"
                flexDirection="row"
                sx={{ height: '24px' }}
                key={index + 1}
                className={`${styles.tab} ${index + 1 === activeTabIndex ? styles.active : ''}`}
                onClick={() => handleTabClick(index + 1)}
              >
                <Label size="s" color="normal">
                  {tab}
                </Label>
              </Wrapper>
            </div>
          ))}
        </div>
      </div>
      <SubmissionTabsArrow
        rotation="right"
        onClick={() => {
          let deltaPos = 200;
          if (
            pos.current - deltaPos <
            -(scrollLeftValue.current[scrollLeftValue.current.length - 1] - tabsRef.current!.offsetWidth)
          )
            pos.current = -(scrollLeftValue.current[scrollLeftValue.current.length - 1] - tabsRef.current!.offsetWidth);
          else pos.current -= deltaPos;

          tabsRef!.current!.style.left = pos.current + 'px';
        }}
      />

      {/* {<div className={styles.tabContent}>
        tabs.length > 0 && 
        // <Tab label={tabs[activeTabIndex].label} content={tabs[activeTabIndex].content} />
        
        
      </div>} */}
    </Box>
  );
};

export default Tabs;
