import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { OrdersProduct } from 'api/orders/types';

import { fetchOrderProducts } from 'api/orders';
import { formatPrice, getProductPrice } from 'utils';
import { orderKeys } from 'api/orders/keys';

interface Props {
  billingPlan: OrdersProduct | null;
  setBillingPlan: React.Dispatch<React.SetStateAction<OrdersProduct | null>>;
  isUnitPricing?: boolean;
}

export const Cards = ({ billingPlan, setBillingPlan, isUnitPricing }: Props) => {
  const { data: products } = useQuery(orderKeys.orderProducts, fetchOrderProducts);

  useEffect(() => {
    if (products?.data?.length) {
      const plan = products?.data?.find(({ active }: { active: any }) => active);
      setBillingPlan(plan || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return (
    <div className="cards">
      {products &&
        products?.data
          ?.filter((product: any) => product.active && product.default_price.tiers)
          .map((product: any) => (
            <div
              key={product.id}
              className={`card ${product.id === billingPlan?.id ? 'active' : ''}`}
              onClick={() => setBillingPlan(product)}
            >
              <div className="cardLeft">
                <div className="radioBtn" />
                <div>
                  <h2 className="typo-heading-s">{product.name}</h2>
                  {product.description && <p className="typo-body-s">{product.description}</p>}
                </div>
              </div>
              <div className="cardRight">
                <p className="typo-heading-m">
                  {formatPrice(getProductPrice(product, isUnitPricing))}{' '}
                  <span className="typo-label-l">/{product.default_price.recurring?.interval}</span>
                </p>
              </div>
            </div>
          ))}
    </div>
  );
};
