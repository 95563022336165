import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { ArrowIcon, LogoIcon, UserIcon } from '../../assets/icons/export';
import useOutsideClick from '../../hooks/useOutsideClick';
import { useAuthStore } from 'store/auth';
import { isActiveClass } from '../../utils';
import { SignOutModal } from 'components/shared/SignOutModal';

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSignOutModal, setIsOpenSignOutModal] = useState(false);

  const { user } = useAuthStore();

  const ref = useRef<HTMLElement>(null);
  useOutsideClick(ref, () => setIsOpen(false));

  return (
    <>
      <header className="header" ref={ref}>
        <NavLink to="/">
          <LogoIcon />
        </NavLink>
        <div className="right">
          <div className={isActiveClass(isOpen, 'settingsMenu')} onClick={() => setIsOpen((p) => !p)}>
            <div className="circle">
              <UserIcon />
            </div>
            <ArrowIcon className="arrow" />
          </div>
        </div>
        {isOpen ? (
          <nav>
            <div className="section">
              <p className="typo-body-s">Signed in as</p>
              <p>{user?.email}</p>
            </div>
            {/* {user && validSubscriptionStatuses.includes(user?.subscription.status) ? ( */}
            <div className="section">
              <NavLink to="/account-settings">Account Settings</NavLink>
              <NavLink to="/my-projects">My Projects</NavLink>
              <NavLink to="/subscription">Subscription</NavLink>
            </div>
            {/* ) : null} */}
            <button type="button" onClick={() => setIsOpenSignOutModal(true)}>
              Log out
            </button>
          </nav>
        ) : null}
      </header>
      {isOpenSignOutModal && <SignOutModal onClose={() => setIsOpenSignOutModal(false)} />}
    </>
  );
};
