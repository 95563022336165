import { Modal } from 'components/ui/Modal';
import GoatImg from 'assets/images/Goat-V3.png';
import { LogoIcon } from 'assets/icons/export';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

interface Props {
  close: () => void;
  className?: string;
}

export const ResubscribeSuccessModal = ({ close, className }: Props) => {
  const navigate = useNavigate();

  return (
    <Modal close={close} className={className} closeHidden={true}>
      <div className="modalWrapper">
        <LogoIcon className="logo" />
        <div className="goatImg">
          <img src={GoatImg} alt="" />
        </div>
        <div className="modalInfo">
          <h3>Your payment has been successful!</h3>
          <p>You plan has successfuly been changed.</p>
          <p>Next invoice bills on Avg 2024.</p>
          <button className="primary" onClick={() => navigate('/')}>
            Go to Dashboard
          </button>
        </div>
      </div>
    </Modal>
  );
};
