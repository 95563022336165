import { checkPassword, deleteAccount } from 'api/users';
import { Checkbox } from 'components/ui/Checkbox';
import { Modal } from 'components/ui/Modal';
import { InputField } from 'components/ui/forms/fields/InputField';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { NotFoundIcon } from 'assets/icons/export';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store/auth';

interface Props {
  close: () => void;
  className?: string;
}

export const DeleteAccountModal = ({ close, className }: Props) => {
  const { mutate } = useMutation(checkPassword);
  const { mutate: deleteAccountMutate } = useMutation(deleteAccount);
  const [isCorectPassword, setIsCorectPassword] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [reason, setReason] = useState('');
  const [other, setOther] = useState(false);
  const [password, setPassword] = useState('');
  const [textArea, setTextArea] = useState('');
  const { setUser, setIsLogged } = useAuthStore();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { password: '' },
    onSubmit: (values) =>
      mutate(values.password, {
        onSuccess: (res) => {
          setPassword(values.password);
          if (res.success) {
            setIsCorectPassword(res.success);
            setActiveStep(2);
          }
        }
      })
  });

  const submitDeleteAccount = () => {
    deleteAccountMutate(
      { password, reason: textArea || reason },
      {
        onSuccess: () => {
          setActiveStep(3);
        }
      }
    );
  };

  const changeOtherValue = () => {
    if (other) {
      setOther(false);
      setTextArea('');
    } else {
      setOther(true);
    }
  };

  const logout = () => {
    localStorage.clear();
    setIsLogged(false);
    setUser(null);
    navigate('/sign-in');
  };

  const closeModal = () => {
    navigate('/');
    window.location.reload();
  };

  const renderStep = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <div className="box">
              <p className="typo-label-l">Step 1</p>
              <h3 className="typo-heading-m">Delete account</h3>
              <div className="steps">
                <div className="active" />
                <div />
              </div>
            </div>
            <div className="deliteInfo">
              <h2>Are you sure you want to delete your account ?</h2>
              <p>
                By deteleting your account, you will lose access to all Data Goat features and all your information will
                be deleted
              </p>
              <div className="formWrapper">
                <form onSubmit={formik.handleSubmit} className="section">
                  <div>
                    <InputField name="password" label="Password*" type="password" formik={formik} />
                    <div className="formBtn">
                      <button type="submit" className="outlined firstBtn">
                        Yes, I’m sure
                      </button>
                      <button onClick={close} className="outlined secondBtn">
                        No, for now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        );

      case 2:
        return (
          <>
            <div className="box">
              <p className="typo-label-l">Step 2</p>
              <h3 className="typo-heading-m">Tell us why?</h3>
              <div className="steps">
                <div />
                <div className="active" />
              </div>
            </div>
            <div className="deliteInfoStep2">
              <h2>Why are you deleting your account?</h2>
              <p>Please check the reasons below that best reflect why you are deleting your account</p>
              <div className="checkboxes">
                {checkboxes.map((item) => (
                  <div className="box">
                    <Checkbox isChecked={reason === item} onChange={() => setReason(item)} />
                    <p>{item}</p>
                  </div>
                ))}
                <button className="outlined" onClick={changeOtherValue}>
                  {other ? '-' : '+'} Other
                </button>
                {other && (
                  <textarea
                    name="reason"
                    onChange={(e) => setTextArea(e.target.value)}
                    className="textArea"
                    rows={6}
                  ></textarea>
                )}
              </div>
              <div className="deleteBtn">
                <button onClick={submitDeleteAccount} className="outlined firstBtn">
                  Submit and cancel
                </button>
                <button onClick={close} className="outlined secondBtn">
                  I changed my mind!
                </button>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="deliteInfoStep3">
              <NotFoundIcon />
              <h2>Account marked for deletion</h2>
              <p>
                Your account is marked for deletion and will be deleted in 90 days. You may recover your account within
                this period by logging in.
              </p>
              <div className="btnWrapper">
                <button onClick={closeModal} className="outlined firstBtn">
                  Recover account
                </button>
                <button onClick={logout} className="outlined logoutBtn">
                  Log out
                </button>
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <Modal close={close} className={className} closeHidden={true}>
      <div className="modalWrapper">{renderStep()}</div>
    </Modal>
  );
};

const checkboxes = [`I don't use it anymore`, `I’m switching to another product`, `It’s not helpful`];
