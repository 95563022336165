import type { SVGProps } from 'react';
import * as React from 'react';

const SvgFormstack = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" {...props}>
    <rect width={12} height={12} fill="#fff" rx={6} />
    <g clipPath="url(#formstack_svg__a)">
      <path
        fill="#21B573"
        d="M8.116 2.998a.21.21 0 0 1 .21.21v6.58a.21.21 0 0 1-.21.21h-5.89a.21.21 0 0 1-.21-.21v-6.58a.21.21 0 0 1 .21-.21zm1.051.7a.21.21 0 0 1 .21.21v5.18a.21.21 0 0 1-.21.21h-.49v-5.6zm1.052.7a.21.21 0 0 1 .21.21v3.78a.21.21 0 0 1-.21.21h-.491v-4.2z"
      />
      <path
        fill="#fff"
        d="M4.528 8.061a.1.1 0 0 1 .012.04v.712a.07.07 0 0 1-.07.07h-.984a.067.067 0 0 1-.068-.068v-.03a.07.07 0 0 1 .03-.058l.982-.683a.07.07 0 0 1 .098.017m1.616-1.634a.07.07 0 0 1 .04.128L3.528 8.378a.07.07 0 0 1-.11-.058V7.236a.07.07 0 0 1 .027-.055l.943-.739a.07.07 0 0 1 .043-.015zm.58-2.31a.07.07 0 0 1 .043.125L3.532 6.826a.07.07 0 0 1-.114-.055V4.187a.07.07 0 0 1 .07-.07z"
      />
    </g>
    <defs>
      <clipPath id="formstack_svg__a">
        <path fill="#fff" d="M2 3h8.454v7H2z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFormstack;
