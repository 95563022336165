import React, { FC } from 'react';
import styles from './Downgrade.module.scss';
import Modal from 'components/ui/Modal/Modal';
import CloseSVG from 'assets/icons/CloseSVG';
import { ModalType, useModalStore } from 'store/modalStore';
import Heading from 'components/ui/Heading/Heading';
import Text from 'components/ui/Text/Text';
import Button from 'components/ui/Button/Button';
import Box from 'components/ui/Box/Box';

interface ModalPlanDowngradeProps {}

const planMap: any = {
  business_monthly: 'Business',
  business_yearly: 'Business',
  startup_monthly: 'Startup',
  startup_yearly: 'Startup',
  free: 'Free'
};

export const ModalPlanDowngrade: FC<ModalPlanDowngradeProps> = () => {
  const { modalData, setModal } = useModalStore();
  const handleCancelClick = () => {
    setModal(ModalType.none);
  };
  return (
    <Modal
      className={styles.modalServerError}
      sx={{ height: 'auto', width: '45%', gap: '32px', flexDirection: 'column', alignItems: 'center' }}
    >
      <CloseSVG className={styles.closeIcon} onClick={handleCancelClick} />
      <Box gap={8} alignItems="center" justifyContent="center">
        <Heading size="l" sx={{ textAlign: 'center' }}>
          Are you sure you want to downgrade your account to {planMap[modalData.plan]}?
        </Heading>
        <Text color="primary-100" sx={{ textAlign: 'center' }}>
          After downgrading your plan, you will lose access to some features only available with your current plan. Your
          plan will be downgraded immediately
        </Text>
      </Box>
      <Button
        padding="big"
        type="delete"
        color="none"
        handleClick={() => {
          modalData.handleDowngradeClick();
        }}
      >
        Downgrade
      </Button>
    </Modal>
  );
};

export default ModalPlanDowngrade;
