import React, { FC } from 'react';
import styles from './WebsiteChannelChart.module.scss';
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';

interface WebsiteChannelChartProps {
  chartData: any;
  activeOption: string;
}

const WebsiteChannelChart: FC<WebsiteChannelChartProps> = ({ chartData, activeOption }) => {
  let min = 0;
  let max = 0;
  let dataKey = 'totalUsers';
  if (chartData) {
    if (activeOption == 'Users') {
      max = Math.max(...chartData!.map((value: any) => parseInt(value.totalUsers)));
      min = Math.min(...chartData!.map((value: any) => parseInt(value.totalUsers)));
    }
    if (activeOption == 'Sessions') {
      dataKey = 'sessions';
      max = Math.max(...chartData!.map((value: any) => parseInt(value.sessions)));
      min = Math.min(...chartData!.map((value: any) => parseInt(value.sessions)));
    }
  }
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        layout="vertical"
        data={chartData}
        width={600}
        height={300}
        margin={{ top: 0, right: 20, left: 10, bottom: 0 }}
      >
        <CartesianGrid horizontal={false} stroke="#134C54" />
        <Bar dataKey={dataKey} fill="#DBF0A3" radius={3} barSize={30}>
          <LabelList
            dataKey="name"
            position="insideLeft"
            style={{ fontSize: 12, lineHeight: '20px', color: '#08282D' }}
          />
        </Bar>
        <XAxis
          dataKey={dataKey}
          type="number"
          style={{ fontSize: 12, lineHeight: '20px', color: '#799892' }}
          axisLine={false}
          tickLine={false}
          tickMargin={15}
          tickCount={10}
          domain={[0, max]}
        />
        <YAxis
          dataKey="firstUserDefaultChannelGroup"
          type="category"
          style={{ fontSize: 12, lineHeight: '20px', color: '#799892' }}
          axisLine={false}
          tickLine={false}
          tickMargin={15}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default WebsiteChannelChart;
