import { useEffect } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { getAnalytics } from 'api/analytics';
import { AnalyticsHookPayload, DateRange } from '../api/analytics/types';
import { get7DayDateRange } from '../utils';

interface Props {
  dimensions: AnalyticsHookPayload['dimensions'];
  metrics?: AnalyticsHookPayload['metrics'];
  key: string[];
  propertyId: string;
  code?: string;
  dateRanges?: DateRange;
  scope?: string;
}

export const useAnalyticsData = ({ dimensions, metrics, key, propertyId, code, dateRanges, scope }: Props) => {
  const transformedD = dimensions.map((val) => ({ name: val }));
  const transformedM = metrics?.map((val) => ({ name: val })) || [];

  let startDate, endDate;

  if (code) {
    if (dateRanges) {
      startDate = dayjs(dateRanges?.startDate).format('YYYY-MM-DD');
      endDate = dayjs(dateRanges?.endDate).format('YYYY-MM-DD');
    }
  } else {
    const { startDate: start, endDate: end } = get7DayDateRange();
    startDate = start;
    endDate = end;
  }

  const transformedDimensions = !scope ? transformedD : [...transformedD, { name: 'pageTitle' }];

  const dates = [
    {
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(endDate)
        .subtract(endDate ? 1 : 0, 'day')
        .format('YYYY-MM-DD')
    }
  ];

  const { data, refetch } = useQuery(
    key,
    () =>
      getAnalytics({
        propertyId,
        dateRanges: dates,
        dimensions: transformedDimensions,
        metrics: transformedM,
        code,
        title: scope
      }),
    { enabled: scope ? !!scope : false }
  );

  useEffect(() => {
    if (propertyId) {
      refetch();
    }
  }, [propertyId, scope]);

  return { data, refetch, propertyId, dateRanges };
};
