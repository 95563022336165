import React, { FC } from 'react';
import styles from './CustomTooltip.module.scss';

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
}

const payloadDict: Record<string, string> = {
  conversion: 'Conversion',
  conversionRate: 'Conversion rate',
  impression: 'Impression'
};
const CustomTooltip: FC<CustomTooltipProps> = ({ active, payload, label }) => {
  const listEl = payload?.map((payloadObj, index) => {
    if (payloadObj.name === 'conversionRate') payloadObj.value += '%';
    return (
      <div className={styles.ListElement} key={index}>
        <p className={styles.Name}>{payloadDict[payloadObj.name]}</p> <p className={styles.Value}>{payloadObj.value}</p>
      </div>
    );
  });
  return active && payload && payload.length ? (
    <div className={styles.CustomTooltip}>
      <p className={styles.Date}>{payload[0]?.payload.name}</p>
      {listEl}
    </div>
  ) : (
    <></>
  );
};

export default CustomTooltip;
