import { useMutation } from 'react-query';
import { Modal } from '../../ui/Modal';

interface Props {
  close: () => void;
  id: any;
  onSuccess?: () => void;
  deleteFunc: any;
  deleteScript?: () => void;
  title: string;
  subtext?: string;
  className?: string;
}

export const DeleteSecurityModal = ({
  close,
  id,
  onSuccess,
  deleteFunc,
  deleteScript,
  title,
  subtext,
  className
}: Props) => {
  const { mutate, isLoading } = useMutation(deleteFunc);

  const onDelete = () =>
    mutate(id, {
      onSuccess: () => {
        if (onSuccess) onSuccess();
        close();
      }
    });

  return (
    <Modal className={className} close={close}>
      <h3 className="title">{title}</h3>
      {subtext ? <p className="subtext">{subtext}</p> : null}
      <div className="buttons">
        <button
          type="button"
          className="delete"
          onClick={() => {
            onDelete();
            deleteScript && deleteScript();
          }}
          disabled={isLoading}
        >
          Delete
        </button>
        <button type="button" className="outlined" onClick={close}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};
