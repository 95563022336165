import { apiCall } from '..';
import { AccountSummaries, DataStream, GetAnalyticsPayload, Porperty, ResponseRow, RunReportPayload } from './types';

export const getAccounts = async (): Promise<AccountSummaries[] | undefined> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `analytics/accounts`, method: 'GET' });
    return data?.data?.accountSummaries;
  } catch (error) {
    //
  }
};

export const getProperties = async (accountId?: string): Promise<Porperty[] | undefined> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `analytics/properties`, method: 'GET', params: { accountId } });
    return data?.data?.properties;
  } catch (error) {
    //
  }
};

export const getDataStreams = async (accountId?: string): Promise<DataStream[] | undefined> => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `analytics/dataStream`,
      method: 'GET',
      params: { accountId }
    });
    return data.data?.dataStreams;
  } catch (error) {
    //
  }
};

export const getAnalytics = async (payload: GetAnalyticsPayload) => {
  const request = await apiCall();

  const url = payload?.code ? `/reports/analytics/${payload.code}` : `/analytics`;

  try {
    const { data } = await request({
      url,
      method: 'GET',
      params: payload
    });

    if (payload.title) {
      const index = payload.dimensions.findIndex(({ name }) => name === 'pageTitle');
      return data.data.rows.filter((row: any) => row.dimensionValues[index].value === payload.title) as any;
    }

    return data?.data?.rows as ResponseRow[];
  } catch (error: any) {}
};

export const getReportAnalytics = async (payload: RunReportPayload, signal?: AbortSignal): Promise<ResponseRow[]> => {
  const request = await apiCall(signal);

  try {
    const { data } = await request({
      url: `/analytics/reports`,
      method: 'GET',
      params: payload
    });

    if (data?.data?.rows) return data?.data?.rows;
    else return [];
  } catch (error: any) {
    if (error == undefined) return error;
    return [];
  }
};

export const getReportAnalyticsV2 = async (
  payload: RunReportPayload,
  signal?: AbortSignal
): Promise<Record<string, string>[]> => {
  const request = await apiCall(signal);

  try {
    let { data } = await request({
      url: `/analytics/reports`,
      method: 'GET',
      params: payload
    });
    if (data?.data?.rows) {
      const dimensionHeaders = data.data.dimensionHeaders;
      const metricHeaders = data.data.metricHeaders;

      const rows: ResponseRow[] = data?.data?.rows;
      const response: Record<string, string>[] = [];
      rows.forEach((row) => {
        const dimensionResponse: Record<string, string> = {};
        if (dimensionHeaders && dimensionHeaders?.length > 0)
          for (let dimensionIndex = 0; dimensionIndex < dimensionHeaders!.length; dimensionIndex++) {
            const value = row.dimensionValues[dimensionIndex].value;
            const name = dimensionHeaders[dimensionIndex].name;
            dimensionResponse[name] = value;
          }
        const metricResponse: Record<string, string> = {};
        if (metricHeaders && metricHeaders?.length > 0)
          for (let metricIndex = 0; metricIndex < metricHeaders!.length; metricIndex++) {
            const value = row.metricValues[metricIndex].value;
            const name = metricHeaders[metricIndex].name;
            metricResponse[name] = value;
          }
        response.push({
          ...dimensionResponse,
          ...metricResponse
        });
      });

      return response;
    } else {
      return [];
    }
    //return data;
  } catch (error: any) {
    if (error == undefined) return error;
    return [];
  }
};
