import { Header } from 'components/abstract/Header';
import { useState } from 'react';
import { OrdersProduct } from 'api/orders/types';
import { usePrivateRoute } from 'hooks/usePrivateRoute';
import { Cards } from 'components/shared/auth/payment-step/Cards';
import { useAuthStore } from 'store/auth';
import { useNavigate } from 'react-router-dom';
import { getProductPrice } from 'utils';

export const ReactivatePlan = () => {
  usePrivateRoute();
  const [billingPlan, setBillingPlan] = useState<OrdersProduct | null>(null);
  const { user } = useAuthStore();
  const navigate = useNavigate();

  const onSubmit = () => {
    if (!billingPlan) return;

    const price = getProductPrice(billingPlan);
    const id = billingPlan?.default_price?.id;

    navigate(`/purchase-summary?price=${price}&id=${id}`);
  };

  return (
    <div className="container">
      <Header />
      <div className="reactivateWrapper">
        <div className="reactivateText">
          <div className="welcomeTitle">
            <p>Welcome back, {user?.firstName}!!</p>
          </div>
          <div className="title">
            <h2>Reactivate your plan</h2>
          </div>
          <div className="subTitle">
            <p>Choose a plan and add payment details to start your free trial.</p>
          </div>
        </div>
        <div className="cardsWrapper">
          <Cards billingPlan={billingPlan} setBillingPlan={setBillingPlan} />
        </div>
        <div className="btnWrapper">
          <button className="primary" onClick={onSubmit}>
            Reactivate account
          </button>
        </div>
      </div>
    </div>
  );
};
