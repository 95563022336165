import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import styles from './Table.module.scss';
import TableHeader from './TableHeader/TableHeader';
import TableRow from './TableRow/TableRow';

interface TableProps {
  headers?: string[];
  tableData?: any[];
  firstColumnMinWidth?: number;
  otherColumnMinWidth?: number;
}

const Table: FC<TableProps> = ({ headers, tableData, firstColumnMinWidth, otherColumnMinWidth }) => {
  return (
    <div className={styles.Table} style={{ width: '100%' }}>
      <div style={{ display: 'flex' }}>
        <TableHeader
          headers={headers}
          firstColumnMinWidth={firstColumnMinWidth}
          otherColumnMinWidth={otherColumnMinWidth}
        ></TableHeader>
      </div>
      <div
        style={{ display: 'flex', overflow: 'scroll', height: '25vh', flexDirection: 'column' }}
        className={styles.bodyTable}
      >
        {tableData?.map((value: any, index: any) => {
          const rowData: any[] = [];

          for (let key in value) rowData.push(value[key]);
          return (
            <TableRow
              key={index}
              rowData={rowData} // Pass headers to TableRow
              firstColumnMinWidth={firstColumnMinWidth}
              otherColumnMinWidth={otherColumnMinWidth}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Table;
