import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPreviewLinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#F5FAEC"
      fillRule="evenodd"
      d="M6 11.665H5A3.67 3.67 0 0 1 1.333 8 3.67 3.67 0 0 1 5 4.332h1v1H5a2.67 2.67 0 0 0-2.667 2.667A2.67 2.67 0 0 0 5 10.665h1zm5 0h-1v-1h1A2.67 2.67 0 0 0 13.667 8 2.67 2.67 0 0 0 11 5.332h-1v-1h1a3.67 3.67 0 0 1 3.667 3.667A3.67 3.67 0 0 1 11 11.665M4.667 7.5h6.666v1H4.667z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPreviewLinkIcon;
