import React, { FC } from 'react';
import styles from './Input.module.scss';

interface InputProps {
  props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
}

const Input: FC<InputProps> = ({ props }) => {
  //const classRecord =
  return <input className={styles.Input} {...props} />;
};

export default Input;
