import * as React from 'react';
import type { SVGProps } from 'react';

const SvgScriptMenuSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} fill="none" {...props}>
    <rect width={32} height={32} y={0.5} fill="#08282D" rx={8} />
    <path
      fill="#F5FAEC"
      fillRule="evenodd"
      d="M18.218 11.714a.75.75 0 1 0-1.436-.43l-3 10a.75.75 0 0 0 1.436.43zm-5.688 1.754a.75.75 0 0 1 0 1.06l-1.97 1.97 1.97 1.97a.75.75 0 0 1-1.06 1.06l-2.5-2.5a.75.75 0 0 1 0-1.06l2.5-2.5a.75.75 0 0 1 1.06 0m6.94 6.06a.75.75 0 0 1 0-1.06l1.97-1.97-1.97-1.97a.75.75 0 0 1 1.06-1.06l2.5 2.5a.75.75 0 0 1 0 1.06l-2.5 2.5a.75.75 0 0 1-1.06 0"
      clipRule="evenodd"
    />
    <circle cx={28} cy={27.5} r={6} fill="#C0E666" />
    <path
      fill="#052025"
      d="M30.307 25.66a.578.578 0 0 1 .825.809l-3.076 3.846a.577.577 0 0 1-.832.016l-2.04-2.04a.578.578 0 1 1 .817-.817l1.614 1.613 2.677-3.41z"
    />
  </svg>
);
export default SvgScriptMenuSuccess;
