import React, { FC, useEffect, useState } from 'react';
import styles from './ModalScriptEmbeding.module.scss';
import LoadingDots from 'components/ui/LoadingDots/LoadingDots';
import Modal from 'components/ui/Modal/Modal';
import Heading from 'components/ui/Heading/Heading';
import Box from 'components/ui/Box/Box';
import { registerWebflowCustomScript } from 'api/webflow';
import CloseSVG from 'assets/icons/CloseSVG';

interface ModalScriptEmbedingProps {
  handleSuccess: () => void;
  handleScriptDuplicate: () => void;
  handleServerError: () => void;
  handleCancelClick: () => void;
  url: string;
  propertyId: string;
}

const ModalScriptEmbedding: FC<ModalScriptEmbedingProps> = ({
  handleSuccess,
  handleScriptDuplicate,
  handleServerError,
  handleCancelClick,
  url,
  propertyId
}) => {
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const registerScript = async () => {
    try {
      const message = await registerWebflowCustomScript({
        propertyId: propertyId,
        url: url
      });
      handleSuccess();
    } catch (error: any) {
      error.data == 'script_already_exists' && error.status == 400 ? handleScriptDuplicate() : handleServerError()
    }
  };
  useEffect(() => {
    registerScript();
    setTimeout(() => {
      setShowCloseIcon(true);
    }, 5000);
  }, []);
  return (
    <Modal className={styles.modalScriptEmbeding} sx={{ height: '200px', width: '40%' }}>
      {showCloseIcon && <CloseSVG className={styles.closeIcon} onClick={handleCancelClick} />}

      <Box gap={16} className={styles.modalContainer} sx={{ justifyContent: 'flex-start' }}>
        <LoadingDots />
        <Heading>Embedding custom script...</Heading>
      </Box>
    </Modal>
  );
};

export default ModalScriptEmbedding;
