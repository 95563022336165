const Google = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="92" height="93" fill="none" viewBox="0 0 92 93">
    <circle cx="46" cy="46.5" r="46" fill="#fff"></circle>
    <path
      fill="#4285F4"
      fillRule="evenodd"
      d="M70 47.546c0-1.702-.156-3.339-.445-4.91H46.49v9.284h13.18c-.568 3-2.293 5.542-4.887 7.244v6.022h7.915C67.328 61.007 70 54.855 70 47.546z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#34A853"
      fillRule="evenodd"
      d="M46.49 71c6.612 0 12.156-2.149 16.208-5.814l-7.915-6.022c-2.193 1.44-4.998 2.29-8.293 2.29-6.379 0-11.778-4.221-13.703-9.894h-8.182v6.218C28.635 65.622 36.917 71 46.49 71z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FBBC05"
      fillRule="evenodd"
      d="M32.787 51.56a14.164 14.164 0 01-.768-4.56c0-1.582.278-3.12.768-4.56v-6.218h-8.182a23.601 23.601 0 000 21.556l8.182-6.218z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#EA4335"
      fillRule="evenodd"
      d="M46.49 32.545c3.595 0 6.824 1.211 9.362 3.59l7.024-6.884C58.635 25.378 53.09 23 46.49 23c-9.573 0-17.856 5.378-21.885 13.222l8.182 6.218c1.925-5.673 7.324-9.895 13.703-9.895z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default Google;
