import { apiCall } from 'api';
import { Report } from './types';

export const getReport = async (code: string) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `reports/data/${code}`, method: 'GET' });

    return data.report as Report;
  } catch (error) {}
};

export const createReport = async (report: Report) => {
  const request = await apiCall();

  try {
    const { data } = await request({
      url: `/reports`,
      method: 'POST',
      data: report
    });

    return data;
  } catch (error: any) {}
};
