import React, { FC, HTMLAttributes, ReactNode, SVGProps } from 'react';
import styles from './Button.module.scss';

interface ButtonProps {
  children?: ReactNode;
  Icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  sx?: React.CSSProperties;
  className?: string;
  type?: 'outlined' | 'outline-hollow' | 'primary' | 'delete' | 'outlinedPrimary' | 'google';
  color?: 'neutral-400' | 'primary-400' | 'white' | 'outline' | 'none';
  padding?: 'big' | 'small' | 'none';
  size?: 's' | 'm' | 'a' | 'buttonm';
  disabled?: boolean;
  handleClick?: () => void;
}

const buttonMap = {
  outlined: styles.outlined,
  'outline-hollow': styles.outlineHollow,
  primary: styles.primary,
  delete: styles.delete,
  outlinedPrimary: styles.outlinedPrimary,
  google: styles.google
};

const colorMap = {
  'neutral-400': styles.colorNeutral,
  'primary-400': styles.colorPrimary,
  outline: styles.colorOutline,
  white: styles.white,
  none: styles.none
};

const paddingMap = {
  small: styles.paddingSmall,
  big: styles.paddingBig,
  none: styles.paddingNone
};

const sizeMap = {
  s: styles.s,
  a: '',
  m: styles.m,
  buttonm: styles.buttonm
};

const Button: FC<ButtonProps> = ({
  children,
  Icon,
  sx,
  className = '',
  type = 'primary',
  color = 'primary-400',
  padding = 'small',
  size = 'a',
  disabled = false,
  handleClick
}) => {
  const isDisabled = disabled ? `${styles.disabled}` : ``;

  const classRecord = `${className} ${buttonMap[type]} ${styles.button} ${colorMap[color]} ${paddingMap[padding]} ${isDisabled} ${sizeMap[size]}`;

  return (
    <div className={classRecord} style={sx} onClick={disabled ? () => {} : handleClick}>
      {children}
      {Icon && <Icon />}
    </div>
  );
};

export default Button;
