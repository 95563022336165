const NotFoundIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" fill="none" viewBox="0 0 48 49" {...props}>
    <path
      fill="#F68989"
      d="M24 .973l7.92 10.282 12.865 1.718-4.945 12 4.945 12L31.92 38.69 24 48.972 16.08 38.69 3.215 36.972l4.945-12-4.945-12 12.865-1.718L24 .973z"
    ></path>
    <path
      stroke="#031B1F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      d="M30 20.174l-8.4 9.6-3.6-3.6"
    ></path>
  </svg>
);

export default NotFoundIconSVG;
