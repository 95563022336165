import type { SVGProps } from 'react';
import * as React from 'react';

const SvgExitIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#C6D7CD"
      fillRule="evenodd"
      d="M3.91 13.422c.16.157.367.243.59.243h4.833v1.004H4.5c-.49 0-.95-.19-1.297-.537a1.82 1.82 0 0 1-.536-1.297V3.17c0-1.014.823-1.837 1.833-1.837h4.833v1.003H4.5a.834.834 0 0 0-.833.834v9.663a.83.83 0 0 0 .243.59m7.11-8.613 2.763 2.763a.84.84 0 0 1 0 1.18l-2.763 2.763-.707-.706 2.143-2.144h-5.79v-1h5.797l-2.15-2.15z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgExitIcon;
