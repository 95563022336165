import { FC, useEffect, useState } from 'react';
import styles from './UserAndProjectAuthorizationPage.module.scss';
import VerifyEmailContent from './VerifyEmailContent/VerifyEmailContent';
import Box from 'components/ui/Box/Box';
import SetupGuide from './SetupGuide/SetupGuide';
import { useAuthStore } from 'store/auth';
import GoogleAuthorization from './GoogleAuthorization/GoogleAuthorization';
import WebflowAuthorization from './WebflowAuthorization/WebflowAuthorization';
import ConnectGoogleAnalytics from './ConnectGoogleAnalytics/ConnectGoogleAnalytics';
import ConnectGoogleAnalyticsLoading from 'pages/UserAndProjectAuthorization/ConnectGoogleAnalyticsLoading/ConnectGoogleAnalyticsLoading';
import ConnectGoogleAnalyticsSuccess from './ConnectGoogleAnalyticsSuccess/ConnectGoogleAnalyticsSuccess';
import Container from 'components/ui/Container/Container';
import { removeWebiste } from 'api/auth';
import { useLoadingStore } from 'store/loading';
import OverlayLoading from 'components/Overlay/Loading/Loading';

interface UserAndProjectAuthorizationPageProps {
  email?: any;
}

class Step {
  static readonly verification = {
    state: 'verification',
    progressBar: 0,
    number: 1
  };

  static readonly google = {
    state: 'google',
    progressBar: 20,
    number: 2
  };
  static readonly webflow = {
    state: 'webflow',
    progressBar: 40,
    number: 3
  };
  static readonly analytics = {
    state: 'analytics',
    progressBar: 60,
    number: 4
  };
  static readonly loading = {
    state: 'loading',
    progressBar: 80,
    number: 5
  };
  static readonly finished = {
    state: 'finished',
    progressBar: 100,
    number: 6
  };
}

const UserAndProjectAuthorizationPage: FC<UserAndProjectAuthorizationPageProps> = ({ email }) => {
  const [websiteProjectList, setWebsiteProjectList] = useState<any>([]);
  const [selectedProject, setSelectedProject] = useState<any>();
  const [step, setStep] = useState<any>(Step.verification);
  const { user, googleAuthorized, webflowAuthorized, setGoogleAnalyticsConnected } = useAuthStore();
  const { loadingPage, setLoadingPage } = useLoadingStore();
  useEffect(() => {
    if (!user?.isVerified) {
      setStep(Step.verification);
    } else if (!googleAuthorized) {
      setStep(Step.google);
    } else if (!webflowAuthorized) {
      setStep(Step.webflow);
    } else setStep(Step.analytics);
  }, [user, googleAuthorized, webflowAuthorized, loadingPage]);

  const handleNextClick = async (websiteProjectList: any, websiteIndex: number) => {
    setStep(Step.loading);
    setWebsiteProjectList(websiteProjectList);
    setSelectedProject(websiteProjectList[websiteIndex]);
  };

  const handleRemoveProject = async (shortName: string, index: number) => {
    await removeWebiste(shortName, index);

    websiteProjectList.splice(index, 1);
    setWebsiteProjectList([...websiteProjectList]);
  };

  const handleConnectingAnalyticsSuccess = () => {
    setStep(Step.finished);
  };

  return (
    <>
      {loadingPage ? (
        <OverlayLoading />
      ) : (
        <Box className={styles.UserAndProjectAuthorizationPage} justifyContent="space-between" flexDirection="row">
          {!(step.state === 'loading' || step.state === 'finished') ? (
            <>
              <Box className={styles.content} justifyContent="center">
                {step.state === 'verification' && (
                  <VerifyEmailContent
                    userVerified={() => {
                      setStep(Step.google);
                    }}
                  />
                )}
                {step.state === 'google' && <GoogleAuthorization />}
                {step.state === 'webflow' && <WebflowAuthorization />}
                {step.state === 'analytics' && (
                  <ConnectGoogleAnalytics
                    websiteProjectList={websiteProjectList}
                    setWebsiteProjectList={setWebsiteProjectList}
                    handleNextClick={handleNextClick}
                    handleRemoveProject={handleRemoveProject}
                  />
                )}
                {/* {step.state === 'finish' && <ConnectGoogleAnalyticsSuccess/>} */}
              </Box>
              <Container>
                <Box className={styles.steps} justifyContent="center">
                  <SetupGuide step={step} />
                </Box>
              </Container>
            </>
          ) : (
            <>
              {step.state === 'loading' && (
                <ConnectGoogleAnalyticsLoading
                  websiteProjectList={websiteProjectList}
                  selectedProject={selectedProject}
                  handleSuccess={handleConnectingAnalyticsSuccess}
                />
              )}
              {step.state === 'finished' && <ConnectGoogleAnalyticsSuccess selectedProject={selectedProject} />}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default UserAndProjectAuthorizationPage;
