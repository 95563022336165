// Reoirt comoonent analytics

import { ReportComponent, ReportComponentAnalytics } from './types';

const newVisitorsAnalytics: ReportComponentAnalytics = {
  dimensions: [],
  metrics: [{ name: 'totalUsers' }, { name: 'newUsers' }]
};

const deviceAnalytics: ReportComponentAnalytics = {
  dimensions: [{ name: 'deviceCategory' }],
  metrics: [{ name: 'activeUsers' }]
};

const sessionsAnalytics: ReportComponentAnalytics = {
  dimensions: [],
  metrics: [{ name: 'averageSessionDuration' }, { name: 'engagementRate' }]
};

const formSubmissionsAnalytics: ReportComponentAnalytics = {
  dimensions: [{ name: 'eventName' }],
  metrics: [{ name: 'eventCount' }]
};

const linkClicksAnalytics: ReportComponentAnalytics = {
  dimensions: [{ name: 'eventName' }],
  metrics: [{ name: 'eventCount' }, { name: 'totalUsers' }]
};

const outboundClicksAnalytics: ReportComponentAnalytics = {
  dimensions: [{ name: 'linkDomain' }],
  metrics: [{ name: 'totalUsers' }]
};

const websiteClicksAnalytics: ReportComponentAnalytics = {
  dimensions: [{ name: 'firstUserDefaultChannelGroup' }],
  metrics: [{ name: 'totalUsers' }, { name: 'sessions' }]
};

const mediumsAnalytics: ReportComponentAnalytics = {
  dimensions: [{ name: 'firstUserSource' }],
  metrics: [{ name: 'totalUsers' }, { name: 'sessions' }]
};

const countriesAnalytics: ReportComponentAnalytics = {
  dimensions: [{ name: 'country' }],
  metrics: [{ name: 'totalUsers' }]
};

const pageRankingAnalytics: ReportComponentAnalytics = {
  dimensions: [{ name: 'pagePath' }],
  metrics: [{ name: 'totalUsers' }]
};

// Report components

const newVisitorsComponent: ReportComponent = {
  name: 'New Visitors',
  id: 'new_visitors_graph',
  analytics: newVisitorsAnalytics
};

const deviceComponent: ReportComponent = {
  name: 'Device',
  id: 'device_chart',
  analytics: deviceAnalytics
};

const sessionsComponent: ReportComponent = {
  name: 'Sessions',
  id: 'sessions_chart',
  analytics: sessionsAnalytics
};

const formSubmissionsComponent: ReportComponent = {
  name: 'Form Submissions',
  id: 'form_submissions_chart',
  analytics: formSubmissionsAnalytics
};

const linkClicksComponent: ReportComponent = {
  name: 'Link Clicks',
  id: 'link_clicks_chart',
  analytics: linkClicksAnalytics
};

const outboundClicksComponent: ReportComponent = {
  name: 'Outbound',
  id: 'outbound_chart',
  analytics: outboundClicksAnalytics
};

const websiteClicksComponent: ReportComponent = {
  name: 'Website Clicks',
  id: 'website_clicks_chart',
  analytics: websiteClicksAnalytics
};

const mediumsComponent: ReportComponent = {
  name: 'Mediums',
  id: 'mediums_chart',
  analytics: mediumsAnalytics
};

const countriesComponent: ReportComponent = {
  name: 'Countries',
  id: 'countries_chart',
  analytics: countriesAnalytics
};

const pageRankingComponent: ReportComponent = {
  name: 'Page Ranking',
  id: 'page_ranking_chart',
  analytics: pageRankingAnalytics
};

// Report components list

export const reportComponents = [
  newVisitorsComponent,
  deviceComponent,
  sessionsComponent,
  formSubmissionsComponent,
  linkClicksComponent,
  outboundClicksComponent,
  websiteClicksComponent,
  mediumsComponent,
  countriesComponent,
  pageRankingComponent
];
