import { ChartPayloadProps } from 'api/previewLink/types';
import { Website } from 'api/users/types';
import { useAnalyticsData } from 'hooks/useAnalyticsData';
import { FC, useEffect, useState } from 'react';
import { formatMinutesAndSeconds } from 'utils';
import { getPayloadSession } from './payloads/payloads';
import { getReportAnalyticsV2 } from 'api/analytics';

interface SessionChart {
  website?: Website;
  shortName?: string;
  propertyId: string;
  startDate: string;
  endDate: string;
}

export const SessionsChart: FC<SessionChart> = ({ propertyId, startDate, endDate }) => {
  const [active, setActive] = useState(0);
  const [sessionData, setSessionData] = useState<any>(null);
  const [chartaData, setChartData] = useState<any>(null);

  //getReportAnalyticsV2(payload)
  const fetchV = async () => {
    const payloadSession = getPayloadSession(propertyId, startDate, endDate);
    const sessionData = await getReportAnalyticsV2(payloadSession);
    setSessionData(sessionData);
  };
  // payload={{
  //   key: ['device', website.propertyId],
  //   dimensions: ['deviceCategory'],
  //   metrics: ['activeUsers'],
  //   propertyId: website.propertyId
  // }}
  useEffect(() => {
    fetchV();
  }, [startDate, propertyId]);
  return (
    <div className="sessionChart">
      <div className="chartPaper sessions" style={{ height: '130%' }}>
        <h2 className="typo-heading-s">Sessions</h2>
        <div className="row">
          <div className="item">
            <p className="typo-body-xxs">Avg. engagement time</p>
            {sessionData && (
              <span className="typo-label-l">
                {formatMinutesAndSeconds(parseFloat(sessionData[0]?.averageSessionDuration))}
              </span>
            )}
          </div>
          <div className="item">
            <p className="typo-body-xxs">Engaged sessions per user</p>
            {sessionData && (
              <span className="typo-label-l">{parseFloat(sessionData[0]?.engagementRate).toFixed(2)}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
