import React, { useEffect, useState } from 'react';

import { getReportAnalytics, getReportAnalyticsV2 } from 'api/analytics';
import { RunReportPayload } from 'api/analytics/types';
import { Website } from 'api/users/types';
import { fetchSources } from '../utils/fetchSources';
import { fetchTopPages } from '../utils/fetchTopPages';
import { fetchChartData } from '../utils/fetchChartData';
import { useAnalyticsStore } from 'store/AnalyticsStore';
import { getPayloadConversions, getPayloadImpressions, getPayloadPageLocation } from './payloads';
import { compareImpressions, compareStatistics } from '../utils/compareStatistics';
import { formLocation } from '../utils/formLocation';
import { useAuthStore } from 'store/auth';
import { fetchInternalReferral } from '../utils/fetchInternalReferral';

export class CancelRequestError extends Error {}

export class NoDataError extends Error {}

interface AllFormsData {
  icon: string;
  formName: string;
  topPerformingPage?: string;
  totalImpressions?: number;
  totalConversions: number;
  conversionRate?: string;
  tabIndex: number;
}

export const useAllFormData = (
  website: Website,
  startDate: string,
  endDate: string,
  totalConversionsRef: React.MutableRefObject<number>,
  activeTabIndex: number,
  setLoading: any,
  loadingNumber: any,
  setLoadingNumber: any,
  changeIndex: any,
  allFormsLoaded: boolean,
  setAllFormsLoaded: React.Dispatch<React.SetStateAction<boolean>>,
  loadingSwitch: any,
  setEmptyState: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { cancelToken } = useAnalyticsStore();
  const { user } = useAuthStore();
  const tabHeaderList: string[] = [];
  const [tabHeader, setTabHeader] = useState<string[]>([]);
  const [formName, setFormNames] = useState<AllFormsData[]>([]);
  const [wholeFormName, setWholeFormName] = useState<string[]>([]);
  const wholeFormNameTemp: string[] = [];
  const [topSources, setTopSources] = useState<any[]>([]);
  const [topPages, setTopPages] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any[]>([]);
  const [location, setLocation] = useState<any[]>([]);
  const [internalData, setInternalData] = useState<any[]>([]);
  const [statistics, setStatistics] = useState<any>();
  const [loadMore, setLoadMore] = useState(true);
  const payloadConversions = getPayloadConversions(website?.propertyId, startDate, endDate);
  const payloadPageLocation = getPayloadPageLocation(website?.propertyId, startDate, endDate);
  const payloadTotalImpressions = getPayloadImpressions(website?.propertyId, startDate, endDate);
  let statisticsData: any;

  async function fetchData() {
    try {
      cancelToken.allForms = new AbortController();
      const signal = cancelToken.allForms.signal;
      const impressionsPromise = getReportAnalyticsV2(payloadTotalImpressions, signal);
      const pageLocationPromise = getReportAnalytics(payloadPageLocation, signal);
      const statisticsPromise = compareStatistics(startDate, endDate, website?.propertyId, signal);
      const conversionsData: any = await getReportAnalytics(payloadConversions, signal);

      if (conversionsData?.length == 0) throw new NoDataError('Nema podataka');

      let allFormsDataArray: AllFormsData[] = conversionsData?.map((item: any, index: number) => {
        let formName = item.dimensionValues?.[0]?.value || '';
        const totalConversions = parseFloat(item.metricValues?.[0]?.value) || 0;
        totalConversionsRef.current += totalConversions;
        wholeFormNameTemp.push(formName);
        return {
          icon: '',
          formName,
          topPerformingPage: '',
          totalImpressions: 0,
          totalConversions,
          conversionRate: '',
          tabIndex: index
        };
      });
      let formNamesWithPageLocation: any[] = [];

      const pageLocationData = await pageLocationPromise; //getReportAnalytics(payloadPageLocation, signal);

      wholeFormNameTemp.forEach((formNameSubmit: any) => {
        pageLocationData.forEach((formNameView, index) => {
          //if (formNameSubmit.slice(10, 40).includes(formNameView.dimensionValues[1].value.slice(13, 40))) {
          if (formNameSubmit.includes(formNameView.dimensionValues[1].value)) {
            let url;
            if (formNameView.dimensionValues[0].value) {
              url = new URL(formNameView.dimensionValues[0].value);
              formNamesWithPageLocation.push({ url: url.pathname, formName: formNameSubmit });
            } else {
              formNamesWithPageLocation.push({ url: 'None', formName: formNameSubmit });
            }
          } else {
            return;
          }
        });
      });
      for (const formData of allFormsDataArray) {
        const matchingData: any = formNamesWithPageLocation.find((item: any) => item.formName == formData.formName);
        if (matchingData) {
          formData.topPerformingPage = matchingData.url === '/' ? '/home' : matchingData.url;
        }

        formData.formName = formData.formName.replace('dg-submit_', '');

        formData.icon = formData.formName.slice(0, 2);

        formData.formName =
          `dg-form-view_${formData.formName}`.length > 40
            ? `dg-form-view_${formData.formName}`.slice(0, 40)
            : `dg-form-view_${formData.formName}`;
        formData.formName = formData.formName.slice(13);
      }

      //let formName = formData.formName;
      //formName = `dg-form-view_${formName}`;

      const impressionsData = await impressionsPromise; //getReportAnalytics(payloadTotalImpressions, signal);
      //const dataImpression = await Promise.all(impressionPromise)
      //compareImpressions(impressionsData)
      let array = allFormsDataArray.map((formObject) => {
        const formImpressions = impressionsData.find((data) => data.eventName.includes(formObject.formName));
        const formPrefixMap: Record<string, string> = {
          wf: 'Webflow',
          hs: 'Hubspot',
          fs: 'Formstack ',
          tf: 'Typeform',
          nt: 'Noteform',
          cl: 'Calendly forms',
          mc: 'Mailhchimp',
          ck: 'Convertkit'
        };

        let premium = false;
        for (const key in formPrefixMap) {
          if (formObject.formName.slice(0, 2) == key && key != 'wf') {
            premium = true;
            break;
          }
        }

        if (formPrefixMap[formObject.formName.slice(0, 2)])
          formObject.formName = formObject.formName
            .replace(formObject.formName.slice(0, 2), formPrefixMap[formObject.formName.slice(0, 2)])
            .replace(/-/g, ' ')
            .replace(/_/g, ' ')
            .trim();
        else formObject.formName = formObject.formName.replace(/-/g, ' ').replace(/_/g, ' ');

        let formNameSplit = formObject.formName.split(' ');

        formNameSplit = formNameSplit.map((name) => {
          name = name?.toLowerCase();
          name = name[0]?.toUpperCase() + name.substring(1);
          return name;
        });

        formObject.formName = formNameSplit.toString().replace(/,/g, ' ');

        if (formImpressions) {
          formObject.totalImpressions = parseFloat(formImpressions!.eventCount);
          formObject.conversionRate =
            ((formObject.totalConversions / (formObject.totalImpressions as number)) * 100).toFixed(0) + '%';
        } //return form
        else {
          formObject.topPerformingPage = formObject.topPerformingPage ? formObject.topPerformingPage : 'None';
          formObject = { ...formObject, totalImpressions: 0, conversionRate: '0%' };
        }
        if (premium) {
          if (user?.tier.limitation.showEmbeddedForms) {
            tabHeaderList.push(formObject.formName);
            return formObject;
          }
        } else {
          tabHeaderList.push(formObject.formName);
          return formObject;
        }
      });
      array = array.filter((el) => el != undefined) as AllFormsData[];
      if (array && array?.length == 0) throw new NoDataError('Nema podataka');
      allFormsDataArray = array as AllFormsData[];
      statisticsData = await statisticsPromise;
      if (
        statisticsData == undefined ||
        conversionsData == undefined ||
        impressionsData == undefined ||
        pageLocationData == undefined
      )
        throw new CancelRequestError('Request Cancel');
      return new Promise<any>((resolve) => {
        resolve(allFormsDataArray);
      });
    } catch (e) {
      if (e instanceof NoDataError) throw new NoDataError('Nema podata');
      else {
        throw new CancelRequestError('Request Cancel');
      }
    }
  }

  const fetchSingleFormData = async () => {
    try {
      const topSourcesPromise = fetchSources(
        wholeFormName[activeTabIndex - 1],
        website,
        startDate,
        endDate,
        cancelToken
      );
      let pagesPromise: any;
      const chartPromise = fetchChartData(wholeFormName[activeTabIndex - 1], website, startDate, endDate, cancelToken);

      const internalPromise = fetchInternalReferral(
        wholeFormName[activeTabIndex - 1],
        website,
        startDate,
        endDate,
        cancelToken
      );
      pagesPromise = fetchTopPages(wholeFormName[activeTabIndex - 1], website, startDate, endDate, cancelToken);
      cancelToken.singleForm = new AbortController();
      const signal = cancelToken.singleForm.signal;
      const location = await formLocation(startDate, endDate, website?.propertyId, signal);
      const topSourcesData = await topSourcesPromise;
      const pagesData = await pagesPromise;
      const chartData = await chartPromise;
      const internalData = await internalPromise;
      if (
        topSourcesData === undefined ||
        pagesData === undefined ||
        chartData === undefined ||
        internalData === undefined
      ) {
        throw 'Nema podataka';
      }

      return new Promise<any>((resolve, reject) => {
        resolve({ topSourcesData, pagesData, chartData, location, internalData });
      });
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    if (!allFormsLoaded) {
      fetchData()
        .then((data) => {
          setFormNames(data);
          setTabHeader(tabHeaderList);
          setWholeFormName(wholeFormNameTemp);
          setStatistics(statisticsData);
          setAllFormsLoaded(true);
          setLoading(false);
          if (data?.length === 1) changeIndex(1);
          else changeIndex(0);
        })
        .catch((e) => {
          if (e instanceof NoDataError) {
            setLoading(false);
            setEmptyState(true);
          }
        });
    } else {
      setLoading(false);
    }
  }, [loadingSwitch[0]]);

  useEffect(() => {
    if (activeTabIndex !== 0) {
      fetchSingleFormData()
        .then((data: any) => {
          const { topSourcesData, pagesData, chartData, location, internalData } = data;
          setTopSources(topSourcesData);
          setTopPages(pagesData);
          setChartData(chartData);
          setLocation(location);
          setInternalData(internalData);
          if (location.length <= 3) setLoadMore(false);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [loadingSwitch[1]]);
  return {
    tabHeader,
    setTabHeader,
    formName,
    wholeFormName,
    topSources,
    topPages,
    chartData,
    internalData,
    setFormNames,
    statistics,
    location,
    loadMore,
    setLoadMore
  };
};
