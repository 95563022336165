const FourDotsSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="4" fill="none" viewBox="0 0 23 4">
    <circle cx="2.715" cy="2" r="2" fill="#DDE9C5"></circle>
    <circle cx="8.715" cy="2" r="2" fill="#DDE9C5"></circle>
    <circle cx="14.715" cy="2" r="2" fill="#DDE9C5"></circle>
    <circle cx="20.715" cy="2" r="2" fill="#DDE9C5"></circle>
  </svg>
);

export default FourDotsSVG;
