import React, { FC } from 'react';
// @ts-ignore
import styles from './CustomLineChart.module.scss';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomTooltip from './CustomTooltip/CustomTooltip';

//import { max } from 'lodash';

interface ChartData {
  conversion: number;
  conversionRate: string;
  impression: number;
  name: string;
}

interface CustomLineChartProps {
  activeLine: number[];
  chartData?: ChartData[];
}

const CustomLineChart: FC<CustomLineChartProps> = ({ activeLine, chartData }) => {
  let conversionRateMax = 0;
  let conversionRateMin = 0;
  let conversionMax = 0;
  let conversionMin = 0;
  let impressionMax = 0;
  let impressionMin = 0;

  let min = 0;
  let max = 0;
  if (chartData) {
    if (activeLine.includes(1)) {
      impressionMax = Math.max(...chartData!.map((value) => value.impression));
      impressionMin = Math.min(...chartData!.map((value) => value.impression));
    }
    if (activeLine.includes(2)) {
      conversionMax = Math.max(...chartData!.map((value) => value.conversion));
      conversionMin = Math.min(...chartData!.map((value) => value.conversion));
    }
    if (activeLine.includes(3)) {
      conversionRateMax = Math.max(...chartData!.map((value) => parseInt(value.conversionRate)));
      conversionRateMin = Math.min(...chartData!.map((value) => parseInt(value.conversionRate)));
    }
  }
  max = Math.max(conversionMax, conversionRateMax, impressionMax);
  min = Math.min(conversionMin, conversionRateMin, impressionMin);
  return (
    <ResponsiveContainer width={`100%`} height={200} className={styles.CustomLineChart}>
      <LineChart
        height={300}
        data={chartData}
        margin={{
          top: 5,
          right: -30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid vertical={false} stroke="#134C54" />
        <XAxis dataKey="name" className={styles.XAxis} stroke="#799892" />
        <YAxis
          orientation="right"
          className={styles.YAxis}
          stroke="#799892"
          type="number"
          domain={[min, max]}
          allowDataOverflow={false}
        />
        {<Tooltip animationDuration={500} offset={20} content={<CustomTooltip />} />}
        {activeLine.includes(1) && (
          <Line
            type="linear"
            dataKey="impression"
            stroke="#C0E666"
            activeDot={{ stroke: 'white', strokeWidth: 1, fill: 'white', r: 3 }}
            dot={false}
          />
        )}
        {activeLine.includes(2) && (
          <Line
            type="linear"
            dataKey="conversion"
            stroke="#FFC56E"
            activeDot={{ stroke: 'white', strokeWidth: 1, fill: 'white', r: 3 }}
            dot={false}
          />
        )}
        {activeLine.includes(3) && (
          <Line
            type="linear"
            dataKey="conversionRate"
            stroke="#FF6E6E"
            activeDot={{ stroke: 'white', strokeWidth: 1, fill: 'white', r: 3 }}
            dot={false}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomLineChart;
