import { getReportAnalyticsV2 } from 'api/analytics';
import { getPayloadDestinationConversions, getPayloadDestinationImpressions } from './payloads';

export const formLocation = async (startDate: string, endDate: string, propertyId: string, signal: AbortSignal) => {
  try {
    // Fetch data for active users per source
    const impressionPayload = getPayloadDestinationImpressions(propertyId, startDate, endDate);
    const conversionPayload = getPayloadDestinationConversions(propertyId, startDate, endDate);

    const impressionsPromise = getReportAnalyticsV2(impressionPayload, signal);

    const conversions = await getReportAnalyticsV2(conversionPayload, signal);
    const impressions = await impressionsPromise;

    const conversionsSlice = conversions.slice(0, 9);
    const result: Record<string, string>[] = [];
    conversionsSlice.forEach((conversion, index) => {
      const pair: Record<string, string> | undefined = impressions.find(
        (impression) => impression.pageLocation == conversion.pageLocation
      );
      if (pair) {
        let conversionRate = (parseFloat(conversion.eventCount) / parseFloat(pair.activeUsers)) * 100;
        let conversionRateRes;
        if (conversionRate >= 1 || conversionRate == 0) conversionRateRes = conversionRate.toFixed(0) + '%';
        else conversionRateRes = conversionRate.toFixed(2) + '%';
        let pageLocation = extractPath(conversion.pageLocation);
        const resultRecord = {
          Destination: pageLocation,
          Users: pair.activeUsers,
          Conversions: conversion.eventCount,
          'Conversion Rate': conversionRateRes
        };
        result.push(resultRecord);
      }
    });

    return new Promise((resolve) => {
      resolve(result);
    });
  } catch (e) {
    return new Promise((reject) => reject(undefined));
  }
};

function extractPath(url: string) {
  if (!url || url === 'None') return 'None';
  try {
    const location = new URL(url).pathname;
    return location === '/' ? '/home' : location;
  } catch (e) {
    console.error(e);
    return 'None';
  }
}
