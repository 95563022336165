import { create } from 'zustand';

import { User } from 'api/users/types';
import { getStorageItem } from 'utils/storage';

interface State {
  isLogged: boolean;
  setIsLogged: (isLogged: State['isLogged']) => void;

  user: User | null;
  setUser: (user: State['user']) => void;

  googleAuthorized: boolean | null;
  setGoogleAuthorized: (googleAuthorized: State['googleAuthorized']) => void;

  webflowAuthorized: boolean | null;
  setWebflowAuthorized: (webflowAuthorized: State['webflowAuthorized']) => void;

  googleAnalyticsConnected: boolean | null;
  setGoogleAnalyticsConnected: (googleAnalyticsConnected: State['googleAnalyticsConnected']) => void;

  wfAuthCode: string;
  setWfAuthCode: (wfAuthCode: State['wfAuthCode']) => void;

  authStep: number;
  setAuthStep: (authStep: State['authStep']) => void;
}

const useAuthStore = create<State>((set) => ({
  isLogged: !!getStorageItem('token'),
  setIsLogged: (isLogged: State['isLogged']) => set({ isLogged }),

  user: null,
  setUser: (user: State['user']) => set({ user }),

  googleAuthorized: false,
  setGoogleAuthorized: (googleAuthorized: State['googleAuthorized']) => set({ googleAuthorized }),

  webflowAuthorized: false,
  setWebflowAuthorized: (webflowAuthorized: State['webflowAuthorized']) => set({ webflowAuthorized }),

  googleAnalyticsConnected: false,
  setGoogleAnalyticsConnected: (googleAnalyticsConnected: State['googleAnalyticsConnected']) =>
    set({ googleAnalyticsConnected }),

  wfAuthCode: '',
  setWfAuthCode: (wfAuthCode: State['wfAuthCode']) => set({ wfAuthCode }),

  authStep: 0,
  setAuthStep: (authStep: State['authStep']) => set({ authStep })
}));

export { useAuthStore };
