import { create } from 'zustand';

export type TooltipType = 'none' | 'script';

interface State {
  tooltip: TooltipType;
  setTooltip: (tooltip: TooltipType) => void;
  tooltipData: any;
  setTooltipData: (tooltipData: any) => void;
}

const useTooltipStore = create<State>((set) => ({
  tooltip: 'script',
  setTooltip: (tooltip) =>
    set({
      tooltip: tooltip
    }),
  tooltipData: {},
  setTooltipData: (tooltipData) => set({ tooltipData: tooltipData })
}));

export { useTooltipStore };
