import { FormikProps } from 'formik';
import { getFormikError, hasError } from 'utils/formik';

interface Props {
  label: string;
  name: string;
  formik?: FormikProps<any>;
}

type InputT = Props & React.InputHTMLAttributes<HTMLInputElement>;

export const InputField = ({ label, name, formik, ...rest }: InputT) => {
  const error = getFormikError({ formik, name });
  const isValid = hasError({ formik, name });

  return (
    <div className={`inputFieldWrapper ${isValid ? 'valid' : 'invalid'}`}>
      <div className="inputField">
        <label className="typo-label-l">{label}</label>
        <input name={name} value={formik?.values[name] || ''} onChange={formik?.handleChange} {...rest} />
      </div>
      {isValid && <p className="typo-label-s errorMsg">{error}</p>}
    </div>
  );
};
