import React, { FC } from 'react';
import styles from './GoogleAuthorization.module.scss';
import { GoogleIcon, GoogleLogoIcon } from 'assets/icons/export';
import Box from 'components/ui/Box/Box';
import Label from 'components/ui/Label/Label';
import Heading from 'components/ui/Heading/Heading';
import Text from 'components/ui/Text/Text';
import { useMutation } from 'react-query';
import { signInWithGoogle } from 'api/auth';
import Button from 'components/ui/Button/Button';
import Wrapper from 'components/ui/Wrapper/Wrapper';

interface GoogleAuthorizationProps {}

const GoogleAuthorization: FC<GoogleAuthorizationProps> = () => {
  const { mutate: signInWithGoogleMutate, isLoading: isLoadingGoogle } = useMutation(signInWithGoogle, {
    onSuccess: (data) => {
      window.location.href = data.authUrl;
    }
  });

  const loginGoogle = () => {
    signInWithGoogleMutate();
  };

  return (
    <Box gap={48} className={styles.GoogleAuthorization}>
      <GoogleIcon />
      <Box gap={12}>
        <Label size="l">Before we start...</Label>
        <Box gap={24} alignItems="flex-start">
          <Box gap={16}>
            <Heading size="xl">Give Data Goat access to you GA4</Heading>
            <Text size="m" color="primary-100">
              Data Goat needs access to your Google account to collect website data and provide valuable insights for
              your Webflow site.
              <br />
              <Text bold={true} size="m" color="primary-100">
                Note: choose the account that is connected to the ga4 account you want to connect
              </Text>
            </Text>
          </Box>
          <Button
            type="google"
            padding="none"
            disabled={isLoadingGoogle}
            handleClick={() => {
              loginGoogle();
            }}
            color="white"
          >
            <Box justifyContent="center">
              <Wrapper padding="12px 0 12px 16px">
                <GoogleLogoIcon />
              </Wrapper>
            </Box>
            <Wrapper padding="12px 24px 12px 8px" alignItems="center">
              Authorize Google account
            </Wrapper>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GoogleAuthorization;
