import SignUpCoverImg from 'assets/images/SignUpCoverImg.png';
import { LogoIcon } from 'assets/icons/export';
import { useMutation } from 'react-query';
import { resetPassword } from 'api/auth';
import { useFormik } from 'formik';
import { InputField } from '../components/ui/forms/fields/InputField';
import { resetPasswordYup } from '../utils/yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ResetPasswordPayload } from '../api/auth/types';
import { usePublicRoute } from 'hooks/usePublicRoute';

export const ResetPassword = () => {
  usePublicRoute();
  const { mutate, isLoading } = useMutation(resetPassword);
  const location = useLocation();
  const navigate = useNavigate();

  const router = useParams();
  const resetToken = router.resetToken as string;

  const formik = useFormik({
    initialValues: { newPassword: '', repeatNewPassword: '' },
    validationSchema: resetPasswordYup,
    onSubmit: (values) => {
      const payload: ResetPasswordPayload = { ...values, resetToken };
      mutate(payload, { onSuccess: () => navigate('/sign-in') });
    }
  });

  return (
    <div className="signUpPage">
      <div className="left">
        <LogoIcon />

        <div className="signUpForm">
          <h1 className="typo-heading-xl">Reset password</h1>
          <p className="subtitle">Enter your new password</p>
          <form onSubmit={formik.handleSubmit}>
            <InputField name="newPassword" label="New password*" type="password" formik={formik} required />
            <InputField
              name="repeatNewPassword"
              label="Repeat new password*"
              type="password"
              formik={formik}
              required
            />
            <button type="submit" className="primary" disabled={isLoading}>
              Reset
            </button>
          </form>
        </div>
      </div>
      <div className="right">
        <img src={SignUpCoverImg} alt="DataGoat" className="coverPhoto" />
      </div>
    </div>
  );
};
