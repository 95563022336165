import { createReport } from 'api/previewLink';
import { reportComponents } from 'api/previewLink/report';
import { CloseIcon, CopyLinkIcon, ProjectIcon, ShareLinkIcon } from 'assets/icons/export';
import { Modal } from 'components/ui/Modal';
import Select from 'components/ui/Select';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { Tooltip } from 'react-tooltip';
import { useAnalyticsStore } from 'store/AnalyticsStore';

interface Props {
  close: () => void;
  className?: string;
  propertyId: string;
}

type Item = {
  value: any;
  label: any;
};

interface StateType {
  days?: Item;
}

export const ShareLinkModal = ({ close, className, propertyId }: Props) => {
  const [days, setDays] = useState<StateType>({});
  const [isCopied, setIsCopied] = useState(false);
  const [reportPath, setReportPath] = useState();
  const { dateRangeLink, setDateRangeLink } = useAnalyticsStore();

  const { mutate } = useMutation(createReport, {
    onSuccess: (data) => {
      setReportPath(data?.report?.path);
    },
    onError: () => {
      setReportPath(undefined);
    }
  });

  const updateDateRange = (days: any) => {
    setReportPath(undefined);
    setDays(days);
    setDateRangeLink(days.value);
  };

  const report = {
    components: reportComponents,
    propertyId,
    dateRange: dateRangeLink
  };

  const copyLink = () => {
    const value = `https://app.datagoat.co/report/${reportPath}`;

    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 4000);
  };

  const redirectToReport = () => {
    window.open(`https://app.datagoat.co/report/${reportPath}`, '_blank', 'noopener,noreferrer');
    //window.open(`http://localhost:3000/report/${reportPath}`, '_blank', 'noopener,noreferrer');
  };

  const updateLink = () => {
    mutate(report);
  };

  return (
    <Modal close={close} className={className} closeHidden={true}>
      <div className="shareLinkModal">
        <div className="headerShareLink">
          <div className="title">
            <ShareLinkIcon />
            <h3>Share preview link</h3>
          </div>
          <CloseIcon className="closeIconBtn" onClick={close} />
        </div>
        <div className="linkSettings">
          <div className="linkTitle">
            <h2>Link settings</h2>
          </div>
          <div className="linkForm">
            <div className="formInout">
              <div className="label">
                <label>Date range</label>
              </div>
              <div className="selectInut">
                <Select
                  options={selectOptions}
                  value={days.days}
                  onChange={(days: any) => {
                    updateDateRange(days);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="linkWrapper">
          <div className="linkTitle">
            <h2>Link</h2>
          </div>
          <div className="linkInfo">
            {reportPath && (
              <>
                <div className="link">
                  <p>https://data.goat/report/...</p>
                </div>

                <div className="linkIcons">
                  <button onClick={copyLink} data-tooltip-id="copyBtn">
                    <CopyLinkIcon />
                  </button>
                  <button onClick={redirectToReport} data-tooltip-id="previewBtn">
                    <ProjectIcon />
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

        <button className="updateLinkBtn" onClick={updateLink}>
          Update link
        </button>
        <Tooltip id="copyBtn" place="bottom" content={isCopied ? 'Link Copied!' : 'Copy Link'} />
        <Tooltip id="previewBtn" place="bottom" content="Open Preview!" />
      </div>
    </Modal>
  );
};

export const selectOptions: Item[] = [
  {
    value: 1,
    label: 'Yesterday'
  },
  {
    value: 7,
    label: 'Last 7 days'
  },
  {
    value: 31,
    label: 'Last Month'
  },
  {
    value: 183,
    label: 'Last 6 Months'
  },
  {
    value: 365,
    label: 'Last Year'
  }
];
