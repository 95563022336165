import DataGoatImg from 'assets/images/Goat-V3.png';

interface Props {
  close?: () => void;
  className?: string;
  code?: string;
}

export const ExtensionLoginSuccessModal = ({ close, code }: Props) => {
  const isArrivedFromExtension = Boolean(code?.includes('g-ex-'));

  const onClose = () => {
    close?.();
    window.close();
  };

  return (
    <div className="extensionLoginSuccessModal">
      <div className="extensionLoginSuccessModalOuter">
        <img src={DataGoatImg} alt="Data-Goat" />
        <h3>Success!</h3>
        {isArrivedFromExtension ? (
          <h2>Google is authorized.</h2>
        ) : (
          <h2>
            Webflow project is <br />
            authorized.
          </h2>
        )}

        <p>
          <span onClick={onClose}>Please close this tab</span> and return to Webflow Designer to <br /> continue setting
          up Data Goat.
        </p>
      </div>
    </div>
  );
};
