//@ts-nocheck
import React, { FC, useState } from 'react';
import styles from './Test.module.scss';
import LoadingSpinner from 'components/ui/LoadingSpinner/LoadingSpinner';
import PaymentCard from 'components/PaymentCard/PaymentCard';

interface TestProps {}

const Test: FC<TestProps> = () => {
  const [array, setArray] = useState<number[]>([1, 2, 3, 4, 5, 6, 7, 8]);
  return (
    <div className={styles.Test}>
      <PaymentCard features={['asd', 'asd', 'asd', 'asd', 'asd']} plan="Fre" price="12" actionText="Down" />
    </div>
  );
};

export default Test;
