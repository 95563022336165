import React, { FC } from 'react';
// @ts-ignore
import styles from './SubmissionsScriptNotDetectedState.module.scss';
import { ScriptMenuError } from 'assets/icons';
import Button from 'components/ui/Button/Button';
import { ModalType, useModalStore } from 'store/modalStore';
import { useAnalyticsStore } from 'store/AnalyticsStore';
import { ModalScriptType } from 'components/modal/ModalGroupScript/ModalGroupScript';

interface SubmissionsScriptNotDetectedStateProps {}

const SubmissionsScriptNotDetectedState: FC<SubmissionsScriptNotDetectedStateProps> = ({}) => {
  const { website } = useAnalyticsStore();
  const { setModalData, setModal } = useModalStore();
  const { modal, modalTypeInGroup, setModalTypeInGroup } = useModalStore();
  return (
    <>
      <ScriptMenuError className={styles.icon} />
      <div className={styles.text}>
        <div className={styles.heading}>No detected custom script to track custom events</div>
        <div className={styles.body}>
          <div>
            {' '}
            It looks like you haven't implemented our custom script to track custom <br /> events yet. By integrating
            the script, you'll unlock all the powerful <br />
            functionalities that Data Goat has to offer.{' '}
          </div>
          <div>
            <Button
              type="outlined"
              color="outline"
              size="m"
              padding="big"
              handleClick={() => {
                setModalData({
                  url: website.url,
                  propertyId: website.propertyId,
                  shortName: website.shortName
                });
                setModal(ModalType.script);

                setModalTypeInGroup(ModalScriptType.generate);
              }}
            >
              Embed custom script
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmissionsScriptNotDetectedState;
