import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { fetchAuthUser, getGoogleAuthCallback } from 'api/users';
import { setStorageItem } from 'utils/storage';
import { fetchUserSubscription } from 'api/orders';
import { orderKeys } from './api/orders/keys';
import { useOrderStore } from './store/order';
import { authKeys } from './api/auth/keys';
import { useAuthStore } from 'store/auth';

export const App = () => {
  const { isLogged, setIsLogged, setUser } = useAuthStore();
  const { setSubscription } = useOrderStore();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { setAuthStep, setGoogleAuthorized, webflowAuthorized } = useAuthStore();
  //const socket = io('http://localhost:5004');
  const queryParams = new URLSearchParams(search);
  const stateValue = queryParams.get('state');

  const { data: user, refetch: reFetchAuthUser } = useQuery(authKeys.me, fetchAuthUser, {
    enabled: isLogged,
    onSuccess: (res) => res && setUser(res),
    onError: () => {
      setUser(null);
      setIsLogged(false);
      window.location.href = `${window.location.origin}/sign-in`;
    }
  });

  const { data } = useQuery(orderKeys.subscription, fetchUserSubscription, { enabled: !!user });

  useEffect(() => {
    if (data) setSubscription(data);
  }, [data]);

  useEffect(() => {
    // Listening for events from the server
    /*socket.on('FromAPI', (data: any) => {
      //setResponse(data);
    });

    // Example of sending a message to the server
    socket.emit('FromClient', 'Hello from the client!');

    return () => {
      socket.disconnect();
    }; // Clean up on unmount
    */
  }, []);

  // useEffect(() => {
  //   reFetchAuthUser()
  // },[webflowAuthorized])

  useQuery(
    authKeys.googleCallback,
    () => getGoogleAuthCallback(new URLSearchParams(search).get('code') || '', stateValue),
    {
      enabled: search.includes('code'),
      onSuccess: (res) => {
        try {
          console.log('Res', res);
          
          if (!res) return;
          setGoogleAuthorized(true);
          if (res.state !== null && res.state?.length > 10) {
            if (res.data.token) {
              setStorageItem('token', res.data.token);
              window.location.href = '/?state=' + res.state;
            }
          } else {
            if (res.data.token) {
              setStorageItem('token', res.data.token);
              window.location.href = '/';
            } else {
              setAuthStep(res.data.webflowAuthorized || res.data.webflowAuthorized === undefined ? 0 : 2);
              navigate('/');
            }
          }
        } catch(err) {
          console.log('App / ggac catch', err);
          throw err
        }
      }
    }
  );
  return <div />;
};
