import React, { FC, useEffect } from 'react';
import styles from './ModalServerError.module.scss';
import LoadingDots from 'components/ui/LoadingDots/LoadingDots';
import Modal from 'components/ui/Modal/Modal';
import Heading from 'components/ui/Heading/Heading';
import Box from 'components/ui/Box/Box';
import { ScriptMenuError } from 'assets/icons/export';
import Text from 'components/ui/Text/Text';
import Button from 'components/ui/Button/Button';
import CloseSVG from 'assets/icons/CloseSVG';

interface ModalServerErrorProps {
  handleTryAgainClick: () => void;
  handleCancelClick: () => void;
}

const ModalServerError: FC<ModalServerErrorProps> = ({ handleTryAgainClick, handleCancelClick }) => {
  return (
    <Modal className={styles.modalServerError} sx={{ height: '260px', width: '50%' }}>
      <CloseSVG className={styles.closeIcon} onClick={handleCancelClick} />

      <Box gap={32} className={styles.modalContainer} sx={{ justifyContent: 'flex-start' }}>
        <Box gap={8}>
          <ScriptMenuError />
          <Heading size="l">Server error.</Heading>
          <Text size="s">We were not able to connect with server. Please try again.</Text>
        </Box>
        <Box className={styles.action} alignItems="flex-end">
          <Button padding="big" type="outlinedPrimary" color="outline" handleClick={handleTryAgainClick}>
            Try again
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalServerError;
