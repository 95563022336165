import { useOrderStore } from 'store/order';
import { DavidsStarIcon } from '../../../../assets/icons/export';
import dayjs from 'dayjs';

export const ThirdStep = () => {
  const { subscription } = useOrderStore();

  return (
    <div className="thirdStep">
      <DavidsStarIcon />
      <p className="text">Your subscription has been canceled!</p>
      <p className="subtext">
        Your plan is active until {dayjs(subscription?.cancel_at * 1000).format('MMMM D, YYYY h:mm A')} You may continue
        to use the app until then
      </p>
    </div>
  );
};
