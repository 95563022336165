import React, { FC } from 'react';
import styles from './PlanLabel.module.scss';
import Wrapper from 'components/ui/Wrapper/Wrapper';
import Label from 'components/ui/Label/Label';
import WrapperPremium from 'components/ui/WrapperPremium/WrapperPremium';

interface PlanLabelProps {
  children?: React.ReactNode;
}

//padding='16px 4px' borderRadius='30px'
const PlanLabel: FC<PlanLabelProps> = ({ children }) => <WrapperPremium>{children}</WrapperPremium>;

export default PlanLabel;
