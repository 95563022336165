import SignUpCoverImg from 'assets/images/SignUpCoverImg.png';
import { usePublicRoute } from 'hooks/usePublicRoute';
import { GoogleLogoIcon, LogoIcon } from 'assets/icons/export';
import { useMutation } from 'react-query';
import { signIn, signInWithGoogle } from 'api/auth';
import { wfConnect } from 'api/webflow';
import { useAuthStore } from '../store/auth';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { queryClient } from '..';
import { InputField } from '../components/ui/forms/fields/InputField';
import Box from 'components/ui/Box/Box';
import Text from 'components/ui/Text/Text';

export const SignInPage = () => {
  usePublicRoute();

  const { mutate, isLoading } = useMutation(signIn);
  const { mutate: connectWf } = useMutation(wfConnect);
  const { mutate: signInWithGoogleMutate, isLoading: isLoadingGoogle } = useMutation(signInWithGoogle, {
    onSuccess: (data) => {
      window.location.href = data.authUrl;
    }
  });

  const { setIsLogged, wfAuthCode } = useAuthStore();
  const navigate = useNavigate();

  const loginGoogle = () => {
    signInWithGoogleMutate();
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: (values, { setErrors }) => {
      mutate(values, {
        onSuccess: () => {
          navigate('/');
          setIsLogged(true);
          if (wfAuthCode)
            connectWf(
              { code: wfAuthCode, state: '' },
              {
                onSuccess: () => {
                  queryClient.refetchQueries('auth-user');
                }
              }
            );
        },
        onError: (error: any) => setErrors({ email: error?.data.message })
      });
    }
  });

  return (
    <div className="signUpPage">
      <div className="left">
        <LogoIcon />
        <div className="signUpForm">
          <h1 className="typo-heading-xl">Log in to your account</h1>
          <p className="subtitle">
            You don't have account ? <Link to="/sign-up">Sign Up</Link>
          </p>
          <div className="signUpGoogle">
            <Box gap={8} sx={{ width: '100%' }}>
              <button type="button" disabled={isLoadingGoogle} onClick={loginGoogle}>
                <div className="btnContent">
                  <GoogleLogoIcon />
                  <p>Log in with Google</p>
                </div>
              </button>

              <Text size="xs" color="primary-100" sx={{ textAlign: 'center' }}>
                Use the Google account that's connected to the GA4 property
                <br /> you wish to track via Data Goat.
              </Text>
            </Box>
            <div className="signUpOr">
              <div className="line" />
              <p className="typo-body-s">or</p>
              <div className="line" />
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <InputField name="email" label="Email Adress*" type="email" formik={formik} required />
            <InputField name="password" label="Password*" type="password" formik={formik} required />
            <p className="forgot" onClick={() => navigate('/forgot-password')}>
              Forgot password?
            </p>
            <button type="submit" className="primary" disabled={isLoading}>
              Log in
            </button>
          </form>
        </div>
      </div>
      <div className="right">
        <img src={SignUpCoverImg} alt="DataGoat" className="coverPhoto" />
      </div>
    </div>
  );
};
