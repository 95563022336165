import * as React from 'react';
import type { SVGProps } from 'react';

const SvgDropdownArrowFill = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#ECF0ED"
      d="M8.707 10.691a1 1 0 0 1-1.414 0L4.707 8.106c-.63-.63-.184-1.708.707-1.708h5.172c.89 0 1.337 1.078.707 1.708z"
    />
  </svg>
);
export default SvgDropdownArrowFill;
