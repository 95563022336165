import React, { CSSProperties, FC, useEffect, useState } from 'react';
import styles from './WebflowProjectWrapper.module.scss';
import Box from 'components/ui/Box/Box';
import { CheckFilled, CrossErrorFilled } from 'assets/icons';
import Text from 'components/ui/Text/Text';
import Wrapper from 'components/ui/Wrapper/Wrapper';
import RadioButton from 'components/ui/RadioButton/RadioButton';
import RadioButtonActive from 'components/ui/RadioButtonActive/RadioButtonActive';
import Tooltip from 'components/Tooltip/Tooltip';
import { CrossProject } from 'assets/icons/export';

type WebsiteState = 'site_not_published' | 'ga4_no_datastream' | 'ga4_success' | 'already_tracking';

interface WebflowProjectWrapperProps {
  websiteName: string;
  websiteState: WebsiteState;
  shortName: string;
  radioButtonActive?: boolean;
  radioButtonClick?: () => void;
  fadeOutProp: boolean;
  transitionDelay: number;
  index: number;
  handleRemoveProject?: (shortName: string, index: number) => void;
}

const stateMap: Record<WebsiteState, any> = {
  site_not_published: {
    description: 'Not published',
    tooltipText: 'You need to publish your website in order to authorise and track data on that project',
    icon: <CrossErrorFilled />,
    backgroundColor: styles.dark
  },
  ga4_no_datastream: {
    description: 'Ga4 not found on your Google account',
    tooltipText: 'We don’t see Google Analytics property that matches this project',

    icon: <CrossErrorFilled />,
    backgroundColor: styles.dark
  },
  ga4_success: {
    description: 'GA4 found on your Google account',
    tooltipText: 'You can match this project',

    icon: <CheckFilled />,
    backgroundColor: styles.light
  },
  already_tracking: {
    description: 'Already connected',
    tooltipText: 'Tracking analytics for this project',
    icon: <CheckFilled />,
    backgroundColor: styles.dark
  }
};
//className={stateMap[websiteState].backgroundColor}>

const WebflowProjectWrapper: FC<WebflowProjectWrapperProps> = ({
  websiteName,
  websiteState,
  shortName,
  radioButtonActive,
  radioButtonClick,
  fadeOutProp,
  transitionDelay,
  handleRemoveProject,
  index
}) => {
  const [fadeOut, setFadeOut] = useState(false);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (fadeOutProp) setFadeOut(true);
  }, []);
  const classNames =
    fadeOut && !radioButtonActive
      ? `${styles.hidden} ${stateMap[websiteState].backgroundColor}`
      : `${styles.visible} ${stateMap[websiteState].backgroundColor}`;
  const sx: CSSProperties =
    fadeOut && !radioButtonActive
      ? {
          visibility: 'hidden',
          opacity: 0,
          transition: `visibility 0s ${1.5 + transitionDelay}s, opacity 1.5s linear ${transitionDelay}s`
        }
      : websiteState === 'ga4_success'
        ? { cursor: 'pointer' }
        : {};
  return (
    <Wrapper onClick={radioButtonClick} sx={sx}>
      <Box flexDirection="row" justifyContent="space-between" className={classNames}>
        <Box flexDirection="row" alignItems="center" gap={8}>
          {websiteState === 'ga4_success' && (radioButtonActive ? <RadioButtonActive /> : <RadioButton />)}
          {websiteState !== 'ga4_success' ? (
            <Text bold={true} color="neutral-100" size="s">
              {websiteName}
            </Text>
          ) : (
            <Text bold={true} color="white" size="s">
              {websiteName}
            </Text>
          )}
        </Box>
        <Box flexDirection="row" alignItems="center" gap={12}>
          <Box
            sx={{ position: 'relative' }}
            onMouseEnter={() => {
              setShow(true);
            }}
            onMouseLeave={() => {
              setShow(false);
            }}
          >
            {stateMap[websiteState].icon}
            <Tooltip parentWidth={8} show={show}>
              <Box flexDirection="row" alignItems="center" justifyContent="center" gap={8}>
                <Box>{stateMap[websiteState].icon}</Box>
                <Text size="xs" color="neutral-100">
                  {stateMap[websiteState].tooltipText}
                </Text>
              </Box>
            </Tooltip>
          </Box>
          <Text size="xs">{stateMap[websiteState].description}</Text>
          {!fadeOut && websiteState !== 'ga4_success' && (
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                if (handleRemoveProject) handleRemoveProject(shortName, index);
              }}
            >
              <CrossProject />
            </Box>
          )}
        </Box>
      </Box>
    </Wrapper>
  );
};

export default WebflowProjectWrapper;
