import { apiCall } from 'api';

import { DecodeAccountPayload, EditUserPayload, User } from './types';
import { toast } from 'react-toastify';
import { removeStorageItem } from '../../utils/storage';

export const fetchAuthUser = async (): Promise<User | undefined> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/me`, method: 'GET' });

    return data;
  } catch (error) {
    removeStorageItem('token');
    throw new Error();
  }
};

export const getGoogleAuthUrl = async (): Promise<string | undefined> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/google`, method: 'GET' });

    return data.authUrl;
  } catch (error) {}
};

export const getGoogleAuthUrlForLogin = async (): Promise<string | undefined> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/google/login`, method: 'GET' });

    return data.authUrl;
  } catch (error) {}
};

export const getGoogleAuthCallback = async (code: string, state: string | null) => {
  try {
    const request = await apiCall();

    const url = `users/google/callback?code=${code}${state ? `&state=${state}` : ''}`;

    const { data } = await request({ url: url, method: 'GET' });
    toast.success('Google connected successfully');

    return { data, state };
  } catch (error) {
    console.log('Google Auth Callback - catch block:', error);
    
  }
};

export const editUseInfo = async (payload: EditUserPayload) => {
  try {
    const request = await apiCall();
    await request({ url: `users/editUserInfo`, method: 'PATCH', data: payload });
    toast.success('Profile updated successfully');
  } catch (err: any) {
    toast.error(err?.data?.message);
  }
};

export const editUser = async (payload: EditUserPayload) => {
  try {
    const request = await apiCall();
    await request({ url: `users/editUser`, method: 'PATCH', data: payload });
  } catch (err: any) {
    toast.error(err?.data?.message);
  }
};

export const deleteAccount = async (payload: DecodeAccountPayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/delete`, method: 'DELETE', data: payload });
    toast.success('Account deleted successfully');
  } catch (err: any) {
    toast.error(err?.data?.message);
  }
};

export const undeleteAccount = async () => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/undelete`, method: 'POST' });
  } catch (err: any) {
    toast.error(err?.data?.message);
  }
};

export const checkPassword = async (password: string) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/checkPassword`, method: 'POST', data: { password } });
    return data;
  } catch (err: any) {
    toast.error(err?.data?.message);
  }
};

export const verifyCode = async (code: string) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/verify`, method: 'POST', data: { code } });
    return data;
  } catch (err: any) {
    toast.error(err?.data?.message);
  }
};

export const trackWebsiteAnalytics = async (websiteProject: any) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/trackWebsite`, method: 'POST', data: { websiteProject } });
    return data;
  } catch (err: any) {
    throw err;
    toast.error(err?.data?.message);
  }
};

export const getActiveTier = async () => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/getActiveTier`, method: 'GET' });
    return data;
  } catch (err: any) {
    //throw err;
    //toast.error(err?.data?.message);
  }
};

export const getUserWebsites = async () => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/getUserWebsites`, method: 'GET' });
    return data;
  } catch (err: any) {
    //throw err;
    //toast.error(err?.data?.message);
  }
};

export const checkWebsiteLimit = async () => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/checkWebsiteLimit`, method: 'GET' });
    return data;
  } catch (err: any) {
    throw err;
  }
};

export const updateShowFreeTrial = async () => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/updateShowFreeTrial`, method: 'PATCH' });
    return data;
  } catch (err: any) {
    throw err;
  }
};

export const updatePaymentError = async () => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/updatePaymentError`, method: 'PATCH' });
    return data;
  } catch (err: any) {
    throw err;
  }
};
