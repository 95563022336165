import type { SVGProps } from 'react';
import * as React from 'react';

const SvgStarPayment = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <g clipPath="url(#star_payment_svg__a)">
      <path
        fill="#C0E666"
        d="M12.28 2c0 8-6.187 10-9.28 10 6.716 0 8.985 6.667 9.28 10 0-7.273 6.48-9.697 9.72-10-7.423 0-9.574-6.667-9.72-10"
      />
    </g>
    <defs>
      <clipPath id="star_payment_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgStarPayment;
