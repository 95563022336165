import React, { FC, useEffect } from 'react';
import Box from 'components/ui/Box/Box';
import WebflowProjectList from '../WebflowProjectList/WebflowProjectList';
import { WebflowBadgeSuccess } from 'assets/icons';
import { useAuthStore } from 'store/auth';

interface ConnectGoogleAnalyticsSuccessProps {
  selectedProject: any;
}

const ConnectGoogleAnalyticsSuccess: FC<ConnectGoogleAnalyticsSuccessProps> = ({ selectedProject }) => {
  const { setGoogleAnalyticsConnected } = useAuthStore();

  useEffect(() => {
    setTimeout(() => {
      setGoogleAnalyticsConnected(true);
    }, 1500);
  }, []);
  return (
    <Box gap={80} justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%' }}>
      <WebflowBadgeSuccess />
      <Box justifyContent="center" alignItems="normal" sx={{ width: '60%' }}>
        <WebflowProjectList
          webflowProjectList={[
            {
              radioButtonActive: true,
              radioButtonClick: () => {},
              ...selectedProject,
              fadeOutProp: false,
              transitionDelay: 0
            }
          ]}
          fadeOut={false}
        />
      </Box>
    </Box>
  );
};

export default ConnectGoogleAnalyticsSuccess;
