import { Portal } from 'react-portal';
import { GoogleIcon, LogoIcon, WDGSIcon } from '../../../../assets/icons/export';

interface Props {
  close: () => void;
  authorize: () => void;
  step: number;
  webflowAuthUrl: string;
  nextStep: (num: number) => void;
}

export const AuthPremissionModal = ({ authorize, step, webflowAuthUrl }: Props) => (
  <Portal>
    <div className={`modal authPremissionModal`}>
      <div className="modalBox" onMouseDown={(e) => e.stopPropagation()}>
        <div className="progress">
          <div className="line">
            <div className="fill" style={{ width: `${(step - 1) * 34}%` }}></div>
          </div>
          <p>{step <= 3 ? `${step} / 3 Google authorization` : 'Complete successfully'}</p>
        </div>
        <div className="content">
          {step === 1 && (
            <>
              <LogoIcon className="logo" />
              <GoogleIcon className="badge google" />
              <p>Before we start...</p>
              <h3>Please authorize your Google account</h3>
              <p className="text">
                Data Goat needs access to your Google account to collect website data and provide valuable insights for
                your Webflow site.
              </p>
              <button type="button" className="googleBtn" onClick={authorize}>
                <GoogleIcon />
                <p>Sign in with Google</p>
              </button>
            </>
          )}
          {step === 2 && (
            <>
              <WDGSIcon className="logo" />
              <h3 className="v2">Connect your Webflow project</h3>
              <p className="text">Choose a project</p>
              <a href={webflowAuthUrl} target="_blank" className="primary lg">
                Authorize your Webflow account
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  </Portal>
);
