import { XAxis, Tooltip, ResponsiveContainer, LineChart, CartesianGrid, Line, YAxis } from 'recharts';
import { useAnalyticsData } from 'hooks/useAnalyticsData';
import { useVisitorsData } from 'hooks/useVisitorsData';
import { useState } from 'react';
import { isActiveClass } from 'utils';
import { ChartPayloadProps } from 'api/previewLink/types';

export const NewVisitorsChart = ({ payload }: ChartPayloadProps) => {
  const [active, setActive] = useState(0);
  const { data }: any = useAnalyticsData(payload);
  const { data: dataForChart } = useVisitorsData({ ...payload, active });

  return (
    <div className="chartPaper newVisitors">
      <div className="left">
        <h2 className="typo-heading-s">New Visitors</h2>
        <div className="sizes">
          <div className={isActiveClass(active === 0, 'size')} onClick={() => setActive(0)}>
            <span className="typo-label-s">Total visitors</span>
            <div className="stats">{data && <p>{data[0]?.metricValues[0]?.value}</p>}</div>
          </div>
          <div className={isActiveClass(active === 1, 'size')} onClick={() => setActive(1)}>
            <span className="typo-label-s">New visitors</span>
            <div className="stats">{data && <p>{data[0]?.metricValues[1]?.value}</p>}</div>
          </div>
        </div>
      </div>
      <div className="chart">
        {dataForChart && (
          <ResponsiveContainer width={'100%'} height="100%">
            <LineChart data={dataForChart} margin={{ bottom: 0, left: 0, right: 10, top: 0 }}>
              <CartesianGrid stroke="#134C54" vertical={false} />
              <YAxis
                tickLine={false}
                axisLine={false}
                tickMargin={0}
                style={{ fontSize: '10px', fontWeight: 400, fill: '#A1B8B4' }}
                orientation="right"
                width={27}
              />
              <XAxis
                dataKey="date"
                tickLine={false}
                axisLine={false}
                tickMargin={5}
                padding={{ left: 30, right: 0 }}
                style={{
                  fontSize: '10px',
                  lineHeight: '10px',
                  fontWeight: 400,
                  fill: '#A1B8B4',
                  textAnchor: 'middle'
                }}
              />
              <Line type="linear" dataKey="value" stroke="#C0E666" dot={false} />
              <Tooltip
                cursor={{ stroke: '#DBF0A3', strokeWidth: 1 }}
                content={({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="device-chart-tooltip">
                        <p className="typo-body-xxs left">{label}</p>
                        <div className="row">
                          <span className="typo-body-xs">Visitors</span>
                          <span className="typo-label-m">{payload[0].value}</span>
                        </div>
                      </div>
                    );
                  }
                  return null;
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};
