import React, { FC } from 'react';
import styles from './ComingSoon.module.scss';
import Label from 'components/ui/Label/Label';
import Wrapper from 'components/ui/Wrapper/Wrapper';

interface ComingSoonProps {}

const ComingSoon: FC<ComingSoonProps> = () => (
  <Wrapper borderRadius="30px" padding="4px 12px" className={styles.comingSoon}>
    <Label className={styles.label} size="s" color="normal">
      Coming soon
    </Label>{' '}
  </Wrapper>
);

export default ComingSoon;
