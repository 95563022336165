import { Report } from 'api/previewLink/types';

interface Props {
  filterInfo: Report | undefined;
}

export const PreviewLinkFilter = ({ filterInfo }: Props) => {
  return (
    <div className="previewLinkFilter">
      <div className="leftFilterText">
        <p>
          Preview results for <span>{filterInfo?.website?.websiteUrl}</span>
        </p>
      </div>
      <div className="rightFilter">
        <div className="filterInput">
          <label className="inputLabel">
            <input
              disabled
              type="text"
              value={`${filterInfo?.dateRange.startDate} - ${filterInfo?.dateRange.endDate}`}
            />
            <span>Time span</span>
          </label>
        </div>
        <div className="filterInput">
          <label className="inputLabel">
            <input disabled type="text" value={filterInfo?.pageTitle ? filterInfo?.pageTitle : 'Whole website'} />
            <span>Page</span>
          </label>
        </div>
      </div>
    </div>
  );
};
