import { getReportAnalytics } from 'api/analytics';
import { RunReportPayload } from 'api/analytics/types';
import { Website } from 'api/users/types';

interface TopInternalReferralData {
  sourceMedia: string;
  activeUsers: number;
  conversions: number;
  conversionRate: string;
}

export const fetchTopPages = async (
  formName: string,
  website: Website,
  startDate: string,
  endDate: string,
  cancelToken: any
) => {
  // Payload to get the total number of users per page referrer
  const payloadUsersPerSource: RunReportPayload = {
    propertyId: website.propertyId,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'pagePath' }],
    metrics: [{ name: 'activeUsers' }],
    dimensionFilter: {
      filter: {
        fieldName: 'eventName',
        stringFilter: {
          matchType: 'EXACT',
          value: `${formName?.replace('dg-submit', 'dg-form-view').slice(0, 40)}`
        }
      }
    }
  };

  // Payload to get the event count for form submissions from the referrer
  let payloadConversionsPerSource: RunReportPayload = {
    propertyId: website.propertyId,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'pagePath' }],
    metrics: [{ name: 'eventCount' }],
    dimensionFilter: {
      filter: {
        fieldName: 'eventName',
        stringFilter: {
          matchType: 'EXACT',
          value: `${formName?.slice(0, 40)}`
        }
      }
    }
  };

  try {
    cancelToken.pages = new AbortController();
    const signal = cancelToken.pages.signal;
    // Fetch data for active users per source
    const usersPromise = getReportAnalytics(payloadUsersPerSource, signal);

    // Fetch data for conversions per source
    let conversionsData: any;
    try {
      conversionsData = await getReportAnalytics(payloadConversionsPerSource, signal);
    } catch (e) {
      console.log(e);
    }
    const usersData = await usersPromise;

    // Process and combine data
    let topPerformingPageData = usersData?.map((userItem) => {
      const source = userItem.dimensionValues?.[0]?.value || 'None';
      const activeUsers = parseFloat(userItem.metricValues?.[0]?.value) || 0;

      // Find the corresponding conversions data
      const conversionsItem = conversionsData.find(
        (conversionItem: any) => conversionItem.dimensionValues?.[0]?.value === source
      );
      const conversions = conversionsItem ? parseFloat(conversionsItem.metricValues?.[0]?.value) || 0 : 0;

      // Calculate conversion rate
      const conversionRate = activeUsers > 0 ? ((conversions / activeUsers) * 100).toFixed(0) + '%' : '0%';

      return {
        sourceMedia: extractPath(source),
        Users: activeUsers,
        Conversions: conversions,
        'Conversion Rate': conversionRate
      };
    });

    topPerformingPageData = topPerformingPageData.filter(Boolean);
    // Sort by conversion rate in descending order and take the top 5
    const sortedTopPerformingPageData = topPerformingPageData
      ?.sort(
        (a, b) =>
          parseFloat(b!['Conversion Rate'].replace('%', '')) - parseFloat(a!['Conversion Rate'].replace('%', ''))
      )
      .slice(0, 9);

    return new Promise((resolve) => {
      resolve(sortedTopPerformingPageData);
    });
  } catch (e) {
    return new Promise((reject) => reject(undefined));
  }
};

function extractPath(url: string) {
  if (!url || url === 'None') return 'None';
  try {
    //const urlObj = new URL(url);
    return url === '/' ? '/home' : url;
  } catch (e) {
    console.error(e);
    return 'None';
  }
}

function truncateURL(url: string, maxLength = 15) {
  if (url === 'None' || url.length <= maxLength) {
    return url;
  }
  return url;
}
