import React, { FC, useEffect } from 'react';
import styles from './End.module.scss';
import { ModalType, useModalStore } from 'store/modalStore';
import CloseSVG from 'assets/icons/CloseSVG';
import GoatBadge from '../../../../assets/images/GoatBadgeError.png';
import Modal from 'components/ui/Modal/Modal';
import Heading from 'components/ui/Heading/Heading';
import Text from 'components/ui/Text/Text';
import Box from 'components/ui/Box/Box';
import Button from 'components/ui/Button/Button';
import { useNavigate } from 'react-router-dom';
import { updateShowFreeTrial } from 'api/users';

interface EndProps {}

const End: FC<EndProps> = () => {
  const { setModal } = useModalStore();
  const navigate = useNavigate();
  useEffect(() => {
    const handler = async () => {
      await updateShowFreeTrial();
    };
    handler();
  }, []);
  return (
    <Modal
      sx={{ height: 'auto', width: '30em', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
    >
      <Box justifyContent="center" alignItems="center" gap={32}>
        <CloseSVG
          className={styles.closeIcon}
          onClick={() => {
            setModal(ModalType.none);
          }}
        />
        <img src={GoatBadge} alt="?" />
        <Box gap={12} alignItems="center">
          <Heading size="m">Your free trial has ended!</Heading>
          <Text size="xs">Please upgrade your account to continue using all features.</Text>
          <Button
            padding="big"
            handleClick={() => {
              navigate('subscription');
              setModal(ModalType.none);
            }}
          >
            Select plan
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default End;
