import { isActiveClass } from '../../utils';

interface Props {
  isChecked: boolean;
  onChange?: () => void;
}

export const Checkbox = ({ isChecked, onChange }: Props) => (
  <div className={isActiveClass(isChecked, 'checkbox')} onClick={onChange}>
    <div className="circle"></div>
  </div>
);
