import { LogoIcon } from 'assets/icons/export';
import { NavLink } from 'react-router-dom';

export const PreviewLinkFooter = () => {
  return (
    <header className="footerPreviewLink">
      <div className="leftFooter">
        <NavLink to="https://www.datagoat.co">
          <LogoIcon />
        </NavLink>
      </div>
      <div className="rightFooter">
        <NavLink className="footerBtn" to="https://www.datagoat.co">
          Homepage
        </NavLink>
        <NavLink className="footerBtn signUp" to="https://www.datagoat.co/privacy-policy">
          Privacy Policy
        </NavLink>
        <NavLink className="footerBtn signUp" to="https://www.datagoat.co/terms-of-service">
          Terms of Use
        </NavLink>
        <NavLink className="footerBtn signUp" to="https://www.datagoat.co/customer-support">
          Support Center
        </NavLink>
        <NavLink className="footerBtn signUp" to="https://www.datagoat.co/docs">
          Docs
        </NavLink>
      </div>
    </header>
  );
};
