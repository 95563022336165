import { usePrivateRoute } from 'hooks/usePrivateRoute';
import { AccountNavigation } from '../components/layout/AccountNavigation';
import { ContactSupport } from 'components/layout/ContactSupport';
import { Hello } from '../components/layout/Hello';
import { AccountInfo } from '../components/layout/home/AccountInfo';
import { PaymentInfo } from '../components/layout/subscription/PaymentInfo';
import { PlanInfo } from '../components/layout/subscription/PlanInfo';
import Container from 'components/ui/Container/Container';
import PaymentCard from 'components/PaymentCard/PaymentCard';
import { useEffect, useRef, useState } from 'react';
import {
  createSubscription,
  fetchOrderProducts,
  fetchUserSubscription,
  hasPaymentMethods,
  updateSubscription
} from 'api/orders';
import Box from 'components/ui/Box/Box';
import Toggle from 'components/ui/Toggle/Toggle';
import { useAuthStore } from 'store/auth';
import Heading from 'components/ui/Heading/Heading';
import { ModalType, useModalStore } from 'store/modalStore';
import { getActiveTier } from 'api/users';

export const SubscriptionPage = () => {
  usePrivateRoute();
  const { user, setUser } = useAuthStore();
  const { setModal, setModalData, modal } = useModalStore();
  const [products, setProducts] = useState<any>();
  const [activeTier, setActiveTier] = useState<any>('Free');
  const firstRender = useRef(true);
  const [active, setActive] = useState<any>({
    free: user?.tier.tier === 'Free',
    startup: user?.tier.tier === 'Startup' && user?.tier.pricing === 'monthly',
    business: user?.tier.tier === 'Business' && user?.tier.pricing === 'monthly'
  });
  const [refresh, setRefresh] = useState(false);
  const [pricing, setPricing] = useState<string>();
  const [subscription, setSubscription] = useState<any[]>([]);
  const [planDate, setPlanDate] = useState<string>('month');
  const price = {
    startup: planDate === 'month' ? '$12/mo' : '$96/yr',
    business: planDate === 'month' ? '$24/mo' : '$192/yr'
  };
  const getSubscription = async () => {
    const subscription: any = await fetchUserSubscription();
    setSubscription(subscription);
  };
  useEffect(() => {
    const getProducts = async () => {
      const products: any = await fetchOrderProducts();
      setProducts(products);
    };

    getProducts();
    getSubscription();
  }, []);

  useEffect(() => {
    if (modal === ModalType.none && refresh) {
      window.location.reload();
    }
  }, [modal, refresh]);

  const handleUpgrade = async (lookupKey: string, level: number, pricing: string, startFreeTrial: boolean) => {
    
    if (!subscription || subscription.length === 0) {
      await createSubscription({ id: products.prices[lookupKey].priceId, level, pricing, startFreeTrial })
        .then(() => {
          if (startFreeTrial) {
            setModal(ModalType.freeTrialStart);
            setModalData({
              pricing,
              level
            });
            setTimeout(() => {
              setRefresh(true);
            }, 1);
          }
        })
        .catch((e: any) => {
          if (e.data.code === 'missing_payment_method') {
            setModal(ModalType.addPayment);
            setModalData({
              plan: { id: products.prices[lookupKey].priceId, level, pricing, startFreeTrial },
              addSubscription: true,
              planFlag: lookupKey
            });
            setTimeout(() => {
              setRefresh(true);
            }, 1);
          }
        });
    } else {
      const { data: hasPaymentMethod } = await hasPaymentMethods();
      if (!hasPaymentMethod) {
        setModal(ModalType.addPayment);
        setModalData({
          plan: { id: products.prices[lookupKey].priceId, level, pricing, startFreeTrial },
          addSubscription: true,
          planFlag: lookupKey
        });
      } else {
        if (user?.subscription.status === 'trialing') {
          setModal(ModalType.freeTrialManualEnd);
          setModalData({
            priceId: products.prices[lookupKey].priceId,
            level,
            pricing
          });
        } else {
          try {

            if (user?.tier.level > level) {
              setModalData({
                handleDowngradeClick: async () => {
                  await updateSubscription({ priceId: products.prices[lookupKey].priceId, level, pricing });
                  await getSubscription();

                  const { tier, pricing: userPricing } = await getActiveTier();
                  const active = {
                    free: tier === 'Free',
                    startup: tier === 'Startup' && userPricing === planDate + 'ly',
                    business: tier === 'Business' && userPricing === planDate + 'ly'
                  };
                  if (user?.tier) user.tier.level = level;
                  setUser(user);
                  setActiveTier(tier);
                  setActive(active);
                  setModal(ModalType.none);
                  window.location.reload();
                },
                plan: lookupKey
              });
              setModal(ModalType.planDowngrade);
            }
            if (user?.tier.level <= level) {
              setModalData({
                handleUpgradeClick: async () => {
                  await updateSubscription({ priceId: products.prices[lookupKey].priceId, level, pricing });
                  await getSubscription();

                  const { tier, pricing: userPricing } = await getActiveTier();
                  const active = {
                    free: tier === 'Free',
                    startup: tier === 'Startup' && userPricing === planDate + 'ly',
                    business: tier === 'Business' && userPricing === planDate + 'ly'
                  };
                  if (user?.tier) user.tier.level = level;
                  setUser(user);
                  setActiveTier(tier);
                  setActive(active);
                  setModal(ModalType.none);
                  window.location.reload();
                },
                plan: lookupKey
              });
              setModal(ModalType.planUpgrade);
            }
          } catch (e: any) {
            if (e.data.code === 'missing_payment_method') {
              setModal(ModalType.addPayment);
              setModalData({
                plan: { id: products.prices[lookupKey].priceId, level, pricing, startFreeTrial },
                addSubscription: true,
                planFlag: lookupKey
              });
            }
          }
        }
      }
    }
  };

  const handlePlanChange = async () => {
    //const subscriptionPromise = await getSubscription();
    const { tier, pricing } = await getActiveTier();
    const active = {
      free: tier === 'Free',
      startup: tier === 'Startup' && pricing === planDate + 'ly',
      business: tier === 'Business' && pricing === planDate + 'ly'
    };
    setActiveTier(tier);
    setPricing(pricing);
    setActive(active);
  };
  useEffect(() => {
    handlePlanChange();
  }, []);
  useEffect(() => {
    if (!firstRender.current) {
      const active = {
        free: activeTier === 'Free',
        startup: activeTier === 'Startup' && pricing === planDate + 'ly',
        business: activeTier === 'Business' && pricing === planDate + 'ly'
      };
      setActive(active);
    }
    firstRender.current = false;
  }, [planDate]);
  return (
    <Container justifyContent="center">
      <Container sx={{ height: '100%', width: '11em' }}></Container>
      <div className="container" style={{ marginTop: '32px' }}>
        {/* <Header /> */}
        <div className="subscriptionPage">
          <Hello text="Welcome to your subscription settings." />

          <AccountNavigation />

          <Container gap={32} flexDirection="column">
            <Box justifyContent="space-between" flexDirection="row">
              <Heading size="l">Upgrade to unleash everything</Heading>
              <Toggle
                toggleSelect={[
                  { label: 'Monthly', value: 'month' },
                  { label: 'Yearly', value: 'year' }
                ]}
                handleToggleClick={(value) => {
                  setPlanDate(value.value);
                }}
              />
            </Box>
            <Box gap={24} flexDirection="row">
              <PaymentCard
                plan="Starter"
                price="Free"
                active={active?.free}
                features={[
                  '1 website',
                  'GA4 Integration',
                  'Custom event tracking',
                  'Website clicks & Outbound clicks',
                  'Analytics in Webflow',
                  'Webflow form tracking',
                  'Monthly Email summaries'
                ]}
                level={1}
                handleUpgrade={() => {
                  handleUpgrade('free', 1, 'free', false);
                }}
              />
              <PaymentCard
                plan="Startup"
                price={price.startup}
                active={active?.startup}
                features={[
                  'track up to 3 sites',
                  'Preview  and share analytics link',
                  'Post tracking',
                  'Track additional websites',
                  'Goal tracking',
                  'Insights panel',
                  'Website speed audit',
                  'AI website suggestions',
                  'Weekly Email summaries'
                ]}
                level={2}
                comingSoon={[false, false, false, false, true, true, true, true, false]}
                handleUpgrade={() => {
                  planDate === 'month'
                    ? handleUpgrade('startup_monthly', 2, 'monthly', false)
                    : handleUpgrade('startup_yearly', 2, 'yearly', false);
                }}
                handleStartFreeTrial={() => {
                  planDate === 'month'
                    ? handleUpgrade('startup_monthly', 2, 'monthly', true)
                    : handleUpgrade('startup_yearly', 2, 'yearly', true);
                }}
                freePlanPriceId={products?.prices['free'].priceId}
                planDate={planDate}
              />
              <PaymentCard
                plan="Business"
                price={price.business}
                active={active?.business}
                features={[
                  'track up to 10 sites',
                  'Third party analytics',
                  'Custom Conversions',
                  'Slack summaries',
                  'SEO insights',
                  'Weekly Email summaries'
                ]}
                level={3}
                comingSoon={[false, true, true, true, true, false]}
                handleUpgrade={() => {
                  planDate === 'month'
                    ? handleUpgrade('business_monthly', 3, 'monthly', false)
                    : handleUpgrade('business_yearly', 3, 'yearly', false);
                }}
                handleStartFreeTrial={() => {
                  planDate === 'month'
                    ? handleUpgrade('business_monthly', 3, 'monthly', true)
                    : handleUpgrade('business_yearly', 3, 'yearly', true);
                }}
                freePlanPriceId={products?.prices['free'].priceId}
                planDate={planDate}
              />
            </Box>
          </Container>
          <div className="cont">
            <div className="left">
              <PlanInfo />
              <PaymentInfo />
            </div>
            <div className="right">
              <AccountInfo />
              <ContactSupport />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
