import * as React from 'react';
import type { SVGProps } from 'react';

const SvgWebflowBadgeSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={113} height={107} fill="none" {...props}>
    <path
      fill="#146EF5"
      d="m53.95 0 7.375 6.888 9.142-4.272 4.885 8.83 10.015-1.238 1.918 9.907 9.907 1.918-1.237 10.015 8.829 4.885-4.272 9.142 6.888 7.375-6.888 7.375 4.272 9.142-8.83 4.885 1.238 10.015-9.907 1.918-1.918 9.907-10.015-1.237-4.885 8.829-9.142-4.272-7.375 6.888-7.375-6.888-9.142 4.272-4.885-8.83-10.015 1.238-1.918-9.907-9.907-1.918 1.237-10.015-8.829-4.885 4.272-9.142L.5 53.45l6.888-7.375-4.272-9.142 8.83-4.885-1.238-10.015 9.907-1.918 1.918-9.907 10.015 1.237 4.885-8.829 9.142 4.272z"
    />
    <g clipPath="url(#webflow-badge-success_svg__a)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m79.5 42.102-14.696 28.63H51.03l6.126-11.876h-.283C51.843 65.43 44.265 69.743 33.5 70.732v-11.7s6.905-.388 10.942-4.665H33.5V42.102h12.288V52.21h.283L51.1 42.1h9.313V52.14h.283l5.17-10.038z"
        clipRule="evenodd"
      />
    </g>
    <path
      fill="#C0E666"
      d="M95 73.188a15.313 15.313 0 1 0 0 30.626 15.313 15.313 0 0 0 0-30.626m-2.187 21.426-4.6-4.6a1.23 1.23 0 1 1 1.74-1.738l2.86 2.86 7.237-7.237a1.23 1.23 0 1 1 1.74 1.74z"
    />
    <defs>
      <clipPath id="webflow-badge-success_svg__a">
        <path fill="#fff" d="M33.5 42.098h46v28.63h-46z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWebflowBadgeSuccess;
