import React, { CSSProperties, FC, ReactNode } from 'react';
import styles from './Label.module.scss';

interface LabelProps {
  size?: 'xs' | 's' | 'm' | 'l';
  color?: 'normal' | 'fade' | 'highlight' | 'white' | 'neutral200' | 'none';
  children?: ReactNode;
  className?: string;
  sx?: CSSProperties;
  handleClick?: () => void;
}

const typoMap = {
  xs: styles.xs,
  s: styles.s,
  m: styles.m,
  l: styles.l
};

const colorMap = {
  normal: styles.normal,
  highlight: styles.highlight,
  white: styles.white,
  fade: styles.fade,
  neutral200: styles.neutral200,
  none: ''
};

const Label: FC<LabelProps> = ({ size = 'm', children, className, color = 'normal', sx, handleClick }) => {
  const classRecord = `${className} ${typoMap[size]} ${colorMap[color]}`;
  return (
    <div className={classRecord} style={sx} onClick={handleClick}>
      {children}
    </div>
  );
};

export default Label;
