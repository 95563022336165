import { getReportAnalyticsV2 } from 'api/analytics';
import { RunReportPayload } from 'api/analytics/types';
import { CancelRequestError } from '../hooks/useAllFormData';

export const compareImpressions = (impressions: Record<string, string>[]) => {
  /*
  const resultList = [];
  for (let i = 0; i < impressions.length; i++) {
    let impression = impressions.shift();
    let pairIndex = impressions.findIndex((impressionEl) => impression!.eventName == impressionEl.eventName);

    const pairImpression = impressions[pairIndex];
    const firstDate = impression!.dateRange;
    const secondDate = pairImpression.dateRange;
    
    resultList.push({
      [firstDate]: { eventCount: impression!.eventCount, eventName: impression!.eventName },
      [secondDate]: { eventCount: pairImpression!.eventCount, eventName: pairImpression!.eventName }
    });
    
    impressions.splice(pairIndex, 1);
  }
  */
};

export const compareStatistics = async (
  startDate: string,
  endDate: string,
  propertyId: string,
  signal: AbortSignal
) => {
  const startDateAsDate = new Date(startDate);
  const endDateAsDate = new Date(endDate);
  let dayNumber = Math.ceil((endDateAsDate.getTime() - startDateAsDate.getTime()) / (1000 * 60 * 60 * 24));

  if (dayNumber === 6 || dayNumber === 29) {
    dayNumber += 1;
    const compareEndDate = new Date(endDate);
    const compareStartDate = new Date(startDate);
    compareEndDate.setDate(endDateAsDate.getDate() - dayNumber);
    compareStartDate.setDate(startDateAsDate.getDate() - dayNumber);
    const compareEndDatePayload = compareEndDate.toISOString().split('T')[0];
    const compareStartDatePayload = compareStartDate.toISOString().split('T')[0];

    const payloadImpressions: RunReportPayload = {
      propertyId: propertyId,
      dateRanges: [
        { startDate: startDate, endDate: endDate },
        { startDate: compareStartDatePayload, endDate: compareEndDatePayload }
      ],
      dimensions: [{ name: 'eventName' }],
      metrics: [{ name: 'eventCount' }],
      dimensionFilter: {
        filter: {
          fieldName: 'eventName',
          stringFilter: {
            matchType: 'BEGINS_WITH',
            value: 'dg-submit'
          }
        }
      }
    };

    const payloadConversions: RunReportPayload = {
      propertyId: propertyId,
      dateRanges: [
        { startDate: startDate, endDate: endDate },
        { startDate: compareStartDatePayload, endDate: compareEndDatePayload }
      ],
      dimensions: [{ name: 'eventName' }],
      metrics: [{ name: 'eventCount' }],
      dimensionFilter: {
        filter: {
          fieldName: 'eventName',
          stringFilter: {
            matchType: 'BEGINS_WITH',
            value: 'dg-form-view'
          }
        }
      }
    };
    try {
      const impressionsPromise = getReportAnalyticsV2(payloadImpressions, signal);
      let conversionsData = await getReportAnalyticsV2(payloadConversions, signal);
      let impressionsData = await impressionsPromise;
      if (conversionsData?.length == 0 || !conversionsData) throw new Error('Cancel Request');
      if (impressionsData.length == 0) {
        impressionsData = [
          { dateRange: 'date_range_0', eventCount: '0' },
          { dateRange: 'date_range_1', eventCount: '0' }
        ];
      }
      if (impressionsData.length == 1) {
        if (impressionsData[0].dateRange == 'date_range_0') {
          impressionsData.push({ dateRange: 'date_range_1', eventCount: '0' });
        } else impressionsData.push({ dateRange: 'date_range_0', eventCount: '0' });
      }

      if (conversionsData.length == 0) {
        conversionsData = [
          { dateRange: 'date_range_0', eventCount: '0' },
          { dateRange: 'date_range_1', eventCount: '0' }
        ];
      }
      if (conversionsData.length == 1) {
        if (conversionsData[0].dateRange == 'date_range_0') {
          conversionsData.push({ dateRange: 'date_range_1', eventCount: '0' });
        } else conversionsData.push({ dateRange: 'date_range_0', eventCount: '0' });
      }
      impressionsData.sort((a: any, b: any) => a['dateRange'].localeCompare(b['dateRange']));
      conversionsData.sort((a: any, b: any) => a['dateRange'].localeCompare(b['dateRange']));
      const impressionsNow = parseInt(impressionsData[0].eventCount);
      const impressionsBefore = parseInt(impressionsData[1].eventCount);
      const conversionsNow = parseInt(conversionsData[0].eventCount);
      const conversionsBefore = parseInt(conversionsData[1].eventCount);
      const conversionRateNow = impressionsNow / conversionsNow;
      const conversionRateBefore = impressionsBefore / conversionsBefore;
      const impressionsDifference = impressionsNow - impressionsBefore;
      const conversionsDifference = conversionsNow - conversionsBefore;
      const conversionRateDifference = conversionRateNow - conversionRateBefore;
      const state = { Impressions: 'equal', Conversions: 'equal', 'Conversion Rate': 'equal' };
      if (impressionsDifference > 0) state.Impressions = 'raise';
      if (impressionsDifference < 0) state.Impressions = 'fall';
      if (conversionsDifference > 0) state.Conversions = 'raise';
      if (conversionsDifference < 0) state.Conversions = 'fall';
      if (conversionRateDifference > 0) state['Conversion Rate'] = 'raise';
      if (conversionRateDifference < 0) state['Conversion Rate'] = 'fall';
      return state;
    } catch (e) {
      return new Promise((reject) => reject(undefined));
    }
  } else {
    return { Impressions: 'none', Conversions: 'none', 'Conversion Rate': 'none' };
  }
};
