import type { SVGProps } from 'react';
import * as React from 'react';

const SvgFormstack = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <rect width={16} height={16} fill="#fff" rx={8} />
    <g clipPath="url(#Formstack_svg__a)">
      <path
        fill="#21B573"
        d="M10.821 3.998a.28.28 0 0 1 .28.28v8.773a.28.28 0 0 1-.28.28H2.97a.28.28 0 0 1-.28-.28V4.278a.28.28 0 0 1 .28-.28zm1.402.933a.28.28 0 0 1 .28.28v6.907a.28.28 0 0 1-.28.28h-.654V4.931zm1.402.934a.28.28 0 0 1 .28.28v5.04a.28.28 0 0 1-.28.28h-.654v-5.6z"
      />
      <path
        fill="#fff"
        d="M6.037 10.751q.017.024.017.053v.949a.094.094 0 0 1-.093.093H4.649a.09.09 0 0 1-.09-.09v-.041c0-.03.015-.06.04-.077l1.308-.91c.043-.03.1-.02.13.023m2.156-2.179a.093.093 0 0 1 .053.17l-3.541 2.431a.093.093 0 0 1-.146-.077V9.651q.001-.045.035-.074l1.257-.985a.1.1 0 0 1 .058-.02zm.772-3.08a.093.093 0 0 1 .059.167L4.71 9.104a.093.093 0 0 1-.151-.073V5.586c0-.052.041-.094.093-.094z"
      />
    </g>
    <defs>
      <clipPath id="Formstack_svg__a">
        <path fill="#fff" d="M2.667 4h11.272v9.333H2.667z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFormstack;
