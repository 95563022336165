import React, { CSSProperties, FC, MutableRefObject, ReactNode, useRef } from 'react';
import styles from './Wrapper.module.scss';

interface WrapperProps {
  className?: string;
  padding?: string;
  borderRadius?: string;
  children?: ReactNode;
  onClick?: () => void;
  sx?: CSSProperties;
  gap?: number;
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'normal' | (string & {});
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'normal' | (string & {});
  flexDirection?: 'column' | 'row';
  ref?: React.LegacyRef<any>;
}

const Wrapper: FC<WrapperProps> = ({
  className = 'none',
  padding = '0px',
  borderRadius = '0px',
  children,
  onClick,
  sx,
  gap = 0,
  flexDirection = 'row',
  justifyContent = 'normal',
  alignItems = 'normal',
  ref
}) => {
  return (
    <div
      className={`${styles.Wrapper} ${className}`}
      style={{
        padding: padding,
        borderRadius: borderRadius,
        gap: gap + 'px',
        flexDirection: flexDirection,
        justifyContent: justifyContent,
        alignItems: alignItems,
        ...sx
      }}
      onClick={onClick}
      ref={ref}
    >
      {children}
    </div>
  );
};
export default Wrapper;
