import React, { FC } from 'react';
import styles from './SubmissionsEmptyState.module.scss';

interface SubmissionsEmptyStateProps {}

const SubmissionsEmptyState: FC<SubmissionsEmptyStateProps> = () => (
  <>
    <img src="assets/icons/search.svg" className={styles.icon} alt="?" />
    <div className={styles.text}>
      <div className={styles.heading}>Oops, no forms to track at the moment!</div>
      <div className={styles.body}>
        {' '}
        A little patience, please — even the best goats need a moment to climb <br />
        the data mountain. In the meantime, check if our Custom Script is <br />
        implemented properly. If the problem persists,{' '}
        <span
          className={styles.link}
          onClick={() => {
            window.open('https://www.datagoat.co/customer-support', '_blank');
          }}
        >
          contact us
        </span>
        .
      </div>
    </div>
  </>
);

export default SubmissionsEmptyState;
