import React, { FC } from 'react';
import styles from './ManualEnd.module.scss';
import Modal from 'components/ui/Modal/Modal';
import Box from 'components/ui/Box/Box';
import { ModalType, useModalStore } from 'store/modalStore';
import CloseSVG from 'assets/icons/CloseSVG';
import Heading from 'components/ui/Heading/Heading';
import Button from 'components/ui/Button/Button';
import { updateSubscription } from 'api/orders';

interface ManualEndProps {}

const ManualEnd: FC<ManualEndProps> = () => {
  const { setModal, modalData } = useModalStore();
  return (
    <Modal
      sx={{ height: 'auto', width: '30em', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
    >
      <Box justifyContent="center" alignItems="center" gap={32}>
        <CloseSVG
          className={styles.closeIcon}
          onClick={() => {
            setModal(ModalType.none);
          }}
        />
        <Box gap={16} alignItems="center">
          <Heading size="m">Are you sure you want to end your free trial?</Heading>
        </Box>
        <Button
          handleClick={async () => {
            try {
              const data = await updateSubscription({
                priceId: modalData.priceId,
                level: modalData.level,
                pricing: modalData.pricing
              });
              window.location.reload();
              setModal(ModalType.none);
            } catch (e: any) {
              if (e.data.code === 'missing_payment_method') {
                setModal(ModalType.addPayment);
              }
            }
          }}
        >
          End free trial
        </Button>
      </Box>
    </Modal>
  );
};

export default ManualEnd;
