import { Dispatch, SetStateAction } from 'react';
import { isActiveClass } from 'utils';

interface Props {
  options: { value: string; label: string }[];
  active?: string;
  setActive: Dispatch<SetStateAction<any>>;
}

export const Toggle = ({ options, active, setActive }: Props) => (
  <div className="toggle">
    <div className={isActiveClass(active === options[1].value, 'activeToggle')}></div>
    {options.map((item) => (
      <div key={item.value} className="tab typo-body-xs  " onClick={() => setActive(item)}>
        <span className={isActiveClass(active === item.value)}>{item.label}</span>
      </div>
    ))}
  </div>
);
