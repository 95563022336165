import * as React from 'react';
import type { SVGProps } from 'react';

const SvgCheckboxChecked = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <rect width={15} height={15} x={0.5} y={0.5} fill="#08282D" stroke="#C0E666" rx={7.5} />
    <path stroke="#C0E666" strokeLinecap="round" strokeLinejoin="round" d="m11.996 5.605-5.227 4.79-2.436-2.508" />
  </svg>
);
export default SvgCheckboxChecked;
