interface Props {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  close: () => void;
}

export const FirstStep = ({ setStep, close }: Props) => (
  <div className="firstStep">
    <p className="text">Are you sure you want to cancel your subscription?</p>
    <p className="subtext">You will loose some amazing, wonderful features!</p>
    <div className="buttons">
      <button type="button" className="delete" onClick={() => setStep(2)}>
        Yes, cancel
      </button>
      <button type="button" className="outlined" onClick={close}>
        No, I'll keep it
      </button>
    </div>
  </div>
);
