import React, { FC } from 'react';
import styles from './VisitorsLineChart.module.scss';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomTooltip from './VisitorsTooltip/CustomTooltip';

//import { max } from 'lodash';

interface ChartData {
  date: string;
  totalUsers: number;
  newUsers: number;
}

interface VisitorsLineChartProps {
  activeLine: boolean[];
  chartData?: ChartData[];
}

const VisitorsLineChart: FC<VisitorsLineChartProps> = ({ activeLine, chartData }) => {
  // let conversionRateMax = 0;
  // let conversionRateMin = 0;
  // let conversionMax = 0;
  // let conversionMin = 0;
  let totalUsersMax = 0;
  let totalUsersMin = 0;
  let newUsersMin = 0;
  let newUsersMax = 0;
  let min = 0;
  let max = 0;
  if (chartData) {
    if (activeLine[0]) {
      totalUsersMax = Math.max(...chartData!.map((value) => value.totalUsers));
      totalUsersMin = Math.min(...chartData!.map((value) => value.totalUsers));
      max = totalUsersMax;
      min = totalUsersMin;
    }
    if (activeLine[1]) {
      newUsersMax = Math.max(...chartData!.map((value) => value.newUsers));
      newUsersMin = Math.min(...chartData!.map((value) => value.newUsers));
      max = newUsersMax;
      min = newUsersMin;
    } //   if (activeLine.includes(2)) {
    //     conversionMax = Math.max(...chartData!.map((value) => value.conversion));
    //     conversionMin = Math.min(...chartData!.map((value) => value.conversion));
    //   }
    //   if (activeLine.includes(3)) {
    //     conversionRateMax = Math.max(...chartData!.map((value) => parseInt(value.conversionRate)));
    //     conversionRateMin = Math.min(...chartData!.map((value) => parseInt(value.conversionRate)));
    //   }
    // }
    //max = Math.max(conversionMax, conversionRateMax, impressionMax);
    //min = Math.min(conversionMin, conversionRateMin, impressionMin);
  }
  return (
    <ResponsiveContainer width={`100%`} height={250} className={styles.VisitorsLineChart}>
      <LineChart
        height={200}
        data={chartData}
        margin={{
          top: 5,
          right: -30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid vertical={false} stroke="#134C54" />
        <XAxis dataKey="date" className={styles.XAxis} stroke="#799892" type="category" />
        <YAxis
          orientation="right"
          className={styles.YAxis}
          stroke="#799892"
          type="number"
          domain={[min, max]}
          allowDataOverflow={false}
        />
        {<Tooltip animationDuration={500} offset={20} content={<CustomTooltip />} />}
        {activeLine[0] && (
          <Line
            type="linear"
            dataKey="totalUsers"
            stroke="#C0E666"
            activeDot={{ stroke: 'white', strokeWidth: 1, fill: 'white', r: 3 }}
            dot={false}
          />
        )}
        {activeLine[1] && (
          <Line
            type="linear"
            dataKey="newUsers"
            stroke="#C0E666"
            activeDot={{ stroke: 'white', strokeWidth: 1, fill: 'white', r: 3 }}
            dot={false}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default VisitorsLineChart;
