import React, { FC } from 'react';
import styles from './ComingSoon.module.scss';
import Overlay from '../Overlay';
import GoatBadge from '../../../assets/images/datagoat_badge.png';
import Heading from 'components/ui/Heading/Heading';
import Text from 'components/ui/Text/Text';

interface ComingSoonProps {}

const ComingSoon: FC<ComingSoonProps> = () => (
  <Overlay>
    <img src={GoatBadge} alt="?" />
    <Heading size="l" className={styles.heading}>
      AI-powered <br />
      insights are coming soon!
    </Heading>
    <Text size="m">Our smart insights are almost there.</Text>
  </Overlay>
);

export default ComingSoon;
