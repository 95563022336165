import { AnalyticsHookPayload } from 'api/analytics/types';
import { Toggle } from 'components/ui/Toggle';
import { useAnalyticsData } from 'hooks/useAnalyticsData';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { isActiveClass } from 'utils';

export type SourceMetric = 'totalUsers' | 'sessions';

interface Props {
  title: string;
  name: string;
  payload: AnalyticsHookPayload;
  nav?: string[];
  sourceMetrics?: SourceMetric;
  setSourceMetrics?: Dispatch<SetStateAction<SourceMetric>>;
  filterBy?: string;
}

export const CardList = ({ title, name, nav, payload, sourceMetrics, setSourceMetrics, filterBy }: Props) => {
  const { data, refetch }: any = useAnalyticsData(payload);

  useEffect(() => {
    if (payload) refetch();
  }, [sourceMetrics]);

  return (
    <div className={`paper cardList card-${name}`}>
      <div className="heading">
        <h2 className="typo-heading-xs">{title}</h2>
        {setSourceMetrics && (
          <Toggle
            active={sourceMetrics}
            options={[
              { label: 'Users', value: 'totalUsers' },
              { label: 'Sessions', value: 'sessions' }
            ]}
            setActive={({ value }) => setSourceMetrics(value)}
          />
        )}
      </div>
      <div className={isActiveClass(name === 'outbound', 'row navRow')}>
        {nav?.map((el, i) => (
          <span key={el + i} className="typo-body-xs">
            {el}
          </span>
        ))}
      </div>
      <ul>
        {data
          ?.filter(({ dimensionValues }: any) => {
            if (filterBy === 'dg-click') return dimensionValues[0].value.includes('dg-click_');
            if (name === 'pageRanking') {
              return dimensionValues[0].value !== '/';
            }
            return true;
          })
          .map(({ dimensionValues, metricValues }: any, i: number) => {
            if (!dimensionValues[0].value.length) return;

            return (
              <li key={i} className={isActiveClass(name === 'outbound', 'row el')}>
                <span>
                  {filterBy === 'dg-click'
                    ? dimensionValues[0].value.replace('dg-click_', '')
                    : dimensionValues[0].value}
                </span>
                {metricValues?.map((m: any, i: number) => (
                  <span className="right" key={i}>
                    {m.value ? m.value : '/'}
                  </span>
                ))}
              </li>
            );
          })}
      </ul>
    </div>
  );
};
