import { useState } from 'react';

import { SignUpPayload } from 'api/auth/types';

import { PaymentStep } from 'components/shared/auth/payment-step';
import { SignUpStep } from 'components/shared/auth/sign-up-step';
import { usePublicRoute } from 'hooks/usePublicRoute';

export const SignUpPage = () => {
  usePublicRoute();

  const [activeStep, setActiveStep] = useState(1);
  const [payload, setPayload] = useState<SignUpPayload>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    paymentMethodId: ''
  });

  const renderStep = () => {
    switch (activeStep) {
      case 1:
        return <SignUpStep setPayload={setPayload} />;
      case 2:
        return <PaymentStep payload={payload} />;
    }
  };

  return <>{renderStep()}</>;
};
