import React, { FC } from 'react';
import styles from './WebflowProjectList.module.scss';
import Box from 'components/ui/Box/Box';
import Label from 'components/ui/Label/Label';
import Wrapper from 'components/ui/Wrapper/Wrapper';
import WebflowProjectWrapper from './WebflowProjectWrapper/WebflowProjectWrapper';
import WrapperPremium from 'components/ui/WrapperPremium/WrapperPremium';

interface WebflowProjectListProps {
  webflowProjectList: any;
  fadeOut: boolean;
  handleRemoveProject?: (shortName: string, index: number) => void;
}

const WebflowProjectList: FC<WebflowProjectListProps> = ({ webflowProjectList, fadeOut, handleRemoveProject }) => {
  let transitionDelay = webflowProjectList.length / 10;
  const deltaTransitionDelay = 0.2;

  return (
    <Box gap={16} alignItems="normal">
      <Box flexDirection="row" gap={16} justifyContent="space-between">
        <Label color="white" size="m">
          Authorized Webflow projects
        </Label>
        <Wrapper borderRadius="4px" padding="4px 8px" className={styles.freeTrialText}>
          <WrapperPremium>Free plan - 1 site per account</WrapperPremium>
        </Wrapper>
      </Box>
      <Box gap={8} alignItems="normal" sx={{ maxHeight: '15em', overflowY: 'scroll', height: '15em' }}>
        {webflowProjectList.map((webflowProject: any, index: number) => {
          let transitionDelayProp = transitionDelay;
          if (!webflowProject.radioButtonActive) transitionDelay -= deltaTransitionDelay;
          return (
            <WebflowProjectWrapper
              key={webflowProject.key}
              {...webflowProject}
              fadeOutProp={fadeOut}
              transitionDelay={transitionDelayProp}
              index={index}
              handleRemoveProject={handleRemoveProject}
            ></WebflowProjectWrapper>
          );
        })}
      </Box>
    </Box>
  );
};

export default WebflowProjectList;
