const DefaultCardSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="106.667" height="106.667" version="1" viewBox="0 0 80 80" {...props}>
    <path
      fill="white"
      d="M76 624c-24-23-24-425 0-448 13-14 57-16 324-16s311 2 324 16c24 23 24 425 0 448-13 14-57 16-324 16s-311-2-324-16zm639-224V185H85l-3 205c-1 112 0 210 3 217 3 11 69 13 317 11l313-3V400z"
      transform="matrix(.1 0 0 -.1 0 80)"
    ></path>
    <path
      fill="white"
      d="M124 456c-3-8-4-25-2-38 3-20 9-23 48-23 45 0 45 0 45 35 0 34-1 35-43 38-30 2-44-1-48-12zM120 270c0-5 5-10 10-10 6 0 10 5 10 10 0 6-4 10-10 10-5 0-10-4-10-10zM150 270c0-5 5-10 10-10 6 0 10 5 10 10 0 6-4 10-10 10-5 0-10-4-10-10zM180 270c0-5 5-10 10-10 6 0 10 5 10 10 0 6-4 10-10 10-5 0-10-4-10-10zM210 270c0-5 5-10 10-10 6 0 10 5 10 10 0 6-4 10-10 10-5 0-10-4-10-10zM270 270c0-5 5-10 10-10 6 0 10 5 10 10 0 6-4 10-10 10-5 0-10-4-10-10zM300 270c0-5 5-10 10-10 6 0 10 5 10 10 0 6-4 10-10 10-5 0-10-4-10-10zM330 270c0-5 5-10 10-10 6 0 10 5 10 10 0 6-4 10-10 10-5 0-10-4-10-10zM360 270c0-5 5-10 10-10 6 0 10 5 10 10 0 6-4 10-10 10-5 0-10-4-10-10zM420 270c0-5 5-10 10-10 6 0 10 5 10 10 0 6-4 10-10 10-5 0-10-4-10-10zM450 270c0-5 5-10 10-10 6 0 10 5 10 10 0 6-4 10-10 10-5 0-10-4-10-10zM480 270c0-5 5-10 10-10 6 0 10 5 10 10 0 6-4 10-10 10-5 0-10-4-10-10zM510 270c0-5 5-10 10-10 6 0 10 5 10 10 0 6-4 10-10 10-5 0-10-4-10-10zM570 270c0-5 5-10 10-10 6 0 10 5 10 10 0 6-4 10-10 10-5 0-10-4-10-10zM600 270c0-5 5-10 10-10 6 0 10 5 10 10 0 6-4 10-10 10-5 0-10-4-10-10zM630 270c0-5 5-10 10-10 6 0 10 5 10 10 0 6-4 10-10 10-5 0-10-4-10-10zM660 270c0-5 5-10 10-10 6 0 10 5 10 10 0 6-4 10-10 10-5 0-10-4-10-10z"
      transform="matrix(.1 0 0 -.1 0 80)"
    ></path>
  </svg>
);

export default DefaultCardSVG;
