import { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { OrdersProduct } from 'api/orders/types';
import { SignUpPayload } from 'api/auth/types';

import { LogoIcon } from 'assets/icons/export';
import { StripeForm } from './StripeForm';
import { formatPrice } from 'utils';
import { Cards } from './Cards';

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || '';

interface Props {
  payload: SignUpPayload;
}

export const PaymentStep = ({ payload }: Props) => {
  const [billingPlan, setBillingPlan] = useState<OrdersProduct | null>(null);

  const stripePromise = loadStripe(STRIPE_KEY);

  return (
    <div className="auth-step2">
      <div className="left">
        <LogoIcon className="logoIcon" />
        <div className="">
          <p className="typo-label-l">Hey {`${payload.firstName} ${payload.lastName}`},</p>
          <h1 className="typo-heading-xl">
            Try DataGoat - <br /> 7 days for free
          </h1>
          <p className="typo-body-m subtext">
            Pick a monthly or yearly payment option and enter your payment info to start your 7-day free trial.
          </p>
          <Cards billingPlan={billingPlan} setBillingPlan={setBillingPlan} />

          <p className="mobileText">
            Scroll down and <span>fill in the Personal Info</span> to proceed to payment.
          </p>
        </div>
      </div>
      <div className="right">
        <div className="box">
          <p className="typo-label-l">Step 2</p>
          <h3 className="typo-heading-m">Payment Info</h3>
          <div className="steps">
            <div />
            <div className="active" />
          </div>
        </div>
        <div className="formWrapper">
          {billingPlan && (
            <div className="selectedPlan">
              <span className="typo-body-s">You chose:</span>
              <p className="typo-heading-s">
                7 Days Free trial + <br /> {billingPlan.name} (
                {formatPrice(billingPlan.default_price.tiers[0].unit_amount)})
              </p>
            </div>
          )}
          {/* <Elements stripe={stripePromise}>
            <StripeForm payload={payload} billingPlan={billingPlan} />
          </Elements> */}
        </div>
      </div>
    </div>
  );
};
