import React, { FC } from 'react';
import styles from './AnalyticsTableCardToggle.module.scss';
import Box from '../Box/Box';
import Card from '../Card/Card';
import Table from '../SubmissionsTable/Table';
import Heading from '../Heading/Heading';
import Toggle from '../Toggle/Toggle';

interface AnalyticsTableCardToggleProps {
  heading: string;
  tableHeaders: string[];
  tableData: any;
  rightColumnMinWidth: number;
  toggleSelect: [{ label: string; value: any }, { label: string; value: any }];
  handleToggleClick: (value: any) => void;
}

const AnalyticsTableCardToggle: FC<AnalyticsTableCardToggleProps> = ({
  heading,
  tableHeaders,
  tableData,
  rightColumnMinWidth,
  toggleSelect,
  handleToggleClick
}) => {
  return (
    <Card sx={{ flex: 1 }}>
      <Box flexDirection="row" justifyContent="space-between">
        {heading && <Heading size="xs">{heading}</Heading>}
        <Toggle toggleSelect={toggleSelect} handleToggleClick={handleToggleClick} />
      </Box>
      <Table headers={tableHeaders} tableData={tableData} otherColumnMinWidth={rightColumnMinWidth}></Table>
    </Card>
  );
};

export default AnalyticsTableCardToggle;
