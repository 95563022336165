import React, { FC } from 'react';
import styles from './LinkText.module.scss';

interface LinkTextProps {
  children?: React.ReactNode;
  href: string;
}

const LinkText: FC<LinkTextProps> = ({ children, href }) => (
  <div
    className={styles.LinkText}
    onClick={() => {
      window.open(href, '_blank');
    }}
  >
    {children}
  </div>
);

export default LinkText;
