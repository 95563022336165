import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { DateRange, ResponseRow } from '../api/analytics/types';
import { Site } from '../api/webflow/types';
import { OrdersProduct } from 'api/orders/types';

export const IS_WINDOW_LOADED: boolean = typeof window !== 'undefined';

export const formatPrice = (price: number, withoutDivide?: boolean) => {
  const dividedPrice = withoutDivide ? price : price / 100;
  return dividedPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

export const getScript = (id?: string) => `
<script async src="https://www.googletagmanager.com/gtag/js?id=${id}"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", "${id}");

  // Function to send custom events to GA
  function sendCustomEventToGA(event_category, eventAction) {
    gtag("event", eventAction, { event_category });
  }

  // Function to track form appearance in the viewport
  function trackFormAppearance(formId) {
    sendCustomEventToGA("Form Appearance", "dg-form-view_" + formId);
  }

  document.addEventListener("DOMContentLoaded", function () {
    // Tracking link clicks
    const aTags = document.querySelectorAll("a");
    aTags.forEach((aTag) => {
      aTag.addEventListener("click", function () {
        const linkText = aTag.getAttribute("href") || "#";
        sendCustomEventToGA("Link Click", "dg-click_" + linkText);
      });
    });

    // Tracking form submissions
    const forms = document.querySelectorAll("form");
    forms.forEach((form) => {
      form.addEventListener("submit", function () {
        const formId = form.id || "Form";
        sendCustomEventToGA("Form Submission", "dg-submit_" + formId);
      });
    });

    // Intersection Observer to track form appearance
    const formObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const formId = entry.target.id || "Form";
          trackFormAppearance(formId);
          // Stop observing once the form appears to avoid multiple triggers
          observer.unobserve(entry.target);
        }
      });
    });

    const formsToObserve = document.querySelectorAll("form");
    formsToObserve.forEach((form) => {
      formObserver.observe(form);
    });
  });
</script>`;

export const isActiveClass = (isTrushy: boolean, defaultClass?: string, activeClass?: string) =>
  isTrushy ? `${defaultClass} ${activeClass || 'active'}` : defaultClass;

export const getPlanString = (interval: string | undefined) => {
  switch (interval) {
    case 'day':
      return 'Daily';
    case 'month':
      return 'Monthly';
    case 'year':
      return 'Yearly';
    default:
      return 'Unknown';
  }
};

export const generateArray = (num: number) => Array.from(Array(num).keys());

export const findObjectByCustomDomain = (sites: Site[], targetUrl: string) =>
  sites.find((site) =>
    site.customDomains.find(({ url }) => {
      if (url === targetUrl) return site;
    })
  );

export const sortMultipleDatesResponse = (arr?: ResponseRow[]) =>
  arr?.sort((a: any, b: any) => {
    const aValue = parseInt(a.dimensionValues?.[0]?.value.split('_')[2]) || 0;
    const bValue = parseInt(b.dimensionValues?.[0]?.value.split('_')[2]) || 0;
    return aValue - bValue;
  });

export const getDatesSlices = ({ startDate, endDate }: DateRange) => {
  const dateArray = [];

  const start = dayjs(startDate);
  const end = dayjs(endDate).subtract(1, 'day');

  const totalDays = end.diff(start, 'day');

  let stepSize = totalDays / 4;

  for (let i = 0; i < 4; i++) {
    const rangeStart = start.add(stepSize * i, 'day').format('YYYY-MM-DD');
    const rangeEnd = start.add(stepSize * (i + 1), 'day').format('YYYY-MM-DD');
    dateArray.push({ startDate: rangeStart, endDate: rangeEnd });
  }

  return dateArray;
};

export const get7DayDateRange = () => {
  const today = new Date();
  const endDate = new Date(today);
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 7);
  return { startDate, endDate };
};

export const formatMinutesAndSeconds = (seconds: number) => {
  dayjs.extend(duration);
  const durationObject = dayjs.duration(seconds, 'seconds');
  const minutes = durationObject.minutes();
  const remainingSeconds = durationObject.seconds();
  return `${minutes}m${remainingSeconds}s`;
};

export const compareArrays = (array1: any[], array2: any[]) => {
  const result: any = [];

  array1.forEach((item1) => {
    const matchingUrls: any = [];

    item1.customDomains.forEach((customDomain: any) => {
      const url = customDomain.url;
      if (array2.some((item2) => item2.url === url)) {
        matchingUrls.push(url);
      }
    });

    if (matchingUrls.length > 0) {
      matchingUrls.forEach((url: any) => {
        result.push({ url, isWfAuthorized: true, isGaConnected: true });
      });
    } else if (item1.customDomains.length > 0) {
      result.push({
        url: item1.customDomains[0].url,
        isWfAuthorized: true,
        isGaConnected: false
      });
    }
  });

  array2.forEach((item2) => {
    const url = item2.url;
    const isWfAuthorized = array1.some((item1) =>
      item1.customDomains.some((customDomain: any) => customDomain.url === url)
    );

    if (!isWfAuthorized) {
      result.push({ url, isWfAuthorized, isGaConnected: true });
    }
  });

  return result;
};

export const getProductPrice = (product: OrdersProduct, isUnitPricing?: boolean) =>
  product.default_price.tiers[isUnitPricing ? 1 : 0].unit_amount;
