import { useAuthStore } from 'store/auth';

interface Props {
  text: string;
}

export const Hello = ({ text }: Props) => {
  const { user } = useAuthStore();
  return (
    <div className="hello">
      <h1>Hello, {user?.firstName}!</h1>
      <p className="typo-body-m">{text}</p>
    </div>
  );
};
