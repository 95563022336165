import * as React from 'react';
import type { SVGProps } from 'react';

const SvgSettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#C6D7CD"
      fillRule="evenodd"
      d="M6.663 14.534c.437.086.887.133 1.337.133v-.003c.446 0 .9-.044 1.336-.134a.83.83 0 0 0 .664-.816v-.77c.463-.184.89-.434 1.283-.74l.667.383c.343.197.78.13 1.037-.163a6.6 6.6 0 0 0 1.34-2.32.83.83 0 0 0-.377-.98l-.667-.384a5.5 5.5 0 0 0 0-1.48l.667-.386c.34-.197.5-.61.377-.98a6.6 6.6 0 0 0-1.34-2.32.83.83 0 0 0-1.037-.164l-.667.384a5.3 5.3 0 0 0-1.28-.74v-.77c0-.394-.28-.74-.663-.817-.874-.18-1.8-.18-2.677 0A.83.83 0 0 0 6 2.284v.77c-.464.183-.89.433-1.284.74L4.05 3.41a.83.83 0 0 0-1.037.164 6.6 6.6 0 0 0-1.34 2.32.83.83 0 0 0 .377.98l.666.386a5.5 5.5 0 0 0 0 1.48l-.666.387c-.34.197-.5.61-.377.98.283.86.737 1.64 1.34 2.32.26.293.693.36 1.037.163l.666-.383q.593.46 1.284.74v.77c0 .393.28.74.663.817M9 13.58a5.8 5.8 0 0 1-2 0v-1.346l-.347-.114a4.3 4.3 0 0 1-1.55-.896l-.27-.244-1.167.674a5.6 5.6 0 0 1-1-1.73l1.167-.674-.073-.353A4.2 4.2 0 0 1 3.666 8c0-.303.034-.603.094-.896l.073-.354-1.167-.673a5.6 5.6 0 0 1 1-1.73l1.167.673.27-.243c.453-.407.973-.71 1.55-.897L7 3.767V2.42a5.8 5.8 0 0 1 2 0v1.344l.346.113c.577.187 1.097.49 1.55.897l.27.243 1.167-.673a5.6 5.6 0 0 1 1 1.73l-1.167.673.074.353q.094.442.093.897t-.093.897l-.074.353 1.167.673a5.6 5.6 0 0 1-1 1.734l-1.167-.674-.27.244c-.453.406-.973.71-1.55.896L9 12.234zM5.333 8A2.67 2.67 0 0 0 8 10.667 2.67 2.67 0 0 0 10.666 8 2.67 2.67 0 0 0 8 5.334 2.67 2.67 0 0 0 5.333 8m1 0a1.667 1.667 0 1 1 3.335.002A1.667 1.667 0 0 1 6.333 8"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSettingsIcon;
