import React, { FC, ReactNode } from 'react';
import styles from './ModalNoPadding.module.scss';

interface ModalNoPaddingProps {
  children: ReactNode;
  className: string;
}

const ModalNoPadding: FC<ModalNoPaddingProps> = ({ children, className }) => {
  const classRecord = `${styles.background} ${styles.modalLuna} ${className} `;

  return (
    <div className={styles.modalOverlay}>
      <div className={classRecord}>{children}</div>
    </div>
  );
};

export default ModalNoPadding;
