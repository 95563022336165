import React, { FC } from 'react';
import styles from './RadioButton.module.scss';
import Wrapper from '../Wrapper/Wrapper';

interface RadioButtonProps {
  onClick?: () => void;
}

const RadioButton: FC<RadioButtonProps> = ({ onClick }) => {
  return (
    <Wrapper
      sx={{ width: '16px', height: '16px' }}
      borderRadius="50%"
      className={styles.radioButton}
      onClick={onClick}
    ></Wrapper>
  );
};

export default RadioButton;
