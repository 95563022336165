const UserSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" fill="none" viewBox="0 0 19 20" {...props}>
    <path
      stroke="#C6D7CD"
      strokeLinecap="round"
      strokeWidth="1.583"
      d="M4.752 15.69V14.9a3.166 3.166 0 013.166-3.167h3.167A3.166 3.166 0 0114.25 14.9v.792M9.502 9.355a2.375 2.375 0 110-4.75 2.375 2.375 0 010 4.75z"
    ></path>
  </svg>
);

export default UserSVG;
