import * as React from 'react';
import type { SVGProps } from 'react';

const SvgWebflowBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="none" {...props}>
    <path
      fill="#146EF5"
      d="m24 0 3.311 3.093 4.105-1.918 2.194 3.964 4.497-.555.861 4.448 4.448.861-.555 4.497 3.964 2.194-1.918 4.105L48 24l-3.093 3.311 1.918 4.105-3.964 2.194.555 4.497-4.448.861-.861 4.448-4.497-.555-2.194 3.964-4.105-1.918L24 48l-3.311-3.093-4.105 1.918-2.194-3.964-4.497.555-.861-4.448-4.448-.861.555-4.497-3.964-2.194 1.918-4.105L0 24l3.093-3.311-1.918-4.105 3.964-2.194-.555-4.497 4.448-.861.861-4.448 4.497.555 2.194-3.964 4.105 1.918z"
    />
    <g clipPath="url(#webflow-badge_svg__a)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m35.472 18.902-6.599 12.856h-6.185l2.751-5.333h-.127c-2.258 2.952-5.66 4.889-10.495 5.333v-5.253s3.101-.175 4.914-2.095h-4.914v-5.508h5.518v4.54h.127l2.258-4.54h4.182v4.508h.127l2.322-4.508z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="webflow-badge_svg__a">
        <path fill="#fff" d="M14.817 18.902h20.655v12.856H14.817z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWebflowBadge;
