import React, { FC, HTMLAttributes, LegacyRef, MutableRefObject, SVGProps, useEffect, useRef, useState } from 'react';
import styles from './Dropdown.module.scss';

import DropdownArrowIcon from 'assets/icons/DropdownArrowIcon';
import Wrapper from 'components/ui/Wrapper/Wrapper';
import Box from 'components/ui/Box/Box';
import { DropdownArrowFill } from 'assets/icons';
import Label from 'components/ui/Label/Label';

interface DropdownListProps {
  title?: string;
  url?: string;
  children?: React.ReactNode;
  dropdownActive: boolean;

  showProjectMenu: boolean;
  projectMenuRef: MutableRefObject<HTMLDivElement | null>;
  handleClick: (showProjectMenu: boolean, dropdownActive: boolean) => void;
}

const DropdownList: FC<DropdownListProps> = ({
  children,
  title,
  url,
  dropdownActive,
  showProjectMenu,
  projectMenuRef,
  handleClick
}) => {
  const wrapperRef = useRef<any>();
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // Check if the clicked element is outside the component
      if (
        wrapperRef.current &&
        projectMenuRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !projectMenuRef.current.contains(event.target)
      ) {
        handleClick(false, false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div style={{ position: 'relative', flex: '1' }} ref={wrapperRef}>
      <Wrapper
        className={dropdownActive ? `${styles.Dropdown}  ${styles.DropdownActive}` : `${styles.Dropdown}`}
        onClick={() => {
          handleClick(!showProjectMenu, !dropdownActive);
        }}
        alignItems="center"
        padding="8px 8px 8px 8px"
        //sx={sx}
      >
        <Box sx={{ flex: 1 }} gap={8} alignItems="center" flexDirection="row">
          <img height="16" width="16" src={`https://api.statvoo.com/favicon/${url}`} />

          <Label>{title}</Label>
        </Box>
        <DropdownArrowFill />
      </Wrapper>
      <div className={styles.OptionContainer} style={dropdownActive ? { display: 'block' } : { display: 'none' }}></div>
      {children}
    </div>
  );
};
export default DropdownList;
