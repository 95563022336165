const VisaSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="10" fill="none" viewBox="0 0 28 10" {...props}>
    <g clipPath="url(#clip0_743_2533)">
      <path
        fill="#fff"
        d="M12.722 4.098c-.014 1.107.986 1.724 1.74 2.091.773.377 1.033.618 1.03.955-.006.516-.617.743-1.19.752-.998.016-1.579-.27-2.04-.485l-.36 1.683c.463.214 1.32.4 2.21.408 2.087 0 3.453-1.03 3.46-2.628.009-2.028-2.804-2.14-2.785-3.046.007-.275.269-.568.843-.643.285-.038 1.07-.066 1.96.343l.35-1.628a5.328 5.328 0 00-1.86-.341c-1.965 0-3.347 1.044-3.358 2.539zm8.575-2.4a.906.906 0 00-.846.564l-2.982 7.12h2.086l.415-1.147h2.55l.24 1.147h1.838L22.994 1.7h-1.697zm.291 2.076l.602 2.886h-1.648l1.046-2.886zM10.194 1.7L8.548 9.382h1.988L12.18 1.7h-1.987m-2.941 0l-2.069 5.23-.837-4.447c-.098-.496-.486-.783-.917-.783H.047L0 1.922c.694.15 1.483.394 1.961.654.293.159.376.297.472.675l1.585 6.131h2.1L9.34 1.7H7.252z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_743_2533">
        <path fill="#fff" d="M0 0H28V9.078H0z" transform="translate(0 .46)"></path>
      </clipPath>
    </defs>
  </svg>
);

export default VisaSVG;
