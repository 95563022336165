import { useMutation } from 'react-query';
import { useEffect, useState } from 'react';
import { getAccounts, getDataStreams, getProperties } from 'api/analytics';
import { analyticsKeys } from 'api/analytics/keys';
import { useQuery } from 'react-query';

import styles from './ModalUsStepOne.module.scss';
import ButtonOutlined from 'components/ui/Button/Button';
import Select from 'components/ui/Select/Select';
import ProgressBar from 'components/ui/ProgressBar/ProgressBar';
import Button from 'components/ui/Button/Button';
import Text from 'components/ui/Text/Text';
import Label from 'components/ui/Label/Label';
import CloseSVG from 'assets/icons/CloseSVG';
import { useModalStore } from 'store/modalStore';
import { ArrowPointingRight } from 'assets/icons';

interface ModalUsStepOneProps {
  handleButtonClick: () => void;
  handleCancelClick: () => void;
}

type SelectItem = {
  value: string;
  label: string;
};

interface FormState {
  accountId?: string;
  propertyId?: string;
  streamId?: string;
}

export const ModalUsStepOne = ({ handleButtonClick, handleCancelClick }: ModalUsStepOneProps) => {
  const [ids, setIds] = useState<FormState>({
    accountId: undefined,
    propertyId: undefined,
    streamId: undefined
  });

  const { data: accounts } = useQuery(analyticsKeys.accounts, getAccounts);

  const { data: properties, refetch: refetchProp } = useQuery(
    analyticsKeys.properties,
    () => getProperties(ids?.accountId),
    { enabled: !!ids.accountId }
  );

  const {
    data: streams,
    refetch: refetchStreams,
    isFetched
  } = useQuery(analyticsKeys.dataStreams, () => getDataStreams(ids?.propertyId), {
    enabled: !!ids.propertyId
  });

  useEffect(() => {
    if (ids.accountId) refetchProp();
  }, [ids.accountId]);

  useEffect(() => {
    if (ids.propertyId) refetchStreams();
  }, [ids.propertyId]);
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalUsStepOne}>
        <ProgressBar progressWidth={50} text="1/2 &nbsp; Generate script" />
        <CloseSVG className={styles.closeIcon} onClick={handleCancelClick} />

        <div className={styles.body}>
          <div className={styles.header}>Embed custom script to track all events</div>
          <div className={styles.bodyText}>
            To unlock all custom events by Data Goat, please embed our custom script by clicking on the button below.
          </div>
          <div className={styles.selectWrapper}>
            {/* <div className={styles.selectText}>Select account</div>
            <div className={styles.select}>
              <Select
                options={accounts?.map(({ displayName, account }) => ({ value: account, label: displayName })) || []}
                handleOptionClickProp={(item: any) =>
                  setIds({ propertyId: undefined, streamId: undefined, accountId: item })
                }
                sx={{ flex: 1, height: '36px' }}
                optionPositionTop={40}
              />
            </div>
            {properties && (
              <>
                <div className={styles.selectText}>Select property</div>
                <div className={styles.select}>
                  <Select
                    //initialOption={ids!.propertyId}
                    options={properties?.map(({ displayName, name }) => ({ value: name, label: displayName })) || []}
                    handleOptionClickProp={(item: any) =>
                      setIds((p) => ({ ...p, streamId: undefined, propertyId: item }))
                    }
                    sx={{ flex: 1, height: '36px' }}
                    optionPositionTop={40}
                  />
                </div>
              </>
            )}
            {streams && (
              <>
                <div className={styles.selectText}>Select Data Stream</div>
                <div className={styles.select}>
                  <Select
                    options={streams?.map(({ displayName, webStreamData }) => ({
                      value: webStreamData.measurementId,
                      label: displayName
                    }))}
                    sx={{ flex: 1, height: '36px' }}
                    optionPositionTop={40}
                    //initialOption={ids.streamId}
                    handleOptionClickProp={(item: any) => setIds((p) => ({ ...p, streamId: item }))}
                  />
                </div>
              </>
            )}
            {isFetched && !streams ? <Label>No data streams</Label> : ''} */}
          </div>
        </div>
        <div className={styles.action}>
          <Button
            type="primary"
            //disabled={ids?.streamId ? false : true}
            className={styles.button}
            //sx={{ color: 'darkgray' }}
            //color={ids?.streamId ? 'primary-400' : 'neutral-400'}
            color="primary-400"
            handleClick={handleButtonClick}
          >
            {/* <Text size="s" color={ids?.streamId ? 'neutral-500' : 'neutral-200'}> */} Embed &nbsp;{' '}
            <ArrowPointingRight />
            {/* </Text> */}
          </Button>

          <div className={styles.actionButtons}>
            {/* <ButtonOutlined sx={{flex:1}}>Custom events + Google tag</ButtonOutlined> */}
            {/* <ButtonOutlined sx={{flex:1}}>Custom events + Google tag</ButtonOutlined> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUsStepOne;
