import dayjs from 'dayjs';
import { useCallback, useEffect } from 'react';
import { getAnalytics } from 'api/analytics';
import { useQueries } from 'react-query';
import { get7DayDateRange, sortMultipleDatesResponse } from '../utils';
import { DateRange } from 'api/analytics/types';

interface Props {
  propertyId: string;
  code?: string;
  dateRanges?: DateRange;
  active?: number;
  isReport?: boolean;
}

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const useVisitorsData = ({ propertyId, code, dateRanges, active, isReport }: Props) => {
  const generateDateRangeArray = () => {
    if (code) {
      const startDate = dateRanges?.startDate;
      const endDate = dateRanges?.endDate;

      if (!startDate || !endDate) return [];
      const period = detectPeriodByDateRange();

      const res = [];
      const currentDate = new Date(startDate);
      const end = new Date(endDate);
      end.setDate(end.getDate() - 1);

      if (period === 'day') {
        while (currentDate <= end) {
          const nextDate = new Date(currentDate);
          res.push({ startDate: formatDate(currentDate), endDate: formatDate(nextDate) });
          currentDate.setDate(currentDate.getDate() + 1);
        }
      } else if (period === 'week') {
        // Add logic as a day but for 7 days for prev month
        while (currentDate <= end) {
          const nextDate = new Date(currentDate);
          nextDate.setDate(nextDate.getDate() + 7);
          res.push({ startDate: formatDate(currentDate), endDate: formatDate(nextDate) });
          currentDate.setDate(currentDate.getDate() + 7);
        }
      } else if (period === 'month') {
        while (currentDate <= end) {
          // Get the current month and year
          const currentMonth = currentDate.getMonth();
          const currentYear = currentDate.getFullYear();

          // Set the next month
          const nextMonth = currentMonth + 1;
          currentDate.setMonth(nextMonth);

          // If the next month is in the next year, reset the month and update the year
          if (nextMonth === 12) {
            currentDate.setFullYear(currentYear + 1);
          }

          // Create the date range for the current month
          const startDateString = formatDate(new Date(currentYear, currentMonth, 1));
          const endDateString = formatDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), 0));
          res.push({ startDate: startDateString, endDate: endDateString });
        }
      } else if (period === 'year') {
        // Add logic as a month but for 3 months
        while (currentDate <= end) {
          // Get the current month and year
          const currentMonth = currentDate.getMonth();
          const currentYear = currentDate.getFullYear();

          // Set the next month
          const nextMonth = currentMonth + 3;
          currentDate.setMonth(nextMonth);

          // If the next month is in the next year, reset the month and update the year
          if (nextMonth >= 12) {
            currentDate.setFullYear(currentYear + 1);
          }

          // Create the date range for the current month
          const startDateString = formatDate(new Date(currentYear, currentMonth, 1));
          const endDateString = formatDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), 0));
          res.push({ startDate: startDateString, endDate: endDateString });
        }
      }

      return res;
    } else {
      let startDate, endDate;

      if (code) {
        startDate = dayjs(dateRanges?.startDate).format('YYYY-MM-DD');
        endDate = dayjs(dateRanges?.endDate).format('YYYY-MM-DD');
      } else {
        const { startDate: start, endDate: end } = get7DayDateRange();
        startDate = start;
        endDate = end;
      }
      // const { startDate, endDate } = get7DayDateRange();
      if (!startDate || !endDate) return [];
      const res = [];
      const currentDate = new Date(startDate);
      const end = new Date(endDate);
      end.setDate(end.getDate() - 1);
      while (currentDate <= end) {
        const nextDate = new Date(currentDate);
        res.push({ startDate: formatDate(currentDate), endDate: formatDate(nextDate) });
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return res;
    }
  };

  const detectPeriodByDateRange = useCallback(() => {
    // const { startDate, endDate } = dateRanges;
    const startDate = dateRanges?.startDate;
    const endDate = dateRanges?.endDate;

    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const diff = end.diff(start, 'day');

    if (diff <= 7) return 'day';
    else if (diff > 7 && diff <= 31) return 'week';
    else if (diff > 32 && diff < 365) return 'month';
    else if (diff <= 365) return 'year';
  }, [dateRanges]);
  // const generateDateRangeArray = () => {
  //   let startDate, endDate;

  //   if (code) {
  //     startDate = dayjs(dateRanges?.startDate).format('YYYY-MM-DD');
  //     endDate = dayjs(dateRanges?.endDate).format('YYYY-MM-DD');
  //   } else {
  //     const { startDate: start, endDate: end } = get7DayDateRange();
  //     startDate = start;
  //     endDate = end;
  //   }

  //   // const { startDate, endDate } = get7DayDateRange();

  //   if (!startDate || !endDate) return [];

  //   const res = [];
  //   const currentDate = new Date(startDate);
  //   const end = new Date(endDate);
  //   end.setDate(end.getDate() - 1);

  //   while (currentDate <= end) {
  //     const nextDate = new Date(currentDate);
  //     res.push({ startDate: formatDate(currentDate), endDate: formatDate(nextDate) });
  //     currentDate.setDate(currentDate.getDate() + 1);
  //   }

  //   return res;
  // };

  const dateRangesArray = generateDateRangeArray();

  const generateQueries = () => {
    const numQueries = Math.ceil(dateRangesArray.length / 4);

    const queryFunctions = [];
    for (let i = 0; i < numQueries; i++) {
      const startIndex = i * 4;
      const endIndex = Math.min(startIndex + 4, dateRangesArray.length);

      queryFunctions.push({
        queryKey: ['new-visitors-chart', i],
        queryFn: () => {
          const dateRanges = dateRangesArray.slice(startIndex, endIndex);
          return getAnalytics({
            propertyId,
            dateRanges,
            dimensions: [],
            metrics: [{ name: 'totalUsers' }, { name: 'newUsers' }],
            code
          });
        },
        enabled: false
      });
    }

    return queryFunctions;
  };

  const results = useQueries(generateQueries());
  const concatResults = results.reduce((acc: any, result) => {
    if (result.data) acc = [...acc, ...(sortMultipleDatesResponse(result.data) || [])];
    return acc;
  }, []);

  const formatDateLabel = (i: number) => {
    const startDate = dateRangesArray[i]?.startDate;
    return dayjs(startDate).format('DD MMM');
  };

  const analyticsData = concatResults?.map(({ metricValues }: any, i: number) => ({
    value: +(metricValues[active || 0]?.value || '0'),
    date: formatDateLabel(i)
  }));

  useEffect(() => {
    if (propertyId) results.forEach((result: any) => result.refetch());
  }, [propertyId]);

  return { data: analyticsData };
};
