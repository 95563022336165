import React, { FC, ReactNode, SVGProps, useEffect, useRef, useState } from 'react';
import styles from './Select.module.scss';
import { DropdownArrowIcon } from 'assets/icons/export';
import Wrapper from '../Wrapper/Wrapper';
import Box from '../Box/Box';
import Label from '../Label/Label';

interface SelectProps {
  initialOption?: SelectItem | undefined;
  initialActiveOption?: number;
  options?: SelectItem[] | undefined;
  handleOptionClickProp?: (value: string) => void;
  sx?: React.CSSProperties;
  optionPositionTop?: number;
  Icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
}

type SelectItem = {
  value: string;
  label: string;
};
const Select: FC<SelectProps> = ({
  initialOption,
  initialActiveOption,
  options,
  handleOptionClickProp,
  sx,
  optionPositionTop = 36,
  Icon
}) => {
  const [selectedOption, setSelectedOption] = useState<SelectItem | undefined>(initialOption || options?.[0]);
  const [dropdownActive, setDropdownActive] = useState<boolean>(false);
  const [activeOption, setActiveOption] = useState<number>(initialActiveOption || 0);

  const handleOptionClick = (option: SelectItem) => {
    setSelectedOption(option);
    setDropdownActive(false);

    if (handleOptionClickProp) handleOptionClickProp(option.value);
  };

  const wrapperRef = useRef<any>();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // Check if the clicked element is outside the component
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDropdownActive(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <
      //   className={dropdownActive ? `${styles.Select}  ${styles.SelectActive}` : `${styles.Select}`}
      //   onClick={() => {
      //     setDropdownActive(!dropdownActive);
      //   }}
      //   ref={wrapperRef}
      //   style={sx}
    >
      <div style={{ position: 'relative' }} ref={wrapperRef}>
        <Wrapper
          className={dropdownActive ? `${styles.Select}  ${styles.SelectActive}` : `${styles.Select}`}
          onClick={() => {
            setDropdownActive(!dropdownActive);
          }}
          padding="8px 12px"
          alignItems="center"
          borderRadius="4px"
          sx={sx}
        >
          {Icon && <Icon />}
          <Box sx={{ flex: 1 }}>
            <Label size="m" color="none">
              {selectedOption?.label}
            </Label>
          </Box>
          <DropdownArrowIcon />
        </Wrapper>
        <div
          className={styles.OptionContainer}
          style={
            dropdownActive
              ? { display: 'block', top: optionPositionTop + 'px' }
              : { display: 'none', top: optionPositionTop + 'px' }
          }
        >
          {options?.map((option, index) => (
            <Wrapper
              key={index}
              padding="8px"
              className={activeOption === index ? `${styles.OptionActive} ${styles.Option}` : `${styles.Option}`}
              onClick={() => {
                handleOptionClick(option);
                setActiveOption(index);
              }}
            >
              {' '}
              {Icon && <Icon />}
              <Label size="m" color="none">
                {option.label}
              </Label>
            </Wrapper>
          ))}
        </div>
      </div>
    </>
  );
};

export default Select;
