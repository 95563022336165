import { Portal } from 'react-portal';
import { CloseIcon } from '../../assets/icons/export';

interface Props {
  close?: () => void;
  children: any;
  className: string;
  closeHidden?: boolean;
}

export const Modal = ({ close, children, className, closeHidden }: Props) => (
  <Portal>
    <div className={`modal ${className}`} onMouseDown={close}>
      <div className="modalBox" onMouseDown={(e) => e.stopPropagation()}>
        {!closeHidden ? <CloseIcon className="close" onClick={close} /> : null}
        {children}
      </div>
    </div>
  </Portal>
);

Modal.defaultProps = {
  className: ''
};
