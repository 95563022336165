import type { SVGProps } from 'react';
import * as React from 'react';

const SvgHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" {...props}>
    <circle cx={6} cy={6} r={6} fill="#134C54" />
    <path
      stroke="#D9D9D9"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.214 4.424c.104-.325.295-.615.552-.837a1.82 1.82 0 0 1 1.889-.303c.312.13.582.346.78.622a1.87 1.87 0 0 1-.524 2.671 1.8 1.8 0 0 1-.956.273v.464m.023 1.854v.046h-.046v-.046z"
    />
  </svg>
);
export default SvgHelp;
