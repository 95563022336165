import React, { CSSProperties, FC, ReactNode } from 'react';
import styles from './Heading.module.scss';

interface HeadingProps {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl';
  color?: 'white' | 'neutral-100';
  className?: string;
  children?: ReactNode;
  sx?: CSSProperties;
}

const typoMap = {
  xs: styles.xs,
  s: styles.s,
  m: styles.m,
  l: styles.l,
  xl: styles.xl
};

const colorMap = {
  white: styles.white,
  'neutral-100': styles.neutral100
};

const Heading: FC<HeadingProps> = ({ size = 'l', color = 'white', children, className, sx }) => {
  const classRecord = `${className} ${typoMap[size]} ${colorMap[color]} ${styles.color}`;
  return (
    <div className={classRecord} style={sx}>
      {children}
    </div>
  );
};

export default Heading;
