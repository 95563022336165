import { create } from 'zustand';
import { Subscription } from '../api/orders/types';

interface State {
  subscription?: Subscription;
  setSubscription: (wfAuthCode: State['subscription']) => void;
}

const useOrderStore = create<State>((set) => ({
  subscription: undefined,
  setSubscription: (subscription: State['subscription']) => set({ subscription })
}));

export { useOrderStore };
