import React, { FC } from 'react';
import styles from './ModalUsPopUp.module.scss';
import Box from 'components/ui/Box/Box';
import Modal from 'components/ui/Modal/Modal';
import Text from 'components/ui/Text/Text';
import Label from 'components/ui/Label/Label';
import Heading from 'components/ui/Heading/Heading';
import Button from 'components/ui/Button/Button';
import ModalNoPadding from 'components/ui/ModalNoPadding/ModalNoPadding';
import { ArrowPointingRight, SuccessPopupSVG } from 'assets/icons';
import CloseSVG from 'assets/icons/CloseSVG';
import ArrowSVG from 'assets/icons/ArrowSVG';

interface ModalUsPopUpProps {
  handleCancelClick: () => void;
  handleStartClick: () => void;
}

const ModalUsPopUp: FC<ModalUsPopUpProps> = ({ handleCancelClick, handleStartClick }) => (
  <ModalNoPadding className={styles.modalPopUp}>
    <CloseSVG className={styles.closeIcon} onClick={handleCancelClick} />
    <Box className={styles.sectionContent}>
      <Box sx={{ flexDirection: 'row' }} gap={8}>
        {/* <Box className={styles.hollowCircle}></Box>
        <Box className={styles.circle}></Box> */}
      </Box>
      <Box gap={12}>
        <Box gap={8}>
          <Label size="m" color="highlight">
            What’s new
          </Label>
          <Heading>Ready for the new, upgraded goat?</Heading>
        </Box>
        <Text size="s">
          To get the latest features by Data Goat, you need to add a new script to your Webflow project. It's a simple
          and quick process.
        </Text>
      </Box>
      <Box className={styles.action}>
        <Button className={styles.button} handleClick={handleStartClick}>
          Start &nbsp;
          <ArrowPointingRight />
        </Button>
      </Box>
    </Box>
    <Box className={styles.sectionSteps} gap={16}>
      <Box gap={8} className={styles.steps}>
        <Box className={styles.numberCircle}>
          <Label sx={{ color: 'white' }}>1</Label>
        </Box>
        <Text className={styles.stepText} size="xs">
          Embed the script with one click
        </Text>
        <Label size="m" color="normal">
          |
        </Label>
        <Label size="m" color="normal">
          |
        </Label>
      </Box>
      <Box gap={8} className={styles.steps}>
        <Box className={styles.numberCircle}>
          <Label sx={{ color: 'white' }}>2</Label>
        </Box>
        <Text className={styles.stepText} size="xs">
          {' '}
          Save the changes and publish your website.{' '}
        </Text>
        <Label size="m" color="normal">
          |
        </Label>
        <Label size="m" color="normal">
          |
        </Label>
      </Box>
      <Box gap={8} className={styles.steps}>
        <SuccessPopupSVG />
        <Label className={styles.stepText} size="s">
          Enjoy the new features.{' '}
        </Label>
      </Box>
    </Box>
  </ModalNoPadding>
);

export default ModalUsPopUp;
