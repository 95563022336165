import type { SVGProps } from 'react';
import * as React from 'react';

const SvgNoteform = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <rect width={16} height={16} fill="#fff" rx={8} />
    <g clipPath="url(#Noteform_svg__a)">
      <path
        fill="#000"
        d="M8.807 2.965c.59 0 1.135.197 1.57.529l.004-.003.029.028q.209.165.38.368l.685.662q.815.93.954 1.348c.128.307.2.644.2.998v3.548a2.593 2.593 0 0 1-2.593 2.592H6.488c-.77 0-1.46-.335-1.935-.867l-.754-.715a7.5 7.5 0 0 1-.733-.965 3 3 0 0 1-.097-.168 2 2 0 0 1-.2-.491 2.6 2.6 0 0 1-.102-.724V5.557A2.593 2.593 0 0 1 5.26 2.965z"
      />
      <path
        fill="#fff"
        d="M10.363 5.393H6.269c-.678 0-1.228.55-1.228 1.228v4.093c0 .678.55 1.228 1.228 1.228h4.094c.678 0 1.228-.55 1.228-1.228V6.621c0-.679-.55-1.228-1.228-1.228"
      />
      <path
        fill="#000"
        d="M8.157 7.894v.81q0 .203.07.285.07.079.291.079h.062v.243H6.982v-.243h.044q.22 0 .286-.081.068-.084.068-.283v-1.76q0-.203-.07-.282-.068-.08-.284-.08h-.053v-.24h2.489l.02.786h-.248a1.1 1.1 0 0 0-.14-.326.4.4 0 0 0-.177-.162.8.8 0 0 0-.28-.037h-.48v1.024h.234q.167 0 .23-.07.065-.072.081-.293h.245v1.02h-.245q-.01-.238-.077-.313-.065-.077-.238-.077z"
      />
    </g>
    <defs>
      <clipPath id="Noteform_svg__a">
        <path fill="#fff" d="M2.667 2.967h9.961v10.07H2.667z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNoteform;
