import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { editUser, getGoogleAuthUrl, verifyCode } from 'api/users';
import { usePrivateRoute } from 'hooks/usePrivateRoute';
import { useAuthStore } from '../store/auth';
import { authKeys } from '../api/auth/keys';
import { wfGetInstallUrl } from 'api/webflow';
import { AuthPremissionModal } from '../components/layout/home/auth-permission-modal/AuthPremissionModal';
import { ReActivateAccountModal } from 'components/layout/home/ReActivateAccountModal';
import { queryClient } from 'index';
import { ExtensionLoginSuccessModal } from 'components/layout/home/ExtensionLoginSuccessModal';
import ModalUsPopUp from 'components/modal/ModalGroupScript/ModalUsPopUp/ModalUsPopUp';
import ModalGroupScript, { ModalScriptType } from 'components/modal/ModalGroupScript/ModalGroupScript';
import { ModalType, useModalStore } from 'store/modalStore';
import SideNavbar from 'components/layout/SideNavbar/SideNavbar';
import Box from 'components/ui/Box/Box';
import UserAndProjectAuthorizationPage from './UserAndProjectAuthorization/UserAndProjectAuthorizationPage';
import { getGoogleAnalyticsConnected, getGoogleAuthorization, getWebflowAuthorization } from 'api/auth';
import { useAnalyticsStore } from 'store/AnalyticsStore';
import Container from 'components/ui/Container/Container';
import ModalAddPayment from 'components/modal/AddPayment/AddPayment';
import { UpgradeClickProvider } from 'context/UpgradeClickContext';
import ModalFreeTrial from 'components/modal/FreeTrial/FreeTrial';
import ModalFreeTrialStart from 'components/modal/FreeTrial/Start/Start';
import ModalFreeTrialEnd from 'components/modal/FreeTrial/End/End';
import ModalFreeTrialManualEnd from 'components/modal/FreeTrial/ManualEnd/ManualEnd';
import ModalPlanDowngrade from 'components/modal/Plan/Downgrade/Downgrade';
import ModalPlanUpgrade from 'components/modal/Plan/Upgrade/Upgrade';
import ModalFreeTrialManualEndWithoutUpgrade from 'components/modal/FreeTrial/ManualEndWithoutUpgrade/ManualEndWithoutUpgrade';
import ModalPlanError from 'components/modal/Plan/Error/Error';
import { useLoadingStore } from 'store/loading';

export const HomePage = () => {
  usePrivateRoute();
  const { setLoadingPage } = useLoadingStore();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { user, authStep, setAuthStep } = useAuthStore();
  const { setWebsite, website } = useAnalyticsStore();
  const [isVerified, setIsVerified] = useState(true);
  const {
    googleAuthorized,
    setGoogleAuthorized,
    webflowAuthorized,
    setWebflowAuthorized,
    googleAnalyticsConnected,
    setGoogleAnalyticsConnected
  } = useAuthStore();
  const [isOpened, setIsOpened] = useState(false);
  const { modal, setModal, modalTypeInGroup, setModalTypeInGroup } = useModalStore();
  const { mutate } = useMutation(verifyCode, {
    onSuccess: () => {
      queryClient.invalidateQueries('user');
      navigate('/');
      window.location.reload();
    }
  });

  const queryParams = new URLSearchParams(search);
  const stateValue = queryParams.get('state');
  useEffect(() => {
    if (stateValue) {
      setIsOpened(true);
    }
  }, [stateValue]);

  useEffect(() => {
    if (user && !user?.isVerified) {
      setIsVerified(false);
      // setLoadingPage(false)
    }
  }, [user]);

  const { refetch } = useQuery(authKeys.google, getGoogleAuthUrl, {
    enabled: false,
    onSuccess: (url) => {
      if (!url) return;
      window.location.href = url;
    }
  });

  const { data: url } = useQuery(['wf-auth-url'], wfGetInstallUrl);

  const checkGoogleAnalyticsConnected = async () => {
    const response = await getGoogleAnalyticsConnected();
    if (response === 'connected') {
      setGoogleAnalyticsConnected(true);
    } else setLoadingPage(false);
  };

  const checkGoogleAuthorized = async () => {
    const response = await getGoogleAuthorization();
    if (response === 'authorized') {
      setGoogleAuthorized(true);
    } else setLoadingPage(false);
  };

  const checkWebflowAuthorized = async () => {
    const response = await getWebflowAuthorization();
    if (response === 'authorized') {
      setWebflowAuthorized(true);
    } else setLoadingPage(false);
  };

  useEffect(() => {
    checkGoogleAuthorized();
  }, [googleAuthorized]);

  useEffect(() => {
    checkWebflowAuthorized();
  }, [webflowAuthorized]);

  useEffect(() => {
    checkGoogleAnalyticsConnected();
  }, [googleAnalyticsConnected]);

  useEffect(() => {
    if (user?.website) {
      setWebsite(user?.website);
    }
    if (user?.showTrialExpired) setModal(ModalType.freeTrialEnd);
    if (user?.paymentError) setModal(ModalType.planError);
  }, [user]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const isConnected = queryParams.get('connected') === 'true';

    if (isConnected) navigate('/');
  }, [search]);

  useEffect(() => {
    if (!user?.isVerified as boolean) {
      const searchParams = new URLSearchParams(window.location.search);
      const verifyCode = searchParams.get('verifyCode');
      if (verifyCode) {
        mutate(verifyCode);
      }
    }
  }, [user]);

  // useEffect(() => {
  //   if (user) {
  //     if (user?.firstLogin) {
  //       setModal('script');
  //       setModalTypeInGroup('popup');
  //     } else {
  //       setModalTypeInGroup('generate');
  //     }
  //   }

  // }, [user]);
  return (
    <>
      {/* <div className="container"> */}
      {/* <ModalUsPopUp/> */}
      {user?.firstLogin && !user?.isNewUser ? (
        <ModalUsPopUp
          handleCancelClick={() => {
            setModal(ModalType.none);
            setModalTypeInGroup(ModalScriptType.generate);
            if (user) {
              user.firstLogin = false;
              editUser(user);
            }
          }}
          handleStartClick={() => {
            setModalTypeInGroup(ModalScriptType.generate);
            if (user) {
              user.firstLogin = false;
              editUser(user);
            }
          }}
        />
      ) : (
        <></>
      )}
      {<ModalGroupScript modalType={modal} modalTypeInGroup={modalTypeInGroup} />}
      {modal === 'add-payment' && <ModalAddPayment />}

      {modal === 'free-trial' && <ModalFreeTrial />}
      {modal === 'free-trial-start' && <ModalFreeTrialStart />}
      {modal === 'free-trial-end' && <ModalFreeTrialEnd />}
      {modal === 'free-trial-manual-end' && <ModalFreeTrialManualEnd />}
      {modal === 'free-trial-manual-end-without-upgrade' && <ModalFreeTrialManualEndWithoutUpgrade />}

      {modal === 'plan-upgrade' && <ModalPlanUpgrade />}
      {modal === 'plan-downgrade' && <ModalPlanDowngrade />}
      {modal === 'plan-error' && <ModalPlanError />}

      {isVerified && googleAuthorized && webflowAuthorized && googleAnalyticsConnected && website ? (
        <>
          {isOpened ? (
            <ExtensionLoginSuccessModal
              close={() => setIsOpened(false)}
              className="extensionLogin"
              code={stateValue || ''}
            />
          ) : (
            <>
              {/* <Header /> */}
              {user?.deletionDate ? (
                <ReActivateAccountModal close={() => {}} className="reActivateAccount" />
              ) : (
                <>
                  <UpgradeClickProvider>
                    <Box flexDirection="row" sx={{ width: '100%', height: '100%', overflow: 'scroll' }}>
                      <SideNavbar />
                      <Box sx={{ flex: 1 }}>
                        {/* <Menu /> */}
                        <Container sx={{ width: '100%', height: '100%', position: 'relative' }} flexDirection="column">
                          <Outlet />
                        </Container>
                      </Box>
                    </Box>
                  </UpgradeClickProvider>
                  {/* <div className="homePage">
                    <Hello text="Here are your GA4 results for this week." />
                    <div className="cont">
                      <div className="left">
                        <Tabs authorizeGoogle={refetch} webflowAuthUrl={url} />

                       

                        <p className="supp">
                          <a href="https://www.datagoat.co/customer-support" target="_blank">
                            Contact Support
                          </a>{' '}
                          if the Google Analytics property does not match your Webflow project
                        </p>
                      </div>
                      <div>
                        <AccountInfo />
                        <Sidetabs />
                      </div>
                    </div>
                  </div> */}
                </>
              )}
              {authStep > 0 && (
                <AuthPremissionModal
                  webflowAuthUrl={url}
                  step={authStep}
                  nextStep={(num: number) => setAuthStep(num)}
                  close={() => {
                    setAuthStep(0);
                  }}
                  authorize={refetch}
                />
              )}
            </>
          )}
        </>
      ) : (
        <UserAndProjectAuthorizationPage email={user?.email} />
        // <VerifyEmailModal close={() => {}} email={user?.email} className="reActivateAccount" />
      )}
    </>
  );
};
