import { toast } from 'react-toastify';
import Axios from 'axios';

import { getStorageItem } from 'utils/storage';

const baseURL = process.env.REACT_APP_BASE_URL || '';

export const apiCall = async (signal?: AbortSignal) => {
  try {
    const headers: any = { Accept: 'application/json' };

    // If token exists, add it to the header for authentication
    const token = getStorageItem('token');
    if (token) headers.Authorization = `Bearer ${token}`;

    // Create an Axios instance
    const axios = Axios.create({ baseURL, headers, signal: signal });

    // Add a request interceptor to handle errors
    axios.interceptors.response.use(
      (data) => Promise.resolve(data),
      ({ response }) => Promise.reject(response)
    );

    return axios;
  } catch (err: any) {
    if (err?.status === 500) toast.error('Something went wrong. Please try again later.');
    return Promise.reject(err);
  }
};
