import type { SVGProps } from 'react';
import * as React from 'react';

const SvgLocked = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} fill="none" {...props}>
    <circle cx={8.668} cy={8.836} r={8} fill="#3C4F52" />
    <path
      fill="#C6D7CD"
      d="M11.668 8.138V6.836c0-1.655-1.345-3-3-3s-3 1.345-3 3v1.302c-.575.165-1 .693-1 1.323v3c0 .757.617 1.375 1.375 1.375h5.25c.758 0 1.375-.618 1.375-1.375v-3a1.38 1.38 0 0 0-1-1.323m-3-3.552c1.24 0 2.25 1.01 2.25 2.25v1.25h-4.5v-1.25c0-1.24 1.01-2.25 2.25-2.25m3.25 7.875c0 .345-.28.625-.625.625h-5.25a.625.625 0 0 1-.625-.625v-3c0-.345.28-.625.625-.625h5.25c.345 0 .625.28.625.625z"
    />
  </svg>
);
export default SvgLocked;
