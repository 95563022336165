import React, { FC, useContext, useState } from 'react';
import styles from './Menu.module.scss';
import Box from 'components/ui/Box/Box';
import Container from 'components/ui/Container/Container';
import Label from 'components/ui/Label/Label';
import Wrapper from 'components/ui/Wrapper/Wrapper';
import Select from 'components/ui/Select/Select';
import { InsightsIconPriimary500, Locked } from 'assets/icons/export';
import { useAnalyticsStore } from 'store/AnalyticsStore';
import { useTooltipStore } from 'store/tooltipStore';
import { ModalType, useModalStore } from 'store/modalStore';
import { useAuthStore } from 'store/auth';
import { PreviewLinkIcon, ScriptMenuSVG } from 'assets/icons';
import { ShareLinkModal } from '../home/websites/ShareLinkModal';
import Button from 'components/ui/Button/Button';
import { UpgradeClickContext } from 'context/UpgradeClickContext';
import { useNavigate } from 'react-router-dom';
import { ModalScriptType } from 'components/modal/ModalGroupScript/ModalGroupScript';

interface MenuProps {}

const dateOptions = [
  { value: 'Yesterday', label: 'Yesterday' },
  { value: 'Last 7 days', label: 'Last 7 days' },
  { value: 'Last 30 days', label: 'Last 30 days' },
  { value: 'Last 90 days', label: 'Last 90 days' },
  { value: 'Last year', label: 'Last year' }
];
const Menu: FC<MenuProps> = () => {
  const { website, cancelToken } = useAnalyticsStore();

  const today = new Date();
  const previousDate = new Date();
  const { user } = useAuthStore();
  previousDate.setDate(today.getDate() - 6);
  //const [selectedStartDate, setSelectedStartDate] = useState<string>(previousDate.toISOString().split('T')[0]);
  //const [selectedEndDate, setSelectedEndDate] = useState<string>(today.toISOString().split('T')[0]);
  const { startDate: selectedStartDate, setStartDate, endDate, setEndDate } = useAnalyticsStore();
  // Function to update the selected date range
  const { tooltip, setTooltip } = useTooltipStore();
  const [openShareLinkModal, setOpenShareLinkModal] = useState(false);
  const { setModalData, setModal } = useModalStore();
  const { modal, modalTypeInGroup, setModalTypeInGroup } = useModalStore();
  const { handleUpgradeClick } = useContext(UpgradeClickContext);
  const navigate = useNavigate();
  const handleDateRangeChange = (option: string) => {
    const today = new Date();

    let startDate = new Date();
    let endDate = new Date();

    switch (option) {
      case 'Yesterday':
        // For "Yesterday," set start date as day before yesterday and end date as yesterday
        startDate.setDate(today.getDate() - 2);
        endDate.setDate(today.getDate() - 1);
        break;
      case 'Last 7 days':
        startDate.setDate(today.getDate() - 6);
        break;
      case 'Last 30 days':
        startDate.setDate(today.getDate() - 29);
        break;
      case 'Last 90 days':
        startDate.setDate(today.getDate() - 89);
        break;
      case 'Last year':
        startDate.setDate(today.getDate() - 364);
        //startDate.setUTCHours(0, 0, 0, 0);
        //startDate.setUTCMonth(0, 1);
        break;
      default:
        break;
    }

    // Format dates as 'YYYY-MM-DD'
    const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = endDate.toISOString().split('T')[0];

    if (selectedStartDate != formattedStartDate) {
      cancelToken.allForms.abort();
      cancelToken.charts.abort();
      cancelToken.pages.abort();
      cancelToken.sources.abort();
      cancelToken.singleForm.abort();
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };
  return (
    <Container className={styles.menu} padding="8px 0px">
      <Container padding="0px 24px" justifyContent="space-between" alignItems="center" sx={{ flex: 1 }}>
        <Wrapper gap={4} padding="8px 0px" flexDirection="column">
          <Box flexDirection="row" gap={8} alignItems="center">
            <img height="16" width="16" src={`https://api.statvoo.com/favicon/${website?.url}`} />
            <Label size="l" color="normal">
              {website?.displayName}
            </Label>
          </Box>
          {/* <Text size="xxs" color="neutral-100">
            Last updated: 2:45pm
          </Text> */}
        </Wrapper>
        <Box flexDirection="row" gap={24}>
          {user?.tier.tier !== 'Business' && (
            <Button
              Icon={(props) => {
                return <InsightsIconPriimary500 />;
              }}
              handleClick={handleUpgradeClick}
            >
              {user?.trialUsed ? 'Upgrade' : 'Start a free trial'}
            </Button>
          )}
          <Select
            options={[{ value: '', label: 'Whole website' }]}
            handleOptionClickProp={function (value: string): void {}}
            sx={{ width: '200px', height: '36px' }}
          ></Select>
          <Select
            options={dateOptions}
            initialOption={{ value: 'Last 7 days', label: 'Last 7 days' }}
            initialActiveOption={1}
            handleOptionClickProp={handleDateRangeChange}
            sx={{ width: '200px', height: '36px' }}
          ></Select>
          {/* <ScriptMenuSuccess /> */}
          {user && (
            <>
              <ScriptMenuSVG
                onClick={() => {
                  setModalData({
                    url: website?.url,
                    propertyId: website?.propertyId,
                    shortName: website?.shortName
                  });
                  setModal(ModalType.script);

                  setModalTypeInGroup(ModalScriptType.generate);
                }}
                style={{ cursor: 'pointer' }}
              />
              {user.tier.limitation.allowReports ? (
                <Wrapper
                  className={styles.previewLinkWrapperL}
                  padding="8px"
                  borderRadius="8px"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: '36px', height: '36px', cursor: 'pointer' }}
                  onClick={() => setOpenShareLinkModal(true)}
                >
                  <PreviewLinkIcon />
                </Wrapper>
              ) : (
                <Wrapper
                  className={styles.previewLinkWrapperL}
                  padding="8px"
                  borderRadius="8px"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: '36px', height: '36px', cursor: 'pointer', position: 'relative' }}
                  onClick={() => {
                    user.trialUsed ? navigate('subscription') : setModal(ModalType.freeTrial);
                  }}
                >
                  <PreviewLinkIcon />
                  <Locked style={{ position: 'absolute', bottom: '-6px', right: '-6px' }} />
                </Wrapper>
              )}
              {openShareLinkModal && (
                <ShareLinkModal
                  close={() => setOpenShareLinkModal(false)}
                  propertyId={website.propertyId}
                  className="shareLinkModal"
                />
              )}
            </>
          )}
        </Box>
      </Container>
    </Container>
  );
};

export default Menu;
