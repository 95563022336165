import React, { FC } from 'react';
// @ts-ignore
import styles from './HoverInfoComponent.module.scss';

interface HoverInfoComponentProps {
  date: string;
  dimension: string;
  metric: string;
  event: React.MouseEvent;
}

const HoverInfoComponent: FC<HoverInfoComponentProps> = ({ date, dimension, metric, event }) => {
  const style: React.CSSProperties = {
    position: 'fixed',
    top: event.clientY + 'px',
    left: event.clientX + 'px'
  };
  return (
    <div className={styles.HoverInfoComponent} style={style}>
      <p className={styles.Date}>{date}</p>
      <div className={styles.Text}>
        <p className={styles.Name}>{dimension}</p> <p className={styles.Value}>{metric}</p>
      </div>
    </div>
  );
};

export default HoverInfoComponent;
