import { useFormik } from 'formik';
import { isActiveClass } from '../../../utils';
import { InputField } from '../../ui/forms/fields/InputField';
import { Checkbox } from '../../ui/Checkbox';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeElementStyle } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useMutation } from 'react-query';
import { addPaymentMethod } from 'api/orders';
import { queryClient } from '../../..';
import { orderKeys } from 'api/orders/keys';
import { toast } from 'react-toastify';

interface Props {
  closeForm: () => void;
  backgroundColor: string;
}

export const AddPaymentMethodForm = ({ closeForm, backgroundColor }: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const { mutate } = useMutation(addPaymentMethod);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { cardHolderName: '', cardNumber: '', expirationDate: '', cvv: '', default: false },
    onSubmit: async (values, { resetForm }) => {
      if (stripe === null || elements === null) return;
      let paymentMethod;
      try {
        const paymentMethodResponse = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardNumberElement)!,
          billing_details: { name: values.cardHolderName }
        });
        if (paymentMethodResponse?.error) toast.error(paymentMethodResponse.error.message);
        else paymentMethod = paymentMethodResponse.paymentMethod;
      } catch (e: any) {
        if (e?.error?.message && e?.error?.code) toast.error(e.error.message);
      }
      mutate(paymentMethod?.id || '', {
        onSuccess: () => {
          queryClient.refetchQueries(orderKeys.paymentInfo);
          resetForm();
          closeForm();
        }
      });
    }
  });

  const style: StripeElementStyle = {
    base: {
      color: '#fff',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '22px',
      padding: '12px',
      '::placeholder': {
        color: '#a1b8b4'
      }
    },
    invalid: {
      color: '#f68989'
    }
  };

  return (
    <div className="wrapperr addCardForm">
      <form onSubmit={formik.handleSubmit} className="inner">
        <InputField required name="cardHolderName" label="Card Holder Name*" formik={formik} />
        <div className="inputFieldWrapper">
          <div className="inputField">
            <label className="typo-label-l">Credit/debit card number*</label>
            <CardNumberElement
              className="stripeField stripeCardNumberField"
              options={{ style, placeholder: 'XXXX XXXX XXXX XXXX' }}
              onChange={(e) => {}}
            />
          </div>
        </div>
        <div className="grid-col-2 gap-4 inputsWrapper">
          <div className="inputFieldWrapper">
            <div className="inputField">
              <label className="typo-label-l">Expiration Date*</label>
              <CardExpiryElement
                className="stripeField stripeCardExpiryField"
                options={{ style }}
                onChange={(e) => {}}
              />
            </div>
          </div>
          <div className="inputFieldWrapper">
            <div className="inputField">
              <label className="typo-label-l">CVC*</label>
              <CardCvcElement className="stripeField stripeCardCvcField" options={{ style, placeholder: 'XXX' }} />
            </div>
          </div>
        </div>

        <button type="submit" className="outlined">
          Add a payment method
        </button>
      </form>
    </div>
  );
};
