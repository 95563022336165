const ProjectSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="#799892"
      fillRule="evenodd"
      d="M12.167 13c.46 0 .833-.373.833-.833V8.333h1v3.834c0 1.01-.823 1.833-1.833 1.833H3.833A1.837 1.837 0 012 12.167V3.833C2 2.823 2.823 2 3.833 2h3.834v1H3.833A.834.834 0 003 3.833v8.334c0 .46.373.833.833.833h8.334zM9 2h4.167c.46 0 .833.373.833.833V7h-1V3.727L7.697 9.343l-.727-.686L12.31 3H9V2z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default ProjectSVG;
