import { LogoIcon } from 'assets/icons/export';
import DataGoatImg from 'assets/images/Goat-V3.png';

export const ExtensionLoginSuccess = () => {
  return (
    <div className="extensionLoginWrapper">
      <div className="header">
        <LogoIcon onClick={() => (window.location.href = '/')} />
      </div>
      <div className="loginSuccessInfo">
        <img src={DataGoatImg} alt="" />
        <h2>Successfully logged in via Google!</h2>
        <p>
          <span>AAaa You may close this tab</span> and return to Webflow Designer to continue tracking your website’s
          performance with Data Goat.
        </p>
      </div>
    </div>
  );
};
