import React, { FC } from 'react';
import styles from './Loading.module.scss';
import Overlay from '../Overlay';
import LoadingDots from 'components/ui/LoadingDots/LoadingDots';

interface OverlayLoadingProps {}

const OverlayLoading: FC<OverlayLoadingProps> = () => (
  <Overlay>
    <LoadingDots />
  </Overlay>
);

export default OverlayLoading;
