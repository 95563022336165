import dayjs from 'dayjs';
import { useAuthStore } from 'store/auth';
import { NavLink } from 'react-router-dom';
import { useOrderStore } from 'store/order';
import { getPlanString } from '../../../utils';

export const AccountInfo = () => {
  const { user } = useAuthStore();
  const { subscription } = useOrderStore();

  return (
    <div className="accountInfo">
      <h3>Account Info</h3>
      <div className="datesData">
        <p>Active since {dayjs(user?.createdAt).format('MMM YYYY')}</p>
        {/* <p>Last billed {dayjs(user?.subscription.currentPeriodStart).format('MMM YYYY')}</p> */}
      </div>

      <div className="statusData">
        {/* <p className="status">
          Account Status: <span>{user?.subscription.status}</span>
        </p> */}
        {/* <p>
          Plan: <span>{getPlanString(subscription?.plan.interval)}</span>
        </p> */}
        <NavLink to="/account-settings">Account Settings</NavLink>
        <p>
          Active projects{' '}
          <span className="activeProjects">
            {user?.websites?.filter((website: any) => website.websiteState.chosenForTracking)?.length} /{' '}
            {user?.webflow?.allowedWebsites}
          </span>
        </p>
      </div>
    </div>
  );
};
