import { Header } from 'components/abstract/Header';
import { useRef, useState } from 'react';
import { useAuthStore } from 'store/auth';
import { useMutation, useQuery } from 'react-query';
import { couponSubscription, createSubscription, fetchUsersPaymentInfo, setDefaultPaymentMethod } from 'api/orders';
import { Elements } from '@stripe/react-stripe-js';
import { ChevronIcon, FourDotsIcon } from 'assets/icons/export';
import { AddPaymentMethodForm } from 'components/layout/subscription/AddPaymentMethodForm';
import { formatPrice, isActiveClass } from 'utils';
import { orderKeys } from 'api/orders/keys';
import { getCardIcon } from 'utils/payments';
import { loadStripe } from '@stripe/stripe-js';
import { useRouterQuery } from 'hooks/useQuery';
import { ResubscribeSuccessModal } from 'components/layout/subscription/resubscribe/ResubscribeSuccessModal';
import useOutsideClick from 'hooks/useOutsideClick';
import CheckGoatIcon from 'assets/images/checkIcon.png';
import NotFoundIcon from 'assets/images/NotFoundIcon.png';
import { usePrivateRoute } from 'hooks/usePrivateRoute';

export const PurchaseSummary = () => {
  usePrivateRoute();

  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openActions, setOpenActions] = useState('');
  const ref = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [isValidCode, setValidCode] = useState('only-wf');

  const onSuccessMutation = () => {
    return setIsOpen(true);
  };

  const onErrorMutation = () => {
    return setIsOpen(false);
  };

  const { mutate, isLoading } = useMutation(createSubscription, {
    onSuccess: () => {
      onSuccessMutation();
    },
    onError: () => {
      onErrorMutation();
    }
  });

  const { mutate: mutateCoupon, isLoading: isLoadingCoupon } = useMutation(couponSubscription, {
    onSuccess: () => {
      onSuccessMutation();
      setValidCode('valid');
      setTimeout(() => setValidCode('only-wf'), 3000);
    },
    onError: () => {
      onErrorMutation();
      setValidCode('invalid');
      setTimeout(() => setValidCode('only-wf'), 3000);
    }
  });

  const { data, refetch } = useQuery(orderKeys.paymentInfo, fetchUsersPaymentInfo);

  const { mutate: setDefault } = useMutation(setDefaultPaymentMethod);

  const { user } = useAuthStore();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

  const query = useRouterQuery();

  const onPay = () => {
    const planID = query.get('id') as string;
    const price = query.get('price') as string;
    if (inputValue.length > 0) {
      mutateCoupon({ coupon: inputValue, id: planID });
    } else {
      mutate({ id: planID, level: 1, pricing: price, startFreeTrial: false });
    }
  };

  const price = Number(query.get('price') || 0);

  useOutsideClick(ref, () => setOpenActions(''));

  const onSuccess = () => {
    setOpenActions('');
    refetch();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="container">
      <Header />
      <div className="purchaseSummaryWrapper">
        <div className="purchaseSummaryTitle">
          <h2>Purchase summary</h2>
        </div>
        <div className="purchaseSummarySubTitle">
          <p>Check if the information you entered is correct and pick a payment method to proceed.</p>
        </div>
        <div className="purchaseSummaryForm">
          <div className="purchasSummary">
            <div className="summaryRow">
              <p>DataGoat additional project</p>
              <p>
                <span className="unitPrice">{formatPrice(price)}</span> / month
              </p>
            </div>

            <div className="summaryRow">
              <p>Tax</p>
              <p>$0</p>
            </div>

            <div className="summaryRow">
              <p>Total</p>
              <p className="unitPrice">{formatPrice(price)}</p>
            </div>
          </div>
          <div className="promoCode">
            <div className="inputFieldWrapper">
              <div className="inputField">
                <label className="typo-label-l">Promo code</label>
                <div className={`inputWrapper ${isValidCode}`}>
                  <input name="promoCode" value={inputValue} onChange={handleInputChange} type="text" />
                  {isValidCode === 'valid' && <span>Applied</span>}
                  {isValidCode === 'valid' && <img src={CheckGoatIcon} />}
                  {isValidCode === 'invalid' && <span>Not Found</span>}
                  {isValidCode === 'invalid' && <img src={NotFoundIcon} />}
                </div>
              </div>
            </div>
          </div>
          <div className="paymentCards">
            <p className="title">Payment methods</p>
            <div className="cards" ref={ref}>
              {data?.paymentInfo?.map(({ card, id }) => (
                <div key={id} className="card">
                  <div className="cardIcon">{getCardIcon(card.brand)}</div>
                  <div className="cardNumber">
                    <FourDotsIcon />
                    {card.last4}
                  </div>
                  <p>
                    {user?.firstName} {user?.lastName}
                  </p>
                  {data.defaultPaymentMethod !== id ? (
                    <p onClick={() => setDefault(id, { onSuccess })} className="chooseBtn">
                      Choose
                    </p>
                  ) : (
                    <img src={CheckGoatIcon} />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={isActiveClass(isOpenForm, 'changePaymentTrigger')} onClick={() => setIsOpenForm((p) => !p)}>
            <p className="typo-body-s">Add payment method</p>
            <ChevronIcon />
          </div>
          <div className="paymentMethodForm">
            {isOpenForm && (
              <Elements stripe={stripePromise}>
                <AddPaymentMethodForm backgroundColor="#134c54" closeForm={() => setIsOpenForm(false)} />
              </Elements>
            )}
          </div>
        </div>
        <button className="primary" onClick={onPay} disabled={isLoading || isLoadingCoupon}>
          Pay now
        </button>
        {isOpen ? <ResubscribeSuccessModal close={() => setIsOpen(false)} className="resubscribe" /> : null}
        <div className="subtext">
          By selecting "Pay with Stripe" or "Pay with invoice", I understand that I am enrolling in a subscription plan,
          and I authorize DataGoat to charge my payment method each year. I understand that I can cancel anytime by
          going to my Billing page in the Account Settings.
        </div>
      </div>
    </div>
  );
};
