import * as React from 'react';
import type { SVGProps } from 'react';

const SvgOpenExternalLink = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      stroke="#052025"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.334 2.668H2.667v9.333A1.333 1.333 0 0 0 4 13.335h8A1.333 1.333 0 0 0 13.334 12V8.668M6 10.001l7.334-7.333m0 0H10m3.334 0v3.333"
    />
  </svg>
);
export default SvgOpenExternalLink;
