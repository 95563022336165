import { NavLink, useLocation } from 'react-router-dom';
import { isActiveClass } from '../../utils';

export const userAccountNavData = [
  {
    label: 'My Account',
    href: '/account-settings'
  },
  // {
  //   label: 'My Project',
  //   href: '/my-projects'
  // },
  {
    label: 'Subscription',
    href: '/subscription'
  }
];

export const AccountNavigation = () => {
  const { pathname } = useLocation();

  return (
    <nav className="accountNavigation">
      <ul>
        {userAccountNavData.map(({ href, label }) => (
          <li key={href} className={isActiveClass(href === pathname)}>
            <NavLink to={href}>{label}</NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};
