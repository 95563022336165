import { FormikProps } from 'formik';
import get from 'lodash/get';

interface Props {
  formik?: FormikProps<any>;
  name: string;
}

export const hasError = ({ formik, name }: Props) => formik && get(formik.touched, name) && get(formik.errors, name);

export const getFormikError = ({ formik, name }: Props): any => {
  if (!formik) return null;
  if (!hasError({ formik, name })) return null;

  return get(formik.errors, name);
};

export const getFormikValue = ({ formik, name }: Props): any => {
  if (!formik) return '';

  const value = get(formik.values, name);
  return value || '';
};
