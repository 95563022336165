import React, { FC, useEffect, useState } from 'react';
// @ts-ignore
import styles from './ContainerCard.module.scss';
import HubSpot from '../../../assets/icons/Hubspot';
import Mailchimp from '../../../assets/icons/Mailchimp';
import Calendly from '../../../assets/icons/Calendly';
import ConvertKit from '../../../assets/icons/Convertkit';
import Typeform from '../../../assets/icons/Typeform';
import Formstack from '../../../assets/icons/Formstack';
import Notion from '../../../assets/icons/Notion';
import Help from '../../../assets/icons/Help';
import { CalendlyM, ConvertkitM, HubspotMed, MailchimpM, TypeformM, Webflow } from '../../../assets/icons/index';
import { useAuthStore } from 'store/auth';
import Wrapper from 'components/ui/Wrapper/Wrapper';
import Heading from 'components/ui/Heading/Heading';
import Text from 'components/ui/Text/Text';
import Button from 'components/ui/Button/Button';
import { useNavigate } from 'react-router-dom';
import Box from 'components/ui/Box/Box';
import WebflowM from 'assets/icons/WebflowM';
import { ModalType, useModalStore } from 'store/modalStore';

interface ContainerCardProps {
  children?: React.ReactNode;
  title: string;
  extraText?: string;
  big?: boolean;
  supportedForms?: boolean;
  titleExtraText?: string | number;
}

const ContainerCard: FC<ContainerCardProps> = ({
  children,
  title,
  titleExtraText,
  extraText,
  big = true,
  supportedForms = true
}) => {
  const navigate = useNavigate();
  const [showSupportedForms, setShowSupportedForms] = useState(false);
  const handleClickOutside = (event: any) => {
    const focusOut = document.querySelector('[class*="supportedFormsFocusOut"]');
    if (event.target === focusOut) {
      setShowSupportedForms(false);
    }
  };
  const { user } = useAuthStore();
  const { setModal } = useModalStore();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSupportedForms]);

  return (
    <div className={big ? `${styles.ContainerCardBig} ${styles.Container}` : styles.ContainerCardSmall}>
      <div className={styles.Header}>
        <div className={styles.Title}>
          <p className={styles.TitleText}>{title}</p>
          <p className={styles.TotalText}>{titleExtraText}</p>
        </div>
        {extraText && (
          <Box flexDirection="column" alignItems="center" justifyContent="center">
            <p className={styles.ExtraText}>{extraText}</p>
            {supportedForms && big && (
              <div className={styles.Footer}>
                <button className={styles.footerButton} onClick={() => setShowSupportedForms(true)}>
                  <Help /> <p className={styles.FooterText}>Supported forms</p>
                </button>
                {showSupportedForms && <div className={styles.Footer__supportedFormsFocusOut}></div>}
                {showSupportedForms && (
                  <div className={styles.supportedForms}>
                    <h4>Supported forms</h4>
                    {user?.tier.limitation.showEmbeddedForms && (
                      <>
                        <div className={styles.supportedForms__section}>
                          <HubSpot />
                          <p>HubSpot</p>
                        </div>
                        <div className={styles.supportedForms__section}>
                          <Mailchimp />
                          <p>Mailchimp</p>
                        </div>
                        {/*{' '}
                        <div className={styles.supportedForms__section}>
                          <Pardot />
                          <p>Pardot</p>
                        </div>{' '}
                    */}
                        <div className={styles.supportedForms__section}>
                          <Calendly />
                          <p>Calendly</p>
                        </div>
                        {/*{' '}
                        <div className={styles.supportedForms__section}>
                          <Marketo />
                          <p>Marketo</p>
                        </div>{' '}
                    */}
                        <div className={styles.supportedForms__section}>
                          <ConvertKit />
                          <p>Convert kit</p>
                        </div>
                        {/*{' '}
                        <div className={styles.supportedForms__section}>
                          <Salesforce />
                          <p>Salesforce</p>
                        </div>{' '}
                    */}
                        <div className={styles.supportedForms__section}>
                          <Typeform />
                          <p>Typeform</p>
                        </div>
                        {/*{' '}
                        <div className={styles.supportedForms__section}>
                          <Zendesk />
                          <p>Zendesk</p>
                        </div>
                        <div className={styles.supportedForms__section}>
                          <Zoho />
                          <p>Zoho</p>
                        </div>{' '}
                    */}
                        <div className={styles.supportedForms__section}>
                          <Formstack />
                          <p>Formstack</p>
                        </div>
                        <div className={styles.supportedForms__section}>
                          <Notion />
                          <p>Notion</p>
                        </div>
                      </>
                    )}
                    <div className={styles.supportedForms__section}>
                      <Webflow />
                      <p>Webflow</p>
                    </div>
                    <h4>
                      Can’t find your form?
                      <span
                        onClick={() => {
                          window.open('https://www.datagoat.co/customer-support', '_blank');
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {' '}
                        Contact us.
                      </span>
                    </h4>
                  </div>
                )}
              </div>
            )}
          </Box>
        )}
      </div>
      {children}

      {supportedForms && big && !user?.tier.limitation.showEmbeddedForms && user?.addedNewScript && (
        <Wrapper
          className={styles.upgradeWrapper}
          gap={8}
          padding="16px"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Box flexDirection="row" gap={8}>
            <Heading size="xs">Upgrade to see all forms</Heading>{' '}
            <Box flexDirection="row" gap={-8}>
              <WebflowM className={styles.marginRight} />
              <CalendlyM className={styles.marginRight} />
              <MailchimpM className={styles.marginRight} />
              <ConvertkitM className={styles.marginRight} />
              <TypeformM className={styles.marginRight} />
              <HubspotMed className={styles.marginRight} />
            </Box>
          </Box>
          <Text size="xs" sx={{ textAlign: 'center' }}>
            Our free plan exclusively supports tracking for Webflow forms.To access tracking <br /> for all forms on
            your site, upgrade your plan.
          </Text>
          <Button
            padding="small"
            handleClick={() => {
              user?.trialUsed ? navigate('subscription') : setModal(ModalType.freeTrial);
            }}
          >
            {user?.trialUsed ? 'Upgrade' : 'Try it for free'}
          </Button>
        </Wrapper>
      )}
    </div>
  );
};

export default ContainerCard;
