import React, { CSSProperties, FC, MutableRefObject, ReactNode, useRef } from 'react';
import styles from './Container.module.scss';

interface ContainerProps {
  className?: string;
  padding?: string;
  borderRadius?: string;
  children?: ReactNode;
  onClick?: () => void;
  sx?: CSSProperties;
  gap?: number;
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'normal' | (string & {});
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'normal' | (string & {});
  flexDirection?: 'column' | 'row';
}

const Container: FC<ContainerProps> = ({
  className = 'none',
  padding = '0px',
  borderRadius = '0px',
  children,
  onClick,
  sx,
  gap = 0,
  flexDirection = 'row',
  justifyContent = 'normal',
  alignItems = 'normal'
}) => {
  return (
    <div
      className={`${styles.Container} ${className}`}
      style={{
        padding: padding,
        borderRadius: borderRadius,
        gap: gap + 'px',
        flexDirection: flexDirection,
        justifyContent: justifyContent,
        alignItems: alignItems,
        ...sx
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
export default Container;
