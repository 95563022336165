import { apiCall } from 'api';
import { toast } from 'react-toastify';
import { ManualAuthorizePayload, Site, WfConnectPayload } from './types';

export const wfConnect = async (payload: WfConnectPayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `webflow/connect`,
      method: 'POST',
      data: payload
    });
    toast.success('Webflow connected successfully');
    return data;
  } catch (error: any) {
    throw error;
  }
};

export const wfGetInstallUrl = async () => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `webflow/getWebflowInstallUrl`, method: 'GET' });

    return data?.url || '';
  } catch (error: any) {
    if (error?.status !== 405) {
      toast.error(error.data.message);
      throw error;
    }
  }
};

export const getSites = async (): Promise<Site[]> => {
  const request = await apiCall();

  try {
    const { data } = await request({
      url: `webflow/sites`,
      method: 'GET'
    });

    return data.sites;
  } catch (error: any) {
    throw error;
  }
};

export const authoriseWebflow = async (clientRequestUris: any): Promise<string> => {
  const request = await apiCall();

  try {
    const { data } = await request({
      url: `/webflow/authorize`,
      method: 'POST',
      data: { clientRequestUris }
    });

    toast.success('GA4 connected successfully!');
    return data?.propertyId;
  } catch (error: any) {
    toast.error(error.data.message);
    throw error;
  }
};

export const removeAuthorizedWebsite = async (clientRequestUri: string) => {
  const request = await apiCall();
  try {
    await request({
      url: `/webflow/authorize`,
      method: 'DELETE',
      data: { clientRequestUri }
    });
    toast.success('Project removed successfully!');
  } catch (error: any) {
    toast.error(error.data.message);
    throw error;
  }
};

export const manualAuthorize = async (payload: ManualAuthorizePayload) => {
  const request = await apiCall();
  try {
    await request({
      url: `/webflow/manualAuthorize`,
      method: 'POST',
      data: payload
    });
    toast.success('GA connected successfully!');
  } catch (error: any) {
    toast.error(error.data.message);
    throw error;
  }
};

export const registerWebflowCustomScript = async (payload: { propertyId: string; url: string }) => {
  const request = await apiCall();
  try {
    const message = await request({
      url: `/webflow/registerCustomScript`,
      method: 'POST',
      data: payload
    });
    return message;
  } catch (error: any) {
    throw error;
  }
};

export const deleteWebflowCustomScript = async (payload: { propertyId: string; siteId: string }) => {
  const request = await apiCall();
  try {
    const message = await request({
      url: `/webflow/deleteCustomScript`,
      method: 'POST',
      data: payload
    });
    return message;
  } catch (error: any) {
    console.log(error);
  }
};
