import React, { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store/auth';
import { ModalType, useModalStore } from 'store/modalStore';

// Step 1: Create a context
export const UpgradeClickContext = createContext<any>({});

// Step 2: Create a provider component
export const UpgradeClickProvider = ({ children }: { children: React.ReactNode }) => {
  const { setModal } = useModalStore();
  const { user } = useAuthStore();
  const navigate = useNavigate();
  const handleUpgradeClick = () => {
    if (!user?.trialUsed) setModal(ModalType.freeTrial);
    else navigate('subscription');
  };

  return <UpgradeClickContext.Provider value={{ handleUpgradeClick }}>{children}</UpgradeClickContext.Provider>;
};
