import React, { FC, useState } from 'react';
import styles from './Toggle.module.scss';
import Wrapper from '../Wrapper/Wrapper';
import Label from '../Label/Label';

interface ToggleProps {
  toggleSelect?: [{ label: string; value: any }, { label: string; value: any }];
  handleToggleClick: (value: any) => void;
}

const Toggle: FC<ToggleProps> = ({ toggleSelect, handleToggleClick }) => {
  const [active, setActive] = useState(0);
  return (
    <Wrapper borderRadius="50px" padding={'2px'} className={styles.toggle}>
      {/* <Wrapper className={styles.activeLeft} padding="4px 37px"></Wrapper> */}
      <Wrapper
        padding="8px 23px"
        className={active === 0 ? styles.active : ''}
        onClick={() => {
          setActive(0);
          handleToggleClick(toggleSelect?.[0]);
        }}
      >
        <Label size="xs">{toggleSelect?.[0].label}</Label>
      </Wrapper>
      <Wrapper
        padding="8px 16px"
        className={active == 1 ? styles.active : ''}
        onClick={() => {
          setActive(1);
          handleToggleClick(toggleSelect?.[1]);
        }}
      >
        <Label size="xs">{toggleSelect?.[1].label}</Label>
      </Wrapper>
    </Wrapper>
  );
};

export default Toggle;
