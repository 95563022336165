import React, { FC } from 'react';
import styles from './ManualEndWithoutUpgrade.module.scss';
import Modal from 'components/ui/Modal/Modal';
import Box from 'components/ui/Box/Box';
import { ModalType, useModalStore } from 'store/modalStore';
import CloseSVG from 'assets/icons/CloseSVG';
import Heading from 'components/ui/Heading/Heading';
import Text from 'components/ui/Text/Text';
import Button from 'components/ui/Button/Button';
import { endFreeTrial, updateSubscription } from 'api/orders';

interface ManualEndWithoutUpgradeProps {}

const ManualEndWithoutUpgrade: FC<ManualEndWithoutUpgradeProps> = () => {
  const { setModal, modalData } = useModalStore();
  return (
    <Modal
      sx={{ height: 'auto', width: '30em', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
    >
      <Box justifyContent="center" alignItems="center" gap={32}>
        <CloseSVG
          className={styles.closeIcon}
          onClick={() => {
            setModal(ModalType.none);
          }}
        />
        <Box gap={16} alignItems="center">
          <Heading size="m">Are you sure you want to end your free trial?</Heading>
        </Box>
        <Button
          handleClick={async () => {
            try {
              await endFreeTrial(modalData.priceId);
              window.location.reload();
              setModal(ModalType.none);
            } catch (e: any) {
              console.log(e);
            }
          }}
        >
          End free trial
        </Button>
      </Box>
    </Modal>
  );
};

export default ManualEndWithoutUpgrade;
