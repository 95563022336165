import { Routes, Route } from 'react-router-dom';

import { ExtensionLoginSuccess } from 'pages/ExtensionLoginSuccess';
import { AccountSettingsPage } from 'pages/AccountSettings';
import { PurchaseSummary } from 'pages/PurchasSummary';
import { ReactivatePlan } from 'pages/ReactivatePlan';
import { SubscriptionPage } from 'pages/Subscription';
import { ResetPassword } from './pages/ResetPassword';
import { ForgotPasswod } from './pages/ForgotPasswod';
import { MyProjects } from './pages/MyProjects';
import { PreviewLink } from 'pages/PreviewLink';
import { SignInPage } from 'pages/SignIn';
import { SignUpPage } from 'pages/SignUp';
import { WfAuthPage } from 'pages/WfAuth';
import { HomePage } from 'pages/Home';
import NotFoundPage from 'pages/404';
import UserAndProjectAuthorizationPage from 'pages/UserAndProjectAuthorization/UserAndProjectAuthorizationPage';
import Test from 'components/Test/Test';
import MainContent from 'components/MainContent/MainContent';
import Insights from 'components/layout/Insights/Insights';
import ConnectGoogleAnalytics from 'pages/AddProject/ConnectGoogleAnalytics/ConnectGoogleAnalytics';

export const AppRouter = () => (
  <Routes>
    <Route path="/" element={<HomePage />}>
      <Route path="/" element={<MainContent />} />
      <Route path="/insights" element={<Insights />} />
      {/* <Route path="/my-projects" element={<MyProjects />} /> */}
      <Route path="/account-settings" element={<AccountSettingsPage />} />
      <Route path="/subscription" element={<SubscriptionPage />} />
    </Route>
    <Route path="/wf-auth" element={<WfAuthPage />} />
    <Route path="/sign-in" element={<SignInPage />} />
    <Route path="/sign-up" element={<SignUpPage />} />
    <Route path="/forgot-password" element={<ForgotPasswod />} />
    <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
    <Route path="/add-project" element={<ConnectGoogleAnalytics />} />
    <Route path="/reactivate-plan" element={<ReactivatePlan />} />
    <Route path="/purchase-summary" element={<PurchaseSummary />} />
    <Route path="/report/:id" element={<PreviewLink />} />
    <Route path="/extension-login" element={<ExtensionLoginSuccess />} />
    <Route path="/user-project-authorization" element={<UserAndProjectAuthorizationPage />} />
    <Route path="/test" element={<Test />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);
