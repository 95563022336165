import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStorageItem } from 'utils/storage';

export const usePrivateRoute = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!getStorageItem('token')) navigate('/sign-in');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
