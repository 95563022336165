import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './FormSubmissions.module.scss';
import ContainerCard from 'features/FormSubmissions/SubmissionsCard/ContainerCard';
import Tabs from './SubmissionsTabs/Tabs';
import Table from 'features/FormSubmissions/SubmissionsTable/Table';
import TableLocation from 'features/FormSubmissions/SubmissionsLocationTable/Table';
import CustomLineChart from 'features/FormSubmissions/SubmissionsLineChart/CustomLineChart';
import LineChartLegend from 'features/FormSubmissions/SubmissionsLineChart/LineChartLegend/LineChartLegend';
import { useAllFormData } from './hooks/useAllFormData';
import DataLoadingSpinner from 'components/ui/DataLoadingSpinner/DataLoadingSpinner';
import { Website } from 'api/users/types';
import { useAnalyticsStore } from 'store/AnalyticsStore';
import SubmissionsEmptyState from './SubmissionsEmptyState/SubmissionsEmptyState';
import hubspot from '../../assets/icons/HubspotMed';
import webflowIcon from '../../assets/icons/WebflowM';
import formstack from '../../assets/icons/FormstackM';

import calendly from '../../assets/icons/CalendlyM';
import typeform from '../../assets/icons/TypeformM';
import noteform from '../../assets/icons/NoteformM';
import convertkit from '../../assets/icons/ConvertkitM';
import marketo from '../../assets/icons/MarketoM';
import mailchimp from '../../assets/icons/MailchimpM';
import { compareStatistics } from './utils/compareStatistics';
import { formLocation } from './utils/formLocation';
import ArrowSVG from 'assets/icons/ArrowSVG';
import SvgLoadArrow from './../../assets/icons/LoadArrow';
import ModalUsPopUp from 'components/modal/ModalGroupScript/ModalUsPopUp/ModalUsPopUp';
import SubmissionsScriptNotDetectedState from './SubmissionsScriptNotDetectedState/SubmissionsScriptNotDetectedState';
import { useAuthStore } from 'store/auth';
import List from 'components/ui/List/List';
import SubmissionsTable from 'components/ui/SubmissionsTable/Table';
import Box from 'components/ui/Box/Box';
import FreeAccountEmpty from './FreeAccountEmpty/FreeAccountEmpty';
import { InsightsIconPriimary500 } from 'assets/icons/export';
import Button from 'components/ui/Button/Button';
import { useNavigate } from 'react-router-dom';

interface FormSubmissions {
  website: any;
  startDate: string;
  endDate: string;
  shortName: string;
}

const formSubmissionHeaders = ['Link', 'Top-performing page', 'Impressions', 'Conversions', 'Conversion Rate'];

const sourcesReferralHeaders = ['Link', 'Users', 'Conversions', 'Conversion Rate'];

const formDestionationsHeaders = ['Page', 'Users', 'Conversions', 'Conversion Rate'];

const FormSubmissions: FC<FormSubmissions> = ({ website, startDate, endDate, shortName }) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [activeFormIndex, setActiveFormIndex] = useState<number>(0);
  const [activeElements, setActiveElements] = useState<number[]>([1, 2, 3]);
  const [emptyState, setEmptyState] = useState(false);
  const totalConversionsRef = useRef<number>(0);
  const [loading, setLoading] = useState(true);
  const [allFormsLoaded, setAllFormsLoaded] = useState(false);
  const loadingNumber = useRef(true);
  const setLoadingNumber = 0;
  const [loadingSwitch, setLoadingSwitch] = useState([false, false]);
  const [slicingLength, setSlicingLength] = useState(3);
  const { user } = useAuthStore();
  const firstUpdate = useRef([true, true]);
  const { cancelToken } = useAnalyticsStore();
  const submissionsWidthRef = useRef<any>();

  const changeIndex = (index: number) => {
    setActiveTabIndex(index);
    let form = formName.find((form) => {
      return form.tabIndex === index - 1;
    });
    let formIndex = 0;
    if (form) formIndex = formName.indexOf(form);

    if (form) setActiveFormIndex(formIndex);
    setEmptyState(false);

    if (index !== 0) setLoading(true);

    if (index === 0) {
      setLoadingSwitch([!loadingSwitch[0], loadingSwitch[1]]);
    } else {
      setLoadingSwitch([loadingSwitch[0], !loadingSwitch[1]]);
    }
  };
  const formatMonthDay = (dateString: string): string => {
    const [year, month, day] = dateString.split('-');
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
    if (activeTabIndex !== index) {
      changeIndex(index);

      cancelToken.charts.abort();
      cancelToken.pages.abort();
      cancelToken.sources.abort();
      cancelToken.singleForm.abort();
      //}
      if (index !== 0) cancelToken.allForms.abort();
      else {
      }
    }
  };
  const handleElementClick = (clickedIndex: number) => {
    const isActive = activeElements.includes(clickedIndex);

    if (isActive) {
      if (activeElements.length > 1) {
        setActiveElements(activeElements.filter((el) => el !== clickedIndex));
      }
    } else {
      setActiveElements([...activeElements, clickedIndex]);
    }
  };

  const handleLoadMore = () => {
    setLoadMore(false);
    setSlicingLength(location.length);
  };

  const {
    tabHeader,
    setTabHeader,
    formName,
    wholeFormName,
    topSources,
    topPages,
    chartData,
    internalData,
    setFormNames,
    statistics,
    location,
    loadMore,
    setLoadMore
  } = useAllFormData(
    website,
    startDate,
    endDate,
    totalConversionsRef,
    activeTabIndex,
    setLoading,
    loadingNumber,
    setLoadingNumber,
    changeIndex,
    allFormsLoaded,
    setAllFormsLoaded,
    loadingSwitch,
    setEmptyState
  );

  useEffect(() => {
    if (firstUpdate.current[0]) {
      firstUpdate.current[0] = false;
      return;
    }
    totalConversionsRef.current = 0;
    setFormNames([]);
    setEmptyState(false);
    setActiveTabIndex(0);
    setTabHeader([]);
    setAllFormsLoaded(false);
    setLoading(true);
    setLoadingSwitch([!loadingSwitch[0], loadingSwitch[1]]);
    setSlicingLength(3);
    setLoadMore(true);
  }, [startDate, website]);

  const prefixMap: Record<string, any> = {
    wf: webflowIcon,
    mc: mailchimp,
    cl: calendly,
    ck: convertkit,
    tf: typeform,
    hs: hubspot,
    nf: noteform,
    fs: formstack
  };
  const getIconPaths = (fullNames: string[]): any[] => {
    if (fullNames) {
      return fullNames.map((form) => {
        for (let key in prefixMap) {
          if (form.slice(10, 12) === key) return prefixMap[key];
        }
        return webflowIcon;
      });
    } else {
      return [];
    }
  };
  const imgPaths = getIconPaths(wholeFormName as string[]);
  const ImgTabTitle = imgPaths[activeTabIndex - 1];
  const navigate = useNavigate();
  return (
    <div className={styles.FormSubmissions} ref={submissionsWidthRef}>
      <ContainerCard
        title="Form Submissions"
        extraText={`${formatMonthDay(startDate)} - ${formatMonthDay(endDate)}`}
        supportedForms={true}
        titleExtraText={
          activeTabIndex > 0 && formName[activeFormIndex]
            ? formName[activeFormIndex].totalConversions + ` Total`
            : `${totalConversionsRef.current} Total `
        }
      >
        {formName.length > 1 && (
          <Tabs
            label={tabHeader}
            activeTabIndex={activeTabIndex}
            handleTabClickProp={handleTabClick}
            width={submissionsWidthRef.current.offsetWidth}
          />
        )}
        {loading ? (
          <div className={styles.LoadingSpinner}>
            <DataLoadingSpinner />
          </div>
        ) : emptyState ? (
          !user?.addedNewScript ? (
            <SubmissionsScriptNotDetectedState />
          ) : !user.tier.limitation.showEmbeddedForms ? (
            <FreeAccountEmpty />
          ) : (
            <SubmissionsEmptyState />
          )
        ) : activeTabIndex === 0 ? (
          <>
            <Box>
              <Table
                headers={formSubmissionHeaders}
                data={formName}
                setFormNames={setFormNames}
                handleRowClick={changeIndex}
                dateRange={`${formatMonthDay(startDate)} - ${formatMonthDay(endDate)}`}
                fullName={wholeFormName}
                clickable={true}
                sortable={true}
                statistics={statistics}
              />
              {!user?.tier.limitation.showEmbeddedForms && (
                <Table
                  headers={[]}
                  data={blurObj}
                  setFormNames={setFormNames}
                  handleRowClick={changeIndex}
                  dateRange={`${formatMonthDay(startDate)} - ${formatMonthDay(endDate)}`}
                  fullName={wholeFormName}
                  clickable={true}
                  sortable={false}
                  statistics={statistics}
                  blur={true}
                />
              )}
            </Box>

            {!user?.tier.limitation.showEmbeddedForms && (
              <Box>
                <div style={{ margin: '0 auto', fontWeight: '800' }}>Upgrade to see all forms</div>
                <div style={{ margin: '0 auto', fontSize: '12px' }}>
                  Our free plan exclusively supports tracking for Webflow forms. To access tracking for all forms on
                  your site, upgrade your plan.
                </div>
                <div style={{ display: 'block', margin: '0 auto', width: 'fit-content', marginTop: '10px' }}>
                  <Button
                    Icon={() => {
                      return <InsightsIconPriimary500 />;
                    }}
                    handleClick={() => {
                      navigate('subscription');
                    }}
                  >
                    {user?.trialUsed ? 'Upgrade' : 'Try it for free'}
                  </Button>
                </div>
              </Box>
            )}
          </>
        ) : (
          <div className={styles.secondTabForms}>
            <span className={styles.secondTabForms__titleImage}>
              <ImgTabTitle />
              <h2>{tabHeader[activeTabIndex - 1]}</h2>
            </span>
            <div className={styles.otherForms}>
              <div className={styles.formConversions}>
                <LineChartLegend
                  id={1}
                  metricName="Total Impressions"
                  metricValue={formName[activeFormIndex]?.totalImpressions?.toString()}
                  activeElements={activeElements}
                  handleElementClick={handleElementClick}
                />
                <LineChartLegend
                  id={2}
                  metricName="Total conversions"
                  metricValue={formName[activeFormIndex]?.totalConversions}
                  activeElements={activeElements}
                  handleElementClick={handleElementClick}
                />
                <LineChartLegend
                  id={3}
                  metricName="Total conversion rate"
                  metricValue={formName[activeFormIndex]?.conversionRate?.toString()}
                  activeElements={activeElements}
                  handleElementClick={handleElementClick}
                />
              </div>
              <CustomLineChart activeLine={activeElements} chartData={chartData} />
            </div>

            <ContainerCard title="Form Destinations" supportedForms={true} big={false}>
              {/* <TableLocation
                headers={formDestionationsHeaders}
                data={location}
                handleRowClick={() => {}}
                dateRange={`${formatMonthDay(startDate)} - ${formatMonthDay(endDate)}`}
                cssClass={false}
                clickable={false}
                sortable={true}
                loadMore={loadMore}
                slicingLength={slicingLength}
                //statistics={statistics}
              /> */}
              <TableLocation
                headers={sourcesReferralHeaders}
                data={topPages}
                handleRowClick={() => {}}
                dateRange={`${formatMonthDay(startDate)} - ${formatMonthDay(endDate)}`}
                cssClass={false}
                clickable={false}
                sortable={true}
                loadMore={loadMore}
                slicingLength={slicingLength}
                //statistics={statistics}
              />

              <div className={styles.loadButton} onClick={handleLoadMore} style={!loadMore ? { display: 'none' } : {}}>
                Load more <SvgLoadArrow />
              </div>
            </ContainerCard>

            <div className={styles.tableDisplay}>
              <ContainerCard title="Top Sources" supportedForms={true} big={false}>
                <Table
                  headers={sourcesReferralHeaders}
                  data={topSources}
                  handleRowClick={() => {}}
                  dateRange={`${formatMonthDay(startDate)} - ${formatMonthDay(endDate)}`}
                  cssClass={true}
                  clickable={false}
                  sortable={false}
                />
              </ContainerCard>
              <ContainerCard title="Top Internal Referral" supportedForms={true} big={false}>
                <Table
                  headers={sourcesReferralHeaders}
                  data={internalData}
                  handleRowClick={() => {}}
                  dateRange={`${formatMonthDay(startDate)} - ${formatMonthDay(endDate)}`}
                  cssClass={true}
                  clickable={false}
                  sortable={false}
                />
              </ContainerCard>
            </div>
          </div>
        )}
      </ContainerCard>
    </div>
  );
};

export default FormSubmissions;

const blurObj = [
  {
    icon: 'tf',
    formName: 'Typeform',
    topPerformingPage: '/datagoat-testing-page-1221',
    totalImpressions: 31,
    totalConversions: 1,
    conversionRate: '3%',
    tabIndex: 27
  },
  {
    icon: 'ck',
    formName: 'Convertkit',
    topPerformingPage: '/datagoat-testing-page-1221',
    totalImpressions: 21,
    totalConversions: 1,
    conversionRate: '5%',
    tabIndex: 22
  }
];
