import { useState } from 'react';
import { Checkbox } from '../../../ui/Checkbox';
import { useMutation } from 'react-query';
import { unsubsscribe } from 'api/orders';
import { queryClient } from '../../../..';
import { orderKeys } from 'api/orders/keys';

interface Props {
  id: string;
  close: () => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

export const SecondStep = ({ setStep, id, close }: Props) => {
  const [reason, setReason] = useState('');

  const { mutate, isLoading } = useMutation(unsubsscribe);

  const onCancle = () => {
    mutate(
      { id, reason },
      {
        onSuccess: () => {
          queryClient.refetchQueries(orderKeys.subscription);
          setStep(3);
        }
      }
    );
  };

  return (
    <div className="secondStep">
      <p className="text">Why are you canceling?</p>
      <p className="subtext">You will loose some amazing, wonderful features!</p>
      <div className="checkboxes">
        {checkboxes.map((item) => (
          <div className="box">
            <Checkbox isChecked={reason === item} onChange={() => setReason(item)} />
            <p>{item}</p>
          </div>
        ))}
      </div>
      <button type="button" className="delete" onClick={onCancle} disabled={isLoading || !reason}>
        Submit and cancel!
      </button>
      <button type="button" className="outlined" onClick={close}>
        I changed my mind!
      </button>
    </div>
  );
};

const checkboxes = [
  `It's too expensive`,
  `I don't use it anymore`,
  `I’m switching to another product`,
  `It’s not helpful`
];
