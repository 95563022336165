import React, { FC } from 'react';
import styles from './SetupGuide.module.scss';
import Heading from 'components/ui/Heading/Heading';
import Box from 'components/ui/Box/Box';
import ProgressBar from 'components/ui/ProgressBar/ProgressBar';
import StepWrapper from './StepWrapper/StepWrapper';
import Wrapper from 'components/ui/Wrapper/Wrapper';
import HorizontalLine from 'components/ui/HorizontalLine/HorizontalLine';
import Text from 'components/ui/Text/Text';
import CircleWrapper from 'components/ui/CircleWrapper/CircleWrapper';
import { CheckboxChecked, PlayIcon } from 'assets/icons';
import DropdownList from 'components/ui/DropdownList/DropdownList';
import Container from 'components/ui/Container/Container';
import Label from 'components/ui/Label/Label';
import Button from 'components/ui/Button/Button';
import LinkText from 'components/ui/LinkText/LinkText';

interface SetupGuideProps {
  step: any;
}

const SetupGuide: FC<SetupGuideProps> = ({ step }) => {
  return (
    <Wrapper sx={{ width: '30em' }} padding="0px 24px" flexDirection="column">
      <Box gap={16}>
        <Box gap={24} className={styles.body}>
          <Wrapper padding="0 24px" sx={{ width: '100%' }} flexDirection="column">
            <Box gap={24} className={styles.heading}>
              <Heading>Set up guide</Heading>
              <ProgressBar progressWidth={step.progressBar} text={`${step.number - 1}/5 steps completed`} />
            </Box>
          </Wrapper>
          <Box className={styles.steps}>
            <StepWrapper active={step.number === 1}>
              {step.number > 1 ? (
                <CheckboxChecked />
              ) : (
                <CircleWrapper color={step.number === 1 ? 'primary-300' : 'neutral-100'} />
              )}{' '}
              <Text size="l" color="white">
                Email Verification
              </Text>
            </StepWrapper>
            <StepWrapper active={step.number === 2}>
              {step.number > 2 ? (
                <CheckboxChecked />
              ) : (
                <CircleWrapper color={step.number === 2 ? 'primary-300' : 'neutral-100'} />
              )}{' '}
              <Text size="l" color="white">
                Google Authorization
              </Text>
            </StepWrapper>
            <StepWrapper active={step.number === 3}>
              {step.number > 3 ? (
                <CheckboxChecked />
              ) : (
                <CircleWrapper color={step.number === 3 ? 'primary-300' : 'neutral-100'} />
              )}{' '}
              <Text size="l" color="white">
                Webflow Authorization
              </Text>
            </StepWrapper>
            <StepWrapper active={step.number === 4}>
              {step.number > 4 ? (
                <CheckboxChecked />
              ) : (
                <CircleWrapper color={step.number === 4 ? 'primary-300' : 'neutral-100'} />
              )}{' '}
              <Text size="l" color="white">
                Connect GA4
              </Text>
            </StepWrapper>
            <StepWrapper active={step.number === 5}>
              {step.number > 5 ? <CheckboxChecked /> : <CircleWrapper color="neutral-300" />}{' '}
              <Text size="l" color="neutral-300">
                Custom script implementation
              </Text>
            </StepWrapper>
          </Box>
        </Box>
        <HorizontalLine />
        <Container padding="24px" className={styles.tutorial}>
          <DropdownList title="Have issues? Tutorials below.">
            <Container gap={16} flexDirection="column">
              <Box></Box>
              <Box>
                <Wrapper padding="8px 16px" alignItems="center" gap={8} sx={{ cursor: 'pointer' }}>
                  <Wrapper className={styles.playIconWrapper}>
                    <PlayIcon />
                  </Wrapper>
                  <LinkText href="https://youtu.be/W3XHu7iYVOE">Installation video</LinkText>
                </Wrapper>
                <Wrapper padding="8px 16px" alignItems="center" gap={8} sx={{ cursor: 'pointer' }}>
                  <Wrapper className={styles.playIconWrapper}>
                    <PlayIcon />
                  </Wrapper>
                  <LinkText href="https://www.loom.com/share/d659e7ac142a48b9aee492211c39b95c"> GA4 Setup</LinkText>
                </Wrapper>
                <Wrapper padding="8px 16px" alignItems="center" gap={8} sx={{ cursor: 'pointer' }}>
                  <Wrapper className={styles.playIconWrapper}>
                    <PlayIcon />
                  </Wrapper>
                  <LinkText href="https://www.loom.com/share/28f346ea739d4614903f192ef1543796">
                    {' '}
                    Webflow Publishing
                  </LinkText>
                </Wrapper>
              </Box>
              <Box gap={16} flexDirection="row" alignItems="center">
                <HorizontalLine />
                <Label size="m">or</Label>
                <HorizontalLine />
              </Box>
              <Button
                className={styles.button}
                type="outlinedPrimary"
                color="outline"
                handleClick={() => {
                  window.open('https://www.datagoat.co/customer-support', '_blank');
                }}
              >
                Contact Support
              </Button>
            </Container>
          </DropdownList>
        </Container>
      </Box>
    </Wrapper>
  );
};

export default SetupGuide;
