import * as React from 'react';
import type { SVGProps } from 'react';

const SvgSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={55} height={56} fill="none" {...props}>
    <path fill="#DBF0A3" d="M39 28.5c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12" />
    <g clipPath="url(#Success_svg__a)">
      <path
        fill="#052025"
        fillRule="evenodd"
        d="M32.576 24.487a.875.875 0 0 1 0 1.237l-6.558 6.559a.934.934 0 0 1-1.32 0l-3.259-3.258a.876.876 0 1 1 1.237-1.238l2.681 2.681 5.981-5.981a.874.874 0 0 1 1.238 0"
        clipRule="evenodd"
      />
    </g>
    <circle cx={48.978} cy={24.656} r={1.866} stroke="#C0E666" strokeWidth={0.268} />
    <circle cx={28} cy={8} r={2} fill="#C0E666" />
    <circle cx={16} cy={12} r={1.866} stroke="#C0E666" strokeWidth={0.268} />
    <circle cx={5} cy={30.824} r={2} fill="#C0E666" />
    <circle cx={18.945} cy={48.527} r={1.866} stroke="#C0E666" strokeWidth={0.268} />
    <circle cx={40.932} cy={45.305} r={2} fill="#C0E666" />
    <circle cx={43.15} cy={19.633} r={1} fill="#DDE9C5" />
    <circle cx={32} cy={13} r={1} fill="#DDE9C5" />
    <circle cx={11.5} cy={18.5} r={1.366} stroke="#DDE9C5" strokeWidth={0.268} />
    <circle cx={10.969} cy={36} r={1} fill="#DDE9C5" />
    <circle cx={26.258} cy={46.18} r={1} fill="#DDE9C5" />
    <circle cx={42.615} cy={37.875} r={0.866} stroke="#DDE9C5" strokeWidth={0.268} />
    <defs>
      <clipPath id="Success_svg__a">
        <path fill="#fff" d="M20.004 21.508h14v14h-14z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSuccess;
