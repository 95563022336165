import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { InputField } from 'components/ui/forms/fields/InputField';
import { signUpYup } from 'utils/yup';
import { useMutation } from 'react-query';
import { registerUser, resendCode, signInWithGoogle, signUpValidator } from 'api/auth';
import { useAuthStore } from 'store/auth';
import { useState } from 'react';
import { GoogleLogoIcon } from 'assets/icons/export';
import axios from 'axios';
import Box from 'components/ui/Box/Box';
import Text from 'components/ui/Text/Text';

export const SignUpForm = () => {
  const { mutate, isLoading } = useMutation(signUpValidator);
  const { mutate: registerMutate } = useMutation(registerUser);
  const { mutate: signInWithGoogleMutate, isLoading: isLoadingGoogle } = useMutation(signInWithGoogle, {
    onSuccess: (data) => {
      window.location.href = data.authUrl;
    }
  });
  const navigate = useNavigate();
  const { setIsLogged } = useAuthStore();

  const signInGoogle = () => {
    signInWithGoogleMutate();
  };
 
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      isAgree: false
    },
    validationSchema: signUpYup,
    onSubmit: async (values, { setErrors }) => {
      mutate(
        {
          email: values.email,
          password: values.password
        },
        {
          onSuccess: async () => {
            try {
              // Submit form data to HubSpot Forms API
              await axios.post(
                'https://api.hsforms.com/submissions/v3/integration/submit/143283763/1e79af55-8d5b-43ac-b75b-b75d74babf47',
                {
                  fields: [
                    {
                      objectTypeId: '0-1',
                      name: 'email',
                      value: values.email
                    },
                    {
                      objectTypeId: '0-1',
                      name: 'firstname',
                      value: values.firstName
                    },
                    {
                      objectTypeId: '0-1',
                      name: 'lastname',
                      value: values.lastName
                    }
                  ]
                }
              );

              // Optionally, you can handle success and error responses from HubSpot API
            } catch (error) {
              console.error('Error submitting form:', error);
            }
            registerMutate(
              {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.password
              },
              {
                onSuccess: () => {
                  setIsLogged(true);
                  navigate('/');
                }
              }
            );
          },
          onError: (error: any) => {
            setErrors({ email: error.data.message });
          }
        }
      );
    }
  });

  return (
    <div className="signUpForm">
      <div className="signUpWrapper">
        <h1 className="typo-heading-xl">Create your account</h1>
        <p className="subtitle">
          Already have an account ? <Link to="/sign-in">Log in</Link>
        </p>

        <div className="signUpGoogle">
          <Box gap={8} sx={{ width: '100%' }}>
            <button type="button" disabled={isLoadingGoogle} onClick={signInGoogle}>
              <div className="btnContent">
                <GoogleLogoIcon />
                <p>Sign in with Google</p>
              </div>
            </button>
            <Text size="xs" color="primary-100" sx={{ textAlign: 'center' }}>
              Use the Google account that's connected to the GA4 property
              <br /> you wish to track via Data Goat.
            </Text>
          </Box>
          <div className="signUpOr">
            <div className="line" />
            <p className="typo-body-s">or</p>
            <div className="line" />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="grid-col-2 gap-4">
            <InputField name="firstName" label="First Name*" formik={formik} />
            <InputField name="lastName" label="Last Name*" formik={formik} />
          </div>
          <InputField name="email" label="Email Adress*" type="email" formik={formik} />
          <InputField name="password" label="Password*" type="password" formik={formik} />
          <InputField name="confirmPassword" label="Confirm Password*" type="password" formik={formik} />
          <div className="legend">
            <div
              className={`radioField ${formik.values.isAgree ? 'active' : ''}`}
              onClick={() => formik.setFieldValue('isAgree', !formik.values.isAgree)}
            />
            <p className="typo-body-s">
              Yes, I have read and I do agree with DataGoat’s{' '}
              <Link to="/">
                Terms of <br /> Services
              </Link>{' '}
              and <Link to="/">Privacy policy</Link>.
            </p>
          </div>
          <button type="submit" className="primary" disabled={!formik.values.isAgree || isLoading}>
            Sign Up
          </button>
        </form>
      </div>
    </div>
  );
};
