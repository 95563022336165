import React, { FC } from 'react';
import styles from './Start.module.scss';
import { ModalType, useModalStore } from 'store/modalStore';
import CloseSVG from 'assets/icons/CloseSVG';
import GoatBadge from '../../../../assets/images/GoatBadgeSuccess.png';
import Modal from 'components/ui/Modal/Modal';
import Heading from 'components/ui/Heading/Heading';
import Text from 'components/ui/Text/Text';
import Box from 'components/ui/Box/Box';
import { format, add } from 'date-fns';
import { useAuthStore } from 'store/auth';

interface StartProps {}

const Start: FC<StartProps> = () => {
  const { setModal, modalData } = useModalStore();
  const { user } = useAuthStore();
  const price =
    modalData?.level === 2
      ? modalData?.pricing === 'monthly'
        ? 12
        : 96
      : modalData?.level === 3
        ? modalData?.pricing === 'monthly'
          ? 24
          : 192
        : 0;
  return (
    <Modal
      sx={{ height: 'auto', width: '30em', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
    >
      <Box justifyContent="center" alignItems="center" gap={32}>
        <CloseSVG
          className={styles.closeIcon}
          onClick={() => {
            setModal(ModalType.none);
          }}
        />
        <img src={GoatBadge} alt="?" />
        <Box gap={12} alignItems="center">
          <Heading size="m">Free trial started!</Heading>
          <Heading size="s">
            You will be charged ${price} on {format(add(new Date(), { days: 7 }), 'MMM dd, yyyy')}!
          </Heading>
          <Text size="xs">Try out our pro features for free for 7 days.</Text>
        </Box>
      </Box>
    </Modal>
  );
};

export default Start;
