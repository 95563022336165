import React, { FC } from 'react';
import styles from './ModalScriptSuccess.module.scss';
import Modal from 'components/ui/Modal/Modal';
import Box from 'components/ui/Box/Box';
import Button from 'components/ui/Button/Button';
import Label from 'components/ui/Label/Label';
import Heading from 'components/ui/Heading/Heading';
import Text from 'components/ui/Text/Text';
import ScriptSuccessGIF from 'assets/gifs/ScriptSuccessGIF.gif';
import CloseSVG from 'assets/icons/CloseSVG';
import { OpenExternalLink } from 'assets/icons';
import ProgressBar from 'components/ui/ProgressBar/ProgressBar';

interface ModalScriptSuccessProps {
  handleButtonClick: () => void;
  handleCancelClick: () => void;
}

const ModalScriptSuccess: FC<ModalScriptSuccessProps> = ({ handleButtonClick, handleCancelClick }) => (
  <Modal className={styles.modalScriptSuccess} sx={{ height: '450px', flexDirection: 'column', gap: '16px' }}>
    <CloseSVG className={styles.closeIcon} onClick={handleCancelClick} />
    <ProgressBar progressWidth={90} text="2/2 &nbsp; Publish project" />

    <Box className={styles.modalContainer} gap={32}>
      <Box gap={32} sx={{ flexDirection: 'row' }}>
        <Box gap={24} className={styles.stepsWrapper} alignItems="center" justifyContent="center">
          <Heading size="m">Successfully embedded!</Heading>

          <Box gap={16}>
            <Box gap={12} className={styles.steps}>
              <Box className={styles.numberCircle} justifyContent="center" alignItems="center">
                <Label sx={{ color: 'white' }}>1</Label>
              </Box>
              <Text size="xs">Make sure to delete previous Data Goat script </Text>
            </Box>
            <Box gap={12} className={styles.steps}>
              <Box className={styles.numberCircle} justifyContent="center" alignItems="center">
                <Label sx={{ color: 'white' }}>2</Label>
              </Box>
              <Text size="xs"> Save the changes and publish your website. </Text>
            </Box>
          </Box>
        </Box>
        <img style={{ width: '400px', height: '250px' }} src={ScriptSuccessGIF} alt="?" />
      </Box>
      <Box className={styles.action} alignItems="flex-end">
        <Button sx={{ padding: '12px 24px' }} type="primary" handleClick={handleButtonClick}>
          Open Project settings &nbsp;
          <OpenExternalLink />
        </Button>
      </Box>
    </Box>
  </Modal>
);

export default ModalScriptSuccess;
