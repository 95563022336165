import React, { FC, useEffect, useState } from 'react';
import styles from './ConnectGoogleAnalytics.module.scss';
import Heading from 'components/ui/Heading/Heading';
import Text from 'components/ui/Text/Text';
import Box from 'components/ui/Box/Box';
import Button from 'components/ui/Button/Button';
import { ExternalLink } from 'assets/icons';
import { WebflowBadge } from 'assets/icons/export';
import { useQuery } from 'react-query';
import { wfGetInstallUrl } from 'api/webflow';
import { useAuthStore } from 'store/auth';
import WebflowProjectList from '../WebflowProjectList/WebflowProjectList';
import { removeGoogleToken } from 'api/auth';

interface ConnectGoogleAnalyticsProps {
  websiteProjectList: any;
  setWebsiteProjectList: any;
  handleNextClick: (websiteProjectList: any, websiteIndex: number) => void;
  handleRemoveProject: (shortName: string, index: number) => void;
}

export type WebsiteState = 'site_not_published' | 'ga4_no_datastream' | 'ga4_success' | 'already_tracking';

const ConnectGoogleAnalytics: FC<ConnectGoogleAnalyticsProps> = ({
  websiteProjectList,
  setWebsiteProjectList,
  handleNextClick,
  handleRemoveProject
}) => {
  const [activeWebsite, setActiveWebsite] = useState(-1);
  const { isLogged, setIsLogged, setUser, user, setGoogleAuthorized } = useAuthStore();
  const { data: webflowInstallUrl } = useQuery(['wf-auth-url'], wfGetInstallUrl);
  const webflowProjectList: any[] = [];

  useEffect(() => {
    user?.websites?.forEach((website: any, index: number) => {
      const getWebflowProject = (websiteState: WebsiteState) => {
        const handleRadioButtonClick =
          websiteState == 'ga4_success'
            ? () => {
                setActiveWebsite(index);
              }
            : () => {};
        return (
          // <WebflowProjectWrapper
          {
            key: website?.url + ' ' + website?.displayName,
            url: website?.url,
            websiteState: websiteState,
            shortName: website.shortName,
            websiteName: website.displayName,
            radioButtonClick: handleRadioButtonClick,
            radioButtonActive: activeWebsite == index
          }
          ///>
        );
      };
      let websiteState: WebsiteState = 'site_not_published';
      if (website.websiteState.chosenForTracking) {
        websiteState = 'already_tracking';
      } else if (!website.websiteState.websitePublished) {
        websiteState = 'site_not_published';
      } else if (!website.websiteState.googleAnalyticsConnected) {
        websiteState = 'ga4_no_datastream';
      } else {
        websiteState = 'ga4_success';
      }
      webflowProjectList.push(getWebflowProject(websiteState));
    });
    setWebsiteProjectList(webflowProjectList);
  }, [user, activeWebsite]);
  return (
    <Box className={styles.ConnectGoogleAnalytics} gap={60} alignItems="normal">
      <Box gap={13}>
        <WebflowBadge />
        <Heading size="xl">Choose projects to add custom analytics on</Heading>
        <Text size="m" bold={true} color="neutral-200">
          Choose sites you want to connect to ga4. Note: Your project need to have a custom domain and you need to have
          the ga4 account set
        </Text>
      </Box>
      <Box gap={24} alignItems="normal">
        <WebflowProjectList
          webflowProjectList={websiteProjectList}
          fadeOut={false}
          handleRemoveProject={handleRemoveProject}
        />
        <Box flexDirection="row" justifyContent="space-between">
          <Button
            handleClick={async () => {
              await removeGoogleToken();
              setGoogleAuthorized(false);
            }}
          >
            Back
          </Button>
          <Box alignItems="flex-end">
            <Box flexDirection="row">
              <Button
                color="none"
                type="outline-hollow"
                Icon={ExternalLink}
                handleClick={() => {
                  window.open(webflowInstallUrl);
                }}
              >
                Authorise again{' '}
              </Button>
              <Button
                color="primary-400"
                handleClick={() => {
                  handleNextClick(websiteProjectList, activeWebsite);
                }}
                disabled={activeWebsite == -1}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ConnectGoogleAnalytics;
