import { getReportAnalytics } from 'api/analytics';
import { RunReportPayload } from 'api/analytics/types';
import { Website } from 'api/users/types';
import { getPayloadChartConversions, getPayloadChartImpressions } from './payloads';

const monthNamesRecord: Record<string, string> = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec'
};
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const toNumberDate = (date: any) => {
  let isoDateString = date.toISOString().split('T')[0];
  let formattedDate = isoDateString.replace(/-/g, '');
  return formattedDate;
};
export const fetchChartData = async (
  formName: string,
  website: Website,
  startDate: string,
  endDate: string,
  cancelToken: any
) => {
  try {
    let date1: any = new Date(startDate);
    let date2: any = new Date(endDate);

    // Calculate the difference in days
    let timeDifference = Math.abs(date2 - date1);
    let daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    let currentDate = new Date();

    let year = currentDate.getFullYear();

    // Calculate the week number
    let startOfYear = new Date(year, 0, 1);

    let millisecondsInWeek = 7 * 24 * 60 * 60 * 1000;
    let weekNumber = Math.ceil((currentDate.getTime() - startOfYear.getTime()) / millisecondsInWeek);
    let dayNumber = Math.ceil((currentDate.getTime() - startOfYear.getTime()) / (1000 * 60 * 60 * 24));

    let datePayload = { name: 'date' };
    let fillerDate = new Date(endDate);
    let isWeeksChart = false;
    let isYearsChart = false;
    // Display the result
    let holeFillerMove = 1;
    let formatDateCheck = true;
    if (daysDifference === 364) {
      datePayload = { name: 'yearMonth' };
      isYearsChart = true;
    }
    if (daysDifference === 89) {
      datePayload = { name: 'yearWeek' };
      holeFillerMove = 7;
      isWeeksChart = true;
    }
    if (daysDifference === 29 || daysDifference === 6) {
      datePayload = { name: 'date' };
    }

    const impressionsPayload = getPayloadChartImpressions(
      website.propertyId,
      startDate,
      endDate,
      datePayload,
      formName
    );
    const conversionsPayload = getPayloadChartConversions(
      website.propertyId,
      startDate,
      endDate,
      datePayload,
      formName
    );

    cancelToken.charts = new AbortController();
    const signal = cancelToken.charts.signal;

    const conversionsPromise = getReportAnalytics(conversionsPayload, signal);
    let impressionsData: any;

    impressionsData = await getReportAnalytics(impressionsPayload, signal);

    const sortedDataImpressions = sortDataByDate(impressionsData);
    // Modify payload for conversions and fetch data
    const conversionsData = await conversionsPromise;
    const sortedDataConversions = sortDataByDate(conversionsData);

    // Map conversions by date for easier lookup
    const conversionsByDate = sortedDataConversions?.reduce((acc, item) => {
      const date = item.dimensionValues?.[1]?.value || '';
      const conversionCount = parseFloat(item.metricValues?.[0]?.value) || 0;
      acc[date] = (acc[date] || 0) + conversionCount; // Accumulate conversions for the same date
      return acc;
    }, {});

    // Prepare chart data with conversion rates

    let k = 0;
    let chartData: any = [];

    let chartDataExtracted = sortedDataImpressions?.map((item) => {
      let date = item.dimensionValues?.[1]?.value || '';
      const impressionCount = parseFloat(item.metricValues?.[0]?.value) || 0;
      const conversionCount = conversionsByDate[date] || 0;
      const conversionRate =
        impressionCount > 0
          ? conversionCount / impressionCount < 1 / 100 && conversionCount / impressionCount > 0
            ? ((conversionCount / impressionCount) * 100).toFixed(2)
            : ((conversionCount / impressionCount) * 100).toFixed(0)
          : 0;
      return {
        name: date,
        impression: impressionCount,
        conversion: conversionCount,
        conversionRate: conversionRate
      };
    });

    if (isWeeksChart) {
      let dateAsNumber = toNumberDate(fillerDate);

      let daysOffset = dayNumber - (weekNumber - 1) * 7;
      let year = dateAsNumber.slice(0, 4);

      let weekCount = 15;
      if (daysOffset >= 4) weekCount = 14;

      for (let num = 1; num <= weekCount; num++) {
        dateAsNumber = toNumberDate(fillerDate);
        let analyticsDate;
        if (chartDataExtracted[k]?.name) analyticsDate = chartDataExtracted[k].name;
        else analyticsDate = '000000';
        if (weekNumber != parseInt(analyticsDate.slice(-2)) && year != analyticsDate.slice(0, 4)) {
          chartData.unshift({
            name: formatDate(dateAsNumber),
            impression: 0,
            conversion: 0,
            conversionRate: 0
          });
        } else {
          chartData.unshift({
            name: formatDate(dateAsNumber),
            impression: chartDataExtracted[k].impression,
            conversion: chartDataExtracted[k].conversion,
            conversionRate: chartDataExtracted[k].conversionRate
          });
          k++;
        }
        if (weekNumber == 1) {
          weekNumber = 53;
          year = (parseInt(year) - 1).toString();
        } else {
          weekNumber--;
        }
        if (num == weekCount - 1) {
          //daysOffset = Math.floor((fillerDate.getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24));
          let startAsDate = new Date(startDate);
          fillerDate = startAsDate;
        } else {
          fillerDate.setDate(fillerDate.getDate() - daysOffset);
        }
        if (daysOffset != 7) daysOffset = 7;
      }
    } else if (isYearsChart) {
      let dateAsNumber = toNumberDate(fillerDate);
      let month = dateAsNumber.slice(4, 6);
      let year = dateAsNumber.slice(0, 4);
      for (let num = 1; num <= 12; num++) {
        let analyticsDate;
        if (chartDataExtracted[k]?.name) analyticsDate = chartDataExtracted[k].name;
        else analyticsDate = '000000';
        if (parseInt(month) != parseInt(analyticsDate.slice(-2)) && year != analyticsDate.slice(0, 4)) {
          chartData.unshift({
            name: monthNamesRecord[month],
            impression: 0,
            conversion: 0,
            conversionRate: 0
          });
        } else {
          chartData.unshift({
            name: monthNamesRecord[month],
            impression: chartDataExtracted[k].impression,
            conversion: chartDataExtracted[k].conversion,
            conversionRate: chartDataExtracted[k].conversionRate
          });
          k++;
        }
        if (month == '01') {
          month = '12';
          year = (parseInt(year) - 1).toString();
        } else {
          month = (parseInt(month) - 1).toString();
          if (month.length == 1) month = '0' + month;
        }
      }
    } else {
      for (; daysDifference >= 0; daysDifference--) {
        let analyticsDate;
        if (chartDataExtracted[k]?.name) analyticsDate = chartDataExtracted[k].name;
        else analyticsDate = '00000000';
        let dateAsNumber = toNumberDate(fillerDate);
        if (dateAsNumber !== analyticsDate) {
          chartData.unshift({
            name: formatDate(dateAsNumber),
            impression: 0,
            conversion: 0,
            conversionRate: 0
          });
        } else {
          chartData.unshift({
            name: formatDate(dateAsNumber),
            impression: chartDataExtracted[k].impression,
            conversion: chartDataExtracted[k].conversion,
            conversionRate: chartDataExtracted[k].conversionRate
          });
          k++;
        }
        fillerDate.setDate(fillerDate.getDate() - 1);
      }
    }

    return new Promise((resolve) => {
      resolve(chartData);
    });
  } catch (e) {
    return new Promise((reject) => reject(undefined));
  }
};
const sortDataByDate = (data: any[], asc?: boolean): any[] => {
  return data?.sort((a, b) => {
    const dateA = a.dimensionValues[1].value;
    const dateB = b.dimensionValues[1].value;
    if (asc) return dateA.localeCompare(dateB);
    else return dateB.localeCompare(dateA);
  });
};

const formatDate = (dateString: string) => {
  // Convert string to Date object
  const dateObject = new Date(`${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`);

  // Array of month names
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Get month and day
  const monthIndex = dateObject.getMonth();
  const day = dateObject.getDate();

  // Format the date as "MonthName - day"
  const formattedDate = `${monthNames[monthIndex]} ${day}`;

  // Print the result
  return formattedDate;
};
