import React, { FC } from 'react';
// @ts-ignore
import styles from './LineChartLegend.module.scss';

interface LineChartLegendProps {
  id: number;
  metricName?: string;
  metricValue?: number | string;
  activeElements: number[];
  handleElementClick: (clickedIndex: number) => void;
}

const colors: Record<number, string> = {
  1: '#C0E666',
  2: '#FFC56E',
  3: '#FF6E6E'
};

const LineChartLegend: FC<LineChartLegendProps> = ({
  id,
  metricName,
  metricValue,
  activeElements,
  handleElementClick
}) => {
  return (
    <div
      className={`${styles.formConversions__section} ${activeElements.includes(1) ? 'active' : ''}`}
      style={{ borderTop: activeElements.includes(id) ? `1px solid ${colors[id]}` : '1px solid transparent' }}
      onClick={() => handleElementClick(id)}
    >
      <span>{metricName}</span>
      <p>{metricValue}</p>
    </div>
  );
};

export default LineChartLegend;
