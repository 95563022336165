import type { SVGProps } from 'react';
import * as React from 'react';

const SvgPlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#ECF0ED"
      d="M4.156 13.75c-.191 0-.38-.05-.546-.146A1.23 1.23 0 0 1 3 12.531V3.47c0-.45.234-.86.609-1.073a1.1 1.1 0 0 1 1.117.014l7.746 4.636a1.125 1.125 0 0 1 0 1.907L4.726 13.59a1.1 1.1 0 0 1-.57.16"
    />
  </svg>
);
export default SvgPlayIcon;
