import * as React from 'react';
import type { SVGProps } from 'react';

const SvgDescendingActive = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" {...props}>
    <path
      fill="#C0E666"
      d="M.667 2h2.666C3.7 2 4 2.3 4 2.667c0 .366-.3.666-.667.666H.667A.67.67 0 0 1 0 2.667C0 2.3.3 2 .667 2M0 9.333c0-.366.3-.666.667-.666h10.666c.367 0 .667.3.667.666s-.3.667-.667.667H.667A.67.67 0 0 1 0 9.333m.667-4h6.666c.367 0 .667.3.667.667s-.3.667-.667.667H.667A.67.67 0 0 1 0 6c0-.367.3-.667.667-.667"
    />
  </svg>
);
export default SvgDescendingActive;
