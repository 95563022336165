import React, { FC } from 'react';
import styles from './AnalyticsTableCard.module.scss';
import Box from '../Box/Box';
import Card from '../Card/Card';
import Table from '../SubmissionsTable/Table';

interface AnalyticsTableCardProps {
  heading: string;
  tableHeaders: string[];
  tableData: any;
  rightColumnMinWidth: number;
}

const AnalyticsTableCard: FC<AnalyticsTableCardProps> = ({ heading, tableHeaders, tableData, rightColumnMinWidth }) => {
  return (
    <Card heading={heading} sx={{ flex: 1 }}>
      <Table headers={tableHeaders} tableData={tableData} otherColumnMinWidth={rightColumnMinWidth}></Table>
    </Card>
  );
};

export default AnalyticsTableCard;
