import { Link, NavLink } from 'react-router-dom';

import GoatImg from '../assets/images/Goat.png';
import { LogoIcon } from 'assets/icons/export';

const NotFoundPage = () => {
  return (
    <div className="notFoundPage">
      <NavLink to="/" className="logo">
        <LogoIcon />
      </NavLink>
      <img src={GoatImg} alt="Goat" className="goatImg" />
      <div className="textContent">
        <span>Error</span>
        <h2>
          Oops... <br />
          Our Goat got lost...
        </h2>
        <p>
          Grow your business with custom analytics insights
          <br /> right in your Webflow Designer with zero code.
        </p>
        <Link to="/" className="primary">
          Go back to your Dashboard
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
