import type { SVGProps } from 'react';
import * as React from 'react';

const SvgHubspot = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" {...props}>
    <g clipPath="url(#hubspot_svg__a)">
      <path
        fill="#fff"
        d="M8.055 4.643v-.948a.73.73 0 0 0 .422-.66v-.022a.733.733 0 0 0-.73-.731h-.023a.733.733 0 0 0-.731.73v.023a.73.73 0 0 0 .417.658l.005.002v.95a2.1 2.1 0 0 0-.99.437l.004-.003-2.61-2.032a.832.832 0 1 0-.385.505l-.004.002 2.565 1.997c-.227.34-.347.74-.346 1.149 0 .43.133.85.383 1.202l-.005-.007-.78.781a.7.7 0 0 0-.194-.031.678.678 0 1 0 .677.677.7.7 0 0 0-.033-.198l.002.005.772-.773a2.082 2.082 0 1 0 1.594-3.711l-.012-.002zM7.734 7.77A1.069 1.069 0 1 1 8.805 6.7v.001A1.07 1.07 0 0 1 7.736 7.77z"
      />
    </g>
    <defs>
      <clipPath id="hubspot_svg__a">
        <path fill="#fff" d="M2 2h8v8H2z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHubspot;
