import { UpdateUserForm } from '../components/layout/account-settings/UpdateUserForm';
import { ContactSupport } from 'components/layout/ContactSupport';
import { Hello } from '../components/layout/Hello';
import { AccountInfo } from '../components/layout/home/AccountInfo';
import { AccountNavigation } from '../components/layout/AccountNavigation';
import { usePrivateRoute } from 'hooks/usePrivateRoute';
import Container from 'components/ui/Container/Container';
import Box from 'components/ui/Box/Box';

export const AccountSettingsPage = () => {
  usePrivateRoute();

  return (
    <Container justifyContent="center">
      <Container sx={{ height: '100%', width: '11em' }}></Container>
      {/* <div className="container" style={{marginTop:'32px'}}> */}
      {/* <Header /> */}
      {/* <div className="accountSettingsPage"> */}
      <Container flexDirection="column" justifyContent="center" padding="32px" sx={{ maxWidth: '1200px', flex: 1 }}>
        <Hello text="Welcome to you account settings." />
        <Box sx={{ marginTop: '-32px' }}>
          <AccountNavigation />
        </Box>
        {/* <div className="cont"> */}
        <Container flexDirection="row" gap={64}>
          <UpdateUserForm />
          {/* <div className="right"> */}
          <Box sx={{ flex: 1 }} gap={32}>
            <AccountInfo />
            <ContactSupport />
          </Box>
        </Container>
      </Container>
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
    </Container>
  );
};
