const WDGSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="188" height="125" fill="none" viewBox="0 0 188 125" {...props}>
    <path
      fill="#fff"
      stroke="#C0E666"
      strokeWidth="1.67"
      d="M60.827 25.498l.412.385.512-.239 8.435-3.941 4.508 8.147.273.493.56-.069 9.24-1.142 1.77 9.142.108.554.554.107 9.14 1.77-1.141 9.24-.07.56.495.273 8.146 4.508-3.94 8.436-.24.51.385.413 6.355 6.805-6.355 6.805-.385.412.24.511 3.94 8.436-8.147 4.508-.493.273.069.56 1.142 9.24-9.141 1.77-.554.107-.108.554-1.77 9.141-9.24-1.141-.56-.069-.273.493-4.508 8.147-8.436-3.941-.51-.239-.413.385-6.805 6.355-6.804-6.355-.413-.385-.511.239-8.436 3.941-4.507-8.147-.274-.493-.56.069-9.24 1.141-1.77-9.141-.107-.554-.554-.107-9.141-1.77 1.142-9.24.069-.56-.494-.273-8.147-4.508 3.941-8.436.24-.51-.386-.413-6.355-6.805 6.355-6.805.385-.412-.239-.511-3.94-8.436 8.146-4.508.494-.273-.07-.56-1.141-9.24 9.14-1.77.555-.107.107-.554 1.77-9.142 9.24 1.142.56.07.274-.494 4.507-8.147 8.436 3.941.511.239.413-.385 6.804-6.355 6.805 6.355z"
    ></path>
    <path
      fill="#4353FF"
      d="M65.003 62.336s-1.935 13.58-2.09 14.7c-.309-1.072-7.265-23.672-7.265-23.672-7.029 1.195-9.94 6.816-11.033 12.408 0 0-2.816 13.761-3.057 14.928-.201-.998-3.12-13.72-3.12-13.72-1.523-6.367-8.118-9.277-12.915-8.461l11.762 34.033c7.36-1.283 10.49-6.91 11.674-12.518 0 0 2.494-12.143 2.59-12.69.112.48 6.943 21.601 6.943 21.601 7.395-1.258 10.578-6.58 11.854-12.017l6.056-27.063c-7.304 1.242-10.312 6.848-11.4 12.47z"
    ></path>
    <path
      fill="#C0E666"
      stroke="#C0E666"
      strokeWidth="1.67"
      d="M140.782 7.596l.412.385.512-.24 8.435-3.94 4.508 8.146.273.494.56-.07 9.241-1.14 1.769 9.14.108.554.554.107 9.141 1.77-1.142 9.24-.069.56.494.274 8.146 4.508-3.941 8.435-.239.511.385.413 6.356 6.805-6.356 6.804-.385.413.239.51 3.941 8.437-8.146 4.507-.494.274.069.56 1.142 9.24-9.141 1.77-.554.107-.108.554-1.769 9.141-9.241-1.142-.56-.069-.273.494-4.508 8.147-8.435-3.942-.512-.238-.412.385-6.805 6.355-6.804-6.355-.413-.385-.511.239-8.435 3.941-4.508-8.147-.274-.494-.559.07-9.241 1.141-1.77-9.141-.107-.554-.554-.107-9.141-1.77 1.142-9.24.069-.56-.494-.274-8.147-4.507 3.941-8.436.24-.511-.386-.413-6.355-6.804 6.355-6.805.385-.413-.239-.51-3.94-8.436 8.146-4.508.494-.273-.07-.56-1.141-9.24 9.141-1.77.554-.108.107-.554 1.77-9.14 9.24 1.14.56.07.274-.494 4.507-8.147 8.436 3.942.511.239.413-.385 6.804-6.356 6.805 6.356z"
    ></path>
    <path
      fill="#08282D"
      d="M101.043 65.323l1.069-5.686 4.51.847 4.311-22.94-4.51-.847 1.069-5.687 14.117 2.654c9.215 1.732 13.233 7.358 11.501 16.574l-1.216 6.47c-1.732 9.215-7.518 13-16.734 11.268l-14.117-2.653zm12.086-3.819l3.235.608c5.196.977 8.083-1.424 9.023-6.424l1.289-6.862c.94-5-.879-8.285-6.074-9.262l-3.236-.608-4.237 22.548zM144.933 74.282c-7.059-1.327-11.726-7.279-10.067-16.102l1.547-8.235c1.659-8.823 7.874-12.73 16.403-11.127 8.431 1.585 12.332 7.291 10.894 14.938l-.036.196-6.373-1.197.074-.393c.682-3.627-1.039-6.894-5.646-7.76-4.608-.866-7.924 1.658-8.827 6.461l-1.584 8.431c-.903 4.804 1.252 8.457 5.958 9.341 4.705.885 7.28-1.473 7.999-5.297l.092-.49-8.137-1.53 1.032-5.49 14.509 2.728-3.298 17.548-5.98-1.124.617-3.284-.883-.166c-.999 1.538-3.001 3.547-8.294 2.552z"
    ></path>
  </svg>
);
export default WDGSVG;
