import { ModalScriptType } from 'components/modal/ModalGroupScript/ModalGroupScript';
import { create } from 'zustand';

export enum ModalType {
  none = 'none',
  analytics = 'analytics',
  script = 'script',
  addPayment = 'add-payment',
  freeTrial = 'free-trial',
  freeTrialStart = 'free-trial-start',
  freeTrialEnd = 'free-trial-end',
  freeTrialManualEnd = 'free-trial-manual-end',
  freeTrialManualEndWithoutUpdate = 'free-trial-manual-end-without-upgrade',
  planDowngrade = 'plan-downgrade',
  planUpgrade = 'plan-upgrade',
  planError = 'plan-error'
}

interface State {
  modal: ModalType;
  setModal: (modal: ModalType) => void;
  modalData: any;
  setModalData: (modalData: any) => void;
  modalTypeInGroup: ModalScriptType;
  setModalTypeInGroup: (modal: ModalScriptType) => void;
}

const useModalStore = create<State>((set) => ({
  modal: ModalType.none,
  setModal: (modal) =>
    set({
      modal: modal
    }),
  modalData: {},
  setModalData: (modalData) => set({ modalData: modalData }),
  modalTypeInGroup: ModalScriptType.none,
  setModalTypeInGroup: (modal) =>
    set({
      modalTypeInGroup: modal
    })
}));

export { useModalStore };
