import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getReport } from 'api/previewLink';
import { DeviceChart } from 'components/layout/preview-link/DeviceChart';
import { NewVisitorsChart } from 'components/layout/preview-link/NewVisitorsChart';
import { SessionsChart } from 'components/layout/preview-link/SessionChart';
import { CardList, SourceMetric } from 'components/layout/preview-link/CardList';
import { FormSubmissions } from 'components/layout/preview-link/FormSubmission';
import { PreviewLinkFilter } from 'components/layout/preview-link/PreviewLinkFilter';
import { PreviewLinkFooter } from 'components/layout/preview-link/PreviewLinkFooter';

export const PreviewLink = () => {
  const [sourceMetrics, setSourceMetrics] = useState<SourceMetric>('totalUsers');

  const router = useParams();
  const pathId = router.id as string;

  const { data } = useQuery(['reports', pathId], () => getReport(pathId), { enabled: !!pathId });

  const getComponentData = (name: string) => data?.components.find((item) => item.name === name);

  const propertyId = data?.propertyId || '';

  const chartData = {
    newVisitors: getComponentData('New Visitors'),
    device: getComponentData('Device'),
    sessions: getComponentData('Sessions'),
    formSubmissions: getComponentData('Form Submissions'),
    linkClicks: getComponentData('Link Clicks'),
    outbound: getComponentData('Outbound'),
    WebsiteChannels: getComponentData('Website Channels'),
    mediums: getComponentData('Mediums'),
    countries: getComponentData('Countries'),
    pageRanking: getComponentData('Page Ranking')
  };

  return (
    <div className="previewLinkWrapper">
      {/* <PreviewLinkHeader /> */}
      <PreviewLinkFilter filterInfo={data} />

      <div className="previewLinkCharts">
        {chartData.newVisitors && (
          <NewVisitorsChart
            payload={{
              key: ['newVisitors', chartData.newVisitors.id],
              dimensions: chartData.newVisitors.analytics.dimensions.map((e) => e.name),
              metrics: chartData.newVisitors.analytics.metrics.map((e) => e.name),
              propertyId,
              code: pathId,
              dateRanges: data?.dateRange,
              scope: data?.pageSeoTitle
            }}
          />
        )}
        {chartData.device && (
          <DeviceChart
            payload={{
              key: ['device', chartData.device.id],
              dimensions: chartData.device.analytics.dimensions.map((e) => e.name),
              metrics: chartData.device.analytics.metrics.map((e) => e.name),
              propertyId,
              code: pathId,
              dateRanges: data?.dateRange,
              scope: data?.pageSeoTitle
            }}
          />
        )}
        {chartData.sessions && (
          <SessionsChart
            payload={{
              key: ['seasson', chartData.sessions.id],
              dimensions: chartData.sessions.analytics.dimensions.map((e) => e.name),
              metrics: chartData.sessions.analytics.metrics.map((e) => e.name),
              propertyId,
              code: pathId,
              dateRanges: data?.dateRange,
              scope: data?.pageSeoTitle
            }}
          />
        )}
      </div>
      {chartData.formSubmissions && (
        <div className="oneInfoWrapper">
          <FormSubmissions
            reportData={chartData.formSubmissions}
            propertyId={propertyId}
            code={pathId}
            dateRanges={data?.dateRange}
            scope={data?.pageSeoTitle}
          />
        </div>
      )}
      <div className="oneInfoWrapper">
        {chartData.linkClicks && (
          <CardList
            name="linkusers"
            title="Link Clicks"
            nav={['Link', 'Clicks', 'Users']}
            filterBy="dg-click"
            payload={{
              key: ['linkClicks', chartData.linkClicks.id],
              dimensions: chartData.linkClicks.analytics.dimensions.map((e) => e.name),
              metrics: chartData.linkClicks.analytics.metrics.map((e) => e.name),
              propertyId,
              code: pathId,
              dateRanges: data?.dateRange,
              scope: data?.pageSeoTitle
            }}
          />
        )}
        {chartData.outbound && (
          <CardList
            name="outbound"
            title="Outbound"
            nav={['Link', 'Users']}
            payload={{
              key: ['outbound', chartData.outbound.id],
              dimensions: chartData.outbound.analytics.dimensions.map((e) => e.name),
              metrics: chartData.outbound.analytics.metrics.map((e) => e.name),
              propertyId,
              code: pathId,
              scope: data?.pageSeoTitle
            }}
          />
        )}
      </div>
      {/* <div className="oneInfoWrapper previewLinkCharts">
        <WebsiteChannels
          reportData={chartData.WebsiteChannels}
          propertyId={propertyId}
          code={pathId}
          scope={data?.pageSeoTitle}
        />
      </div> */}
      <div className="twoInfoWrapper">
        {chartData.mediums && (
          <CardList
            name="mediums"
            title="Mediums"
            nav={['source/mediums', sourceMetrics === 'sessions' ? 'Sessions' : 'Users']}
            payload={{
              key: ['mediums', chartData.mediums.id],
              dimensions: chartData.mediums.analytics.dimensions.map((e) => e.name),
              metrics: [sourceMetrics],
              propertyId,
              code: pathId,
              dateRanges: data?.dateRange,
              scope: data?.pageSeoTitle
            }}
            setSourceMetrics={setSourceMetrics}
            sourceMetrics={sourceMetrics}
          />
        )}
        {chartData.countries && (
          <CardList
            name="countries"
            title="Countries"
            nav={['Link', 'Users']}
            payload={{
              key: ['countries', chartData.countries.id],
              dimensions: chartData.countries.analytics.dimensions.map((e) => e.name),
              metrics: chartData.countries.analytics.metrics.map((e) => e.name),
              propertyId,
              code: pathId,
              dateRanges: data?.dateRange,
              scope: data?.pageSeoTitle
            }}
          />
        )}
      </div>
      {!data?.pageTitle && (
        <div className="oneInfoWrapper">
          {chartData.pageRanking && (
            <CardList
              name="pageRanking"
              title="Page Rankin"
              nav={['Link', 'Users']}
              payload={{
                key: ['pageRankin', chartData.pageRanking.id],
                dimensions: chartData.pageRanking.analytics.dimensions.map((e) => e.name),
                metrics: chartData.pageRanking.analytics.metrics.map((e) => e.name),
                propertyId,
                code: pathId,
                dateRanges: data?.dateRange
              }}
            />
          )}
        </div>
      )}
      <PreviewLinkFooter />
    </div>
  );
};
