import * as Yup from 'yup';

// Set default locale
Yup.setLocale({
  mixed: {
    required: 'The field is required',
    oneOf: ''
  },
  string: {
    email: 'You have entered wrong email address',
    min({ min }) {
      return `The field must have at least ${min} characters`;
    },
    max({ max }) {
      return `The field must have at least ${max} characters`;
    }
  }
});

// Utils
const emailYup = Yup.string().email().max(255).required();
const passwordYup = Yup.string()
  .min(8, 'Password should have a minimum of 8 characters')
  .matches(/[A-Z]/, 'Password should contain at least one uppercase letter')
  .matches(/[0-9]/, 'Password should contain at least one number')
  .matches(/[!@#$%^&*]/, 'Password should contain at least one special character')
  .required();

// Schemas
export const signUpYup = Yup.object().shape({
  firstName: Yup.string().max(255).required(),
  lastName: Yup.string().max(255).required(),
  email: emailYup,
  password: passwordYup,
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required()
});

export const editUserOneYup = Yup.object().shape({
  firstName: Yup.string().max(255).required(),
  lastName: Yup.string().max(255).required()
});

export const editUserTwoYup = Yup.object().shape({ email: emailYup });

export const editUserThreeYup = Yup.object().shape({
  oldPassword: Yup.string(),
  newPassword: Yup.string(),
  repeatPassword: Yup.string()
});

export const resetPasswordYup = Yup.object().shape({
  newPassword: passwordYup,
  repeatNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required()
});
