import React, { FC } from 'react';
// @ts-ignore
import styles from './FreeAccountEmpty.module.scss';
import { WebflowXL } from 'assets/icons/export';
import Button from 'components/ui/Button/Button';
import { useNavigate } from 'react-router';
import { useAuthStore } from 'store/auth';
import { ModalType, useModalStore } from 'store/modalStore';

interface FreeAccountEmptyProps {}

const FreeAccountEmpty: FC<FreeAccountEmptyProps> = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { setModal } = useModalStore();
  return (
    <>
      <WebflowXL className={styles.icon} />
      <div className={styles.text}>
        <div className={styles.heading}>No Webflow native forms found</div>
        <div className={styles.body}>
          {' '}
          Our free plan exclusively supports tracking for Webflow forms. To access <br />
          tracking for all forms on your site, upgrade your plan.{' '}
        </div>
        <Button
          padding="small"
          handleClick={() => {
            user?.trialUsed ? navigate('subscription') : setModal(ModalType.freeTrial);
          }}
        >
          Upgrade to track all forms
        </Button>
      </div>
    </>
  );
};

export default FreeAccountEmpty;
