import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { StripeElementStyle } from '@stripe/stripe-js';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { OrdersProduct } from 'api/orders/types';
import { SignUpPayload } from 'api/auth/types';

import { InputField } from 'components/ui/forms/fields/InputField';
import { addPaymentMethod, createSubscription, fetchUserSubscription } from 'api/orders';
import { useAuthStore } from 'store/auth';
import { signUp } from 'api/auth';
import { wfConnect } from 'api/webflow';
import { queryClient } from '../../../..';
import { ModalType, useModalStore } from 'store/modalStore';
import { orderKeys } from 'api/orders/keys';

const style: StripeElementStyle = {
  base: {
    color: '#fff',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22px',
    '::placeholder': {
      color: '#a1b8b4'
    }
  },
  invalid: {
    color: '#f68989'
  }
};

interface Props {
  payload?: SignUpPayload;
  billingPlan: { id: string; level: number; pricing: string; startFreeTrial: boolean } | null;
  addSubscription?: boolean;
}

export const StripeForm = ({ payload, billingPlan, addSubscription }: Props) => {
  const [cardName, setCardName] = useState('');
  const { setModal, setModalData } = useModalStore();
  const stripe = useStripe();
  const elements = useElements();
  const { user, setUser } = useAuthStore();
  const { mutate: onSignUp, isLoading } = useMutation(signUp);
  const { mutate: onCreateSubscription } = useMutation(createSubscription);
  const navigate = useNavigate();
  const { setIsLogged, wfAuthCode } = useAuthStore();
  const { mutate: connectWf } = useMutation(wfConnect);
  const { mutate } = useMutation(addPaymentMethod);

  const onChange = (e: any, target: string) => {
    if (e.complete) {
      const el: HTMLInputElement | null = document.querySelector(`.${target} input`);
      el?.focus();
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (stripe === null || elements === null) return;
    console.log('Stripe Form Card', elements.getElement(CardNumberElement));
    const { paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement)!,
      billing_details: { name: cardName }
    });

    mutate(paymentMethod?.id || '', {
      onSuccess: async () => {
        await queryClient.refetchQueries(orderKeys.paymentInfo);
        //resetForm();
        const subscriptions = await fetchUserSubscription();
        if (!subscriptions && addSubscription && billingPlan) {
          await createSubscription({ ...billingPlan }).then(() => {
            setModal(ModalType.freeTrialStart);
            setModalData({
              pricing: billingPlan.pricing,
              level: billingPlan.level
            });
          });
        } else {
          setModal(ModalType.none);
        }
      }
    });
  };
  /*const body: SignUpPayload = {
      //...payload,
      paymentMethodId: paymentMethod?.id || ''
    };*/

  //   onSignUp(body, {
  //     onSuccess: () =>
  //       // TODO: Proveri da li je ovo dobro
  //       onCreateSubscription(billingPlan?.default_price.id || '', {
  //         onSuccess: () => {
  //           if (wfAuthCode)
  //             connectWf(
  //               { code: wfAuthCode, state: '' },
  //               {
  //                 onSuccess: () => {
  //                   queryClient.refetchQueries('auth-user');
  //                 }
  //               }
  //             );
  //           setIsLogged(true);
  //           navigate('/');
  //         }
  //       })
  //   });
  // };

  return (
    <div className="stripeForm" style={{ width: '100%' }}>
      <h2 className="typo-heading-m">Card details</h2>
      <form onSubmit={onSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
        <InputField
          label="Card holder name*"
          name="name"
          value={cardName}
          onChange={(e) => setCardName(e.target.value)}
          required
        />
        <div className="inputFieldWrapper">
          <div className="inputField">
            <label className="typo-label-l">Credit/debit card number*</label>
            <CardNumberElement
              className="stripeField stripeCardNumberField"
              options={{ style, placeholder: 'XXXX XXXX XXXX XXXX' }}
              onChange={(e) => onChange(e, 'stripeCardExpiryField')}
            />
          </div>
        </div>
        <div className="grid-col-2" style={{ gap: '16px' }}>
          <div className="inputFieldWrapper">
            <div className="inputField">
              <label className="typo-label-l">Expiration Date*</label>
              <CardExpiryElement
                className="stripeField stripeCardExpiryField"
                options={{ style }}
                onChange={(e) => onChange(e, 'stripeCardCvcField')}
              />
            </div>
          </div>
          <div className="inputFieldWrapper">
            <div className="inputField">
              <label className="typo-label-l">CVC*</label>
              <CardCvcElement className="stripeField stripeCardCvcField" options={{ style, placeholder: 'XXX' }} />
            </div>
          </div>
        </div>
        <button type="submit" className="primary" disabled={isLoading}>
          Continue to confirmation
        </button>
      </form>
    </div>
  );
};
