import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { editUserOneYup, editUserThreeYup, editUserTwoYup } from 'utils/yup';
import { InputField } from '../../ui/forms/fields/InputField';
import { editUseInfo } from 'api/users';
import { useAuthStore } from 'store/auth';
import { queryClient } from '../../..';
import { useState } from 'react';
import { DeleteAccountModal } from './deleteAccountModal';
import Container from 'components/ui/Container/Container';
import Box from 'components/ui/Box/Box';

export const UpdateUserForm = () => {
  const { user } = useAuthStore();
  const { mutate } = useMutation(editUseInfo);
  const [isOpen, setIsOpen] = useState(false);

  const onSuccess = () => queryClient.refetchQueries('auth-user');

  const formikFirst = useFormik({
    enableReinitialize: true,
    initialValues: { firstName: user?.firstName || '', lastName: user?.lastName || '' },
    validationSchema: editUserOneYup,
    onSubmit: (values) => mutate(values, { onSuccess })
  });

  const formikSecond = useFormik({
    enableReinitialize: true,
    initialValues: { email: user?.email || '' },
    validationSchema: editUserTwoYup,
    onSubmit: (values) => mutate(values, { onSuccess })
  });

  const formikThird = useFormik({
    initialValues: { oldPassword: '', newPassword: '', repeatPassword: '' },
    validationSchema: editUserThreeYup,
    onSubmit: (values) => mutate(values, { onSuccess })
  });

  return (
    <Container sx={{ flex: 1 }} flexDirection="column">
      <form onSubmit={formikFirst.handleSubmit} className="section">
        <p className="borderTopTitle" style={{ marginBottom: '16px' }}>
          Update your personal info
        </p>
        <div>
          <InputField name="firstName" label="First Name*" formik={formikFirst} />
          <InputField name="lastName" label="Last Name*" formik={formikFirst} />
          <button type="submit" className="outlined" style={{ width: '200px' }}>
            Update Info
          </button>
        </div>
      </form>

      <form onSubmit={formikSecond.handleSubmit} className="section">
        <p className="borderTopTitle" style={{ marginBottom: '16px' }}>
          Update your email info
        </p>
        <div>
          <InputField
            name="email"
            label="Email*"
            placeholder="example@example.com"
            type="email"
            formik={formikSecond}
          />
          <button type="submit" className="outlined" style={{ width: '200px' }}>
            Update Info
          </button>
        </div>
      </form>

      <form onSubmit={formikThird.handleSubmit} className="section">
        <p className="borderTopTitle" style={{ marginBottom: '16px' }}>
          Update your email info
        </p>
        <div>
          <InputField name="oldPassword" label="Old Password*" type="password" formik={formikThird} />
          <InputField name="newPassword" label="New Password*" type="password" formik={formikThird} />
          <InputField name="repeatPassword" label="Confirm New Password*" type="password" formik={formikThird} />

          <button type="submit" className="outlined" style={{ width: '200px' }}>
            Update Info
          </button>
        </div>
      </form>
      <div className="section">
        <p className="borderTopTitle" style={{ marginBottom: '16px' }}>
          Account delete
        </p>
        <div className="deleteAccount">
          {/* <h4>Current plan</h4>
          <h2>Data Goat</h2>
          <p>Free plan</p> */}
          <button className="outlined" onClick={() => setIsOpen(true)} style={{ width: '200px' }}>
            Delete account
          </button>
          {isOpen ? <DeleteAccountModal close={() => setIsOpen(false)} className="deleteAccountModal" /> : null}
        </div>
      </div>
    </Container>
  );
};
