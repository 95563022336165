import { RegisterPayload, ResetPasswordPayload, SignInPayload, SignUpPayload } from './types';

import { setStorageItem } from 'utils/storage';
import { apiCall } from 'api';
import { toast } from 'react-toastify';
import { VerifyCodePayload } from 'api/users/types';

export const signUp = async (payload: SignUpPayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `users/register`,
      method: 'POST',
      data: payload
    });

    setStorageItem('token', data.token);
  } catch (error) {
    throw error;
  }
};

export const registerUser = async (payload: RegisterPayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `users/register`,
      method: 'POST',
      data: payload
    });

    setStorageItem('token', data.token);
  } catch (error: any) {
    toast.error(error?.data?.message);
  }
};

export const signUpValidator = async (payload: SignInPayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `users/validateRegister`,
      method: 'POST',
      data: payload
    });

    return data;
  } catch (error: any) {
    throw error;
  }
};

export const signIn = async (payload: SignInPayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `users/login`,
      method: 'POST',
      data: payload
    });

    setStorageItem('token', data.token);
  } catch (error: any) {
    throw error;
  }
};

export const forgotPassword = async (email: string) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `users/forgotPassword`,
      method: 'POST',
      data: { email }
    });

    toast.success(data.message);
  } catch (error: any) {
    throw error;
  }
};

export const resetPassword = async (payload: ResetPasswordPayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `users/resetPassword`,
      method: 'POST',
      data: payload
    });

    toast.success(data?.message);
  } catch (error: any) {
    throw error;
  }
};

export const signInWithGoogle = async () => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `users/google/login`,
      method: 'GET'
    });
    return data;
  } catch (error: any) {
    throw error;
  }
};

export const resendCode = async (email: string) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `users/verifyRequest`,
      method: 'POST',
      data: { email }
    });
    toast.success(data.message);
  } catch (error: any) {
    //toast.error(error);
    toast.error(error.data.message);
  }
};

export const verifyEmailCode = async (payload: VerifyCodePayload) => {
  const request = await apiCall();

  try {
    const { data } = await request({
      url: `/users/verify`,
      method: 'POST',
      data: payload
    });

    toast('You logged in successfully!', { type: 'success' });
    return data;
  } catch (error: any) {
    throw toast.error('The verification code is not valid or has expired');
  }
};

export const getGoogleAuthorization = async () => {
  const request = await apiCall();

  try {
    const { data } = await request({
      url: `/users/googleAuthorized`,
      method: 'POST'
    });

    return data;
  } catch (error: any) {
    return error;
  }
};

export const getWebflowAuthorization = async () => {
  const request = await apiCall();

  try {
    const { data } = await request({
      url: `/users/webflowAuthorized`,
      method: 'POST'
    });

    return data;
  } catch (error: any) {
    return error;
  }
};

export const getGoogleAnalyticsConnected = async () => {
  const request = await apiCall();

  try {
    const { data } = await request({
      url: `/users/googleAnalyticsConnected`,
      method: 'POST'
    });

    return data;
  } catch (error: any) {
    return error;
  }
};

export const removeGoogleToken = async () => {
  const request = await apiCall();

  try {
    const { data } = await request({
      url: `/users/removeGoogleToken`,
      method: 'DELETE'
    });

    return data;
  } catch (error: any) {
    return error;
  }
};

export const removeWebiste = async (shortName: string, index: number) => {
  const request = await apiCall();

  try {
    const { data } = await request({
      url: `/users/removeWebsite`,
      method: 'delete',
      data: { shortName: shortName, index: index }
    });

    return data;
  } catch (error: any) {
    return error;
  }
};
