import * as React from 'react';
import type { SVGProps } from 'react';

const SvgScriptMenuError = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} fill="none" {...props}>
    <rect width={32} height={32} y={0.5} fill="#0A2F35" rx={8} />
    <path
      fill="#F5FAEC"
      fillRule="evenodd"
      d="M18.218 11.714a.75.75 0 1 0-1.436-.43l-3 10a.75.75 0 0 0 1.436.43zm-5.688 1.754a.75.75 0 0 1 0 1.06l-1.97 1.97 1.97 1.97a.75.75 0 0 1-1.06 1.06l-2.5-2.5a.75.75 0 0 1 0-1.06l2.5-2.5a.75.75 0 0 1 1.06 0m6.94 6.06a.75.75 0 0 1 0-1.06l1.97-1.97-1.97-1.97a.75.75 0 0 1 1.06-1.06l2.5 2.5a.75.75 0 0 1 0 1.06l-2.5 2.5a.75.75 0 0 1-1.06 0"
      clipRule="evenodd"
    />
    <circle cx={28} cy={27.5} r={6} fill="#FF7575" />
    <path
      fill="#052025"
      fillRule="evenodd"
      d="m28.042 28.388 1.682 1.682a.476.476 0 0 0 .673-.673l-1.683-1.682 1.683-1.682a.475.475 0 1 0-.673-.673l-1.682 1.682-1.682-1.682a.476.476 0 1 0-.673.673l1.683 1.682-1.683 1.682a.476.476 0 1 0 .673.672z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgScriptMenuError;
