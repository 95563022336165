import type { SVGProps } from 'react';
import * as React from 'react';

const SvgCheckFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" {...props}>
    <path fill="#DBDBDB" d="M0 0h12v12H0z" />
    <path fill="#031B1F" d="M-1061-457H379v911h-1440z" />
    <path fill="#0A2F35" d="M-643-12a4 4 0 0 1 4-4H18a4 4 0 0 1 4 4v36a4 4 0 0 1-4 4h-657a4 4 0 0 1-4-4z" />
    <circle cx={6} cy={6} r={6} fill="#C0E666" />
    <path
      fill="#052025"
      d="M8.307 4.16a.578.578 0 0 1 .825.809L6.056 8.815a.58.58 0 0 1-.832.016l-2.04-2.04a.578.578 0 1 1 .817-.817l1.614 1.613 2.677-3.41z"
    />
  </svg>
);
export default SvgCheckFilled;
