import { Portal } from 'react-portal';
import { CloseIcon } from '../../../../assets/icons/export';
import { useState } from 'react';
import { FirstStep } from './FirstStep';
import { SecondStep } from './SecondStep';
import { ThirdStep } from './ThirdStep';
import { isActiveClass } from 'utils';
import { Modal } from '../../../ui/Modal';

interface Props {
  close: () => void;
  className?: string;
  id: string;
}

export const CancleSubscriptionModal = ({ close, className, id }: Props) => {
  const [step, setStep] = useState(1);

  const getStep = () => {
    switch (step) {
      case 1:
        return <FirstStep setStep={setStep} close={close} />;
      case 2:
        return <SecondStep setStep={setStep} id={id} close={close} />;
      case 3:
        return <ThirdStep />;
    }
  };

  return (
    <Modal close={close} className={className}>
      <CloseIcon className="close" onClick={close} />
      {step !== 3 && (
        <div className="stepHeader">
          <p className="typo-body-xs">Step {step}</p>
          <h3>Cancel subscription</h3>
          <div className="tabs">
            <div className="tab"></div>
            <div className={isActiveClass(step === 1, 'tab')}></div>
          </div>
        </div>
      )}
      {getStep()}
    </Modal>
  );
};
