const DavidsStarSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48" {...props}>
    <path
      fill="#F68989"
      d="M24 0l7.92 10.282L44.785 12 39.84 24l4.945 12-12.865 1.718L24 48l-7.92-10.282L3.215 36 8.16 24 3.215 12l12.865-1.718L24 0z"
    ></path>
    <path
      stroke="#031B1F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      d="M30 19.201l-8.4 9.6-3.6-3.6"
    ></path>
  </svg>
);

export default DavidsStarSVG;
