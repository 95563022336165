import React, { FC, useEffect, useState } from 'react';
import Box from 'components/ui/Box/Box';
import LoadingSpinner from 'components/ui/LoadingSpinner/LoadingSpinner';
import WebflowProjectList from '../WebflowProjectList/WebflowProjectList';
import { trackWebsiteAnalytics } from 'api/users';
import { useAnalyticsStore } from 'store/AnalyticsStore';

interface ConnectGoogleAnalyticsLoadingProps {
  websiteProjectList: any;
  selectedProject: any;
  handleSuccess: () => void;
}

const ConnectGoogleAnalyticsLoading: FC<ConnectGoogleAnalyticsLoadingProps> = ({
  websiteProjectList,
  selectedProject,
  handleSuccess
}) => {
  const { setWebsite } = useAnalyticsStore();
  const [trackingSuccess, setTrackingSuccess] = useState<boolean>(false);
  const [animationFinished, setAnimationFinished] = useState<boolean>(false);
  useEffect(() => {
    const trackWebsiteAnalyticsHandler = async () => {
      try {
        const website = await trackWebsiteAnalytics(selectedProject);
        setWebsite(website);
        setTrackingSuccess(true);
      } catch (e) {
        console.log(e);
      }
    };
    trackWebsiteAnalyticsHandler();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setAnimationFinished(true);
    }, 2500);
  }, []);

  useEffect(() => {
    if (animationFinished && trackingSuccess) {
      handleSuccess();
    }
  }, [animationFinished, trackingSuccess]);

  return (
    <Box gap={80} justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%' }}>
      <LoadingSpinner />
      <Box justifyContent="center" alignItems="normal" sx={{ width: '60%' }}>
        <WebflowProjectList webflowProjectList={websiteProjectList} fadeOut={true} />
      </Box>
    </Box>
  );
};

export default ConnectGoogleAnalyticsLoading;
