import React, { FC, LegacyRef, useEffect, useRef } from 'react';
import styles from './Tooltip.module.scss';
import Box from 'components/ui/Box/Box';

import Button from 'components/ui/Button/Button';
import Label from 'components/ui/Label/Label';
import Text from 'components/ui/Text/Text';
import Wrapper from 'components/ui/Wrapper/Wrapper';
import Container from 'components/ui/Container/Container';

interface TooltipProps {
  children?: React.ReactNode;
  parentWidth?: number;
  show: boolean;
}

const Tooltip: FC<TooltipProps> = ({ children, parentWidth, show }) => {
  const widthRef: LegacyRef<HTMLDivElement> = useRef<any>();
  const triangleRef: LegacyRef<HTMLDivElement> = useRef<any>();

  useEffect(() => {
    if (widthRef.current && triangleRef.current) {
      const leftPos = `calc(50% - ${widthRef.current.offsetWidth / 2}px)`;
      widthRef.current.style.left = leftPos;
      //triangleRef.current.style.left = leftPos
    }
  }, []);

  return (
    <>
      <div ref={widthRef} className={styles.posit} style={show ? { visibility: 'visible' } : { visibility: 'hidden' }}>
        <div ref={triangleRef} style={{ left: '0px' }} className={styles.triangle}></div>

        <Wrapper className={styles.TooltipL}>{children}</Wrapper>
      </div>
    </>
  );
};

export default Tooltip;
