import * as React from 'react';
import type { SVGProps } from 'react';

const SvgScriptMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={33} fill="none" {...props}>
    <rect width={32} height={32} y={0.5} fill="#0A2F35" rx={8} />
    <path
      fill="#F5FAEC"
      fillRule="evenodd"
      d="M18.218 11.714a.75.75 0 1 0-1.436-.43l-3 10a.75.75 0 0 0 1.436.43zm-5.688 1.754a.75.75 0 0 1 0 1.06l-1.97 1.97 1.97 1.97a.75.75 0 0 1-1.06 1.06l-2.5-2.5a.75.75 0 0 1 0-1.06l2.5-2.5a.75.75 0 0 1 1.06 0m6.94 6.06a.75.75 0 0 1 0-1.06l1.97-1.97-1.97-1.97a.75.75 0 0 1 1.06-1.06l2.5 2.5a.75.75 0 0 1 0 1.06l-2.5 2.5a.75.75 0 0 1-1.06 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgScriptMenu;
