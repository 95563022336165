import { DateRange } from 'api/analytics/types';
import { ReportComponent } from 'api/previewLink/types';

import { useAnalyticsData } from 'hooks/useAnalyticsData';

interface Props {
  reportData?: ReportComponent;
  propertyId?: string;
  code?: string;
  dateRanges?: DateRange;
  scope?: string;
}

export const FormSubmissions = ({ reportData, propertyId, code, dateRanges, scope }: Props) => {
  const reportDataId = propertyId || '';

  const { data }: any = useAnalyticsData({
    key: ['form_submission', reportData?.id || ''],
    dimensions: reportData?.analytics.dimensions.map((el) => el.name) || [],
    metrics: reportData?.analytics.metrics.map((el) => el.name) || [],
    propertyId: reportDataId,
    code,
    dateRanges,
    scope
  });

  const getData = () => {
    return data
      ?.filter(
        (el: any) =>
          el.dimensionValues[0].value.includes('dg-form-view') || el.dimensionValues[0].value.includes('dg-submit')
      )
      ?.map((el: any) => ({
        type: el.dimensionValues[0].value.split('_')[0],
        label: el.dimensionValues[0].value.split('_')[1],
        value: el.metricValues[0]?.value
      }))
      ?.reduce((acc: any, curr: any) => {
        const index = acc.findIndex((el: any) => el.label === curr.label);

        if (index === -1) {
          acc.push({
            label: curr.label,
            [curr.type]: curr.value
          });
        } else {
          acc[index][curr.type] = curr.value;
        }

        return acc;
      }, []);
  };

  const numberOfConversions = getData()?.reduce((acc: any, curr: any) => acc + Number(curr['dg-submit'] || 0), 0) || 0;

  return (
    <div className="paper cardList card-formSubmissions">
      <div className="heading">
        <h2 className="typo-heading-xs">
          Form Submissions
          <span>{numberOfConversions} Total</span>
        </h2>
      </div>
      <div className="row navRow">
        <span className="typo-body-xs">Link</span>
        <span className="typo-body-xs">Impressions</span>
        <span className="typo-body-xs">Conversions</span>
      </div>
      <ul>
        {getData()?.map((el: any, index: number) => (
          <li className="row el" key={index}>
            <span>{el.label}</span>
            <span>{el['dg-form-view'] || '/'}</span>
            <span>{el['dg-submit'] || '/'}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
