import React, { FC, SVGProps } from 'react';
import styles from './Item.module.scss';
import Wrapper from 'components/ui/Wrapper/Wrapper';
import Link from 'components/ui/Link/Link';
import Text from 'components/ui/Text/Text';

interface ItemProps {
  Icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  active?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}

const Item: FC<ItemProps> = ({ Icon, active, onClick, children }) => {
  return (
    <Wrapper className={styles.item} padding="16px 12px" gap={8} flexDirection="row" sx={{ width: '100%' }}>
      <Link className={active ? `${styles.link} ${styles.active}` : `${styles.link}`} onClick={onClick}>
        {Icon && <Icon />} {children}
      </Link>
    </Wrapper>
  );
};

export default Item;
