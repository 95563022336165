import SignUpCoverImg from 'assets/images/SignUpCoverImg.png';
import { usePublicRoute } from 'hooks/usePublicRoute';
import { DavidsStarIcon, LogoIcon } from 'assets/icons/export';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { forgotPassword } from 'api/auth';
import { useFormik } from 'formik';
import { InputField } from '../components/ui/forms/fields/InputField';

export const ForgotPasswod = () => {
  usePublicRoute();

  const [isSent, setIsSent] = useState(false);
  const { mutate, isLoading } = useMutation(forgotPassword);

  const formik = useFormik({
    initialValues: { email: '' },
    onSubmit: (values, { setErrors }) => {
      mutate(values.email, {
        onSuccess: () => setIsSent(true),
        onError: (error: any) => setErrors({ email: error?.data.message })
      });
    }
  });

  return (
    <div className="signUpPage">
      <div className="left">
        <LogoIcon />
        <>
          {isSent ? (
            <div className="instructions">
              <DavidsStarIcon />
              <p className="title">Instructions sent!</p>
              <p className="subtitle">Please check your email to find your unique verification link.</p>
            </div>
          ) : (
            <div className="signUpForm">
              <h1 className="typo-heading-xl">Forgot password</h1>
              <p className="subtitle">Enter your regestered email</p>
              <form onSubmit={formik.handleSubmit}>
                <InputField name="email" label="Email Adress*" type="email" formik={formik} required />
                <button type="submit" className="primary" disabled={isLoading}>
                  Send instructions
                </button>
              </form>
            </div>
          )}
        </>
      </div>
      <div className="right">
        <img src={SignUpCoverImg} alt="DataGoat" className="coverPhoto" />
      </div>
    </div>
  );
};
