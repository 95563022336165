import React, { FC } from 'react';
import styles from './LoadingDots.module.scss';
import Box from '../Box/Box';

interface LoadingDotsProps {}

const LoadingDots: FC<LoadingDotsProps> = () => (
  <Box gap={8} sx={{ flexDirection: 'row' }} className={styles.loaderContainer}>
    <div className={styles.loader}></div>
    <div className={styles.loader}></div>
    <div className={styles.loader}></div>
  </Box>
);

export default LoadingDots;
