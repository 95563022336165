import React, { FC, useState } from 'react';
import styles from './TableHeader.module.scss';

interface TableHeaderProps {
  headers?: string[];
  firstColumnMinWidth?: number;
  otherColumnMinWidth?: number;
}

const TableHeader: FC<TableHeaderProps> = ({ headers, firstColumnMinWidth, otherColumnMinWidth }) => {
  return (
    <div className={styles.TableHeader} style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      {headers?.map((name, columnIndex) =>
        columnIndex === 0 ? (
          <div
            className={`${styles.dataMetrics}`}
            key={columnIndex}
            style={{
              textAlign: 'left',
              minWidth: firstColumnMinWidth,
              flexGrow: 1
            }}
          >
            <span className={styles.headerText}>{name}</span>
          </div>
        ) : (
          <div className={`${styles.dataMetrics}`} key={columnIndex} style={{ minWidth: otherColumnMinWidth }}>
            <span className={styles.headerText}>{name}</span>
          </div>
        )
      )}
    </div>
  );
};
//
export default TableHeader;
