import type { StylesConfig } from 'react-select';

export const selectStyles: StylesConfig = {
  singleValue: (styles) => ({
    ...styles,
    color: '#C6D7CD'
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    fontSize: 14,
    background: '#08282D',
    fontWeight: 300,
    borderColor: isFocused ? 'none' : '#A1B8B4',
    borderRadius: 6,
    width: 200,
    borderStyle: 'hidden',
    outline: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      borderColor: 'none'
    }
  }),
  menu: (styles) => ({
    ...styles,
    background: '#08282D',
    borderRadius: 4,
    padding: 4
  }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    fontSize: 14,
    fontWeight: 300,
    background: isSelected || isFocused ? '#134C54' : '#08282D',
    color: '#C6D7CD',
    borderRadius: 4,
    margin: '2px 0',
    '&:active': {
      background: isSelected || isFocused ? '#134C54' : '#08282D'
    }
  })
};
