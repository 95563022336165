import React, { FC } from 'react';
import styles from './CircleWrapper.module.scss';
import Wrapper from '../Wrapper/Wrapper';

interface CircleWrapperProps {
  color?: 'primary-300' | 'primary-400' | 'neutral-100' | 'neutral-300';
}

const colorMap = {
  'primary-300': styles.primary300,
  'primary-400': styles.primary400,
  'neutral-100': styles.neutral100,
  'neutral-300': styles.neutral300
};

const CircleWrapper: FC<CircleWrapperProps> = ({ color = 'neutral-100' }) => {
  return (
    <Wrapper
      sx={{ width: '16px', height: '16px' }}
      borderRadius="50%"
      className={`${colorMap[color]} ${styles.background}`}
    ></Wrapper>
  );
};

export default CircleWrapper;
