import { RunReportPayload } from 'api/analytics/types';

export const getPayloadPageLocation = (propertyId: string, startDate: string, endDate: string): RunReportPayload => {
  return {
    propertyId: `${propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'pageLocation' }, { name: 'eventName' }],
    metrics: [{ name: 'eventCount' }],
    dimensionFilter: {
      filter: {
        fieldName: 'eventName',
        stringFilter: {
          matchType: 'BEGINS_WITH',
          value: `dg-submit_`
        }
      }
    }
  };
};

export const getPayloadConversions = (propertyId: string, startDate: string, endDate: string): RunReportPayload => {
  return {
    propertyId: `${propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'eventName' }],
    metrics: [{ name: 'eventCount' }],
    dimensionFilter: {
      filter: {
        fieldName: 'eventName',
        stringFilter: {
          matchType: 'BEGINS_WITH',
          value: 'dg-submit_'
        }
      }
    }
  };
};

export const getPayloadImpressions = (propertyId: string, startDate: string, endDate: string): RunReportPayload => {
  const startDateAsDate = new Date(startDate);
  const endDateAsDate = new Date(endDate);
  let dayNumber = Math.ceil((endDateAsDate.getTime() - startDateAsDate.getTime()) / (1000 * 60 * 60 * 24));

  if (dayNumber === 6 || dayNumber === 29) {
    dayNumber += 1;
    const compareEndDate = new Date(endDate);
    const compareStartDate = new Date(startDate);
    compareEndDate.setDate(endDateAsDate.getDate() - dayNumber);
    compareStartDate.setDate(startDateAsDate.getDate() - dayNumber);
    const compareEndDatePayload = compareEndDate.toISOString().split('T')[0];
    const compareStartDatePayload = compareStartDate.toISOString().split('T')[0];
    return {
      propertyId: `${propertyId}`,
      dateRanges: [
        { startDate: startDate, endDate: endDate },
        { startDate: compareStartDatePayload, endDate: compareEndDatePayload }
      ],
      dimensions: [{ name: 'eventName' }],
      metrics: [{ name: 'eventCount' }],
      dimensionFilter: {
        filter: {
          fieldName: 'eventName',
          stringFilter: {
            matchType: 'BEGINS_WITH',
            value: 'dg-form-view_'
          }
        }
      }
    };
  } else {
    return {
      propertyId: `${propertyId}`,
      dateRanges: [{ startDate: startDate, endDate: endDate }],
      dimensions: [{ name: 'eventName' }],
      metrics: [{ name: 'eventCount' }],
      dimensionFilter: {
        filter: {
          fieldName: 'eventName',
          stringFilter: {
            matchType: 'BEGINS_WITH',
            value: 'dg-form-view_'
          }
        }
      }
    };
  }
};
