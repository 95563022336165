export const ContactSupport = () => (
  <div className="contanctSupport">
    <h3>Contact Support</h3>
    <p>
      If you're experiencing any issues or have questions about our product, contacting customer support can often be
      the best way to get help.
    </p>
    <a className="primary" href="https://www.datagoat.co/customer-support" target="_blank" rel="noreferrer">
      Email Us
    </a>
  </div>
);
