import { toast } from 'react-toastify';
import { apiCall } from '..';
import { PaymentInfo, Subscription } from './types';
import { queryClient } from 'index';
import { authKeys } from 'api/auth/keys';

export const fetchOrderProducts = async (): Promise</*OrdersProductResponse*/ any | undefined> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `orders/products`, method: 'GET' });

    return data;
  } catch (error: any) {
    toast.error(error?.data?.message);
  }
};

export const couponSubscription = async (payload: { id: string; coupon: string }) => {
  try {
    const request = await apiCall();
    await request({
      url: `orders/couponSubscription`,
      method: 'POST',
      data: { items: [{ price: payload.id }], coupon: payload.coupon }
    });

    toast.success('You have successfully signed up with coupon!');

    queryClient.refetchQueries(authKeys.me);
  } catch (error: any) {
    toast.error(error?.data?.message);
    throw error;
  }
};

export const createSubscription = async (payload: {
  id: string;
  level: number;
  pricing: string;
  startFreeTrial: boolean;
}): Promise<any | undefined> => {
  try {
    const request = await apiCall();
    await request({
      url: `orders/subscribe`,
      method: 'POST',
      data: {
        items: [{ price: payload.id }],
        level: payload.level,
        pricing: payload.pricing,
        startFreeTrial: payload.startFreeTrial
      }
    });

    toast.success('You have changed your plan!');
    queryClient.refetchQueries(authKeys.me);
  } catch (error: any) {
    throw error;
    //toast.error(error.data);
    //throw error;
  }
};

export const fetchUserSubscription = async (): Promise<Subscription | undefined> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `orders/userSubscriptions`, method: 'GET' });

    return data.subscriptions[0];
  } catch (error: any) {
    if (error?.status !== 405) {
      toast.error(error?.data?.message);
    }
  }
};

export const fetchUsersPaymentInfo = async (): Promise<PaymentInfo | undefined> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `orders/userPaymentInfo`, method: 'GET' });
    return data;
  } catch (error: any) {
    // toast.error(error?.data?.message);
  }
};

export const addPaymentMethod = async (paymentMethodId: string) => {
  try {
    const request = await apiCall();
    await request({
      url: `orders/paymentMethod`,
      method: 'POST',
      data: { paymentMethodId }
    });

    toast.success('Payment method added successfully!');
  } catch (error: any) {
    toast.error(error?.data?.message);
  }
};

export const deletePaymentMethod = async (paymentMethodId: string) => {
  try {
    const request = await apiCall();
    await request({
      url: `orders/paymentMethod`,
      method: 'DELETE',
      data: { paymentMethodId }
    });

    toast.success('Payment method deleted successfully!');
  } catch (error: any) {
    toast.error(error?.data?.message);
  }
};

export const setDefaultPaymentMethod = async (paymentMethodId: string) => {
  try {
    const request = await apiCall();
    await request({
      url: `orders/paymentMethod`,
      method: 'PATCH',
      data: { paymentMethodId }
    });

    toast.success('Payment updated successfully!');
  } catch (error: any) {
    toast.error(error?.data?.message);
  }
};

export const unsubsscribe = async (payload: { id: string; reason: string }) => {
  const { id, reason } = payload;
  try {
    const request = await apiCall();
    await request({
      url: `orders/unsubscribe`,
      method: 'POST',
      data: { subscriptionId: id, reason }
    });

    toast.success('Unsubscribed successfully!');
  } catch (error: any) {
    toast.error(error?.data?.message);
  }
};

export const resumeSubscription = async (subscriptionId: string) => {
  try {
    const request = await apiCall();
    await request({
      url: `orders/resumeSubscription`,
      method: 'POST',
      data: { subscriptionId }
    });

    toast.success('Subscription updated successfully!');
  } catch (error: any) {
    toast.error(error?.data?.message);
  }
};

export const updateSubscription = async (payload: { priceId: string; level: number; pricing: string }) => {
  try {
    const request = await apiCall();
    await request({
      url: `orders/updateSubscription`,
      method: 'POST',
      data: { priceId: payload.priceId, level: payload.level, pricing: payload.pricing }
    });

    toast.success('Subscription updated successfully!');
  } catch (error: any) {
    throw error;
  }
};

export const hasPaymentMethods = async () => {
  try {
    const request = await apiCall();
    return await request({
      url: `orders/hasPaymentMethods`,
      method: 'GET'
    });
  } catch (error: any) {
    throw error;
  }
};

export const endFreeTrial = async (priceId: string) => {
  try {
    const request = await apiCall();
    return await request({
      url: `orders/endFreeTrial`,
      method: 'PATCH',
      data: { priceId }
    });
  } catch (error: any) {
    throw error;
  }
};
