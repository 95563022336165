import React, { CSSProperties, FC, ReactNode } from 'react';
import styles from './Text.module.scss';

interface TextProps {
  size?: 'xxs' | 'xs' | 's' | 'm' | 'l';
  color?: 'white' | 'neutral-100' | 'neutral-200' | 'neutral-300' | 'neutral-500' | 'primary-100';
  bold?: boolean;
  children?: ReactNode;
  className?: string;
  sx?: CSSProperties;
}

const typoMap = {
  xxs: styles.xxs,
  xs: styles.xs,
  s: styles.s,
  m: styles.m,
  l: styles.l
};
const colorMap = {
  white: styles.white,
  'primary-100': styles.primary100,
  'neutral-100': styles.neutral100,
  'neutral-200': styles.neutral200,
  'neutral-300': styles.neutral300,
  'neutral-500': styles.neutral500
};
const Text: FC<TextProps> = ({ size = 's', color = 'neutral-200', bold, children, className, sx }) => {
  const classBold = bold ? styles.bold : '';
  const classRecord = `${className} ${typoMap[size]} ${colorMap[color]} ${classBold}`;
  return (
    <div className={classRecord} style={sx}>
      {children}
    </div>
  );
};

export default Text;
