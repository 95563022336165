import React, { CSSProperties, FC, HTMLAttributes, ReactNode } from 'react';
import styles from './Box.module.scss';

interface BoxProps {
  gap?: number;
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'normal' | (string & {});
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'normal' | (string & {});
  flexDirection?: 'column' | 'row';
  children?: ReactNode;
  className?: string;
  sx?: CSSProperties;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const Box: FC<BoxProps> = ({
  gap = 0,
  justifyContent = 'normal',
  alignItems = 'normal',

  flexDirection = 'column',
  children,
  className = '',
  sx,
  onClick,
  onMouseEnter,
  onMouseLeave
}) => {
  const classRecord = `${className} ${styles.wrapper}`;
  return (
    <div
      className={classRecord}
      style={{
        gap: gap + 'px',
        justifyContent: justifyContent,
        alignItems: alignItems,
        flexDirection: flexDirection,
        ...sx
      }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
};

export default Box;
