import React, { FC, useState } from 'react';
// @ts-ignore
import styles from './TableHeader.module.scss';
import SvgNoSort from '../../../../assets/icons/NoSort';
import SvgDescendingActive from '../../../../assets/icons/DescendingActive';
import SvgAscendingActive from '../../../../assets/icons/AscendingActive';

interface TableHeaderProps {
  headerText?: string;
  metrics: string[];
  sortable: boolean | undefined;
  sortBy: (header: string, asc: number) => void;
  statistics: any;
}

const textToIndex: Record<string, number> = {
  Users: 0,
  Impressions: 0,
  Conversions: 1,
  'Conversion Rate': 2
};

const statisticsMapList: Record<string, string>[] = [
  {
    raise: 'r',
    fall: 'f',
    equal: 'e',
    none: ''
  },
  {
    raise: 'r',
    fall: 'f',
    equal: 'e',
    none: ''
  },
  {
    raise: 'r',
    fall: 'f',
    equal: 'e',
    none: ''
  }
];

const TableHeader: FC<TableHeaderProps> = ({ headerText = 'Link', metrics, sortable, sortBy, statistics }) => {
  const [headerIcons, setHeaderIcons] = useState<any[]>([
    <SvgNoSort className={styles.icons} />,
    <SvgNoSort className={styles.icons} />,
    <SvgDescendingActive className={styles.icons} />
  ]);

  const iconsList = [
    <SvgDescendingActive className={styles.icons} />,
    <SvgAscendingActive className={styles.icons} />
    //<SvgNoSort className={styles.icons} />
  ];
  const [sortActive, setSortActive] = useState('Conversion Rate');
  const [sortState, setSortState] = useState(0);
  return (
    <tr className={styles.TableHeader}>
      {metrics?.map((name, index) => {
        if (sortable && (name == 'Users' || name == 'Conversions' || name == 'Conversion Rate'))
          return (
            <th
              className={`${styles.dataMetrics} ${styles.sort}`}
              key={index}
              onClick={() => {
                if (sortActive != name) {
                  let headerIconsList = [
                    <SvgNoSort className={styles.icons} />,
                    <SvgNoSort className={styles.icons} />,
                    <SvgNoSort className={styles.icons} />
                  ];
                  headerIconsList[textToIndex[name]] = <SvgDescendingActive className={styles.icons} />;
                  setHeaderIcons(headerIconsList);
                  setSortActive(name);
                  setSortState(0);
                  sortBy(name, 0);
                } else {
                  let headerIconsList = headerIcons;
                  headerIconsList[textToIndex[name]] = iconsList[(sortState + 1) % 2];
                  setHeaderIcons(headerIconsList);
                  setSortState((sortState + 1) % 2);
                  sortBy(name, (sortState + 1) % 2);
                }
              }}
            >
              <span className={styles.headerText}>
                {statistics ? statisticsMapList[textToIndex[name]][statistics[name]] : ''} &nbsp;
                {name} &nbsp; {headerIcons[textToIndex[name]]}
              </span>
            </th>
          );
        else {
          if (index === 0) {
            return <th className={styles.dataText}>{name}</th>;
          } else {
            return (
              <th className={styles.dataMetrics} key={index}>
                {name}
              </th>
            );
          }
        }
      })}
    </tr>
  );
};

export default TableHeader;
