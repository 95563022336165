import * as React from 'react';
import type { SVGProps } from 'react';

const SvgCrossProject = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" {...props}>
    <path
      fill="#A1B8B4"
      d="m7.392 6.4 3.897-3.896a.985.985 0 0 0-1.393-1.395L6 5.006 2.104 1.109A.986.986 0 1 0 .709 2.504L4.605 6.4.71 10.297a.986.986 0 0 0 1.395 1.394L6 7.795l3.896 3.896a.986.986 0 0 0 1.395-1.394z"
    />
  </svg>
);
export default SvgCrossProject;
