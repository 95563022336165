import React, { FC } from 'react';
import styles from './LineChartLegend.module.scss';

interface LineChartLegendProps {
  id: number;
  metricName?: string;
  metricValue?: number | string;
  active: boolean;
  handleElementClick: (clickedIndex: number) => void;
}

const colors: Record<number, string> = {
  1: '#C0E666',
  0: '#C0E666'
};

const LineChartLegend: FC<LineChartLegendProps> = ({ id, metricName, metricValue, active, handleElementClick }) => {
  return (
    <div
      className={`${styles.formConversions__section} ${active ? 'active' : ''}`}
      style={{ borderTop: active ? `1px solid ${colors[id]}` : '1px solid transparent' }}
      onClick={() => handleElementClick(id)}
    >
      <span>{metricName}</span>
      <p>{metricValue}</p>
    </div>
  );
};

export default LineChartLegend;
