import React, { FC } from 'react';
import styles from './WrapperPremium.module.scss';
import Wrapper from '../Wrapper/Wrapper';
import Label from '../Label/Label';

interface WrapperPremiumProps {
  children?: React.ReactNode;

  size?: 'xs' | 's' | 'm' | 'l';
}

const typoMap = {
  xs: styles.xs,
  s: styles.s,
  m: styles.m,
  l: styles.l
};

const WrapperPremium: FC<WrapperPremiumProps> = ({ children, size = 's' }) => {
  return (
    <Wrapper borderRadius="30px" padding="4px 12px" className={`${styles.WrapperPremium} ${typoMap[size]}`}>
      <Label color="highlight" size={size}>
        {children}
      </Label>
    </Wrapper>
  );
};

export default WrapperPremium;
