import React, { FC, useEffect, useState } from 'react';
import styles from './ConnectGoogleAnalytics.module.scss';
import Heading from 'components/ui/Heading/Heading';
import Text from 'components/ui/Text/Text';
import Box from 'components/ui/Box/Box';
import Button from 'components/ui/Button/Button';
import { ExternalLink } from 'assets/icons';
import { WebflowBadge } from 'assets/icons/export';
import { useQuery } from 'react-query';
import { wfGetInstallUrl } from 'api/webflow';
import { useAuthStore } from 'store/auth';
import { trackWebsiteAnalytics } from 'api/users';
import WebflowProjectList from '../../UserAndProjectAuthorization/WebflowProjectList/WebflowProjectList';
import { useAnalyticsStore } from 'store/AnalyticsStore';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Container from 'components/ui/Container/Container';
import { removeWebiste } from 'api/auth';

interface ConnectGoogleAnalyticsProps {}

export type WebsiteState = 'site_not_published' | 'ga4_no_datastream' | 'ga4_success' | 'already_tracking';

const ConnectGoogleAnalytics: FC<ConnectGoogleAnalyticsProps> = ({}) => {
  const [activeWebsite, setActiveWebsite] = useState(-1);
  const { setWebsite } = useAnalyticsStore();
  const [websiteProjectList, setWebsiteProjectList] = useState<any>([]);

  const navigate = useNavigate();

  const { user } = useAuthStore();
  const { data: webflowInstallUrl } = useQuery(['wf-auth-url'], wfGetInstallUrl);
  const webflowProjectList: any[] = [];

  useEffect(() => {
    user?.websites?.forEach((website: any, index: number) => {
      const getWebflowProject = (websiteState: WebsiteState) => {
        const handleRadioButtonClick =
          websiteState == 'ga4_success'
            ? () => {
                setActiveWebsite(index);
              }
            : () => {};
        return (
          // <WebflowProjectWrapper
          {
            key: website?.url + ' ' + website?.displayName,
            url: website?.url,
            websiteState: websiteState,
            shortName: website.shortName,
            websiteName: website.displayName,
            radioButtonClick: handleRadioButtonClick,
            radioButtonActive: activeWebsite == index
          }
          ///>
        );
      };
      let websiteState: WebsiteState = 'site_not_published';
      if (website.websiteState.chosenForTracking) {
        websiteState = 'already_tracking';
      } else if (!website.websiteState.websitePublished) {
        websiteState = 'site_not_published';
      } else if (!website.websiteState.googleAnalyticsConnected) {
        websiteState = 'ga4_no_datastream';
      } else {
        websiteState = 'ga4_success';
      }
      webflowProjectList.push(getWebflowProject(websiteState));
    });
    setWebsiteProjectList(webflowProjectList);
  }, [user, activeWebsite]);

  const handleRemoveProject = async (shortName: string, index: number) => {
    await removeWebiste(shortName, index);

    websiteProjectList.splice(index, 1);
    setWebsiteProjectList([...websiteProjectList]);
  };
  return (
    <Container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
      <Box className={styles.ConnectGoogleAnalytics} gap={60} alignItems="normal">
        <Box gap={13}>
          <WebflowBadge />
          <Heading size="xl">Choose projects to add custom analytics on</Heading>
          <Text size="m" bold={true} color="neutral-200">
            Choose sites you want to connect to ga4. Note: Your project need to have a custom domain and you need to
            have the ga4 account set
          </Text>
        </Box>
        <Box gap={24} alignItems="normal">
          <WebflowProjectList
            webflowProjectList={websiteProjectList}
            fadeOut={false}
            handleRemoveProject={handleRemoveProject}
          />
          <Box justifyContent="space-between">
            <Box flexDirection="row">
              <Button
                color="primary-400"
                handleClick={async () => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
              <Box flexDirection="row" justifyContent="flex-end" sx={{ flex: 1 }}>
                <Button
                  color="none"
                  type="outline-hollow"
                  Icon={ExternalLink}
                  handleClick={() => {
                    window.open(webflowInstallUrl);
                  }}
                >
                  Authorise again{' '}
                </Button>
                <Button
                  color="primary-400"
                  handleClick={async () => {
                    const trackWebsiteAnalyticsHandler = async () => {
                      try {
                        const website = await trackWebsiteAnalytics(websiteProjectList[activeWebsite]);
                        setWebsite(website);
                        navigate('../');
                        window.location.reload();
                      } catch (e: any) {
                        toast.error(e.data);
                      }
                    };
                    trackWebsiteAnalyticsHandler();
                  }}
                  disabled={activeWebsite == -1}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ConnectGoogleAnalytics;
