import React, { CSSProperties, FC } from 'react';
import styles from './Card.module.scss';
import Box from '../Box/Box';
import Wrapper from '../Wrapper/Wrapper';
import Heading from '../Heading/Heading';

interface CardProps {
  heading?: string;
  children?: React.ReactNode;
  sx?: CSSProperties;
}

const Card: FC<CardProps> = ({ heading, children, sx }) => (
  <Wrapper flexDirection="column" gap={16} padding="16px" borderRadius="8px" className={styles.Card} sx={sx}>
    {heading && <Heading size="xs">{heading}</Heading>}
    {children}
  </Wrapper>
);

export default Card;
