import React, { FC, LegacyRef, useEffect, useState } from 'react';
import styles from './ProjectMenu.module.scss';
import Container from 'components/ui/Container/Container';
import Wrapper from 'components/ui/Wrapper/Wrapper';
import Label from 'components/ui/Label/Label';
import Button from 'components/ui/Button/Button';
import Heading from 'components/ui/Heading/Heading';
import WrapperPremium from 'components/ui/WrapperPremium/WrapperPremium';
import Box from 'components/ui/Box/Box';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store/auth';
import { checkWebsiteLimit } from 'api/users';
import { useAnalyticsData } from 'hooks/useAnalyticsData';
import { useAnalyticsStore } from 'store/AnalyticsStore';
import Locked from 'assets/icons/Locked';
import { CheckFilled } from 'assets/icons';
import Text from 'components/ui/Text/Text';
import { toast } from 'react-toastify';

interface ProjectMenuProps {
  show: boolean;
  websiteList?: any;
  projectMenuRef?: LegacyRef<HTMLDivElement>;
  handleWebsiteClick: (websiteProject: any) => void;
  handleUpgradeClick: () => void;
}

// @ts-ignore
const ProjectMenu: FC<ProjectMenuProps> = ({
  show,
  websiteList,
  projectMenuRef,
  handleWebsiteClick,
  handleUpgradeClick
}) => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { website } = useAnalyticsStore();
  useEffect(() => {}, [user, website]);
  return (
    show && (
      <div ref={projectMenuRef}>
        <Container className={styles.ProjectMenu} flexDirection="column" gap={8} sx={{ height: '100%' }}>
          <Wrapper className={styles.header} padding="12px" justifyContent="space-between" sx={{ width: '100%' }}>
            <Label size="s" color="neutral200">
              Connected projects
            </Label>
            {
              <Label size="s" color="neutral200">
                {
                  websiteList?.filter(
                    (website: any) => website.value.websiteState.chosenForTracking && !website.value.websiteState.locked
                  ).length
                }
                /{user?.tier.limitation.maxWebsites} connected
              </Label>
            }
          </Wrapper>
          <Wrapper gap={8} flexDirection="column">
            {websiteList?.map((website: any) => (
              <Wrapper
                padding="8px 12px"
                onClick={() => {
                  !website.value.websiteState.locked
                    ? handleWebsiteClick(website.value)
                    : toast.error('Website locked, please upgrade your plan');
                }}
                alignItems="center"
                justifyContent="space-between"
                className={styles.projectWrapper}
              >
                <Box flexDirection="row" gap={8} alignItems="center">
                  <img height="16" width="16" src={`https://api.statvoo.com/favicon/${website.value.url}`} />
                  <Label size="m" color={website.value.websiteState.locked ? 'fade' : 'normal'}>
                    {website.label}
                  </Label>
                </Box>
                {website.value.websiteState.locked ? (
                  <Box sx={{ width: '16px', height: '16px' }} alignItems="center" justifyContent="center">
                    <Locked />
                  </Box>
                ) : (
                  <Box flexDirection="row" gap={8}>
                    <Text size="xs" color="neutral-100">
                      {' '}
                      Connected{' '}
                    </Text>
                    <Box sx={{ width: '16px', height: '16px' }} alignItems="center" justifyContent="center">
                      <CheckFilled />
                    </Box>
                  </Box>
                )}
              </Wrapper>
            ))}
          </Wrapper>
          <Wrapper
            className={styles.action}
            padding="12px"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            sx={{ height: '100%' }}
          >
            <Label
              size="m"
              color="highlight"
              handleClick={async () => {
                try {
                  await checkWebsiteLimit();
                  navigate('add-project');
                } catch (e: any) {
                  if (e.data === 'upgrade_plan') handleUpgradeClick();
                }
              }}
              sx={{ cursor: 'pointer' }}
            >
              + Add a project
            </Label>
            {user?.tier.tier !== 'Business' && (
              <Wrapper
                className={styles.upgradeWrapper}
                borderRadius="4px"
                padding="12px"
                flexDirection="column"
                gap={12}
              >
                <Box alignItems="flex-start">
                  <WrapperPremium size="xs">Business</WrapperPremium>
                </Box>
                <Heading size="xs">Track up to 10 websites!</Heading>
                <Label size="m">
                  {user?.trialUsed
                    ? 'Try out all our Pro Features'
                    : 'Start a free trial and try out all our Business Features'}
                </Label>
                <Button handleClick={handleUpgradeClick}>{user?.trialUsed ? 'Upgrade' : 'Try it out for free'}</Button>
              </Wrapper>
            )}
          </Wrapper>
        </Container>
      </div>
    )
  );
};

export default ProjectMenu;
