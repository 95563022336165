import * as React from 'react';
import type { SVGProps } from 'react';

const SvgInsightsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#C6D7CD"
      d="m10.498 14.266 1.097-2.417L14 10.763l-2.405-1.086-1.097-2.417-1.074 2.417-2.429 1.086 2.429 1.086zM5.585 7.17l.584-2.335 2.335-.584-2.335-.584-.584-2.335-.583 2.335-2.335.584 2.335.584z"
    />
  </svg>
);
export default SvgInsightsIcon;
