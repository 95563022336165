import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
// @ts-ignore
import styles from './Table.module.scss';
import TableHeader from './TableHeader/TableHeader';
import TableRow from './TableRow/TableRow';
import HoverInfoComponent from './HoverInfoComponent/HoverInfoComponent';

interface TableProps {
  headers: string[];
  data?: any[];
  handleRowClick: any;
  dateRange?: string;
  fullName?: string[];
  cssClass?: boolean;
  clickable?: boolean;
  sortable?: boolean;
  statistics?: any;
  loadMore: boolean;
  slicingLength: number;
}

const Table: FC<TableProps> = ({
  headers,
  data,
  handleRowClick,
  dateRange,
  fullName,
  cssClass,
  clickable,
  sortable,
  statistics,
  slicingLength
}) => {
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number } | undefined>(undefined);
  const [hoveredCellPosition, setHoveredCellPosition] = useState<{ top: number; left: number } | null>(null);
  const [hoveredInfo, setHoveredInfo] = useState<{ dimension: string | null; metric: string | null }>({
    dimension: null,
    metric: null
  });
  const [sortedData, setSortedData] = useState<any[] | undefined>(data);

  const sortBy = (header: string, asc: number) => {
    let newData = data;
    if (header === 'Users') {
      if (asc === 0) {
        newData?.sort((a, b) => b!.Users - a!.Users);
      } else {
        newData?.sort((a, b) => a!.Users - b!.Users);
      }
    }

    if (header === 'Conversions') {
      if (asc === 0) {
        newData?.sort((a, b) => b!.Conversions - a!.Conversions);
      } else {
        newData?.sort((a, b) => a!.Conversions - b!.Conversions);
      }
    }

    if (header === 'Conversion Rate') {
      if (asc === 0) {
        newData?.sort(
          (a, b) =>
            parseFloat(b['Conversion Rate'].replace('%', '')) - parseFloat(a!['Conversion Rate'].replace('%', ''))
        );
      } else {
        newData?.sort(
          (a, b) =>
            parseFloat(a!['Conversion Rate'].replace('%', '')) - parseFloat(b!['Conversion Rate'].replace('%', ''))
        );
      }
    }
    setSortedData([...(newData as any[])]);
  };

  const [hoveredEvent, setHoveredEvent] = useState<React.MouseEvent | null>(null);

  const handleHover = (dimension: string, metric: string, event: React.MouseEvent) => {
    setHoveredInfo({ dimension, metric });
    setHoveredEvent(event);
  };
  const handleMouseLeave = () => {
    setHoveredInfo({ dimension: null, metric: null });
    setHoveredEvent(null);
  };
  /*
  useEffect(() => {
    if (loadMore && sortedData) setSlicingLength(sortedData.length);
    else setSlicingLength(3);
  }, [loadMore]);*/
  return (
    <div className={styles.Table}>
      <table className={styles.Table}>
        <thead>
          <TableHeader metrics={headers} sortable={sortable} sortBy={sortBy} statistics={statistics}></TableHeader>
        </thead>
        <tbody>
          {sortedData?.slice(0, slicingLength)?.map((value: any, index: any) => {
            const metrics: any[] = [];

            for (let key in value) metrics.push(value[key]);
            return (
              <TableRow
                key={index}
                tableText={metrics[0]}
                metricValues={metrics.slice(1)}
                handleRowClick={handleRowClick}
                index={index + 1}
                onHover={handleHover}
                onMouseLeave={handleMouseLeave}
                headers={headers} // Pass headers to TableRow
                cssClass={cssClass}
                clickable={clickable}
              />
            );
          })}
        </tbody>
      </table>
      {hoveredInfo.dimension !== null && hoveredInfo.metric !== null && hoveredEvent !== null && (
        <HoverInfoComponent
          date={dateRange as string}
          dimension={hoveredInfo.dimension}
          metric={hoveredInfo.metric}
          event={hoveredEvent} // Pass the entire event object
        />
      )}
    </div>
  );
};

export default Table;
