import { RunReportPayload } from 'api/analytics/types';

export const getPayloadChartImpressions = (
  propertyId: string,
  startDate: string,
  endDate: string,
  datePayload: { name: string },
  formName: string
): RunReportPayload => {
  return {
    propertyId: `${propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'eventName' }, datePayload],
    metrics: [{ name: 'eventCount' }],
    dimensionFilter: {
      filter: {
        fieldName: 'eventName',
        stringFilter: {
          matchType: 'EXACT',
          value: `${formName?.replace('dg-submit', 'dg-form-view').slice(0, 40)}`
        }
      }
    }
  };
};

export const getPayloadChartConversions = (
  propertyId: string,
  startDate: string,
  endDate: string,
  datePayload: { name: string },
  formName: string
): RunReportPayload => {
  return {
    propertyId: `${propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'eventName' }, datePayload],
    metrics: [{ name: 'eventCount' }],
    dimensionFilter: {
      filter: {
        fieldName: 'eventName',
        stringFilter: {
          matchType: 'EXACT',
          value: `${formName.slice(0, 40)}`
        }
      }
    }
  };
};

export const getPayloadDestinationConversions = (
  propertyId: string,
  startDate: string,
  endDate: string
): RunReportPayload => {
  return {
    propertyId: `${propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'pageLocation' }],
    metrics: [{ name: 'eventCount' }],
    dimensionFilter: {
      filter: {
        fieldName: 'eventName',
        stringFilter: {
          matchType: 'BEGINS_WITH',
          value: `dg-submit_`
        }
      }
    }
  };
};

export const getPayloadDestinationImpressions = (
  propertyId: string,
  startDate: string,
  endDate: string
): RunReportPayload => {
  return {
    propertyId: `${propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'pageLocation' }],
    metrics: [{ name: 'activeUsers' }],
    dimensionFilter: {
      filter: {
        fieldName: 'eventName',
        stringFilter: {
          matchType: 'BEGINS_WITH',
          value: `dg-form-view_`
        }
      }
    }
  };
};
