import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './DropdownList.module.scss';
import Wrapper from '../Wrapper/Wrapper';
import Heading from '../Heading/Heading';
import Box from '../Box/Box';
import DropdownArrowIcon from 'assets/icons/DropdownArrowIcon';

interface DropdownListProps {
  title?: string;
  children?: React.ReactNode;
}

const DropdownList: FC<DropdownListProps> = ({ children, title }) => {
  const [dropdownActive, setDropdownActive] = useState<boolean>(false);
  const wrapperRef = useRef<any>();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // Check if the clicked element is outside the component
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDropdownActive(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div style={{ position: 'relative', flex: '1' }} ref={wrapperRef}>
      <Wrapper
        className={dropdownActive ? `${styles.DropdownList}  ${styles.DropdownListActive}` : `${styles.DropdownList}`}
        onClick={() => {
          setDropdownActive(!dropdownActive);
        }}
        alignItems="center"

        //sx={sx}
      >
        <Box sx={{ flex: 1 }}>
          <Heading size="xs">{title}</Heading>
        </Box>
        <DropdownArrowIcon stroke="white" />
      </Wrapper>
      <div className={styles.OptionContainer} style={dropdownActive ? { display: 'block' } : { display: 'none' }}>
        {children}
      </div>
    </div>
  );
};
export default DropdownList;
