import { RunReportPayload } from 'api/analytics/types';
import { getReportAnalytics } from 'api/analytics';
import { Website } from 'api/users/types';

export const fetchInternalReferral = async (
  formName: string,
  website: Website,
  startDate: string,
  endDate: string,
  cancelToken: any
) => {
  //const { propertyId, scope, titleData } = useAnalyticsStore();
  cancelToken.pages = new AbortController();
  const signal = cancelToken.pages.signal;
  //const isCurrent = scope === 'current';
  let payloadUsersPerSource: RunReportPayload = {
    propertyId: website.propertyId,
    //title: isCurrent ? titleData.seoTitle : undefined,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'pageReferrer' }],
    metrics: [{ name: 'activeUsers' }],
    dimensionFilter: {
      filter: {
        fieldName: 'eventName',
        stringFilter: {
          matchType: 'EXACT',
          value: `${formName?.replace('dg-submit', 'dg-form-view').slice(0, 40)}`
        }
      }
    }
  };

  // Payload to get the event count for form submissions from the referrer
  let payloadConversionsPerSource: RunReportPayload = {
    propertyId: website.propertyId,
    //title: isCurrent ? titleData.seoTitle : undefined,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'pageReferrer' }],
    metrics: [{ name: 'eventCount' }],
    dimensionFilter: {
      filter: {
        fieldName: 'eventName',
        stringFilter: {
          matchType: 'EXACT',
          value: `${formName?.slice(0, 40)}`
        }
      }
    }
  };

  try {
    // Fetch data for active users per source
    const usersPromise = getReportAnalytics(payloadUsersPerSource, signal);

    // Fetch data for conversions per source

    const conversionsData = await getReportAnalytics(payloadConversionsPerSource, signal);
    const usersData = await usersPromise;

    // Process and combine data
    let topInternalReferralData = usersData?.map((userItem) => {
      const source = userItem.dimensionValues?.[0]?.value || 'None';
      const activeUsers = parseFloat(userItem.metricValues?.[0]?.value) || 0;

      // Find the corresponding conversions data
      const conversionsItem = conversionsData.find(
        (conversionItem) => conversionItem.dimensionValues?.[0]?.value === source
      );
      const conversions = conversionsItem ? parseFloat(conversionsItem.metricValues?.[0]?.value) || 0 : 0;

      // Calculate conversion rate
      const conversionRate = activeUsers > 0 ? ((conversions / activeUsers) * 100).toFixed(0) + '%' : '0%';

      //if (source.includes(domain))
      return {
        sourceMedia: extractPath(source),
        activeUsers,
        conversions,
        conversionRate
      };
      //else
      //return
    });
    topInternalReferralData = topInternalReferralData.filter(Boolean);

    // Sort by conversion rate in descending order and take the top 5
    const sortedTopInternalReferrals = topInternalReferralData
      .sort((a, b) => parseFloat(b!.conversionRate!.replace('%', '')) - parseFloat(a!.conversionRate!.replace('%', '')))
      .slice(0, 5);

    return new Promise((resolve) => {
      resolve(sortedTopInternalReferrals);
    });
  } catch (e) {
    return new Promise((reject) => reject(undefined));
    //throw(e)
  }
};

function extractPath(url: string) {
  if (!url || url === 'None') return 'None';
  try {
    const urlObj = new URL(url);
    return urlObj.pathname === '/' ? '/home' : urlObj.pathname;
  } catch (e) {
    console.error(e);
    return 'None';
  }
}

function truncateURL(url: string, maxLength = 15) {
  if (url === 'None' || url.length <= maxLength) {
    return url;
  }
  return url;
}
