import React, { FC } from 'react';
import styles from './Overlay.module.scss';
import Container from 'components/ui/Container/Container';

interface OverlayProps {
  children?: React.ReactNode;
}

const Overlay: FC<OverlayProps> = ({ children }) => (
  <Container
    gap={12}
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    sx={{ width: '100%', height: '100%' }}
    className={styles.Overlay}
  >
    {children}
  </Container>
);

export default Overlay;
