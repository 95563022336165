import { PieChart, Pie, Cell, Tooltip } from 'recharts';

import { ChartPayloadProps } from 'api/previewLink/types';

import { useAnalyticsData } from 'hooks/useAnalyticsData';
import dayjs from 'dayjs';
import { Website } from 'api/users/types';
import { FC, useEffect, useState } from 'react';
import { getPayloadDeivce } from './payloads/payloads';
import { getReportAnalyticsV2 } from 'api/analytics';

const COLORS = [
  { key: 'desktop', color: '#134c54' },
  { key: 'tablet', color: '#c0e666' },
  { key: 'mobile', color: '#dbf0a3' }
];

interface SessionChart {
  website?: Website;
  shortName?: string;
  propertyId: string;
  startDate: string;
  endDate: string;
}

export const DeviceChart: FC<SessionChart> = ({ propertyId, startDate, endDate }) => {
  const [active, setActive] = useState(0);
  const [deviceData, setDeviceData] = useState<any>(null);

  //getReportAnalyticsV2(payload)
  const fetchV = async () => {
    const payloadDevice = getPayloadDeivce(propertyId, startDate, endDate);
    const deviceData = await getReportAnalyticsV2(payloadDevice);
    const formatedDeviceData = deviceData.map((data: any) => {
      return { deviceCategory: data.deviceCategory, activeUsers: parseInt(data.activeUsers) };
    });
    setDeviceData(formatedDeviceData);
  };

  useEffect(() => {
    fetchV();
  }, [startDate, propertyId]);

  const data02 = [
    {
      name: 'Group A',
      value: 2400
    },
    {
      name: 'Group B',
      value: 4567
    },
    {
      name: 'Group C',
      value: 1398
    },
    {
      name: 'Group D',
      value: 9800
    },
    {
      name: 'Group E',
      value: 3908
    },
    {
      name: 'Group F',
      value: 4800
    }
  ];
  return (
    <div className="deviceChart">
      <div className="chartPaper device">
        <div className="left">
          <h2 className="typo-heading-s">Device</h2>
          <div className="sizes">
            {deviceData
              ?.sort((a: any, b: any) => b.activeUsers - a.activeUsers)
              .map((item: any, index: number) => (
                <div className="item" key={index}>
                  <div
                    className="box"
                    style={{ background: COLORS.find((el) => el.key === item.deviceCategory)?.color }}
                  />
                  <p className="typo-label-s">
                    <span> {item.deviceCategory}</span> <span> - </span>{' '}
                    <span>
                      {
                        deviceData.find(({ deviceCategory }: any) => deviceCategory === item.deviceCategory)
                          ?.activeUsers
                      }
                    </span>
                  </p>
                </div>
              ))}
          </div>
        </div>
        <div className="chart">
          {
            <PieChart width={144} height={144}>
              <Pie
                data={deviceData}
                cx={68}
                cy={67}
                innerRadius={50}
                outerRadius={70}
                fill="#8884d8"
                dataKey="activeUsers"
                nameKey="deviceCategory"
                stroke="none"
                paddingAngle={-1}
              >
                {deviceData?.map((item: any, index: any) => (
                  <Cell key={`cell-${index}`} fill={COLORS.find(({ key }) => key === item.deviceCategory)?.color} />
                ))}
              </Pie>
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="device-chart-tooltip">
                        <p className="typo-body-xxs">
                          {dayjs().subtract(7, 'day').format('MMM DD')} -{' '}
                          {dayjs().subtract(1, 'day').format('MMM DD, YYYY')}
                        </p>
                        <div className="row">
                          <span className="typo-body-xs">Users</span>
                          <span className="typo-label-m">{payload[0].value}</span>
                        </div>
                      </div>
                    );
                  }

                  return null;
                }}
              />
            </PieChart>
          }
        </div>
      </div>
    </div>
  );
};
