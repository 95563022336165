import React, { FC, useState } from 'react';
import { resendCode, verifyEmailCode } from 'api/auth';
import { useAuthStore } from 'store/auth';
import { Navigate, useNavigate } from 'react-router-dom';
import styles from './VerifyEmailContent.module.scss';
import Heading from 'components/ui/Heading/Heading';
import Text from 'components/ui/Text/Text';
import Label from 'components/ui/Label/Label';
import Button from 'components/ui/Button/Button';
import Box from 'components/ui/Box/Box';
import Input from 'components/ui/Input/Input';
import Link from 'components/ui/Link/Link';

interface VerifyEmailContentProps {
  userVerified: () => void;
}

const VerifyEmailContent: FC<VerifyEmailContentProps> = ({ userVerified }) => {
  const { user } = useAuthStore();
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const { setUser, setIsLogged } = useAuthStore();
  const resendVerifyCode = () => {
    if (user && user?.email) resendCode(user?.email);
  };
  const checkInputSize = (event: any) => {
    const newCode = event.target.value.slice(0, 5);
    setCode(newCode);
  };

  const logout = () => {
    localStorage.clear();
    setIsLogged(false);
    setUser(null);
    navigate('/sign-up');
  };

  const checkInput = async () => {
    try {
      if (user && user?.email) {
        await verifyEmailCode({ code, email: user.email });
        userVerified();
        setIsLogged(true);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <Box gap={48} className={styles.VerifyEmailContent}>
      <Box gap={32}>
        <Heading size="xl">Verify your e-mail!</Heading>
        <Text size="m" color="primary-100">
          You’re almost there! We sent a verification code to {user?.email} Please enter the code below to confirm your
          email address.
        </Text>
        <Box gap={16} className={styles.form}>
          <Box gap={8} className={styles.inputWrapper}>
            <Label size="s" color="normal">
              Enter 5 digits code
            </Label>
            <Input
              props={{
                type: 'number',
                name: 'code',
                value: code,
                onChange: checkInputSize,
                style: { height: '50px', boxSizing: 'border-box' }
              }}
            />
          </Box>
          <Button className={styles.button} color="primary-400" disabled={code.length < 5} handleClick={checkInput}>
            Submit
          </Button>
        </Box>
      </Box>
      <Box gap={12}>
        <Text size="s" color="primary-100">
          Can’t find the email?
        </Text>
        <Button className={styles.button} handleClick={resendVerifyCode} type="outlinedPrimary" color="outline">
          Resend verification email
        </Button>
      </Box>
      <Box
        gap={8}
        justifyContent='start'
        flexDirection='row'
        alignItems='center'
      >
        <Text size="m" color="primary-100">
          Want to sign up with another email?
        </Text>
        <Link className='Highlighted' onClick={logout}>
          Back to Sign Up
        </Link>
      </Box>
    </Box>
  );
};

export default VerifyEmailContent;

