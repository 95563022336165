import React, { FC, useEffect, useState } from 'react';
import styles from './MainContent.module.scss';
import { Websites } from 'components/layout/home/websites/Websites';
import Container from 'components/ui/Container/Container';
import Box from 'components/ui/Box/Box';
import { DeviceChart } from 'components/layout/home/charts/DeviceChart';
import { compareArrays, findObjectByCustomDomain } from 'utils';
import { orderBy } from 'lodash';
import { useModal } from 'hooks/useModal';
import { useQuery } from 'react-query';
import { useAuthStore } from 'store/auth';
import { getSites } from 'api/webflow';
import { WebsitePanel } from 'components/layout/home/websites/WebsitePanel';
import { NewVisitorsChart } from 'components/layout/home/charts/NewVisitorsChart';
import { SessionsChart } from 'components/layout/home/charts/SessionChart';
import { useAnalyticsStore } from 'store/AnalyticsStore';
import FormSubmissions from 'features/FormSubmissions/FormSubmissions';
import { CardList } from 'components/shared/CardList';
import Card from 'components/ui/Card/Card';
import Table from 'components/ui/SubmissionsTable/Table';
import { getReportAnalyticsV2 } from 'api/analytics';
import {
  getPayloadCountry,
  getPayloadLinkClick,
  getPayloadMedium,
  getPayloadOutbound,
  getPayloadPageRanking,
  getPayloadWebsiteChannel
} from './payload/payload';
import AnalyticsTableCard from 'components/ui/AnalyticsTableCard/AnalyticsTableCard';
import WebsiteChannelChart from './WebsiteChannelChart/WebsiteChannelChart';
import Heading from 'components/ui/Heading/Heading';
import Label from 'components/ui/Label/Label';
import Toggle from 'components/ui/Toggle/Toggle';
import AnalyticsTableToggleCard from 'components/ui/AnalyticsTableToggleCard/AnalyticsTableToggleCard';
import OverlayComingSoon from 'components/Overlay/ComingSoon/ComingSoon';
import { Outlet, useLocation } from 'react-router-dom';
import Menu from 'components/layout/Menu/Menu';

interface MainContentProps {}

export type SourceMetric = 'totalUsers' | 'sessions';

const MainContent: FC<MainContentProps> = ({}) => {
  const { website, startDate, endDate } = useAnalyticsStore();
  const [mediumData, setMediumData] = useState<any>({ label: 'Users', value: [''] });
  const [mediumToggleOptions, setMediumToggleOptions] = useState<any>([
    { label: 'Users', value: [''] },
    { label: 'Sessions', value: [''] }
  ]);

  const [outboundData, setOutboundData] = useState<any>();
  const [linkData, setLinkData] = useState<any>();
  const [countryData, setCountryData] = useState<any>();
  const [pageRankingData, setPageRanking] = useState<any>();
  const [websiteChannelData, setWebsiteChannelData] = useState<any>({ label: 'Users', value: [''] });
  const [websiteChannelToggleOptions, setWebsiteChannelToggleOptions] = useState<any>([
    { label: 'Users', value: [''] },
    { label: 'Sessions', value: [''] }
  ]);
  const [render, setRender] = useState(false);
  const location = useLocation();
  /*const resultArr = compareArrays(
    sites || [],
    user?.webflow?.websites?.map((item) => ({ ...item, url: item.websiteUrl })) || []
  );
    
  const sorted = orderBy(resultArr, [
    (obj) => -(obj.isGaConnected && obj.isWfAuthorized),
    (obj) => -(obj.isGaConnected && !obj.isWfAuthorized),
    (obj) => -obj.isGaConnected,
    (obj) => -obj.isWfAuthorized
  ]);*/

  const getAnalytics = async () => {
    const payloadLinkClick = getPayloadLinkClick(website?.propertyId, startDate, endDate);
    const payloadOutbound = getPayloadOutbound(website?.propertyId, startDate, endDate);
    const payloadMedium = getPayloadMedium(website?.propertyId, startDate, endDate);
    const payloadCountry = getPayloadCountry(website?.propertyId, startDate, endDate);
    const payloadPageRanking = getPayloadPageRanking(website?.propertyId, startDate, endDate);
    const payloadWebsiteChannel = getPayloadWebsiteChannel(website?.propertyId, startDate, endDate);
    const analyticsData: any = {};
    const analyticsPromise: Record<string, any> = {
      linkData: getReportAnalyticsV2(payloadLinkClick),
      outboundData: getReportAnalyticsV2(payloadOutbound),
      mediumData: getReportAnalyticsV2(payloadMedium),
      countryData: getReportAnalyticsV2(payloadCountry),
      pageRankingData: getReportAnalyticsV2(payloadPageRanking),
      websiteChannelData: getReportAnalyticsV2(payloadWebsiteChannel)
    };
    for (let key in analyticsPromise) {
      analyticsData[key] = await analyticsPromise[key];
    }
    analyticsData.linkData = analyticsData.linkData.map((linkData: any) => {
      return {
        ...linkData,
        eventName: linkData.eventName.replace('dg-click_', '')
      };
    });

    analyticsData.mediumToggleOptions = [
      {
        label: 'Users',
        value: analyticsData.mediumData.map((mediumData: any) => {
          return {
            firstUserSource: mediumData.firstUserSource,
            totalUsers: mediumData.totalUsers
          };
        })
      },
      {
        label: 'Sessions',
        value: analyticsData.mediumData.map((mediumData: any) => {
          return {
            firstUserSource: mediumData.firstUserSource,
            sessions: mediumData.sessions
          };
        })
      }
    ];

    analyticsData.websiteChannelToggleOptions = [
      {
        label: 'Users',
        value: analyticsData.websiteChannelData.map((websiteChannelData: any) => {
          return {
            firstUserDefaultChannelGroup: websiteChannelData.firstUserDefaultChannelGroup,
            totalUsers: websiteChannelData.totalUsers
          };
        })
      },
      {
        label: 'Sessions',
        value: analyticsData.websiteChannelData.map((websiteChannelData: any) => {
          return {
            firstUserDefaultChannelGroup: websiteChannelData.firstUserDefaultChannelGroup,
            sessions: websiteChannelData.sessions
          };
        })
      }
    ];

    const { linkData, outboundData, mediumToggleOptions, countryData, pageRankingData, websiteChannelToggleOptions } =
      analyticsData;
    setLinkData(linkData);
    setOutboundData(outboundData.slice(1, outboundData.length));
    setMediumData(mediumToggleOptions[0]);
    setMediumToggleOptions(mediumToggleOptions);
    setCountryData(countryData);
    setPageRanking(pageRankingData);
    setWebsiteChannelData(websiteChannelToggleOptions[0]);
    setWebsiteChannelToggleOptions(websiteChannelToggleOptions);
  };
  useEffect(() => {
    setRender(!render);
    getAnalytics();
  }, [website, startDate]);
  return (
    <>
      <Menu />
      <Container gap={16} padding="24px" className={styles.MainContent} flexDirection="column" sx={{ width: '100%' }}>
        {/* <OverlayComingSoon/> */}
        <Box>
          <FormSubmissions website={website} shortName={'asd'} startDate={startDate} endDate={endDate} />
        </Box>
        {/* <div className="charts"> */}

        <Box flexDirection="row" gap={16}>
          <Box sx={{ flex: 2 }}>
            <NewVisitorsChart propertyId={website?.propertyId} startDate={startDate} endDate={endDate} />
          </Box>
          <Box sx={{ flex: 1, height: '100%' }} gap={16}>
            <DeviceChart propertyId={website?.propertyId} startDate={startDate} endDate={endDate} />
            <SessionsChart propertyId={website?.propertyId} startDate={startDate} endDate={endDate} />
          </Box>
        </Box>

        <Container flexDirection="row" gap={16} sx={{ flexWrap: 'wrap' }}>
          <AnalyticsTableCard
            heading="Link Clicks"
            tableHeaders={['Link', 'Clicks', 'Users']}
            tableData={linkData}
            rightColumnMinWidth={60}
          />
          <AnalyticsTableCard
            heading="Outbound"
            tableHeaders={['Link', 'Users']}
            tableData={outboundData}
            rightColumnMinWidth={60}
          />
          <AnalyticsTableToggleCard
            heading="Mediums"
            tableHeaders={['source/mediums', mediumData.label]}
            tableData={mediumData.value}
            rightColumnMinWidth={60}
            toggleSelect={mediumToggleOptions}
            handleToggleClick={(option: any) => {
              setMediumData(option);
            }}
          />
        </Container>
        <Container flexDirection="row" gap={16}>
          <AnalyticsTableCard
            heading="Countries"
            tableHeaders={['Link', 'Users']}
            tableData={countryData}
            rightColumnMinWidth={60}
          />
          <AnalyticsTableCard
            heading="Page Ranking"
            tableHeaders={['Link', 'Users']}
            tableData={pageRankingData}
            rightColumnMinWidth={60}
          />
        </Container>
        <Card>
          <Box justifyContent="space-between" flexDirection="row">
            <Heading size="s">Website Channels</Heading>
            <Toggle
              toggleSelect={websiteChannelToggleOptions}
              handleToggleClick={(option: any) => {
                setWebsiteChannelData(option);
              }}
            />
          </Box>
          <Label color="fade">Where do new users come from?</Label>
          <WebsiteChannelChart chartData={websiteChannelData.value} activeOption={websiteChannelData.label} />
        </Card>
      </Container>
    </>
  );
};

export default MainContent;
