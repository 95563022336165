import { RunReportPayload } from 'api/analytics/types';

export const getPayloadVisitors = (propertyId: string, startDate: string, endDate: string): RunReportPayload => {
  return {
    propertyId: `${propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [],
    metrics: [{ name: 'totalUsers' }, { name: 'newUsers' }]
  };
};

export const getPayloadChartVisitors = (
  propertyId: string,
  startDate: string,
  endDate: string,
  datePayload: { name: string }
): RunReportPayload => {
  return {
    propertyId: `${propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [datePayload],
    metrics: [{ name: 'totalUsers' }, { name: 'newUsers' }]
  };
};

export const getPayloadSession = (propertyId: string, startDate: string, endDate: string): RunReportPayload => {
  return {
    propertyId: `${propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [],
    metrics: [{ name: 'averageSessionDuration' }, { name: 'engagementRate' }]
  };
};

export const getPayloadDeivce = (propertyId: string, startDate: string, endDate: string): RunReportPayload => {
  return {
    propertyId: `${propertyId}`,
    dateRanges: [{ startDate: startDate, endDate: endDate }],
    dimensions: [{ name: 'deviceCategory' }],
    metrics: [{ name: 'activeUsers' }]
  };
};
// payload={{
//   key: ['device', website.propertyId],
//   dimensions: ['deviceCategory'],
//   metrics: ['activeUsers'],
//   propertyId: website.propertyId
// }}
// payload={{
//   key: ['newVisitors', website.propertyId],
//   metrics: ['totalUsers', 'newUsers'],
//   dimensions: [],
//   propertyId: website.propertyId
// }}

// payload={{
//   key: ['device', website.propertyId],
//   dimensions: ['deviceCategory'],
//   metrics: ['activeUsers'],
//   propertyId: website.propertyId
// }}
