const GoogleLogoSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_3243_2310)">
      <path
        fill="#4285F4"
        d="M23.715 12.225c0-.984-.08-1.701-.253-2.445H12.234v4.438h6.59c-.132 1.103-.85 2.764-2.444 3.88l-.022.148 3.55 2.75.246.025c2.259-2.086 3.56-5.156 3.56-8.796z"
      ></path>
      <path
        fill="#34A853"
        d="M12.234 23.917c3.229 0 5.94-1.063 7.92-2.896l-3.774-2.924c-1.01.705-2.365 1.196-4.146 1.196-3.162 0-5.846-2.086-6.803-4.97l-.14.013-3.692 2.856-.048.135c1.966 3.906 6.006 6.59 10.683 6.59z"
      ></path>
      <path
        fill="#FBBC05"
        d="M5.43 14.324a7.362 7.362 0 01-.398-2.365c0-.824.146-1.621.386-2.366l-.007-.158-3.738-2.903-.122.058A11.97 11.97 0 00.275 11.96c0 1.926.465 3.747 1.276 5.368l3.88-3.003z"
      ></path>
      <path
        fill="#EB4335"
        d="M12.234 4.624c2.245 0 3.76.97 4.624 1.78l3.375-3.295C18.16 1.183 15.463 0 12.234 0 7.557 0 3.517 2.684 1.551 6.59l3.866 3.004c.97-2.884 3.655-4.97 6.817-4.97z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_3243_2310">
        <path fill="#fff" d="M0 0H23.45V24H0z" transform="translate(.275)"></path>
      </clipPath>
    </defs>
  </svg>
);

export default GoogleLogoSVG;
