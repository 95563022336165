import React, { Dispatch, FC, SetStateAction } from 'react';
import styles from './TableRow.module.scss';

interface TableRowProps {
  rowData: string[];
  firstColumnMinWidth?: number;
  otherColumnMinWidth?: number;
}

const TableRow: FC<TableRowProps> = ({ rowData, firstColumnMinWidth, otherColumnMinWidth }) => {
  return (
    <div className={`${styles.TableRow}`}>
      {rowData?.map((value, columnIndex) =>
        columnIndex === 0 ? (
          <div
            className={styles.dataMetrics}
            key={columnIndex}
            style={{ textAlign: 'left', minWidth: firstColumnMinWidth, flex: 1 }}
          >
            <span>{value}</span>
          </div>
        ) : (
          <div className={styles.dataMetrics} key={columnIndex} style={{ minWidth: otherColumnMinWidth }}>
            <span>{value}</span>
          </div>
        )
      )}
    </div>
  );
};

export default TableRow;
