import React, { FC } from 'react';
import styles from './WebflowAuthorization.module.scss';
import { DropdownArrowIcon, WDGSIcon } from 'assets/icons/export';
import Box from 'components/ui/Box/Box';
import Heading from 'components/ui/Heading/Heading';
import Text from 'components/ui/Text/Text';
import Button from 'components/ui/Button/Button';
import { useQuery } from 'react-query';
import { wfGetInstallUrl } from 'api/webflow';
import { useAuthStore } from 'store/auth';
import { removeGoogleToken } from 'api/auth';
import Label from 'components/ui/Label/Label';

interface WebflowAuthorizationProps {}

const WebflowAuthorization: FC<WebflowAuthorizationProps> = () => {
  const { data: webflowInstallUrl } = useQuery(['wf-auth-url'], wfGetInstallUrl);
  const { setGoogleAuthorized } = useAuthStore();

  return (
    <Box gap={48} className={styles.WebflowAuthorization}>
      <Box
        flexDirection="row"
        sx={{ cursor: 'pointer' }}
        onClick={async () => {
          await removeGoogleToken();
          setGoogleAuthorized(false);
        }}
        alignItems="center"
      >
        <DropdownArrowIcon stroke="white" style={{ transform: 'rotate(90deg)' }} />
        <Label size="m" color="white">
          Back
        </Label>
      </Box>
      <WDGSIcon className="logo" />
      <Box gap={16}>
        <Heading size="xl">Connect your Webflow project</Heading>
        <Text size="m">
          Authorize all Webflow projects that you want to connect to Data Goat and start tracking analytics.
        </Text>
      </Box>
      <Box flexDirection="row" gap={16}>
        <Button
          className={styles.button}
          padding="big"
          handleClick={() => {
            window.open(webflowInstallUrl);
          }}
        >
          Connect to Webflow
        </Button>
      </Box>
    </Box>
  );
};

export default WebflowAuthorization;
