import * as React from 'react';
import type { SVGProps } from 'react';

const SvgCrossErrorFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" {...props}>
    <circle cx={6} cy={6} r={6} fill="#FF7575" />
    <path
      fill="#052025"
      fillRule="evenodd"
      d="M6.042 6.888 7.723 8.57a.476.476 0 0 0 .673-.673L6.714 6.215l1.682-1.682a.476.476 0 0 0-.673-.673L6.042 5.542 4.36 3.86a.476.476 0 1 0-.673.673l1.682 1.682-1.682 1.682a.475.475 0 1 0 .673.672z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCrossErrorFilled;
