import { useAuthStore } from 'store/auth';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';
import { resumeSubscription } from 'api/orders';
import { orderKeys } from 'api/orders/keys';
import { useOrderStore } from 'store/order';
import { queryClient } from '../../..';
import { useState } from 'react';
import { CancleSubscriptionModal } from './cancle-subscription-modal/CancleSubscriptionModal';

export const PlanInfo = () => {
  const { user } = useAuthStore();
  const { subscription } = useOrderStore();

  const { mutate: resume } = useMutation(resumeSubscription);

  const [subscriptionId, setSubscriptionId] = useState('');

  const cancelAt = subscription?.cancel_at ? dayjs(subscription?.cancel_at * 1000).format('MMMM D, YYYY h:mm A') : null;

  const onSuccess = () => queryClient.refetchQueries(orderKeys.subscription);
  return (
    <div className="planInfo grid-col-2">
      <p className="borderTopTitle">Account info</p>
      <div>
        <p className="heading">Current plan</p>
        <p className="price">{user?.tier.tier}</p>

        <p className="renew">
          {cancelAt
            ? `Your subscription ends on ${cancelAt}`
            : user?.subscription.status === 'trialing'
              ? `Your free trial expires on ${dayjs(user?.subscription.currentPeriodEnd).format('MMMM D, YYYY h:mm A')}`
              : `Your plan renews on ${dayjs(user?.subscription.currentPeriodEnd).format('MMMM D, YYYY h:mm A')}`}
        </p>

        <div className="buttons">
          {/* <div>
            <button className="outlined">Upgrade to yearly</button>
            <p className="note">Save 20% with yearly billing!</p>
          </div> */}
          {/* {cancelAt ? (To je to 
            <button
              className="primary"
              onClick={() => resume(subscription?.id || '', { onSuccess })}
              disabled={!subscription}
            >
              Resume Subscription
            </button>
          ) : (
            <button
              className="outlined danger"
              onClick={() => setSubscriptionId(subscription?.id || '')}
              disabled={!subscription}
            >
              Cancel subscription
            </button>
          )} */}
        </div>
      </div>

      {subscriptionId && (
        <CancleSubscriptionModal id={subscriptionId} close={() => setSubscriptionId('')} className="cancleSub" />
      )}
    </div>
  );
};
